import React from 'react';

import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './privateRoute';
import store from '../store/store';

import Page404 from '../pages/page404';
import Login from '../pages/login';
import Home from '../pages/home';
import CadastroUsuario from '../pages/administrador/usuario/cadastroUsuario';
import CadastroUsuarioLogin from '../pages/cadastroUsuarioLogin';
import NovaSenha from '../pages/novasenha';
import ConfirmacaoAlteracaoSenha from '../pages/confirmacaoalteracaosenha';

import HomeAdm from '../pages/administrador/homeadm';
import ConsultaUsuario from '../pages/administrador/usuario/consultaUsuario';
import ConsultaRamoNegocio from '../pages/administrador/ramoNegocio/consultaRamoNegocio';
import CadastroRamoNegocio from '../pages/administrador/ramoNegocio/cadastroRamoNegocio';
import ConsultaCotacaoAdministrador from '../pages/administrador/cotacao/consultaCotacaoAdministrador';
import ConsultaPedidoAdministrador from '../pages/administrador/pedido/consultaPedidoAdministrador';
import ConsultaFinanceiro from '../pages/administrador/financeiro/consultaFinanceiro';
import CadastroFinanceiro from '../pages/administrador/financeiro/cadastroFinanceiro';
import GerarFinanceiro from '../pages/administrador/financeiro/gerarFinanceiro';
import AlterarSenha from '../pages/administrador/dadospessoais/alterarSenha';
import AlterarDados from '../pages/administrador/dadospessoais/alterarDados';
import AlterarReceberEmails from '../pages/administrador/dadospessoais/alterarReceberEmails';
import ConsultaConfiguracao from '../pages/administrador/configuracao/consultaConfiguracao';
import CadastroConfiguracao from '../pages/administrador/configuracao/cadastroConfiguracao';
import ConsultaBilhete from '../pages/administrador/bilhete/consultaBilhete';
import CadastroBilhete from '../pages/administrador/bilhete/cadastroBilhete';
import ConsultaAviso from '../pages/administrador/aviso/consultaAviso';
import CadastroAviso from '../pages/administrador/aviso/cadastroAviso';
import RelatorioFinanceiro from '../pages/administrador/relatorio/relatorioFinanceiro';

import HomeComprador from '../pages/comprador/homecomprador';
import ConsultaProduto from '../pages/comprador/produto/consultaProduto';
import CadastroProduto from '../pages/comprador/produto/cadastroProduto';
import ConsultaCotacao from '../pages/comprador/cotacao/consultaCotacao';
import ConsultaProdutoPedido from '../pages/comprador/produto/consultaProdutoPedido';
import CadastroCotacao from '../pages/comprador/cotacao/cadastroCotacao';
import CadastroCotacaoRespondendo from '../pages/comprador/cotacao/cadastroCotacaoRespondendo';
import ConsultaPedido from '../pages/comprador/pedido/consultaPedido';
import CadastroPedido from '../pages/comprador/pedido/cadastroPedido';
import ConsultaVendedor from '../pages/comprador/vendedor/consultaVendedor';
import ConvidarVendedor from '../pages/comprador/vendedor/convidarVendedor';
import RelatorioPedido from '../pages/comprador/relatorio/relatorioPedido';
import RelatorioPedidoAnalitico from '../pages/comprador/relatorio/relatorioPedidoAnalitico';
import FinanceiroComprador from '../pages/comprador/financeiro/financeiroComprador';
import RelatorioFinanceiroComprador from '../pages/comprador/relatorio/relatorioFinanceiroComprador';

import HomeVendedor from '../pages/vendedor/homevendedor';
import ConsultaComprador from '../pages/vendedor/comprador/consultaComprador';
import ConvidarComprador from '../pages/vendedor/comprador/convidarComprador';
import ConsultaCotacaoVendedor from '../pages/vendedor/cotacao/consultaCotacaoVendedor';
import CadastroCotacaoVendedor from '../pages/vendedor/cotacao/cadastroCotacaoVendedor';
import ConsultaPedidoVendedor from '../pages/vendedor/pedido/consultaPedidoVendedor';
import CadastroPedidoVendedor from '../pages/vendedor/pedido/cadastroPedidoVendedor';
import RelatorioPedidoVendedor from '../pages/vendedor/relatorio/relatorioPedidoVendedor';
import FinanceiroVendedor from '../pages/vendedor/financeiro/financeiroVendedor';
import RelatorioFinanceiroVendedor from '../pages/vendedor/relatorio/relatorioFinanceiroVendedor';

export default function Rotas() {
  let licenca = false;

  const { dataLicenca } = store.getState().auth.user;
  const data = new Date();
  const dataAtual = data.getFullYear()+('00' + (data.getMonth() + 1)).substr(-2)+('00' + data.getDate()).substr(-2);
  let dataLicencaAtual = '';
  if (dataLicenca) {
    dataLicencaAtual = dataLicenca[0]+ ''+('00' + dataLicenca[1]).slice(-2)+ ''+('00' + dataLicenca[2]).slice(-2);
  } else {
    dataLicencaAtual = dataAtual;
  }
  if (dataAtual <= dataLicencaAtual) {
    licenca = true;
  }
  return (
    <Routes>
      <Route path="/home" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/cadastrousuariologin" element={<CadastroUsuarioLogin />} />
      <Route path="/novasenha" element={<NovaSenha />} />
      <Route path="/confirmacaoalteracaosenha/:id" element={<ConfirmacaoAlteracaoSenha />} />

      <Route path="/homeadm" element={<PrivateRoute tipoUsuario="ADMINISTRADOR" licenca="true"><HomeAdm /></PrivateRoute>} />
      <Route path="/cadastrousuario" element={<PrivateRoute tipoUsuario="ADMINISTRADOR" licenca="true"><CadastroUsuario /></PrivateRoute>} />
      <Route path="/cadastrousuario/:id" element={<PrivateRoute tipoUsuario="ADMINISTRADOR" licenca="true"><CadastroUsuario /></PrivateRoute>} />
      <Route path="/consultausuario" element={<PrivateRoute tipoUsuario="ADMINISTRADOR" licenca="true"><ConsultaUsuario /></PrivateRoute>} />
      <Route path="/consultacotacaoadministrador" element={<PrivateRoute tipoUsuario="ADMINISTRADOR" licenca="true"><ConsultaCotacaoAdministrador /></PrivateRoute>} />
      <Route path="/consultapedidoadministrador" element={<PrivateRoute tipoUsuario="ADMINISTRADOR" licenca="true"><ConsultaPedidoAdministrador /></PrivateRoute>} />
      <Route path="/cadastroramonegocio" element={<PrivateRoute tipoUsuario="ADMINISTRADOR" licenca="true"><CadastroRamoNegocio /></PrivateRoute>} />
      <Route path="/cadastroramonegocio/:id" element={<PrivateRoute tipoUsuario="ADMINISTRADOR" licenca="true"><CadastroRamoNegocio /></PrivateRoute>} />
      <Route path="/consultaramonegocio" element={<PrivateRoute tipoUsuario="ADMINISTRADOR" licenca="true"><ConsultaRamoNegocio /></PrivateRoute>} />
      <Route path="/consultafinanceiro" element={<PrivateRoute tipoUsuario="ADMINISTRADOR" licenca="true"><ConsultaFinanceiro /></PrivateRoute>} />
      <Route path="/cadastrofinanceiro" element={<PrivateRoute tipoUsuario="ADMINISTRADOR" licenca="true"><CadastroFinanceiro /></PrivateRoute>} />
      <Route path="/cadastrofinanceiro/:id" element={<PrivateRoute tipoUsuario="ADMINISTRADOR" licenca="true"><CadastroFinanceiro /></PrivateRoute>} />
      <Route path="/gerarfinanceiro" element={<PrivateRoute tipoUsuario="ADMINISTRADOR" licenca="true"><GerarFinanceiro /></PrivateRoute>} />
      <Route path="/consultaconfiguracao" element={<PrivateRoute tipoUsuario="ADMINISTRADOR" licenca="true"><ConsultaConfiguracao /></PrivateRoute>} />
      <Route path="/cadastroconfiguracao" element={<PrivateRoute tipoUsuario="ADMINISTRADOR" licenca="true"><CadastroConfiguracao /></PrivateRoute>} />
      <Route path="/cadastroconfiguracao/:id" element={<PrivateRoute tipoUsuario="ADMINISTRADOR" licenca="true"><CadastroConfiguracao /></PrivateRoute>} />
      <Route path="/alterarsenhaadministrador" element={<PrivateRoute tipoUsuario="ADMINISTRADOR" licenca="true"><AlterarSenha /></PrivateRoute>} />
      <Route path="/consultabilheteadministrador" element={<PrivateRoute tipoUsuario="ADMINISTRADOR" licenca="true"><ConsultaBilhete /></PrivateRoute>} />
      <Route path="/cadastrobilheteadministrador" element={<PrivateRoute tipoUsuario="ADMINISTRADOR" licenca="true"><CadastroBilhete /></PrivateRoute>} />
      <Route path="/cadastrobilheteadministrador/:id" element={<PrivateRoute tipoUsuario="ADMINISTRADOR" licenca="true"><CadastroBilhete /></PrivateRoute>} />
      <Route path="/consultaAviso" element={<PrivateRoute tipoUsuario="ADMINISTRADOR" licenca="true"><ConsultaAviso /></PrivateRoute>} />
      <Route path="/cadastroAviso" element={<PrivateRoute tipoUsuario="ADMINISTRADOR" licenca="true"><CadastroAviso /></PrivateRoute>} />
      <Route path="/cadastroAviso/:id" element={<PrivateRoute tipoUsuario="ADMINISTRADOR" licenca="true"><CadastroAviso /></PrivateRoute>} />
      <Route path="/relatorioFinanceiro" element={<PrivateRoute tipoUsuario="ADMINISTRADOR" licenca="true"><RelatorioFinanceiro /></PrivateRoute>} />

      <Route path="/homecomprador" element={<PrivateRoute tipoUsuario="COMPRADOR" licenca="true"><HomeComprador /></PrivateRoute>} />
      <Route path="/consultabilhete" element={<PrivateRoute tipoUsuario="COMPRADOR" licenca={licenca}><ConsultaBilhete /></PrivateRoute>} />
      <Route path="/cadastroproduto" element={<PrivateRoute tipoUsuario="COMPRADOR" licenca={licenca}><CadastroProduto /></PrivateRoute>} />
      <Route path="/cadastroproduto/:id" element={<PrivateRoute tipoUsuario="COMPRADOR" licenca={licenca}><CadastroProduto /></PrivateRoute>} />
      <Route path="/consultaproduto" element={<PrivateRoute tipoUsuario="COMPRADOR" licenca={licenca}><ConsultaProduto /></PrivateRoute>} />
      <Route path="/consultaprodutopedido/:id" element={<PrivateRoute tipoUsuario="COMPRADOR" licenca={licenca}><ConsultaProdutoPedido /></PrivateRoute>} />
      <Route path="/cadastrocotacao" element={<PrivateRoute tipoUsuario="COMPRADOR" licenca={licenca}><CadastroCotacao /></PrivateRoute>} />
      <Route path="/cadastrocotacao/:id" element={<PrivateRoute tipoUsuario="COMPRADOR" licenca={licenca}><CadastroCotacao /></PrivateRoute>} />
      <Route path="/cadastrocotacaorespondendo/:id" element={<PrivateRoute tipoUsuario="COMPRADOR" licenca={licenca}><CadastroCotacaoRespondendo /></PrivateRoute>} />
      <Route path="/consultacotacao" element={<PrivateRoute tipoUsuario="COMPRADOR" licenca={licenca}><ConsultaCotacao /></PrivateRoute>} />
      <Route path="/consultapedido" element={<PrivateRoute tipoUsuario="COMPRADOR" licenca={licenca}><ConsultaPedido /></PrivateRoute>} />
      <Route path="/cadastropedido/:id" element={<PrivateRoute tipoUsuario="COMPRADOR" licenca={licenca}><CadastroPedido /></PrivateRoute>} />
      <Route path="/consultavendedor" element={<PrivateRoute tipoUsuario="COMPRADOR" licenca={licenca}><ConsultaVendedor /></PrivateRoute>} />
      <Route path="/convidarvendedor" element={<PrivateRoute tipoUsuario="COMPRADOR" licenca={licenca}><ConvidarVendedor /></PrivateRoute>} />
      <Route path="/relatoriopedido" element={<PrivateRoute tipoUsuario="COMPRADOR" licenca={licenca}><RelatorioPedido /></PrivateRoute>} />
      <Route path="/relatoriopedidoanalitico" element={<PrivateRoute tipoUsuario="COMPRADOR" licenca={licenca}><RelatorioPedidoAnalitico /></PrivateRoute>} />
      <Route path="/relatoriofinanceirocomprador" element={<PrivateRoute tipoUsuario="COMPRADOR" licenca={licenca}><RelatorioFinanceiroComprador /></PrivateRoute>} />
      <Route path="/financeirocomprador" element={<PrivateRoute tipoUsuario="COMPRADOR" licenca="true"><FinanceiroComprador /></PrivateRoute>} />
      <Route path="/alterarsenhacomprador" element={<PrivateRoute tipoUsuario="COMPRADOR" licenca="true"><AlterarSenha /></PrivateRoute>} />
      <Route path="/alterardadoscomprador" element={<PrivateRoute tipoUsuario="COMPRADOR" licenca="true"><AlterarDados /></PrivateRoute>} />
      <Route path="/alterarreceberemailscomprador" element={<PrivateRoute tipoUsuario="COMPRADOR" licenca="true"><AlterarReceberEmails /></PrivateRoute>} />
      <Route path="/consultabilhetecomprador" element={<PrivateRoute tipoUsuario="COMPRADOR" licenca="true"><ConsultaBilhete /></PrivateRoute>} />
      <Route path="/cadastrobilhetecomprador" element={<PrivateRoute tipoUsuario="COMPRADOR" licenca="true"><CadastroBilhete /></PrivateRoute>} />

      <Route path="/homevendedor" element={<PrivateRoute tipoUsuario="VENDEDOR" licenca="true"><HomeVendedor /></PrivateRoute>} />
      <Route path="/consultacomprador" element={<PrivateRoute tipoUsuario="VENDEDOR" licenca={licenca}><ConsultaComprador /></PrivateRoute>} />
      <Route path="/convidarcomprador" element={<PrivateRoute tipoUsuario="VENDEDOR" licenca={licenca}><ConvidarComprador /></PrivateRoute>} />
      <Route path="/cadastrocotacaovendedor/:id" element={<PrivateRoute tipoUsuario="VENDEDOR" licenca={licenca}><CadastroCotacaoVendedor /></PrivateRoute>} />
      <Route path="/consultacotacaovendedor" element={<PrivateRoute tipoUsuario="VENDEDOR" licenca={licenca}><ConsultaCotacaoVendedor /></PrivateRoute>} />
      <Route path="/consultapedidovendedor" element={<PrivateRoute tipoUsuario="VENDEDOR" licenca={licenca}><ConsultaPedidoVendedor /></PrivateRoute>} />
      <Route path="/cadastropedidovendedor/:id" element={<PrivateRoute tipoUsuario="VENDEDOR" licenca={licenca}><CadastroPedidoVendedor /></PrivateRoute>} />
      <Route path="/relatoriopedidovendedor" element={<PrivateRoute tipoUsuario="VENDEDOR" licenca={licenca}><RelatorioPedidoVendedor /></PrivateRoute>} />
      <Route path="/relatoriofinanceirovendedor" element={<PrivateRoute tipoUsuario="VENDEDOR" licenca={licenca}><RelatorioFinanceiroVendedor /></PrivateRoute>} />
      <Route path="/financeirovendedor" element={<PrivateRoute tipoUsuario="VENDEDOR" licenca="true"><FinanceiroVendedor /></PrivateRoute>} />
      <Route path="/alterarsenhavendedor" element={<PrivateRoute tipoUsuario="VENDEDOR" licenca="true"><AlterarSenha /></PrivateRoute>} />
      <Route path="/alterardadosvendedor" element={<PrivateRoute tipoUsuario="VENDEDOR" licenca="true"><AlterarDados /></PrivateRoute>} />
      <Route path="/alterarreceberemailsvendedor" element={<PrivateRoute tipoUsuario="VENDEDOR" licenca="true"><AlterarReceberEmails /></PrivateRoute>} />
      <Route path="/consultabilhetevendedor" element={<PrivateRoute tipoUsuario="VENDEDOR" licenca="true"><ConsultaBilhete /></PrivateRoute>} />
      <Route path="/cadastrobilhetevendedor" element={<PrivateRoute tipoUsuario="VENDEDOR" licenca="true"><CadastroBilhete /></PrivateRoute>} />

      <Route path="*" element={<Login />} />
    </Routes>
  );
}
