import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

import { get, orderBy } from 'lodash';

import 'react-toastify/dist/ReactToastify.css';
import { displayErrors } from '../../../util/diversos';

import { fillColumns } from '../../../components/table';
import { Input100px, Input155px, Input250px, InputCampoBusca100px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import Loading from '../../loading';
import axiosCoto from '../../../services/axiosCoto';
import IconButton from '../../../components/IconButton';

export default function ConsultaCotacaoAdministrador() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const idUsuario = useSelector((state) => state.auth.user.id);

  const [isLoading, setIsLoading] = useState(false);
  const [euConcordo, setEuConcordo] = useState();
  const [cotacaoSelecionada] = useState(null);
  const [cotacoesSelecionadas, setCotacoesSelecionadas] = useState(null);
  const [dataTableListaCotacoes, setDataTableListaCotacoes] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showInformacoesCotacaoDialog, setShowInformacoesCotacaoDialog] = useState(false);

  const [numProdutos, setNumProdutos] = useState(0);
  const [numRespostas, setNumRespostas] = useState(0);
  const [listaVendedores, setListaVendedores] = useState([]);
  const [listaProdutosSemRespostas, setListaProdutosSemRespostas] = useState([]);

  const [findField, setFindField] = useState('');
  const [sortField, setSortField] = useState('razaoSocial');
  const [sortOrder, setSortOrder] = useState(null);
  const [dataTableColumns] = useState([
    { field: 'id', header: 'Id', sort: true },
    { field: 'razaoSocial', header: 'Razão Social', sort: true },
    { field: 'descricao', header: 'Descricão' },
    { field: 'dataAbertura', header: 'Abertura', sort: false },
    { field: 'dataFechamento', header: 'Fechamento', sort: false },
    { field: 'status', header: 'status', sort: false },
  ]);

  async function getData(searchFilter) {
    try {
      setIsLoading(true);

      let params = `/cotacao/paraconsultaAdministrador/?idUsuario=${idUsuario}`;
      if (searchFilter && searchFilter.findField !== '') {
        params = `${params}&${sortField}=${searchFilter.findField}`;
      }

      let response = '';
      await axiosCoto.get(params).then((res) => {
        response = res.data;
      });

      setDataTableListaCotacoes(response);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const handleFind = () => {
    const searchFilter = {
      findField,
      idUsuario,
    };

    setFindField('');

    getData(searchFilter);
  };

  useEffect(() => {
    getData('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleInformacoesCotacao = async (e) => {
    e.preventDefault();

    const padrao = get(cotacoesSelecionadas, 'padrao', false);
    if (padrao) {
      toast.error('Não há informações para cotacões padrão.');
      return;
    }

    const id = get(cotacoesSelecionadas, 'id', 0);

    if (id > 0) {
      try {
        setIsLoading(true);

        const response = await axiosCoto.get(`/cotacao/informacaocotacaoadministrador/${id}`);

        setNumProdutos(get(response.data, 'numProdutos', 0));
        setNumRespostas(get(response.data, 'numRespostas', 0));
        setListaVendedores(get(response.data, 'razaoSocialVendedores', []));
        setListaProdutosSemRespostas(get(response.data, 'produtosSemResposta', []));
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }

      setShowInformacoesCotacaoDialog(true);
    } else {
      toast.warning('Selecione um registro para exibir informações.');
    }
  };

  /* const handleDelete = (e) => {
    e.preventDefault();

    const id = get(cotacoesSelecionadas, 'id', 0);

    if (id > 0) {
      setShowConfirmDialog(true);
    } else {
      toast.warning('Selecione um registro para exclusão.');
    }
  }; */

  const cancelDelete = () => {
    setShowConfirmDialog(false);
  };

  const confirmDelete = async () => {
    const idExclusao = get(cotacoesSelecionadas, 'id', 0);

    if (euConcordo.toUpperCase() !== 'EU CONCORDO') {
      toast.success('Palavra chave não digitada para exclusão.');
      return;
    }

    setShowConfirmDialog(false);

    try {
      setIsLoading(true);

      if (idExclusao > 0) {
        await axiosCoto.delete(`/cotacao/${idExclusao}`);

        const _itens = dataTableListaCotacoes.filter(val => val.id !== idExclusao);
        setDataTableListaCotacoes(_itens);

        toast.success('Registro excluído com sucesso.');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setEuConcordo('');
      setIsLoading(false);
    }
  };

  const cancelInformacoescotacao = () => {
    setShowInformacoesCotacaoDialog(false);
  };

  const confirmDialogFooter = (
    <div>
      <IconButton titulo="Sim" mensagem="Sim" btnStyle="primary" onClick={confirmDelete} icon="check" />
      <IconButton titulo="Não" mensagem="Não" btnStyle="danger" onClick={cancelDelete} icon="times" />
    </div>
  );

  const informacoesCotacaoDialogFooter = (
    <div>
      <IconButton titulo="Fechar" mensagem="Fechar" btnStyle="primary" onClick={cancelInformacoescotacao} icon="times" />
    </div>
  );

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableListaCotacoes(orderBy(dataTableListaCotacoes, [sortField], [sortOrder]));
  };

  const renderTablePrimeReact = useMemo(() => (
    <div>
      <div className="card">
        <DataTable
          id="tabelaCotacoes"
          value={dataTableListaCotacoes}
          size="small"
          stripedRows
          sortField={sortField}
          setOrder={sortOrder}
          onSort={onSort}
          responsiveLayout="scroll"
          selectionMode="single"
          selection={cotacaoSelecionada}
          onSelectionChange={e => setCotacoesSelecionadas(e.value)}
          dataKey="id"
          paginator
          rows={10}
          emptyMessage="Nenhum registro a ser exibido"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="{currentPage} de {totalPages}"
          rowsPerPageOptions={[10, 25, 50]}
        >
          {fillColumns(dataTableColumns)}
        </DataTable>
      </div>
    </div>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [dataTableListaCotacoes, cotacoesSelecionadas]);

  return (
    <>
      <Loading isLoading={isLoading} />
      <Card title="Cotações">
        <div>
          <div className="row align-items-center">
            <div className="col-8">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Buscar por:
                </Input155px>
                <InputCampoBusca100px className="p-inputgroup-addon">
                  {dataTableColumns.find(element => element.field === sortField).header}
                </InputCampoBusca100px>
                <InputText
                  autoFocus
                  maxLength={200}
                  value={findField}
                  onChange={e => setFindField(e.target.value)}
                />
                &nbsp; &nbsp;
                <IconButton titulo="" mensagem="Buscar" btnStyle="success" onClick={handleFind} icon="search" />
              </div>
            </div>
            <div className="col-4" style={{ textAlign: 'right' }}>
              <IconButton titulo="" mensagem="Informações" btnStyle="dark" onClick={handleInformacoesCotacao} icon="eye" />
            </div>
          </div>
        </div>
        <br />

        {renderTablePrimeReact}

        <Dialog
          header="Exclusão da Cotação"
          visible={showConfirmDialog}
          style={{ width: '50vw' }}
          footer={confirmDialogFooter}
          onHide={() => setShowConfirmDialog(false)}
        >
          <p>
            Digite: EU CONCORDO na caixa,
            <InputText
              maxLength={11}
              value={euConcordo}
              onChange={e => setEuConcordo(e.target.value)}
            />
            para a exclusão da cotação.

          </p>
        </Dialog>

        <Dialog
          header="Informações da Cotação"
          visible={showInformacoesCotacaoDialog}
          style={{ width: '50vw' }}
          footer={informacoesCotacaoDialogFooter}
          onHide={() => setShowInformacoesCotacaoDialog(false)}
        >
          <div className="row">
            <div className="col-3">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Produtos
                </Input100px>
                <InputCampoBusca100px className="p-inputgroup-addon">
                  {numProdutos}
                </InputCampoBusca100px>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Respostas
                </Input100px>
                <InputCampoBusca100px className="p-inputgroup-addon">
                  {numRespostas}
                </InputCampoBusca100px>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="p-inputgroup">
                <Input250px className="p-inputgroup-addon">
                  Vendedores que responderam
                </Input250px>
                <Dropdown
                  emptyMessage="Nenhum vendedor respondeu."
                  options={listaVendedores}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="p-inputgroup">
                <Input250px className="p-inputgroup-addon">
                  Produtos sem resposta
                </Input250px>
                <Dropdown
                  emptyMessage="Todos os produtos receberam resposta."
                  options={listaProdutosSemRespostas}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </Card>
    </>
  );
}
