import React from 'react';

function IconButton(props) {
  if (props.hide) {
    return null;
  }
  return (
    <button
      type="button"
      className={`btn btn-${props.btnStyle}`}
      onClick={props.onClick}
      title={props.mensagem}
      disabled={props.disabled}
      style={{
        fontFamily: 'tahoma, verdana, arial',
        borderRadius: '5px',
        borderTop: '0px',
        borderLeft: '0px',
        marginTop: '3px',
        boxShadow: '2px 2px #C0C0C0',
      }}
    >
      <i className={`pi pi-${props.icon}`}></i>
      { props.titulo }
    </button>
  );
}

export default IconButton;
