export function obterLista() {
  return (
    [
      { label: 'Personalizado', value: 'PERSONALIZADO' },
      { label: 'Hoje', value: 'HOJE' },
      { label: 'Ontem', value: 'ONTEM' },
      { label: 'Última semana', value: 'SEMANA' },
      { label: 'Últimos 30 dias', value: 'MES' },
      { label: 'Últimos 6 meses', value: 'SEMESTRE' },
      { label: 'Último ano', value: 'ANO' },
    ]
  );
}


export function obterListaSemPersonalizado() {
  return (
    [
      { label: 'Hoje', value: 'HOJE' },
      { label: 'Ontem', value: 'ONTEM' },
      { label: 'Última semana', value: 'SEMANA' },
      { label: 'Últimos 30 dias', value: 'MES' },
      { label: 'Últimos 6 meses', value: 'SEMESTRE' },
      { label: 'Último ano', value: 'ANO' },
    ]
  );
}
