import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { AutoComplete } from 'primereact/autocomplete';
import { ToggleButton } from 'primereact/togglebutton';
import Card from '../../../components/card';

import Loading from '../../loading';
import { Input155px } from '../../../styles/inputGroup';
import axiosCoto from '../../../services/axiosCoto';
import IconButton from '../../../components/IconButton';

export default function AlterarReceberEmails() {
  const navigate = useNavigate();
  const tipoUsuarioLogado = useSelector((state) => state.auth.user.tipoUsuario);

  let id = useSelector((state) => state.auth.user.id);

  const [usuario, setUsuario] = useState();
  const [listaUsuarios, setListaUsuarios] = useState();
  const [filteredUsuarios, setFilteredUsuarios] = useState(null);
  const [receberEmails, setReceberEmails] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function getUsuarios() {
      try {
        setIsLoading(true);

        const listDropdown = [];
        const { data: _usuarios } = await axiosCoto.get('/usuario/todos');
        _usuarios.forEach(element => {
          listDropdown.push({ label: element.razaoSocial, value: element.id });
        });

        setListaUsuarios(listDropdown);
      } catch (err) {
        toast.error('Não foi possível recuperar os dados do servidor.');
        navigate('/consultafinanceiro');
      } finally {
        setIsLoading(false);
      }
    }
    getUsuarios();

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosCoto.get(`/usuario/${id}`);
        setReceberEmails(data.receberEmails);
      } catch (err) {
        toast.error('Não foi possível recuperar os dados do servidor.');
        navigate('/consultausuario');
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  async function salvarReceberEmails() {
    try {
      setIsLoading(true);

      if (tipoUsuarioLogado === 'ADMINISTRADOR' && usuario.value !== undefined) {
        id = usuario.value;
      }

      const shippingData = {
        id,
        receberEmails,
      };

      if (id > 0) {
        await axiosCoto.put(`/usuario/alterarreceberemailsporid/${id}`, shippingData);
        toast.success('Receber emails alterado com sucesso.');
      }
    } catch (err) {
      const errors = get(err.response.data, 'errors', []);
      if (errors.length > 0) {
        errors.map(error => toast.error(error));
      } else {
        toast.error('Senha não pode ser alterada.');
      }
    } finally {
      setIsLoading(false);
    }
  }

  const searchUsuarios = (event) => {
    const listDropdown = [];

    listaUsuarios.forEach(element => {
      if (element.label.toLowerCase().startsWith(event.query.toLowerCase())) {
        listDropdown.push({ label: element.label, value: element.value });
      }
    });

    setFilteredUsuarios(listDropdown);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;
    if (tipoUsuarioLogado === 'ADMINISTRADOR' && usuario.value === undefined) {
      formErros = true;
      toast.error('Selecione o usuario para alteração do recebimento de emails');
    }

    if (formErros) return;

    salvarReceberEmails();
  };

  const handleVoltar = (e) => {
    e.preventDefault();
    // eslint-disable-next-line no-restricted-globals
    history.back();
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <Card title="Alterar Recebimento de email">
        {(tipoUsuarioLogado === 'ADMINISTRADOR')
          ? (
            <div className="row form-elo">
              <div className="col-12">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Usuario *
                  </Input155px>
                  <AutoComplete
                    value={usuario}
                    maxLength={40}
                    suggestions={filteredUsuarios}
                    completeMethod={searchUsuarios}
                    field="label"
                    dropdown
                    forceSelection
                    onChange={(e) => setUsuario(e.target.value)}
                  />
                </div>
              </div>
            </div>
          ) : ('')}
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Receber Emails
              </Input155px>
              <ToggleButton
                onLabel="Sim"
                offLabel="Não"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                checked={receberEmails}
                onChange={(e) => setReceberEmails(e.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ textAlign: 'right' }}>
            <IconButton titulo="Salvar" mensagem="Salvar" btnStyle="success" onClick={handleSubmit} icon="check" />
            <IconButton titulo="Cancelar" mensagem="Cancelar" btnStyle="danger" onClick={handleVoltar} icon="times" />
          </div>
        </div>
      </Card>
    </>
  );
}
