import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { get, orderBy } from 'lodash';
import haversine from 'haversine';

import { TabView, TabPanel } from 'primereact/tabview';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';

import 'react-toastify/dist/ReactToastify.css';
import { displayErrors } from '../../../util/diversos';

import { obterListaRejeicaoComprador } from '../../../services/conviteRejeicaoService';

import { fillColumns } from '../../../components/table';
import { Input155px, Input250px, InputCampoBusca100px } from '../../../styles/inputGroup';
import IconButton from '../../../components/IconButton';

import Card from '../../../components/card';
import Loading from '../../loading';
import axiosCoto from '../../../services/axiosCoto';
import Avaliacao from '../../../components/avaliacao';

export default function ConsultaProduto() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const idComprador = useSelector((state) => state.auth.user.id);
  const latitudeComprador = useSelector((state) => state.auth.user.latitude);
  const longitudeComprador = useSelector((state) => state.auth.user.longitude);
  const [isLoading, setIsLoading] = useState(false);

  const listaRejeicaoConviteComprador = obterListaRejeicaoComprador();
  const [rejeicaoCompradorMensagem, setRejeicaoCompradorMensagem] = useState();

  const [dataTableList, setDataTableList] = useState([]);
  const [dataTableListConvidar, setDataTableListConvidar] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showConfirmDialogConvite, setShowConfirmDialogConvite] = useState(false);
  const [showRejeitarDialogConvite, setShowRejeitarDialogConvite] = useState(false);
  const [showClassificarDialog, setShowClassificarDialog] = useState(false);
  const [avaliacaoId, setAvaliacaoId] = useState(0);
  const [totalEstrelasAtendimento, setTotalEstrelasAtendimento] = useState(0);
  const [estrelasAtendimento1, setEstrelasAtendimento1] = useState(true);
  const [estrelasAtendimento2, setEstrelasAtendimento2] = useState(false);
  const [estrelasAtendimento3, setEstrelasAtendimento3] = useState(false);
  const [estrelasAtendimento4, setEstrelasAtendimento4] = useState(false);
  const [estrelasAtendimento5, setEstrelasAtendimento5] = useState(false);
  const [totalEstrelasEntrega, setTotalEstrelasEntrega] = useState(0);
  const [estrelasEntrega1, setEstrelasEntrega1] = useState(true);
  const [estrelasEntrega2, setEstrelasEntrega2] = useState(false);
  const [estrelasEntrega3, setEstrelasEntrega3] = useState(false);
  const [estrelasEntrega4, setEstrelasEntrega4] = useState(false);
  const [estrelasEntrega5, setEstrelasEntrega5] = useState(false);
  const [totalEstrelasProduto, setTotalEstrelasProduto] = useState(0);
  const [estrelasProduto1, setEstrelasProduto1] = useState(true);
  const [estrelasProduto2, setEstrelasProduto2] = useState(false);
  const [estrelasProduto3, setEstrelasProduto3] = useState(false);
  const [estrelasProduto4, setEstrelasProduto4] = useState(false);
  const [estrelasProduto5, setEstrelasProduto5] = useState(false);

  const [selectedItens, setSelectedItens] = useState(null);
  const [selectedItensConvidar, setSelectedItensConvidar] = useState(null);
  const [findField, setFindField] = useState('');
  const [findFieldConvidar, setFindFieldConvidar] = useState('');
  const [sortField, setSortField] = useState('email');
  const [sortOrder, setSortOrder] = useState(null);

  const [dataTableColumn] = useState([
    { field: 'razaoSocial', header: 'Nome', sort: true },
    { field: 'email', header: 'E-mail', sort: true },
    { field: 'telefone1', header: 'Telefone', sort: true },
    { field: 'cidade', header: 'Cidade', sort: false },
    { field: 'convite', header: 'Situação', sort: false },
    { field: 'avaliacao', header: 'Minha Avaliação', sort: false },
    { field: 'avaliacaoGeral', header: 'Avaliação Geral', sort: false },
    { field: 'rejeicaoVendedor', header: '', sort: false, hidden: true },
  ]);

  async function getData(searchFilter) {
    try {
      setIsLoading(true);

      let params = `/vinculocompradorvendedor/?idComprador=${idComprador}`;
      if (searchFilter && searchFilter.findField !== '') {
        params = `${params}&${sortField}=${searchFilter.findField}`;
      }

      let response = '';
      await axiosCoto.get(params).then((res) => {
        response = res.data;
      }).catch(() => {
        toast.warning('Erro na conexão. Atualize a página ou faça o login novamente.');
      });

      const paramsAvaliacao = `/avaliacaovendedor/?idComprador=${idComprador}`;
      let avaliacoes = '';
      await axiosCoto.get(paramsAvaliacao).then((res) => {
        avaliacoes = res.data;
      });

      const paramsAvaliacaoTodos = '/avaliacaovendedor/todos';
      let avaliacoesTodos = '';
      await axiosCoto.get(paramsAvaliacaoTodos).then((res) => {
        avaliacoesTodos = res.data;
      });

      const listaVendedores = [];
      // verifica se vendedor aceitou o convite do comprador para exibir na consulta
      for (let x = 0; x < response.vendedores.length; x++) {
        if (response.vendedores[x].aceiteComprador && response.vendedores[x].aceiteVendedor) {
          response.vendedores[x].convite = 'Convite aceito.';
          listaVendedores.push(response.vendedores[x]);
        } else if (response.vendedores[x].aceiteVendedor) {
          response.vendedores[x].convite = 'Aguardando aceite do comprador... ';
          listaVendedores.push(response.vendedores[x]);
        } else {
          response.vendedores[x].convite = 'Aguardando aceite do vendedor... ';
          listaVendedores.push(response.vendedores[x]);
        }

        if (response.vendedores[x].rejeicaoVendedor) {
          response.vendedores[x].convite = `Convite rejeitado. ${response.vendedores[x].rejeicaoVendedorMensagem}`;
        }

        if (response.vendedores[x].rejeicaoComprador) {
          response.vendedores[x].convite = `Convite Rejeitado. ${response.vendedores[x].rejeicaoCompradorMensagem}`;
        }

        let media = 0;
        let contador = 0;
        for (let z = 0; z < avaliacoesTodos.length; z++) {
          if (avaliacoesTodos[z].idVendedor === response.vendedores[x].id) {
            media += avaliacoesTodos[z].classificacaoMedia;
            contador++;
          }
        }
        response.vendedores[x].avaliacaoGeral = <Avaliacao valor={media / contador} votos={contador} />;

        for (let y = 0; y < avaliacoes.length; y++) {
          response.vendedores[x].avaliacao = 'Não avaliado';
          if (avaliacoes[y].idVendedor === response.vendedores[x].id) {
            response.vendedores[x].avaliacao = <Avaliacao valor={avaliacoes[y].classificacaoMedia} />;
            break;
          }
        }
      }

      setDataTableList(listaVendedores);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  async function getDataConvidar(searchFilterConvidar) {
    try {
      setIsLoading(true);
      let params = `/usuario/?idComprador=${idComprador}`;

      if (searchFilterConvidar && searchFilterConvidar.findFieldConvidar !== '') {
        params = `${params}&email=${searchFilterConvidar.findFieldConvidar}`;
      }

      let response = '';
      await axiosCoto.get(params).then((res) => {
        response = res.data;
      }).catch(() => {
        toast.warning('Erro na conexão. Atualize a página ou faça o login novamente.');
      });

      let vinculos = '';
      await axiosCoto.get(`/vinculocompradorvendedor/?idComprador=${idComprador}`).then((res) => {
        vinculos = res.data;
      }).catch(() => {
        toast.warning('Erro na conexão. Atualize a página ou faça o login novamente.');
      });

      const encontreVinculo = function (lista, id) {
        const existe = lista.find((value) => value.id === id && (value.dataConviteComprador !== null || value.aceiteComprador !== true));
        return existe;
      };

      const paramsAvaliacaoTodos = '/avaliacaovendedor/todos';
      let avaliacoesTodos = '';
      await axiosCoto.get(paramsAvaliacaoTodos).then((res) => {
        avaliacoesTodos = res.data;
      });

      const listaVendedoresConvidar = [];
      for (let x = 0; x < response.length; x++) {
        // calculo km usando latitude e longitude no change cidade.
        const start = {
          latitude: latitudeComprador,
          longitude: longitudeComprador,
        };
        const end = {
          latitude: response[x].latitude,
          longitude: response[x].longitude,
        };

        // distancia entre as cidades
        const distanciaEmKm = haversine(start, end, { unit: 'km' });

        if (haversine(start, end, { unit: 'km' }) >= 0) {
          if (response[x].raioAtuacao >= distanciaEmKm && response[x].tipoUsuario === 'VENDEDOR') {
            // verifica se vinculo existe e se ja enviou convite
            let media = 0;
            let contador = 0;
            if (!encontreVinculo(vinculos.vendedores, response[x].id)) {
              for (let z = 0; z < avaliacoesTodos.length; z++) {
                if (avaliacoesTodos[z].idVendedor === response[x].id) {
                  media += avaliacoesTodos[z].classificacaoMedia;
                  contador++;
                }
              }
              response[x].avaliacao = <Avaliacao valor={media / contador} votos={contador} />;
              listaVendedoresConvidar.push(response[x]);
            }
          }
        }
      }

      setDataTableListConvidar(listaVendedoresConvidar);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const handleFind = () => {
    const searchFilter = {
      findField,
      idComprador,
    };

    setFindField('');

    getData(searchFilter);
  };

  const handleFindConvidar = () => {
    const searchFilterConvidar = {
      findFieldConvidar,
      idComprador,
    };

    setFindFieldConvidar('');

    getDataConvidar(searchFilterConvidar);
  };

  useEffect(() => {
    getData('');
    getDataConvidar('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleDelete = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);
    const rejeicaoVendedor = get(selectedItens, 'rejeicaoVendedor', '');

    if (id > 0) {
      if (!rejeicaoVendedor) {
        setShowConfirmDialog(true);
      } else {
        toast.info('Não é possível excluir convite rejeitado.');
      }
    } else {
      toast.info('Selecione um registro para exclusão.');
    }
  };

  const handleAceitarConvite = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);
    const dataConviteVendedor = get(selectedItens, 'dataConviteVendedor', '');
    const rejeicaoVendedor = get(selectedItens, 'rejeicaoVendedor', '');
    const convite = get(selectedItens, 'convite');

    if (id > 0) {
      if (dataConviteVendedor !== null && convite !== 'Convite aceito.') {
        if (!rejeicaoVendedor) {
          setShowConfirmDialogConvite(true);
        } else {
          toast.info('Não é possível aceitar um convite rejeitado.');
        }
      } else {
        toast.warning('Convite será ou está aceito pelo vendedor.');
      }
    } else {
      toast.info('Selecione um vendedor para aceitar o convite.');
    }
  };

  const handleRejeitarConvite = (e) => {
    e.preventDefault();
    console.log('vendedor selecionado: ', selectedItens);
    const id = get(selectedItens, 'id', 0);
    const rejeicaoVendedor = get(selectedItens, 'rejeicaoVendedor', 0);
    const convite = get(selectedItens, 'convite');

    if (rejeicaoVendedor) {
      toast.info('Convite rejeitado não pode ser editado.');
      return;
    }

    if (id > 0) {
      setShowRejeitarDialogConvite(true);
    } else {
      toast.warning('Selecione um vendedor para rejeitar o convite.');
    }
  };

  const cancelAceitarConvite = () => {
    setShowConfirmDialogConvite(false);
  };

  const cancelRejeitarConvite = () => {
    setShowRejeitarDialogConvite(false);
  };

  const cancelDelete = () => {
    setShowConfirmDialog(false);
  };

  const cancelClassificar = () => {
    setShowClassificarDialog(false);
  };

  const confirmDelete = async () => {
    setShowConfirmDialog(false);

    try {
      setIsLoading(true);

      const id = get(selectedItens, 'id', 0);

      const response = await axiosCoto.get(`/vinculocompradorvendedor/idvendedoridcomprador/${id}/${idComprador}`);

      const idExclusao = response.data.id;

      if (idExclusao > 0) {
        await axiosCoto.delete(`/vinculocompradorvendedor/${idExclusao}`).then(async () => {
          await axiosCoto.get(`/avaliacaovendedor/obteravaliacao/${id}/${idComprador}`).then(res => {
            if (res.data.id) {
              axiosCoto.delete(`/avaliacaovendedor/${res.data.id}`);
            }
          }).catch(error => {
            console.log(error);
          });
        });
        const _itens = dataTableList.filter(val => val.id !== id);
        setDataTableList(_itens);

        toast.success('Registro excluído com sucesso.');
        getDataConvidar();
        getData();
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmAceitarConvite = async () => {
    setShowConfirmDialogConvite(false);

    try {
      setIsLoading(true);

      const idVendedor = get(selectedItens, 'id', 0);
      const dataConviteVendedor = get(selectedItens, 'dataConviteVendedor', '');
      const aceiteVendedor = get(selectedItens, 'aceiteVendedor', '');
      const dataConviteComprador = new Date().toISOString();
      const aceiteComprador = true;

      const shippingData = {
        idVendedor,
        idComprador,
        aceiteComprador,
        aceiteVendedor,
        dataConviteVendedor,
        dataConviteComprador,
        rejeicaoVendedor: false,
        rejeicaoComprador: false,
        rejeicaoCompradorMensagem: '',
      };

      if (idComprador > 0) {
        await axiosCoto.post('/vinculocompradorvendedor/atualizarconvite', shippingData);
        toast.success('Convite aceito com sucesso.');
        getData('');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmRejeitarConvite = async () => {
    if (rejeicaoCompradorMensagem == null) {
      toast.warning('Rejeicao não selecionada.');
      return;
    }

    setShowRejeitarDialogConvite(false);
    try {
      setIsLoading(true);

      const idVendedor = get(selectedItens, 'id', 0);
      const dataConviteVendedor = get(selectedItens, 'dataConviteVendedor', '');
      const aceiteVendedor = get(selectedItens, 'aceiteVendedor', '');
      const dataConviteComprador = new Date().toISOString();
      const aceiteComprador = true;

      const shippingData = {
        idVendedor,
        idComprador,
        aceiteComprador,
        aceiteVendedor,
        dataConviteVendedor,
        dataConviteComprador,
        rejeicaoVendedor: false,
        rejeicaoComprador: true,
        rejeicaoCompradorMensagem,
      };

      console.log('shippingdata: ', shippingData);

      if (idComprador > 0) {
        await axiosCoto.post('/vinculocompradorvendedor/atualizarconvite', shippingData).then(async () => {
          
          const res = await axiosCoto.get(`/avaliacaovendedor/?idVendedor=${idVendedor}&idComprador=${idComprador}`);
          
          console.log('res: ', res);
          if (res.data > 0) {
            await axiosCoto.delete(`/avaliacaovendedor/${res.data.id}`);
          }
        });
        toast.success('Rejeição enviada com sucesso.');
        getData();
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmClassificar = async () => {
    const idVendedor = get(selectedItens, 'id', 0);
    const id = avaliacaoId;
    try {
      setIsLoading(true);
      setShowClassificarDialog(false);
      const media = parseFloat((parseFloat(totalEstrelasAtendimento) + parseFloat(totalEstrelasEntrega) + parseFloat(totalEstrelasProduto)) / 3).toFixed(1);
      const shippingData = {
        id,
        idVendedor,
        idComprador,
        classificacaoAtendimento: parseFloat(totalEstrelasAtendimento),
        classificacaoEntrega: parseFloat(totalEstrelasEntrega),
        classificacaoProduto: parseFloat(totalEstrelasProduto),
        classificacaoMedia: parseFloat(media),
      };
      if (id > 0) {
        await axiosCoto.put(`/avaliacaovendedor/${id}`, shippingData);
      } else {
        await axiosCoto.post('/avaliacaovendedor', shippingData);
      }
      toast.success('Avaliação enviada com sucesso.');
    } catch (error) {
      console.log('error: ', error);
      toast.info('Não foi possível salvar avaliação');
    } finally {
      getData();
      setIsLoading(false);
    }
  };

  const confirmDialogFooter = (
    <div>
      <IconButton titulo="Sim" mensagem="Sim" btnStyle="primary" onClick={confirmDelete} icon="check" />
      <IconButton titulo="Não" mensagem="Não" btnStyle="danger" onClick={cancelDelete} icon="times" />
    </div>
  );

  const confirmDialogFooterConvite = (
    <div>
      <IconButton titulo="Sim" mensagem="Sim" btnStyle="primary" onClick={confirmAceitarConvite} icon="check" />
      <IconButton titulo="Não" mensagem="Não" btnStyle="danger" onClick={cancelAceitarConvite} icon="times" />
    </div>
  );

  const rejeitarDialogFooterConvite = (
    <div>
      <IconButton titulo="Sim" mensagem="Sim" btnStyle="primary" onClick={confirmRejeitarConvite} icon="check" />
      <IconButton titulo="Não" mensagem="Não" btnStyle="danger" onClick={cancelRejeitarConvite} icon="times" />
    </div>
  );

  const classificarDialogFooter = (
    <div>
      <IconButton titulo="Confirmar" mensagem="Confirmar" btnStyle="primary" onClick={confirmClassificar} icon="check" />
      <IconButton titulo="Cancelar" mensagem="Cancelar" btnStyle="danger" onClick={cancelClassificar} icon="times" />
    </div>
  );

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  async function handleConvidar(e) {
    let conviteComSucesso = false;
    let emailVendedor = '';
    try {
      setIsLoading(true);

      const idVendedor = get(e, 'id', 0);
      emailVendedor = get(e, 'email', '');
      const dataConviteComprador = new Date().toISOString();
      const aceiteComprador = true;
      const shippingData = {
        idVendedor,
        idComprador,
        dataConviteComprador,
        aceiteComprador,
      };

      if (idVendedor > 0) {
        await axiosCoto.post('/vinculocompradorvendedor/', shippingData);
        toast.success('Convite enviado ao Vendedor com sucesso.');
        getData('');
        getDataConvidar('');
      }

      conviteComSucesso = true;
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }

    if (conviteComSucesso) {
      await axiosCoto.put(`/vinculocompradorvendedor/enviaremailconvite/${emailVendedor}`);
    }
  }

  const handleSetarEstrelasAtendimento = async (estrelas) => {
    setTotalEstrelasAtendimento(estrelas);
    switch (estrelas) {
      case '2':
        setEstrelasAtendimento1(true);
        setEstrelasAtendimento2(true);
        setEstrelasAtendimento3(false);
        setEstrelasAtendimento4(false);
        setEstrelasAtendimento5(false);
        break;
      case '3':
        setEstrelasAtendimento1(true);
        setEstrelasAtendimento2(true);
        setEstrelasAtendimento3(true);
        setEstrelasAtendimento4(false);
        setEstrelasAtendimento5(false);
        break;
      case '4':
        setEstrelasAtendimento1(true);
        setEstrelasAtendimento2(true);
        setEstrelasAtendimento3(true);
        setEstrelasAtendimento4(true);
        setEstrelasAtendimento5(false);
        break;
      case '5':
        setEstrelasAtendimento1(true);
        setEstrelasAtendimento2(true);
        setEstrelasAtendimento3(true);
        setEstrelasAtendimento4(true);
        setEstrelasAtendimento5(true);
        break;
      default:
        setEstrelasAtendimento1(true);
        setEstrelasAtendimento2(false);
        setEstrelasAtendimento3(false);
        setEstrelasAtendimento4(false);
        setEstrelasAtendimento5(false);
    }
  };

  const handleSetarEstrelasEntrega = async (estrelas) => {
    setTotalEstrelasEntrega(estrelas);
    switch (estrelas) {
      case '2':
        setEstrelasEntrega1(true);
        setEstrelasEntrega2(true);
        setEstrelasEntrega3(false);
        setEstrelasEntrega4(false);
        setEstrelasEntrega5(false);
        break;
      case '3':
        setEstrelasEntrega1(true);
        setEstrelasEntrega2(true);
        setEstrelasEntrega3(true);
        setEstrelasEntrega4(false);
        setEstrelasEntrega5(false);
        break;
      case '4':
        setEstrelasEntrega1(true);
        setEstrelasEntrega2(true);
        setEstrelasEntrega3(true);
        setEstrelasEntrega4(true);
        setEstrelasEntrega5(false);
        break;
      case '5':
        setEstrelasEntrega1(true);
        setEstrelasEntrega2(true);
        setEstrelasEntrega3(true);
        setEstrelasEntrega4(true);
        setEstrelasEntrega5(true);
        break;
      default:
        setEstrelasEntrega1(true);
        setEstrelasEntrega2(false);
        setEstrelasEntrega3(false);
        setEstrelasEntrega4(false);
        setEstrelasEntrega5(false);
    }
  };

  const handleSetarEstrelasProduto = async (estrelas) => {
    setTotalEstrelasProduto(estrelas);
    switch (estrelas) {
      case '2':
        setEstrelasProduto1(true);
        setEstrelasProduto2(true);
        setEstrelasProduto3(false);
        setEstrelasProduto4(false);
        setEstrelasProduto5(false);
        break;
      case '3':
        setEstrelasProduto1(true);
        setEstrelasProduto2(true);
        setEstrelasProduto3(true);
        setEstrelasProduto4(false);
        setEstrelasProduto5(false);
        break;
      case '4':
        setEstrelasProduto1(true);
        setEstrelasProduto2(true);
        setEstrelasProduto3(true);
        setEstrelasProduto4(true);
        setEstrelasProduto5(false);
        break;
      case '5':
        setEstrelasProduto1(true);
        setEstrelasProduto2(true);
        setEstrelasProduto3(true);
        setEstrelasProduto4(true);
        setEstrelasProduto5(true);
        break;
      default:
        setEstrelasProduto1(true);
        setEstrelasProduto2(false);
        setEstrelasProduto3(false);
        setEstrelasProduto4(false);
        setEstrelasProduto5(false);
    }
  };

  const handleClassificar = async (e) => {
    const convite = get(selectedItens, 'convite', '0');
    if (convite !== 'Convite aceito.') {
      toast.info('Avaliação somente para convites aceitos.');
      return;
    }
    e.preventDefault();
    const idVendedor = get(selectedItens, 'id', 0);
    if (idVendedor > 0) {
      try {
        await axiosCoto.get(`/avaliacaovendedor/?idVendedor=${idVendedor}&idComprador=${idComprador}`).then((res) => {
          if (res) {
            setAvaliacaoId(res.data.id);
            handleSetarEstrelasAtendimento(res.data.classificacaoAtendimento.toString());
            setTotalEstrelasAtendimento(res.data.classificacaoAtendimento.toString());
            handleSetarEstrelasEntrega(res.data.classificacaoEntrega.toString());
            handleSetarEstrelasProduto(res.data.classificacaoProduto.toString());
          } else {
            setAvaliacaoId(0);
            handleSetarEstrelasAtendimento('1');
            handleSetarEstrelasEntrega('1');
            handleSetarEstrelasProduto('1');
          }
        }).catch(() => {
          setAvaliacaoId(0);
          handleSetarEstrelasAtendimento('1');
          handleSetarEstrelasEntrega('1');
          handleSetarEstrelasProduto('1');
        });
      } catch (error) {
        console.log(error);
        toast.info('Não foi possivel coletar avaliação.');
      }
      setShowClassificarDialog(true);
    } else {
      toast.warning('Selecione um vendedor para classificar.');
    }
  };

  const actionBodyTemplate = (rowData) => <IconButton mensagem="Convidar" btnStyle="primary" onClick={() => handleConvidar(rowData)} icon="plus" />;

  return (
    <>
      <Loading isLoading={isLoading} />

      <Card title="Vendedores">
        <TabView className="tabview-header-icon">
          <TabPanel header="Convidar" leftIcon="pi pi-plus">
            <Card title="Convidar">
              <div>
                <div className="row align-items-center">
                  <div className="col-12">
                    <div className="p-inputgroup">
                      <Input155px className="p-inputgroup-addon">
                        Buscar por Email:
                      </Input155px>
                      <InputText
                        autoFocus
                        maxLength={250}
                        value={findFieldConvidar}
                        onChange={e => setFindFieldConvidar(e.target.value)}
                      />
                      &nbsp; &nbsp;
                      <IconButton titulo="" mensagem="Buscar" btnStyle="success" onClick={handleFindConvidar} icon="search" />
                    </div>
                  </div>
                </div>
              </div>
              <DataTable
                value={dataTableListConvidar}
                size="small"
                stripedRows
                sortField={sortField}
                setOrder={sortOrder}
                onSort={onSort}
                responsiveLayout="scroll"
                selectionMode="single"
                selection={selectedItensConvidar}
                onSelectionChange={e => setSelectedItensConvidar(e.value)}
                selectableRows
                dataKey="id"
                paginator
                rows={10}
                emptyMessage="Nenhum registro a ser exibido"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="{currentPage} de {totalPages}"
                rowsPerPageOptions={[10, 25, 50]}
              >
                <Column field="razaoSocial" header="Nome"></Column>
                <Column field="email" header="Email" sortable></Column>
                <Column field="telefone1" header="Telefone"></Column>
                <Column field="cidade" header="Cidade"></Column>
                <Column field="avaliacao" header="Avaliação"></Column>
                <Column body={actionBodyTemplate} exportable={false} style={{ width: '10px' }}></Column>
              </DataTable>
            </Card>
          </TabPanel>
          <TabPanel header="Convidados / Convites" leftIcon="pi pi-pencil">
            <Card title="Convidados">
              <div>
                <div className="row align-items-center">
                  <div className="col-9">
                    <div className="p-inputgroup">
                      <Input155px className="p-inputgroup-addon">
                        Buscar por:
                      </Input155px>
                      <InputCampoBusca100px className="p-inputgroup-addon">
                        {dataTableColumn.find(element => element.field === sortField).header}
                      </InputCampoBusca100px>
                      <InputText
                        autoFocus
                        maxLength={250}
                        value={findField}
                        onChange={e => setFindField(e.target.value)}
                      />
                      &nbsp; &nbsp;
                      <IconButton titulo="" mensagem="Buscar" btnStyle="success" onClick={handleFind} icon="search" />
                    </div>
                  </div>
                  <div className="col-3" style={{ textAlign: 'right' }}>
                    <IconButton mensagem="Avaliar" btnStyle="warning" onClick={handleClassificar} icon="star" />
                    <IconButton mensagem="Aceitar Convite" btnStyle="success" onClick={handleAceitarConvite} icon="check-square" />
                    <IconButton titulo="" mensagem="Rejeitar Convite" btnStyle="info" onClick={handleRejeitarConvite} icon="times-circle" />
                    <IconButton mensagem="Excluir" btnStyle="danger" onClick={handleDelete} icon="trash" />
                  </div>
                </div>
              </div>
              <br />

              <DataTable
                value={dataTableList}
                size="small"
                stripedRows
                sortField={sortField}
                setOrder={sortOrder}
                onSort={onSort}
                responsiveLayout="scroll"
                selectionMode="single"
                selection={selectedItens}
                onSelectionChange={e => setSelectedItens(e.value)}
                selectableRows
                dataKey="id"
                paginator
                rows={10}
                emptyMessage="Nenhum registro a ser exibido"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="{currentPage} de {totalPages}"
                rowsPerPageOptions={[10, 25, 50]}
              >
                {fillColumns(dataTableColumn)}
              </DataTable>
            </Card>
          </TabPanel>
        </TabView>
      </Card>
      <Dialog
        header="Confirmação"
        visible={showConfirmDialog}
        style={{ width: '50vw' }}
        footer={confirmDialogFooter}
        onHide={() => setShowConfirmDialog(false)}
      >
        <p>
          Excluir o registro?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação de convite pendente"
        visible={showConfirmDialogConvite}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterConvite}
        onHide={() => setShowConfirmDialogConvite(false)}
      >
        <p>
          Aceitar o convite pendente?
        </p>
      </Dialog>

      <Dialog
        header="Rejeitar Convite"
        visible={showRejeitarDialogConvite}
        style={{ width: '50vw' }}
        footer={rejeitarDialogFooterConvite}
        onHide={() => setShowRejeitarDialogConvite(false)}
      >
        <p> Motivo da rejeição: </p>
        <div className="col-8">
          <div className="p-inputgroup">
            <Input155px className="p-inputgroup-addon">
              Rejeição
            </Input155px>
            <Dropdown
              options={listaRejeicaoConviteComprador}
              value={rejeicaoCompradorMensagem}
              onChange={e => setRejeicaoCompradorMensagem(e.target.value)}
            />
          </div>
        </div>
        <br />
        <p>
          Rejeitar o convite pendente?
        </p>
      </Dialog>

      <Dialog
        header="Classificar Vendedor"
        visible={showClassificarDialog}
        style={{ width: '65vw' }}
        footer={classificarDialogFooter}
        onHide={() => setShowClassificarDialog(false)}
      >
        <div className="row">
          <div className="col-4" style={{ align: 'center' }}>

          </div>
          <div className="col-4" style={{ align: 'center' }}>
            <Input250px className="p-inputgroup-addon">
              Atendimento
            </Input250px>
          </div>
        </div>
        <div className="row">
          <div className="col-12" style={{ textAlign: 'center' }}>
            <IconButton mensagem="Classificar" btnStyle="warning" onClick={e => handleSetarEstrelasAtendimento('1')} icon={estrelasAtendimento1 ? 'star-fill' : 'star'} />
            <IconButton mensagem="Classificar" btnStyle="warning" onClick={e => handleSetarEstrelasAtendimento('2')} icon={estrelasAtendimento2 ? 'star-fill' : 'star'} />
            <IconButton mensagem="Classificar" btnStyle="warning" onClick={e => handleSetarEstrelasAtendimento('3')} icon={estrelasAtendimento3 ? 'star-fill' : 'star'} />
            <IconButton mensagem="Classificar" btnStyle="warning" onClick={e => handleSetarEstrelasAtendimento('4')} icon={estrelasAtendimento4 ? 'star-fill' : 'star'} />
            <IconButton mensagem="Classificar" btnStyle="warning" onClick={e => handleSetarEstrelasAtendimento('5')} icon={estrelasAtendimento5 ? 'star-fill' : 'star'} />
          </div>
        </div>
        <br />
        <br />
        <div className="row">
          <div className="col-4" style={{ align: 'center' }}>
          </div>
          <div className="col-4" style={{ textAlign: 'center' }}>
            <Input250px className="p-inputgroup-addon">
              Entrega
            </Input250px>
          </div>
        </div>
        <div className="row">
          <div className="col-12" style={{ textAlign: 'center' }}>
            <IconButton mensagem="Classificar" btnStyle="warning" onClick={e => handleSetarEstrelasEntrega('1')} icon={estrelasEntrega1 ? 'star-fill' : 'star'} />
            <IconButton mensagem="Classificar" btnStyle="warning" onClick={e => handleSetarEstrelasEntrega('2')} icon={estrelasEntrega2 ? 'star-fill' : 'star'} />
            <IconButton mensagem="Classificar" btnStyle="warning" onClick={e => handleSetarEstrelasEntrega('3')} icon={estrelasEntrega3 ? 'star-fill' : 'star'} />
            <IconButton mensagem="Classificar" btnStyle="warning" onClick={e => handleSetarEstrelasEntrega('4')} icon={estrelasEntrega4 ? 'star-fill' : 'star'} />
            <IconButton mensagem="Classificar" btnStyle="warning" onClick={e => handleSetarEstrelasEntrega('5')} icon={estrelasEntrega5 ? 'star-fill' : 'star'} />
          </div>
        </div>
        <br />
        <br />
        <div className="row">
          <div className="col-4" style={{ align: 'center' }}>
          </div>
          <div className="col-4" style={{ textAlign: 'center' }}>
            <Input250px className="p-inputgroup-addon">
              Produto
            </Input250px>
          </div>
        </div>
        <div className="row">
          <div className="col-12" style={{ textAlign: 'center' }}>
            <IconButton mensagem="Classificar" btnStyle="warning" onClick={e => handleSetarEstrelasProduto('1')} icon={estrelasProduto1 ? 'star-fill' : 'star'} />
            <IconButton mensagem="Classificar" btnStyle="warning" onClick={e => handleSetarEstrelasProduto('2')} icon={estrelasProduto2 ? 'star-fill' : 'star'} />
            <IconButton mensagem="Classificar" btnStyle="warning" onClick={e => handleSetarEstrelasProduto('3')} icon={estrelasProduto3 ? 'star-fill' : 'star'} />
            <IconButton mensagem="Classificar" btnStyle="warning" onClick={e => handleSetarEstrelasProduto('4')} icon={estrelasProduto4 ? 'star-fill' : 'star'} />
            <IconButton mensagem="Classificar" btnStyle="warning" onClick={e => handleSetarEstrelasProduto('5')} icon={estrelasProduto5 ? 'star-fill' : 'star'} />
          </div>
        </div>
      </Dialog>
    </>
  );
}
