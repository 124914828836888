import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { Password } from 'primereact/password';
import { AutoComplete } from 'primereact/autocomplete';
import Card from '../../../components/card';

import Loading from '../../loading';
import { Input155px } from '../../../styles/inputGroup';
import axiosCoto from '../../../services/axiosCoto';
import IconButton from '../../../components/IconButton';

export default function AlterarSenha() {
  const navigate = useNavigate();
  const tipoUsuarioLogado = useSelector((state) => state.auth.user.tipoUsuario);
  let id = useSelector((state) => state.auth.user.id);

  const [usuario, setUsuario] = useState();
  const [listaUsuarios, setListaUsuarios] = useState();
  const [filteredUsuarios, setFilteredUsuarios] = useState(null);
  const [senha, setSenha] = useState('');
  const [repetiSenha, setRepetiSenha] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function getUsuarios() {
      try {
        setIsLoading(true);

        const listDropdown = [];

        const { data: _usuarios } = await axiosCoto.get('/usuario/todos');

        _usuarios.forEach(element => {
          listDropdown.push({ label: element.razaoSocial, value: element.id });
        });

        setListaUsuarios(listDropdown);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        toast.error('Não foi possível recuperar os dados do servidor.');
        navigate('/consultafinanceiro');
      }
    }
    getUsuarios();
  }, [navigate]);

  async function salvarSenha() {
    try {
      setIsLoading(true);

      if (tipoUsuarioLogado === 'ADMINISTRADOR' && usuario.value !== undefined) {
        id = usuario.value;
      }

      const shippingData = {
        id,
        senha,
        email: '',
      };

      if (id > 0) {
        await axiosCoto.put(`/usuario/alterarsenhaporid/${id}`, shippingData);
        toast.success('Senha alterada com sucesso.');
      }

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      const errors = get(err.response.data, 'errors', []);
      if (errors.length > 0) {
        errors.map(error => toast.error(error));
      } else {
        toast.error('Senha não pode ser alterada.');
      }
    }
  }

  const searchUsuarios = (event) => {
    const listDropdown = [];

    listaUsuarios.forEach(element => {
      if (element.label.toLowerCase().startsWith(event.query.toLowerCase())) {
        listDropdown.push({ label: element.label, value: element.value });
      }
    });

    setFilteredUsuarios(listDropdown);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;
    if (tipoUsuarioLogado === 'ADMINISTRADOR' && usuario.value === undefined) {
      formErros = true;
      toast.error('Selecione o usuario para alteração da senha');
    }
    if (senha.length < 3 || senha.length > 50) {
      formErros = true;
      toast.error('Senha deve conter de 3 à 50 caracteres.');
    }
    if (repetiSenha.length < 3 || repetiSenha.length > 50) {
      formErros = true;
      toast.error('Repeti senha deve conter de 3 à 50 caracteres.');
    }
    if (senha !== repetiSenha) {
      formErros = true;
      toast.error('Senhas não conferem.');
    }

    if (formErros) return;

    salvarSenha();
  };

  const handleVoltar = (e) => {
    e.preventDefault();
    history.back();
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <Card title="Alterar Senha">
        {(tipoUsuarioLogado === 'ADMINISTRADOR')
          ? (
            <div className="row form-elo">
              <div className="col-12">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Usuario *
                  </Input155px>
                  <AutoComplete
                    value={usuario}
                    maxLength={40}
                    suggestions={filteredUsuarios}
                    completeMethod={searchUsuarios}
                    field="label"
                    dropdown
                    forceSelection
                    onChange={(e) => setUsuario(e.target.value)}
                  />
                </div>
              </div>
            </div>
          ) : ('')}
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Senha *
              </Input155px>
              <Password
                autoFocus
                maxLength={50}
                toggleMask
                weakLabel="Fraca"
                mediumLabel="Média"
                strongLabel="Forte"
                value={senha}
                onChange={e => setSenha(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Repita a Senha *
              </Input155px>
              <Password
                maxLength={50}
                toggleMask
                weakLabel="Fraca"
                mediumLabel="Média"
                strongLabel="Forte"
                value={repetiSenha}
                onChange={e => setRepetiSenha(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ textAlign: 'right' }}>
            <IconButton titulo="Salvar" mensagem="Salvar" btnStyle="success" onClick={handleSubmit} icon="check" />
            <IconButton titulo="Cancelar" mensagem="Cancelar" btnStyle="danger" onClick={handleVoltar} icon="times" />
          </div>
        </div>
      </Card>
    </>
  );
}
