import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export default function HomeAdm() {
  const dispatch = useDispatch();

  useEffect(() => {

  }, [dispatch]);

  return (
    <div>

    </div>
  );
}
