import React, { useState, useEffect /* useRef */ } from 'react';
// import { Captcha } from 'primereact/captcha';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmail } from 'validator';
import { toast } from 'react-toastify';

// import { get } from 'lodash';
import store from '../store/store';

import 'react-toastify/dist/ReactToastify.css';
import * as actions from '../store/modules/auth/actions';
import { /* SITEKEY */ } from '../config/constantes';

import Card from '../components/card';
import FormGroup from '../components/formgroup';
import Loading from './loading';
import IconButton from '../components/IconButton';

// import axiosCoto from '../services/axiosCoto';

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const { versaoSistema, setVersaoSistema} = useState();
  const { isLoading, isLoggedIn } = useSelector((state) => state.auth);
  const { dataLicenca } = store.getState().auth.user;

  const data = new Date();
  const dataAtual = data.getFullYear() + (`00${data.getMonth() + 1}`).slice(-2) + (`00${data.getDate()}`).slice(-2);
  let dataLicencaAtual = '';
  if (dataLicenca) {
    dataLicencaAtual = dataLicenca[0] + (`00${dataLicenca[1]}`).slice(-2) + (`00${dataLicenca[2]}`).slice(-2);
  } else {
    dataLicencaAtual = dataAtual;
  }

  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  // const [captchaResponse, setCaptchaResponse] = useState();
  // const [versaoLocal, setVersaoLocal] = useState();
  // const recaptchaReference = useRef(null);

  /* async function atualizarVersao() {
    await axiosCoto.get('/configuracao/getversaosistema').then((res) => {
      fetch('/meta.json').then((response) => response.json()).then((meta) => {
        setVersaoLocal(meta.buildDate);
        if (versaoLocal < res.data) {
          // toast.warning('Uma nova versão esta disponivel. Use F5 ou CTRL + F5 para atualizar.');
          //  window.location.reload(true);
        }
      });
    }).catch(() => {
      // HANDLE THE ERROR (e)
    });
  } */

  useEffect(() => {
    // atualizarVersao();

    const { tipoUsuario } = store.getState().auth.user;

    // const captcha = get(captchaResponse, 'response', '');

    if (isLoggedIn && tipoUsuario === 'ADMINISTRADOR') {
      navigate('/consultacotacaoadministrador');
    }

    if (isLoggedIn && tipoUsuario === 'COMPRADOR') {
      if (dataAtual <= dataLicencaAtual) {
        navigate('/homecomprador');
      } else {
        navigate('/financeirocomprador');
      }
    }

    if (isLoggedIn && tipoUsuario === 'VENDEDOR') {
      if (dataAtual <= dataLicencaAtual) {
        navigate('/homevendedor');
      } else {
        navigate('/financeirovendedor');
      }
    }
  }, [isLoggedIn, navigate, dispatch, dataAtual, dataLicencaAtual]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let formErros = false;

    // const captcha = get(captchaResponse, 'response', '');
    /* if (!captcha) {
      formErros = true;
      toast.error('Mostre que você não é um robô.');
    } */
    if (!isEmail(email)) {
      formErros = true;
      toast.error('Email inválido.');
    }

    if (senha.length <= 0) {
      formErros = true;
      toast.error('Senha deve ser informada.');
    }

    if (formErros) return;
    const captcha = '';

    dispatch(actions.loginRequest({ email, senha, captcha }));

    // setCaptchaResponse();
    // grecaptcha.reset();
  };

  const handleNewUser = () => {
    navigate('/cadastrousuariologin');
  };

  const handleNewSenha = () => {
    navigate('/novasenha');
  };

  // const isReady = () => typeof window !== 'undefined' && typeof window.grecaptcha !== 'undefined';

  return (
    <div className="row">
      <Loading isLoading={isLoading} />
      <div className="col-md-7 offset-md-2">
        <div className="bs-docs-section">
          <Card title="Login" largura="600px">
            <div className="row">
              <div className="col-12">
                <div className="bs-component">
                  <fieldset>
                    <FormGroup label="E-mail" htmlFor="inputEmail">
                      <input
                        type="email"
                        className="form-control"
                        id="inputEmail"
                        maxLength={254}
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                      />
                    </FormGroup>
                    <br />
                    <FormGroup label="Senha" htmlFor="inputSenha">
                      <input
                        type="password"
                        maxLength={50}
                        className="form-control"
                        id="inputSenha"
                        value={senha}
                        onChange={e => setSenha(e.target.value)}
                      />
                    </FormGroup>
                  </fieldset>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                {/* <Captcha
                  siteKey={SITEKEY}
                  onResponse={setCaptchaResponse}
                  language="pt-br"
                  ref={recaptchaReference}
                  isReady={isReady}
                /> */}
                <br />
              </div>
              <div className="d-flex justify-content-center">
                &nbsp;
              </div>
              <div className="col-12" style={{ textAlign: 'center' }}>
                <IconButton titulo="Entrar" mensagem="Entrar" btnStyle="success" onClick={handleSubmit} icon="sign-in" />
                &nbsp; &nbsp; &nbsp;
                <IconButton titulo="Cadastrar" mensagem="Cadastrar" btnStyle="primary" onClick={handleNewUser} icon="pencil" />
                &nbsp; &nbsp; &nbsp;
                <IconButton titulo="Esqueci a senha" mensagem="Esqueci a senha" btnStyle="warning" onClick={handleNewSenha} icon="key" />
              </div>
              <div className="col-12" style={{ textAlign: 'center' }}>
                <p>

                </p>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}
