/* eslint-disable prefer-template */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Dropdown } from 'primereact/dropdown';
import { displayErrors } from '../../../util/diversos';
import Card from '../../../components/card';

import axiosCoto from '../../../services/axiosCoto';
import IconButton from '../../../components/IconButton';

import { Input155px } from '../../../styles/inputGroup';

import Loading from '../../loading';

export default function RelatorioPedidoAnalitico() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const idUsuario = useSelector((state) => state.auth.user.id);

  const [pedido, setPedido] = useState();
  const [vendedor, setVendedor] = useState();
  const [totalPedido, setTotalPedido] = useState(0);
  const [listaPedidos, setListaPedidos] = useState([]);

  function headRows() {
    return [
      { numItemPedido: 'Item', Produto: 'Produto / Vendedor', Pedido: 'Pedido', Total: 'Pedido', Total2: 'Segundo preço' },
    ];
  }

  const [headerTabelaImpressao, setHeaderTabelaImpressao] = useState(headRows());

  async function getData() {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();
    try {
      setIsLoading(true);

      // pega os campos da busca
      const id = pedido;
      let idVendedor = vendedor;

      if (!idVendedor) idVendedor = 0;
      if (id > 0 && idVendedor >= 0) {
        const { data: _relatorio } = await axiosCoto.get(`/pedido/pedidopararelatoriocomprador/${id}/${idVendedor}`);

        const dataFechamento = `${('00' + _relatorio.data[2]).slice(-2)}/${('00' + _relatorio.data[1]).slice(-2)}/${_relatorio.data[0]}`;

        let totalPedido = 0;
        let totalPedidoPreco2 = 0;
        const result = [];
        let data = {
          id: '0',
          Pedido: '0',
          Produto: '',
          Total: '0',
          Total2: '0',
        };

        setHeaderTabelaImpressao(headRows());

        _relatorio.pedidoProdutos = _.sortBy(_relatorio.pedidoProdutos, 'descricaoProduto');
        let contador = 1;
        for (let x = 0; x < _relatorio.pedidoProdutos.length; x++) {
          if (_relatorio.pedidoProdutos[x].valorTotalCompra > 0) {
            data.numItemPedido = contador.toString();
            data.Produto = _relatorio.pedidoProdutos[x].descricaoProduto.toString().toUpperCase();
            data.Pedido = _relatorio.pedidoProdutos[x].qtdeTotalCompra.toString();
            data.Total = `R$ ${_relatorio.pedidoProdutos[x].valorTotalCompra.toFixed(2).replace('.', ',').toString()}`;

            _relatorio.pedidoProdutos[x].pedidoProdutoRespostas = _.sortBy(_relatorio.pedidoProdutos[x].pedidoProdutoRespostas, 'valorUnitario');
            let total2 = 0;
            data.Total2 = 0;
            for (let z = 0; z < _relatorio.pedidoProdutos[x].pedidoProdutoRespostas.length; z++) {
              if (z === 0) {
                data.Qtde = _relatorio.pedidoProdutos[x].pedidoProdutoRespostas[0].qtdePorCaixa;
              }
              if (z === 1) {
                total2 = data.Pedido * (_relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].valorUnitario * data.Qtde);
                if (total2 > _relatorio.pedidoProdutos[x].valorTotalCompra) {
                  data.Total2 = `R$ ${total2.toFixed(2).replace('.', ',').toString()}`;
                  totalPedidoPreco2 += total2;
                }
                break;
              }
            }

            if (data.Total2 === 0) {
              totalPedidoPreco2 += parseFloat(_relatorio.pedidoProdutos[x].valorTotalCompra.toFixed(2));
              data.Total2 = data.Total;
            }

            result.push({ ...data });
            contador++;
          }
          totalPedido += parseFloat(_relatorio.pedidoProdutos[x].valorTotalCompra.toFixed(2));
        }

        // linha em branco entre produtos
        data = {
          id: '',
          Produto: '',
          Pedido: '',
          Total: '',
          Total2: '',
        };
        result.push({ ...data });

        setTotalPedido(totalPedido);
        data.Total = `R$ ${totalPedido.toFixed(2).replace('.', ',').toString()}`;
        data.Total2 = `R$ ${totalPedidoPreco2.toFixed(2).replace('.', ',').toString()}`;
        result.push({ ...data });

        data.Produto = '*Valor economia aproximado quando o mesmo produto adquirido de vários vendedores.';
        data.Total = 'Economia*';
        data.Total2 = `R$ ${(totalPedidoPreco2 - totalPedido).toFixed(2).replace('.', ',').toString()}`;
        result.push({ ...data });

        doc.autoTable(
          {
            styles: { fontSize: 8 },
            margin: { top: 38, left: 5, right: 5 },
            theme: 'grid',
            head: headerTabelaImpressao,
            body: result,
            didDrawPage: (dados) => {
              // Header
              doc.rect(5, 5, 200, 30); //  header
              doc.addImage('logo.png', 'JPEG', 10, 12, 25, 15);
              doc.setFontSize(20);
              doc.text('Análise de Pedido', 75, 15);

              doc.setFontSize(10);
              doc.text('Número: ', 164, 12);
              doc.text(pedido.toString(), 180, 12);

              doc.text('Descrição:', 45, 20);
              doc.text(_relatorio.descricao, 63, 20);

              doc.text('Fechamento:', 164, 32);
              doc.text(dataFechamento, 186, 32);

              const { pageSize } = doc.internal;
              const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
              doc.text(`Pag ${doc.internal.getNumberOfPages()}`, dados.settings.margin.right, pageHeight - 8);
              doc.text('Elo Informática Barbacena', 82, pageHeight - 8);
              const periodo = new Date();
              const mes = periodo.getMonth() + 1;
              doc.text('Impresso: ', 172, pageHeight - 8);
              doc.text((`${('00' + periodo.getDate()).slice(-2)}/${('00' + mes).slice(-2)}/${periodo.getFullYear()}`).toString(), 189, pageHeight - 8);
            },
          },
        ).setFontSize(5);
        window.open(doc.output('bloburl'));
      } else {
        toast.warning('Nenhuma informação para o relatório.');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    async function getPedidos() {
      setIsLoading(true);

      try {
        const listDropdown = [];

        const { data: _pedidos } = await axiosCoto.get(`/pedido/?idUsuario=${idUsuario}&resumida=true`);

        _pedidos.forEach(element => {
          const dia = ('00' + element.data[2]).slice(-2);
          const mes = ('00' + element.data[1]).slice(-2);
          const data = (`${dia}/${mes}/${element.data[0]}`);
          const labelExibicao = `${element.id} - ${data} - ${element.descricao}`;
          listDropdown.push({ label: labelExibicao, value: element.id });
        });

        listDropdown.reverse();

        setListaPedidos(listDropdown);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getPedidos();
  }, [dispatch, idUsuario, pedido, navigate]);

  const handleFind = () => {
    getData();
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <Card title="Análise de Economia em Pedidos">
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Pedido
              </Input155px>
              <Dropdown
                autoFocus
                options={listaPedidos}
                value={pedido}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setPedido(e.target.value)}
              />
              &nbsp; &nbsp;
              <IconButton titulo="imprimir" title="Gerar Relatório" btnStyle="primary" onClick={handleFind} icon="print" />
            </div>
          </div>
        </div>
        <div className="row form-elo">
        </div>
      </Card>
    </>
  );
}
