import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';

import { get } from 'lodash';
import Card from '../../../components/card';
import Loading from '../../loading';
import axiosCoto from '../../../services/axiosCoto';

import 'react-toastify/dist/ReactToastify.css';
import { displayErrors } from '../../../util/diversos';

import { fillColumns } from '../../../components/table';
import IconButton from '../../../components/IconButton';

export default function ConsultaConfiguracao() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const [dataTableList, setDataTableList] = useState([]);
  const [selectedItens, setSelectedItens] = useState(null);

  const [dataTableColumn] = useState([
    { field: 'valorMensalComprador', header: 'Valor Mensal Comprador', sort: false },
    { field: 'valorMensalVendedor', header: 'Valor Mensal Vendedor', sort: false },
    { field: 'chavePix', header: 'Chave Pix', sort: false },
  ]);

  async function getData() {
    try {
      setIsLoading(true);

      let response = '';
      await axiosCoto.get('/configuracao').then((res) => {
        response = res.data;
      });

      setDataTableList(response);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const handleDelete = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      setShowConfirmDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para exclusão');
    }
  };

  useEffect(() => {
    getData('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  /* const handleRegister = () => {
    navigate('/cadastroconfiguracao');
  }; */

  const handleEdit = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      navigate(`/cadastroconfiguracao/${id}`);
    } else {
      toast.warning('Por favor, selecione o registro para edição');
    }
  };

  const confirmDelete = async () => {
    setShowConfirmDialog(false);

    try {
      setIsLoading(true);

      const idExclusao = get(selectedItens, 'id', 0);

      if (idExclusao > 0) {
        await axiosCoto.delete(`/configuracao/${idExclusao}`);

        const _itens = dataTableList.filter(val => val.id !== idExclusao);
        setDataTableList(_itens);

        toast.success('Registro excluído com sucesso');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  };

  const cancelDelete = () => {
    setShowConfirmDialog(false);
  };

  const confirmDialogFooter = (
    <div>
      <IconButton titulo="Sim" mensagem="Sim" btnStyle="primary" onClick={confirmDelete} icon="check" />
      <IconButton titulo="Não" mensagem="Não" btnStyle="danger" onClick={cancelDelete} icon="check" />
    </div>
  );

  const renderTablePrimeReact = useMemo(() => (
    <div className="datatable-configuracao">
      <div className="card">
        <DataTable
          value={dataTableList}
          size="small"
          stripedRows
          responsiveLayout="scroll"
          selectionMode="single"
          selection={selectedItens}
          onSelectionChange={e => setSelectedItens(e.value)}
          selectableRows
          dataKey="id"
          paginator
          rows={10}
          emptyMessage="Nenhum registro a ser exibido"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="{currentPage} de {totalPages}"
          rowsPerPageOptions={[10, 25, 50]}
        >
          {fillColumns(dataTableColumn)}
        </DataTable>
      </div>
    </div>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [dataTableList, selectedItens]);

  return (
    <>
      <Loading isLoading={isLoading} />
      <Card title="Configurações">
        <div className="col-12" style={{ textAlign: 'right' }}>
          <IconButton mensagem="Editar" btnStyle="success" onClick={handleEdit} icon="pencil" />
          <IconButton mensagem="Excluir" btnStyle="danger" onClick={handleDelete} icon="trash" />
        </div>
        <br />
        {renderTablePrimeReact}
        <Dialog
          header="Confirmação"
          visible={showConfirmDialog}
          style={{ width: '50vw' }}
          footer={confirmDialogFooter}
          onHide={() => setShowConfirmDialog(false)}
        >
          <p>
            Excluir o registro?
          </p>
        </Dialog>
      </Card>
    </>
  );
}
