/* eslint-disable prefer-template */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { ToggleButton } from 'primereact/togglebutton';
import { Dropdown } from 'primereact/dropdown';
import { orderBy } from 'lodash';
import { displayErrors } from '../../../util/diversos';
import Card from '../../../components/card';

import axiosCoto from '../../../services/axiosCoto';
import IconButton from '../../../components/IconButton';

import { Input155px, Input250px } from '../../../styles/inputGroup';

import Loading from '../../loading';

export default function RelatorioPedido() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const idUsuario = useSelector((state) => state.auth.user.id);

  const [pedido, setPedido] = useState();
  const [vendedor, setVendedor] = useState();
  // eslint-disable-next-line no-unused-vars
  const [totalPedido, setTotalPedido] = useState(0);
  const [mostrarProdutosZerados, setMostrarProdutosZerados] = useState(false);

  const [listaPedidos, setListaPedidos] = useState([]);
  const [listaVendedores, setListaVendedores] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  function headRows() {
    return [
      { numItemPedido: 'Item', Pedido: 'Pedido', Produto: 'Produto / Vendedor', Caixa: 'Valor Caixa ', Qtde: 'Qtde Por CX', UN: 'Valor UN', Total: 'Total' },
    ];
  }

  function headRowsICMSIPI() {
    return [
      { numItemPedido: 'Item', Pedido: 'Pedido', Produto: 'Produto / Vendedor', Caixa: 'Valor Caixa ', Qtde: 'Qtde Por CX', UN: 'Valor UN', Total: 'Total', percentualICMS: 'Perc. ICMS', percentualIPI: 'Perc. IPI' },
    ];
  }
  const [headerTabelaImpressao, setHeaderTabelaImpressao] = useState(headRows());

  async function getData() {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();
    try {
      setIsLoading(true);

      // pega os campos da busca
      const id = pedido;
      let idVendedor = vendedor;

      if (!idVendedor) idVendedor = 0;
      if (id > 0 && idVendedor >= 0) {
        const { data: _relatorio } = await axiosCoto.get(`/pedido/pedidopararelatoriocomprador/${id}/${idVendedor}`);

        const dataFechamento = `${('00' + _relatorio.data[2]).slice(-2)}/${('00' + _relatorio.data[1]).slice(-2)}/${_relatorio.data[0]}`;

        let pagamentoPrazo = '';
        if (_relatorio.pedidoVendedorPrazo.id > 0) {
          pagamentoPrazo = _relatorio.pedidoVendedorPrazo.pagamentoPrazo;
        } else {
          pagamentoPrazo = '';
        }

        let diasEntrega = '';
        let tipoEntrega = '';
        if (_relatorio.pedidoVendedorPrazo.id > 0) {
          diasEntrega = _relatorio.pedidoVendedorPrazo.diasEntrega;
          if (_relatorio.pedidoVendedorPrazo.tipoEntrega) {
            tipoEntrega = _relatorio.pedidoVendedorPrazo.tipoEntrega;
            diasEntrega += ` / ${tipoEntrega}`;
          }
        } else {
          diasEntrega = '';
        }

        let nomeVendedor = 'Todos';
        listaVendedores.forEach(element => {
          if (element.value === idVendedor) {
            nomeVendedor = element.label;
          }
        });

        // eslint-disable-next-line no-shadow
        let totalPedido = 0;
        const result = [];
        let data = {
          id: '0',
          Pedido: '0',
          QtdeAtacado: 0,
          ValorAtacado: 0,
          Produto: '',
          Caixa: '',
          Qtde: '0',
          UN: '0',
          Total: '0',
          percentualICMS: '',
          percentualIPI: '',
        };

        setHeaderTabelaImpressao(headRows());

        _relatorio.pedidoProdutos = _relatorio.pedidoProdutos.map((prod) => {
          prod.descricaoProduto = prod.descricaoProduto.toLowerCase();
          return prod;
        });

        _relatorio.pedidoProdutos = _.sortBy(_relatorio.pedidoProdutos, 'descricaoProduto');
        let contador = 1;
        if (idVendedor > 0) {
          for (let x = 0; x < _relatorio.pedidoProdutos.length; x++) {
            if (_relatorio.pedidoProdutos[x].valorTotalCompra > 0 || mostrarProdutosZerados) {
              _relatorio.pedidoProdutos[x].pedidoProdutoRespostas = _.sortBy(_relatorio.pedidoProdutos[x].pedidoProdutoRespostas, 'valorUnitario');
              for (let z = 0; z < _relatorio.pedidoProdutos[x].pedidoProdutoRespostas.length; z++) {
                if (_relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].idVendedor === idVendedor) {
                  let valorCompra = 0;
                  if (_relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].qtdePedido > 0 || mostrarProdutosZerados === 'Sim') {
                    data.numItemPedido = contador.toString();
                    data.Produto = _relatorio.pedidoProdutos[x].descricaoProduto.toString().toUpperCase();
                    data.Caixa = _relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].valorCaixa.toFixed(2).toString();
                    data.Qtde = _relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].qtdePorCaixa.toString();
                    data.UN = _relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].valorUnitario.toFixed(3).toString();
                    data.Pedido = _relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].qtdePedido.toString();
                    if (_relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].qtdeAtacado === null) {
                      data.QtdeAtacado = '0';
                    } else {
                      data.QtdeAtacado = _relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].qtdeAtacado.toString();
                    }
                    if (_relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].valorAtacado === null) {
                      data.ValorAtacado = '0';
                    } else {
                      data.ValorAtacado = _relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].valorAtacado.toFixed(2).toString();
                    }
                    if (parseFloat(data.Pedido) >= parseFloat(data.QtdeAtacado) && parseFloat(data.QtdeAtacado) > 0) {
                      data.Caixa = data.ValorAtacado;
                      data.UN = (parseFloat(data.ValorAtacado).toFixed(2) / parseFloat(data.Qtde).toFixed(2));
                      valorCompra = (parseFloat(data.ValorAtacado).toFixed(2) * parseFloat(data.Pedido).toFixed(2));
                    } else {
                      valorCompra = (parseFloat(data.Caixa).toFixed(2) * parseFloat(data.Pedido).toFixed(2));
                    }
                    data.Caixa = `R$ ${parseFloat(data.Caixa).toFixed(2).replace('.', ',').toString()}`;

                    data.UN = `R$ ${parseFloat(data.UN).toFixed(3).replace('.', ',').toString()}`;
                    data.Total = `R$ ${valorCompra.toFixed(2).replace('.', ',').toString()}`;
                    totalPedido += valorCompra;

                    if (_relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].percentualICMS === null) {
                      data.percentualICMS = '0';
                    } else {
                      data.percentualICMS = _relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].percentualICMS.toString();
                    }
                    if (_relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].percentualIPI === null) {
                      data.percentualIPI = '0';
                    } else {
                      data.percentualIPI = _relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].percentualIPI.toString();
                    }

                    if (parseFloat(data.percentualICMS) > 0 || parseFloat(data.percentualIPI) > 0) {
                      setHeaderTabelaImpressao(headRowsICMSIPI());
                    }

                    result.push({ ...data });
                    contador++;
                  }
                }
              }
            }
          }
        } else {
          _relatorio.pedidoProdutos = _relatorio.pedidoProdutos.map((prod) => {
            prod.descricaoProduto = prod.descricaoProduto.toLowerCase();
            return prod;
          });
          for (let x = 0; x < _relatorio.pedidoProdutos.length; x++) {
            if (_relatorio.pedidoProdutos[x].valorTotalCompra > 0 || mostrarProdutosZerados) {
              data.numItemPedido = contador.toString();
              data.Produto = _relatorio.pedidoProdutos[x].descricaoProduto.toString().toUpperCase();
              data.Pedido = _relatorio.pedidoProdutos[x].qtdeTotalCompra.toString();
              data.Total = `R$ ${_relatorio.pedidoProdutos[x].valorTotalCompra.toFixed(2).replace('.', ',').toString()}`;
              data.Caixa = '';
              data.Qtde = '';
              data.UN = '';

              result.push({ ...data });

              for (let z = 0; z < _relatorio.pedidoProdutos[x].pedidoProdutoRespostas.length; z++) {
                _relatorio.pedidoProdutos[x].pedidoProdutoRespostas = _.sortBy(_relatorio.pedidoProdutos[x].pedidoProdutoRespostas, 'valorUnitario');
                let valorCompra = 0;
                if (_relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].qtdePedido > 0 || mostrarProdutosZerados) {
                  data.numItemPedido = '';
                  data.Produto = _relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].razaoSocial;
                  data.Caixa = _relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].valorCaixa.toFixed(2).toString();
                  data.Qtde = _relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].qtdePorCaixa.toString();
                  data.UN = _relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].valorUnitario.toFixed(3).toString();
                  data.Pedido = _relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].qtdePedido.toString();
                  if (_relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].qtdeAtacado === null) {
                    data.QtdeAtacado = '0';
                  } else {
                    data.QtdeAtacado = _relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].qtdeAtacado.toString();
                  }
                  if (_relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].valorAtacado === null) {
                    data.ValorAtacado = '0';
                  } else {
                    data.ValorAtacado = _relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].valorAtacado.toFixed(2).toString();
                  }
                  if (parseFloat(data.Pedido) >= parseFloat(data.QtdeAtacado) && parseFloat(data.QtdeAtacado) > 0) {
                    data.Caixa = data.ValorAtacado;
                    data.UN = (parseFloat(data.ValorAtacado).toFixed(2) / parseFloat(data.Qtde).toFixed(2));
                    valorCompra = (parseFloat(data.ValorAtacado).toFixed(2) * parseFloat(data.Pedido).toFixed(2));
                  } else {
                    valorCompra = (parseFloat(data.Caixa).toFixed(2) * parseFloat(data.Pedido).toFixed(2));
                  }
                  data.Caixa = `R$ ${data.Caixa.replace('.', ',')}`;
                  data.Total = `R$ ${valorCompra.toFixed(2).replace('.', ',').toString()}`;
                  data.UN = `R$ ${parseFloat(data.UN).toFixed(3).replace('.', ',').toString()}`;
                  totalPedido += valorCompra;

                  if (_relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].percentualICMS === null) {
                    data.percentualICMS = '0';
                  } else {
                    data.percentualICMS = _relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].percentualICMS.toString();
                  }
                  if (_relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].percentualIPI === null) {
                    data.percentualIPI = '0';
                  } else {
                    data.percentualIPI = _relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].percentualIPI.toString();
                  }

                  if (parseFloat(data.percentualICMS) > 0 || parseFloat(data.percentualIPI) > 0) {
                    setHeaderTabelaImpressao(headRowsICMSIPI);
                  }

                  result.push({ ...data });
                }
              }

              /* data = {
                id: '',
                Produto: '',
                Caixa: '',
                Qtde: '',
                UN: '',
                Pedido: '',
                QtdeAtacado: '',
                ValorAtacado: '',
                Total: '',
              };

              result.push({ ...data }); */
              contador++;
            }
          }
        }

        // linha em branco entre produtos
        data = {
          id: '',
          Produto: '',
          Caixa: '',
          Qtde: '',
          UN: '',
          Pedido: '',
          ValorAtacado: '',
          QtdeAtacado: '',
          Total: '',
          percentualICMS: '',
          percentualIPI: '',
        };

        setTotalPedido(totalPedido);
        data.UN = 'Total';
        data.Total = `R$ ${totalPedido.toFixed(2).replace('.', ',').toString()}`;
        result.push({ ...data });

        doc.autoTable(
          {
            styles: { fontSize: 8 },
            margin: { top: 38, left: 5, right: 5 },
            theme: 'grid',
            head: headerTabelaImpressao,
            body: result,
            didParseCell: function (HookData) {
              if (HookData.row.cells.Qtde.text[0] === '') {
                HookData.cell.styles.fillColor = '#f4f0ec';
              }
            },
            didDrawPage: (dados) => {
              // Header
              doc.rect(5, 5, 200, 30); //  header
              doc.addImage('logo.png', 'JPEG', 10, 12, 25, 15);
              doc.setFontSize(20);
              doc.text('Relatório de Pedido', 75, 15);

              doc.setFontSize(10);
              doc.text('Número: ', 164, 12);
              doc.text(pedido.toString(), 180, 12);

              doc.text('Descrição:', 45, 20);
              doc.text(_relatorio.descricao, 63, 20);
              doc.text('Vendedor:', 45, 24);
              doc.text(nomeVendedor, 63, 24);
              if (idVendedor > 0) {
                doc.text('Forma de Pagamento:', 45, 28);
                doc.text(pagamentoPrazo, 82, 28);
              }
              if (idVendedor > 0) {
                doc.text('Dias Entrega / Tipo Entrega:', 45, 32);
                doc.text(diasEntrega, 90, 32);
              }
              // dias e tipo entrega

              doc.text('Fechamento:', 164, 32);
              doc.text(dataFechamento, 186, 32);

              const { pageSize } = doc.internal;
              const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
              doc.text(`Pag ${doc.internal.getNumberOfPages()}`, dados.settings.margin.right, pageHeight - 8);
              doc.text('Elo Informática Barbacena', 82, pageHeight - 8);
              const periodo = new Date();
              const mes = periodo.getMonth() + 1;
              doc.text('Impresso: ', 172, pageHeight - 8);
              doc.text((`${('00' + periodo.getDate()).slice(-2)}/${('00' + mes).slice(-2)}/${periodo.getFullYear()}`).toString(), 189, pageHeight - 8);
            },
          },
        )
        window.open(doc.output('bloburl'));
      } else {
        toast.warning('Nenhuma informação para o relatório.');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    async function getPedidos() {
      try {
        setIsLoading(true);
        const listDropdown = [];

        const { data: _pedidos } = await axiosCoto.get(`/pedido/?idUsuario=${idUsuario}&resumida=true`);

        _pedidos.forEach(element => {
          const dia = ('00' + element.data[2]).slice(-2);
          const mes = ('00' + element.data[1]).slice(-2);
          const data = (`${dia}/${mes}/${element.data[0]}`);
          const labelExibicao = `${element.id} - ${data} - ${element.descricao}`;
          listDropdown.push({ label: labelExibicao, value: element.id });
        });

        listDropdown.reverse();

        const _listDropDown = orderBy(listDropdown, 'id', 'desc');

        setListaPedidos(_listDropDown);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getPedidos();

    async function getVendedores() {
      setIsLoading(true);

      const listDropdown = [{ label: 'Todos', value: '' }];
      const id = pedido;

      try {
        if (id > 0) {
          const { data: _vendedores } = await axiosCoto.get(`/pedidoprodutoresposta/buscarvendedoresdistintos/${id}`);

          _vendedores.forEach(element => {
            const labelVendedor = `${element.email} - ${element.telefone1}`;
            listDropdown.push({ label: labelVendedor, value: element.id });
          });

          setListaVendedores(listDropdown);
        } else {
          setListaVendedores([]);
        }
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getVendedores();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, idUsuario, pedido]);

  const handleFind = () => {
    getData();
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <Card title="Relatório de Pedidos">
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Pedido
              </Input155px>
              <Dropdown
                autoFocus
                options={listaPedidos}
                value={pedido}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setPedido(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-7">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Vendedor
              </Input155px>
              <Dropdown
                options={listaVendedores}
                value={vendedor}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setVendedor(e.target.value)}
              />
            </div>
          </div>
          <div className="col-5">
            <div className="p-inputgroup">
              <Input250px className="p-inputgroup-addon">
                Mostrar produtos sem pedido
              </Input250px>
              <ToggleButton
                onLabel="Sim"
                offLabel="Não"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                checked={mostrarProdutosZerados}
                onChange={(e) => setMostrarProdutosZerados(e.value)}
              />
              &nbsp; &nbsp;
              <IconButton titulo="imprimir" title="Gerar Relatório" btnStyle="primary" onClick={handleFind} icon="print" />
            </div>
          </div>
        </div>
        <div className="row form-elo">
        </div>
      </Card>
    </>
  );
}
