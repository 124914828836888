import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as actions from '../store/modules/auth/actions';

export default function Home() {
  const dispatch = useDispatch();

  useEffect(() => {
    const newTitle = 'Home';
    dispatch(actions.titleHeaderChange({ newTitle }));
  }, [dispatch]);

  return (
    <div>

    </div>
  );
}
