import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { displayErrors } from '../../../util/diversos';

import Card from '../../../components/card';

import Loading from '../../loading';
import { Input155px, Input250px } from '../../../styles/inputGroup';
import axiosCoto from '../../../services/axiosCoto';
import IconButton from '../../../components/IconButton';

import { obterListaTipoUsuarioDestinatario } from '../../../services/usuarioService';

export default function CadastroAviso() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(useParams(), 'id', 0);

  const [assunto, setAssunto] = useState();
  const [mensagem, setMensagem] = useState();
  const [tipoUsuarioDestinatario, setTipoUsuarioDestinatario] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosCoto.get(`/bilhete/${id}`);

        setAssunto(data.assunto);
        setMensagem(data.mensagem);
        setTipoUsuarioDestinatario(data.setTipoUsuarioDestinatario);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  async function enviarAviso() {
    let salvoComsucesso = false;
    let shippingData = '';
    try {
      setIsLoading(true);

      shippingData = {
        assunto,
        mensagem,
        tipoUsuarioDestinatario,
      };

      if (id > 0) {
        await axiosCoto.put(`/aviso/${id}`, shippingData);
        toast.success('Aviso enviado com sucesso.');
      } else {
        await axiosCoto.post('/aviso', shippingData);
        toast.success('Aviso cadastrado com sucesso.');
        salvoComsucesso = true;
        navigate('/consultaaviso');
      }
    } catch (err) {
      const errors = get(err.response.data, 'errors', []);
      if (errors.length > 0) {
        errors.map(error => toast.error(error));
      } else {
        toast.error('Aviso não pode ser enviado.');
      }
    } finally {
      setIsLoading(false);
    }

    if (salvoComsucesso) {
      await axiosCoto.put('/aviso/enviaremail', shippingData);
      toast.info('Aviso enviado com sucesso.');
    }
  }

  const handleEnviar = (e) => {
    e.preventDefault();

    let formErros = false;
    if (assunto === null) {
      formErros = true;
      toast.error('Assunto não pode estar em branco.');
    }
    if (mensagem === null) {
      formErros = true;
      toast.error('Mensagem não pode estar em branco.');
    }
    if (tipoUsuarioDestinatario === null) {
      formErros = true;
      toast.error('Destinatário deve ser selecionado.');
    }
    if (formErros) return;

    enviarAviso();
  };

  const handleVoltar = (e) => {
    e.preventDefault();
    // eslint-disable-next-line no-restricted-globals
    history.back();
  };

  const listaTipoUsuarioDestinatario = obterListaTipoUsuarioDestinatario();

  return (
    <>
      <Loading isLoading={isLoading} />
      <Card title="Cadastro Aviso">
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Assunto*
              </Input155px>
              <InputText
                autoFocus
                maxLength={250}
                value={assunto}
                onChange={e => setAssunto(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input250px className="p-inputgroup-addon">
                Mensagem (250 letras)*
              </Input250px>
              <InputText
                maxLength={250}
                value={mensagem}
                onChange={e => setMensagem(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Destinatário *
              </Input155px>
              <Dropdown
                options={listaTipoUsuarioDestinatario}
                value={tipoUsuarioDestinatario}
                onChange={e => setTipoUsuarioDestinatario(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ textAlign: 'right' }}>
            <IconButton titulo="Enviar" mensagem="Salvar/Enviar" btnStyle="success" onClick={handleEnviar} icon="check" />
            <IconButton titulo="Cancelar" mensagem="Cancelar" btnStyle="danger" onClick={handleVoltar} icon="times" />
          </div>
        </div>
      </Card>
    </>
  );
}
