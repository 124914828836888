import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import '../styles/navbar.css';
import '../styles/menubar.css';

import { Menubar } from 'primereact/menubar';
import axiosCoto from '../services/axiosCoto';
import * as actions from '../store/modules/auth/actions';
import store from '../store/store';

export default function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [licencaValida, setLicencaValida] = useState(false);

  const { isLoggedIn } = store.getState().auth;

  function logout() {
    dispatch(actions.loginFailure());
    navigate('/login');
  }

  const itemsAdmin = [
    {
      label: 'Início',
      icon: 'pi pi-fw pi-home',
      command: () => { navigate('/homeadm'); },
    },
    {
      label: 'Usuários',
      icon: 'pi pi-fw pi-users',
      command: () => { navigate('/consultausuario'); },
    },
    {
      label: 'Cotações',
      icon: 'pi pi-fw pi-chart-line',
      command: () => { navigate('/consultacotacaoadministrador'); },
    },
    {
      label: 'Pedidos',
      icon: 'pi pi-fw pi-book',
      command: () => { navigate('/consultapedidoadministrador'); },
    },
    {
      label: 'Financeiro',
      icon: 'pi pi-fw pi-dollar',
      command: () => { navigate('/consultafinanceiro'); },
    },
    {
      label: 'Relatórios',
      icon: 'pi pi-fw pi-print',
      items: [
        {
          label: 'Financeiro',
          icon: 'pi pi-fw pi-dollar',
          command: () => { navigate('/relatoriofinanceiro'); },
        },
      ],
    },
    {
      label: 'Outros',
      icon: 'pi pi-fw pi-align-justify',
      items: [
        {
          label: 'Avisos',
          icon: 'pi pi-fw pi-envelope',
          command: () => { navigate('/consultaaviso'); },
        },
        {
          label: 'Ramo Negócio',
          icon: 'pi pi-fw pi-slack',
          command: () => { navigate('/consultaramonegocio'); },
        },
        {
          label: 'Alterar Senha',
          icon: 'pi pi-fw pi-key',
          command: () => { navigate('/alterarsenhaadministrador'); },
        },
        {
          label: 'Configuração',
          icon: 'pi pi-fw pi-cog',
          command: () => { navigate('/consultaconfiguracao'); },
        },
        {
          label: 'Sugestões e Críticas',
          icon: 'pi pi-fw pi-comments',
          command: () => { navigate('/consultabilheteadministrador'); },
        },
      ],
    },
    {
      label: 'Sair',
      icon: 'pi pi-fw pi-power-off',
      command: () => logout(),
    },
  ];

  const itemsComprador = [
    {
      label: 'Início',
      icon: 'pi pi-fw pi-home',
      command: () => { navigate('/homecomprador'); },
    },
    {
      label: 'Cotacões',
      icon: 'pi pi-fw pi-chart-line',
      command: () => { navigate('/consultacotacao'); },
    },
    {
      label: 'Pedidos',
      icon: 'pi pi-fw pi-book',
      command: () => { navigate('/consultapedido'); },
    },
    {
      label: 'Produtos',
      icon: 'pi pi-fw pi-shopping-bag',
      command: () => { navigate('/consultaproduto'); },
    },
    {
      label: 'Vendedores',
      icon: 'pi pi-fw pi-user',
      command: () => { navigate('/consultavendedor'); },

    },
    {
      label: 'Relatórios',
      icon: 'pi pi-fw pi-print',
      items: [
        {
          label: 'Pedidos',
          icon: 'pi pi-fw pi-book',
          command: () => { navigate('/relatoriopedido'); },
        },
        {
          label: 'Análise Compras',
          icon: 'pi pi-fw pi-book',
          command: () => { navigate('/relatoriopedidoanalitico'); },
        },
        {
          label: 'Financeiro',
          icon: 'pi pi-fw pi-book',
          command: () => { navigate('/relatoriofinanceirocomprador'); },
        },
      ],
    },
    {
      label: 'Outros',
      icon: 'pi pi-fw pi-align-justify',
      items: [
        {
          label: 'Financeiro',
          icon: 'pi pi-fw pi-dollar',
          command: () => { navigate('/financeirocomprador'); },
        },
        {
          label: 'Alterar Dados',
          icon: 'pi pi-fw pi-user-edit',
          command: () => { navigate('/alterardadoscomprador'); },
        },
        {
          label: 'Alterar Senha',
          icon: 'pi pi-fw pi-key',
          command: () => { navigate('/alterarsenhacomprador'); },
        },
        {
          label: 'Sugestões e Críticas',
          icon: 'pi pi-fw pi-comments',
          command: () => { navigate('/consultabilhetecomprador'); },
        },
      ],
    },
    {
      label: 'Sair',
      icon: 'pi pi-fw pi-power-off',
      command: () => logout(),
    },
  ];

  const itemsCompradorBloqueado = [
    {
      label: 'Início',
      icon: 'pi pi-fw pi-home',
      command: () => { navigate('/homecomprador'); },
    },
    {
      label: 'Financeiro',
      icon: 'pi pi-fw pi-dollar',
      command: () => { navigate('/financeirocomprador'); },
    },
    {
      label: 'Alterar Recebimentos Emails',
      icon: 'pi pi-fw pi-envelope',
      command: () => { navigate('/alterarreceberemailscomprador'); },
    },
    {
      label: 'Sair',
      icon: 'pi pi-fw pi-power-off',
      command: () => logout(),
    },
  ];

  const itemsVendedorBloqueado = [
    {
      label: 'Início',
      icon: 'pi pi-fw pi-home',
      command: () => { navigate('/homevendedor'); },
    },
    {
      label: 'Financeiro',
      icon: 'pi pi-fw pi-dollar',
      command: () => { navigate('/financeirovendedor'); },
    },
    {
      label: 'Alterar Recebimentos Emails',
      icon: 'pi pi-fw pi-envelope',
      command: () => { navigate('/alterarreceberemailsvendedor'); },
    },
    {
      label: 'Sair',
      icon: 'pi pi-fw pi-power-off',
      command: () => logout(),
    },
  ];

  const itemsVendedor = [
    {
      label: 'Início',
      icon: 'pi pi-fw pi-home',
      command: () => { navigate('/homevendedor'); },
    },
    {
      label: 'Compradores',
      icon: 'pi pi-fw pi-user',
      command: () => { navigate('/consultacomprador'); },
    },
    {
      label: 'Cotacões',
      icon: 'pi pi-fw pi-chart-line',
      command: () => { navigate('/consultacotacaovendedor'); },
    },
    {
      label: 'Pedidos',
      icon: 'pi pi-fw pi-book',
      command: () => { navigate('/consultapedidovendedor'); },
    },
    {
      label: 'Relatórios',
      icon: 'pi pi-fw pi-print',
      items: [
        {
          label: 'Pedido',
          icon: 'pi pi-fw pi-book',
          command: () => { navigate('/relatoriopedidovendedor'); },
        },
        {
          label: 'Financeiro',
          icon: 'pi pi-fw pi-book',
          command: () => { navigate('/relatoriofinanceirovendedor'); },
        },
      ],
    },
    {
      label: 'Outros',
      icon: 'pi pi-fw pi-align-justify',
      items: [
        {
          label: 'Financeiro',
          icon: 'pi pi-fw pi-money-bill',
          command: () => { navigate('/financeirovendedor'); },
        },
        {
          label: 'Alterar Dados',
          icon: 'pi pi-fw pi-user-edit',
          command: () => { navigate('/alterardadosvendedor'); },
        },
        {
          label: 'Alterar Senha',
          icon: 'pi pi-fw pi-key',
          command: () => { navigate('/alterarsenhavendedor'); },
        },
        {
          label: 'Sugestões e Críticas',
          icon: 'pi pi-fw pi-comments',
          command: () => { navigate('/consultabilhetevendedor'); },
        },
      ],
    },
    {
      label: 'Sair',
      icon: 'pi pi-fw pi-power-off',
      command: () => logout(),
    },
  ];

  const { tipoUsuario } = store.getState().auth.user;
  const { email } = store.getState().auth.user;
  const { id } = store.getState().auth.user;

  async function verificarValidade() {
    const { data } = await axiosCoto.get(`/usuario/verificarvalidade/${id}`);
    setLicencaValida(data);
  }

  useEffect(() => {
    if (tipoUsuario !== undefined && tipoUsuario !== 'ADMINISTRADOR') {
      verificarValidade();
    }
  });

  const { dataLicenca } = store.getState().auth.user;

  const menuItens = () => {
    if (tipoUsuario === 'ADMINISTRADOR' && isLoggedIn) {
      return itemsAdmin;
    }
    if (tipoUsuario === 'COMPRADOR' && isLoggedIn) {
      if (licencaValida) {
        return itemsComprador;
      }
      return itemsCompradorBloqueado;
    }
    if (tipoUsuario === 'VENDEDOR' && isLoggedIn) {
      if (licencaValida) {
        return itemsVendedor;
      }
      return itemsVendedorBloqueado;
    }
    return '';
  };

  let dataLicencaComBarras = '';
  if (dataLicenca) {
    dataLicencaComBarras = ('00' + dataLicenca[2]).slice(-2)+'/'+('00' + dataLicenca[1]).slice(-2)+'/'+dataLicenca[0];
  }
  const start = <img alt="logo" src="logo.png" height="40" className="mr-2" style={{ marginLeft: '8px' }}></img>;
  const end = (
    <div>
      {tipoUsuario
        ? (
          <div style={{ color: '#fff' }}>
            Tipo: &nbsp;
            { tipoUsuario }
          </div>
        )
        : ('')}
      <div style={{ color: '#fff' }}>
        Email: &nbsp;
        {email}
        &nbsp; &nbsp;
      </div>
    </div>
  );

  return (
    <div>
      {tipoUsuario
        ? (
          <header className="navbar navbar-expand navbar-dark flex-column flex-md-row" style={{ backgroundColor: '#2c3e50', height: '70px', zIndex: '99' }}>
            <div className="col-12">
              <Menubar model={menuItens()} start={start} end={end} />
            </div>
          </header>
        )
        : (
          <header style={{ backgroundColor: '#2c3e50', height: '70px' }}>
            <div className="col-12">
              <img alt="logo" src="logo.png" height="40" className="mr-2" style={{ marginLeft: '8px', marginTop: '15px', zIndex: '99' }}></img>
            </div>
          </header>
        )}
    </div>
  );
}
