import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { get, orderBy } from 'lodash';
import haversine from 'haversine';

import { TabView, TabPanel } from 'primereact/tabview';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Column } from 'primereact/column';

import 'react-toastify/dist/ReactToastify.css';
import { displayErrors } from '../../../util/diversos';

import { fillColumns } from '../../../components/table';
import { Input155px, InputCampoBusca100px } from '../../../styles/inputGroup';
import IconButton from '../../../components/IconButton';

import { obterListaRejeicaoVendedor } from '../../../services/conviteRejeicaoService';

import Card from '../../../components/card';
import Loading from '../../loading';
import axiosCoto from '../../../services/axiosCoto';

export default function ConsultaProduto() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const idVendedor = useSelector((state) => state.auth.user.id);
  const latitudeVendedor = useSelector((state) => state.auth.user.latitude);
  const longitudeVendedor = useSelector((state) => state.auth.user.longitude);

  const listaRejeicaoConviteVendedor = obterListaRejeicaoVendedor();
  const [rejeicaoVendedorMensagem, setRejeicaoVendedorMensagem] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [dataTableList, setDataTableList] = useState([]);
  const [dataTableListConvidar, setDataTableListConvidar] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showConfirmDialogConvite, setShowConfirmDialogConvite] = useState(false);
  const [showRejeitarDialogConvite, setShowRejeitarDialogConvite] = useState(false);
  const [selectedItens, setSelectedItens] = useState(null);
  const [selectedItensConvidar, setSelectedItensConvidar] = useState(null);
  const [findField, setFindField] = useState('');
  const [findFieldConvidar, setFindFieldConvidar] = useState('');
  const [sortField, setSortField] = useState('razaoSocial');
  const [sortOrder, setSortOrder] = useState(null);

  const [dataTableColumn] = useState([
    { field: 'razaoSocial', header: 'Nome', sort: true },
    { field: 'email', header: 'E-mail', sort: true },
    { field: 'telefone1', header: 'Telefone', sort: true },
    { field: 'cidade', header: 'Cidade', sort: true },
    { field: 'pendente', header: 'Situação', sort: true },
    { field: 'rejeicaoComprador', header: '', sort: false, hidden: true },
  ]);

  async function getData(searchFilter) {
    try {
      setIsLoading(true);

      let params = `/vinculocompradorvendedor/?idVendedor=${idVendedor}`;

      if (searchFilter && searchFilter.findField !== '') {
        params = `${params}&${sortField}=${searchFilter.findField}`;
      }

      const response = await axiosCoto.get(params);

      const listaCompradores = [];
      for (let x = 0; x < response.data.compradores.length; x++) {
        // aguardando aceite do vendedor
        if (response.data.compradores[x].aceiteComprador && response.data.compradores[x].aceiteVendedor) {
          response.data.compradores[x].pendente = 'Ok';
        } else if (response.data.compradores[x].aceiteComprador) {
          response.data.compradores[x].pendente = 'Aguardando você aceitar o convite...';
        } else {
          response.data.compradores[x].pendente = 'Aguardando aceite do comprador...';
        }

        if (response.data.compradores[x].rejeicaoComprador) {
          if (response.data.compradores[x].rejeicaoCompradorMensagem === 'Bloquear') {
            response.data.compradores[x].pendente = 'Ok';
          } else {
            response.data.compradores[x].pendente = `Convite Rejeitado. ${response.data.compradores[x].rejeicaoCompradorMensagem}`;
          }
        }

        if (response.data.compradores[x].rejeicaoVendedor) {
          response.data.compradores[x].pendente = `Convite Rejeitado. ${response.data.compradores[x].rejeicaoVendedorMensagem}`;
        }

        listaCompradores.push(response.data.compradores[x]);
      }
      setDataTableList(listaCompradores);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  async function getDataConvidar(searchFilterConvidar) {
    try {
      setIsLoading(true);
      let params = `/usuario/?idVendedor=${idVendedor}`;

      if (searchFilterConvidar && searchFilterConvidar.findFieldConvidar !== '') {
        params = `${params}&email=${searchFilterConvidar.findFieldConvidar}`;
      }

      let response = '';
      await axiosCoto.get(params).then((res) => {
        response = res.data;
      }).catch(() => {
        toast.warning('Erro na conexão. Atualize a página ou faça o login novamente.');
      });

      let vinculos = '';
      await axiosCoto.get(`/vinculocompradorvendedor/?idVendedor=${idVendedor}`).then((res) => {
        vinculos = res.data;
      }).catch(() => {
        toast.warning('Erro na conexão. Atualize a página ou faça o login novamente.');
      });

      const encontreVinculo = function (lista, id) {
        const existe = lista.find((value) => value.id === id);
        return existe;
      };

      const listaCompradoresConvidar = [];
      for (let x = 0; x < response.length; x++) {
        const start = {
          latitude: latitudeVendedor,
          longitude: longitudeVendedor,
        };
        const end = {
          latitude: response[x].latitude,
          longitude: response[x].longitude,
        };

        const distanciaEmKm = haversine(start, end, { unit: 'km' });

        if (distanciaEmKm >= 0) {
          if (response[x].raioAtuacao >= distanciaEmKm && response[x].tipoUsuario === 'COMPRADOR') {
            // verifica se vinculo existe
            if (!encontreVinculo(vinculos.compradores, response[x].id)) {
              listaCompradoresConvidar.push(response[x]);
            }
          }
        }
      }

      setDataTableListConvidar(listaCompradoresConvidar);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const handleFind = () => {
    const searchFilter = {
      findField,
      idVendedor,
    };
    setFindField('');
    getData(searchFilter);
  };

  useEffect(() => {
    getData();
    getDataConvidar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleDelete = (e) => {
    e.preventDefault();
    const id = get(selectedItens, 'id', 0);
    const rejeicaoComprador = get(selectedItens, 'rejeicaoComprador', '');

    if (id > 0) {
      if (!rejeicaoComprador) {
        setShowConfirmDialog(true);
      } else {
        toast.info('Não é possível excluir um convite rejeitado.');
      }
    } else {
      toast.info('Por favor, selecione o registro para exclusão.');
    }
  };

  const handleAceitarConvite = (e) => {
    e.preventDefault();
    const id = get(selectedItens, 'id', 0);
    const dataConviteComprador = get(selectedItens, 'dataConviteComprador', '');
    const rejeicaoComprador = get(selectedItens, 'rejeicaoComprador', '');
    const pendente = get(selectedItens, 'pendente', '');

    if (id > 0) {
      if (dataConviteComprador !== null && pendente !== 'Ok') {
        if (!rejeicaoComprador) {
          setShowConfirmDialogConvite(true);
        } else {
          toast.info('Não é possível aceitar um convite rejeitado.');
        }
      } else {
        toast.info('Convite será ou está aceito pelo comprador.');
      }
    } else {
      toast.info('Selecione um comprador para aceitar o convite.');
    }
  };

  const handleRejeitarConvite = (e) => {
    e.preventDefault();
    const id = get(selectedItens, 'id', 0);
    const dataConviteComprador = get(selectedItens, 'dataConviteComprador', '');
    const rejeicaoComprador = get(selectedItens, 'rejeicaoComprador', 0);
    const pendente = get(selectedItens, 'pendente', '');

    if (rejeicaoComprador) {
      toast.info('Convite rejeitado não pode ser editado.');
      return;
    }

    if (id > 0) {
      if (dataConviteComprador !== null && pendente === 'Ok') {
        setShowRejeitarDialogConvite(true);
      } else {
        toast.warning('Não foi possível rejeitar convite enviado.');
      }
    } else {
      toast.warning('Selecione um comprador para rejeitar o convite.');
    }
  };

  const cancelAceitarConvite = () => {
    setShowConfirmDialogConvite(false);
  };

  const cancelRejeitarConvite = () => {
    setShowRejeitarDialogConvite(false);
  };

  const cancelDelete = () => {
    setShowConfirmDialog(false);
  };

  async function handleConvidar(e) {
    let vinculoSucesso = false;
    let emailComprador = '';
    try {
      setIsLoading(true);

      const idComprador = get(e, 'id', 0);
      emailComprador = get(e, 'email', '');
      const dataConviteVendedor = new Date().toISOString();
      const aceiteVendedor = true;
      const shippingData = {
        idVendedor,
        idComprador,
        dataConviteVendedor,
        aceiteVendedor,
      };

      if (idComprador > 0) {
        await axiosCoto.post('/vinculocompradorvendedor/', shippingData);
        toast.success('Convite enviado ao Comprador com sucesso.');
        getData('');
        getDataConvidar('');
      }

      vinculoSucesso = true;
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }

    if (vinculoSucesso) {
      try {
        await axiosCoto.put(`/vinculocompradorvendedor/enviaremailconvite/${emailComprador}`);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }
  }

  const confirmDelete = async () => {
    setShowConfirmDialog(false);
    try {
      setIsLoading(true);
      const id = get(selectedItens, 'id', 0);
      const response = await axiosCoto.get(`/vinculocompradorvendedor/idvendedoridcomprador/${idVendedor}/${id}`);
      const idExclusao = response.data.id;

      if (idExclusao > 0) {
        await axiosCoto.delete(`/vinculocompradorvendedor/${idExclusao}`);

        const _itens = dataTableList.filter(val => val.id !== id);
        setDataTableList(_itens);
        toast.success('Convite deletado com sucesso.');
        getDataConvidar();
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmAceitarConvite = async () => {
    setShowConfirmDialogConvite(false);
    try {
      setIsLoading(true);

      const idComprador = get(selectedItens, 'id', 0);
      const dataConviteComprador = get(selectedItens, 'dataConviteComprador', '');
      const aceiteComprador = get(selectedItens, 'aceiteComprador', '');
      const dataConviteVendedor = new Date().toISOString();
      const aceiteVendedor = true;

      const shippingData = {
        idVendedor,
        idComprador,
        aceiteVendedor,
        aceiteComprador,
        dataConviteComprador,
        dataConviteVendedor,
        rejeicaoComprador: false,
        rejeicaoVendedor: false,
        rejeicaoVendedorMensagem: null,
      };

      if (idComprador > 0) {
        await axiosCoto.post('/vinculocompradorvendedor/atualizarconvite', shippingData);
        toast.success('Convite aceito com sucesso.');
        getData('');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmRejeitarConvite = async () => {
    if (rejeicaoVendedorMensagem == null) {
      toast.warning('Rejeicao não selecionada.');
      return;
    }

    setShowRejeitarDialogConvite(false);
    try {
      setIsLoading(true);

      const idComprador = get(selectedItens, 'id', 0);
      const dataConviteComprador = get(selectedItens, 'dataConviteComprador', '');
      const aceiteComprador = get(selectedItens, 'aceiteComprador', '');
      const dataConviteVendedor = new Date().toISOString();
      const aceiteVendedor = true;

      const shippingData = {
        idVendedor,
        idComprador,
        aceiteVendedor,
        aceiteComprador,
        dataConviteComprador,
        dataConviteVendedor,
        rejeicaoVendedor: true,
        rejeicaoVendedorMensagem,
        rejeicaoComprador: false,
      };

      if (idComprador > 0) {
        await axiosCoto.post('/vinculocompradorvendedor/atualizarconvite', shippingData);
        toast.success('Rejeição enviada com sucesso.');
        getData();
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmDialogFooter = (
    <div>
      <IconButton titulo="Sim" mensagem="Sim" btnStyle="primary" onClick={confirmDelete} icon="check" />
      <IconButton titulo="Não" mensagem="Não" btnStyle="danger" onClick={cancelDelete} icon="times" />
    </div>
  );

  const confirmDialogFooterConvite = (
    <div>
      <IconButton titulo="Sim" mensagem="Sim" btnStyle="primary" onClick={confirmAceitarConvite} icon="check" />
      <IconButton titulo="Não" mensagem="Não" btnStyle="danger" onClick={cancelAceitarConvite} icon="times" />
    </div>
  );

  const rejeitarDialogFooterConvite = (
    <div>
      <IconButton titulo="Sim" mensagem="Sim" btnStyle="primary" onClick={confirmRejeitarConvite} icon="check" />
      <IconButton titulo="Não" mensagem="Não" btnStyle="danger" onClick={cancelRejeitarConvite} icon="times" />
    </div>
  );

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  const handleFindConvidar = () => {
    const searchFilterConvidar = {
      findFieldConvidar,
      idVendedor,
    };

    setFindFieldConvidar('');

    getDataConvidar(searchFilterConvidar);
  };

  const actionBodyTemplate = (rowData) => <IconButton titulo="" mensagem="Convidar" btnStyle="primary" onClick={() => handleConvidar(rowData)} icon="plus" />;

  return (
    <>
      <Loading isLoading={isLoading} />
      <Card title="Compradores">
        <TabView>
          <TabPanel header="Convidar" leftIcon="pi pi-plus">
            <div>
              <div className="row align-items-center">
                <div className="col-12">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Buscar por Email:
                    </Input155px>
                    <InputText
                      autoFocus
                      maxLength={250}
                      value={findFieldConvidar}
                      onChange={e => setFindFieldConvidar(e.target.value)}
                    />
                    &nbsp; &nbsp;
                    <IconButton titulo="" mensagem="Buscar" btnStyle="success" onClick={handleFindConvidar} icon="search" />
                  </div>
                </div>
              </div>
            </div>
            <DataTable
              value={dataTableListConvidar}
              size="small"
              stripedRows
              sortField={sortField}
              setOrder={sortOrder}
              onSort={onSort}
              responsiveLayout="scroll"
              selectionMode="single"
              selection={selectedItensConvidar}
              onSelectionChange={e => setSelectedItensConvidar(e.value)}
              selectableRows
              dataKey="id"
              paginator
              rows={10}
              emptyMessage="Nenhum registro a ser exibido"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{currentPage} de {totalPages}"
              rowsPerPageOptions={[10, 25, 50]}
            >
              <Column field="razaoSocial" header="Nome"></Column>
              <Column field="email" header="Email" sortable></Column>
              <Column field="telefone1" header="Telefone"></Column>
              <Column field="cidade" header="Cidade"></Column>
              <Column field="ramoNegocio.descricao" header="Ramo Negócio"></Column>
              <Column body={actionBodyTemplate} exportable={false} style={{ width: '90px' }}></Column>
            </DataTable>
          </TabPanel>
          <TabPanel header="Convidados / Convites" leftIcon="pi pi-pencil">
            <div>
              <div className="row align-items-center">
                <div className="col-10">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Buscar por:
                    </Input155px>
                    <InputCampoBusca100px className="p-inputgroup-addon">
                      {dataTableColumn.find(element => element.field === sortField).header}
                    </InputCampoBusca100px>
                    <InputText
                      autoFocus
                      maxLength={250}
                      value={findField}
                      onChange={e => setFindField(e.target.value)}
                    />
                    <IconButton titulo="" mensagem="Buscar" btnStyle="success" onClick={handleFind} icon="search" />
                  </div>
                </div>
                <div className="col-2" style={{ textAlign: 'right' }}>
                  <IconButton titulo="" mensagem="Aceitar Convite" btnStyle="success" onClick={handleAceitarConvite} icon="check-square" />
                  <IconButton titulo="" mensagem="Rejeitar Convite" btnStyle="warning" onClick={handleRejeitarConvite} icon="times-circle" />
                  <IconButton titulo="" mensagem="Excluir" btnStyle="danger" onClick={handleDelete} icon="trash" />
                </div>
              </div>
            </div>
            <br />

            <DataTable
              value={dataTableList}
              size="small"
              stripedRows
              sortField={sortField}
              setOrder={sortOrder}
              onSort={onSort}
              responsiveLayout="scroll"
              selectionMode="single"
              selection={selectedItens}
              onSelectionChange={e => setSelectedItens(e.value)}
              selectableRows
              dataKey="id"
              paginator
              rows={10}
              emptyMessage="Nenhum registro a ser exibido"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{currentPage} de {totalPages}"
              rowsPerPageOptions={[10, 25, 50]}
            >
              {fillColumns(dataTableColumn)}
            </DataTable>
          </TabPanel>
        </TabView>
      </Card>

      <Dialog
        header="Confirmação"
        visible={showConfirmDialog}
        style={{ width: '50vw' }}
        footer={confirmDialogFooter}
        onHide={() => setShowConfirmDialog(false)}
      >
        <p>
          Excluir o registro?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação de convite pendente"
        visible={showConfirmDialogConvite}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterConvite}
        onHide={() => setShowConfirmDialogConvite(false)}
      >
        <p>
          Aceitar o convite pendente?
        </p>
      </Dialog>

      <Dialog
        header="Rejeitar Convite"
        visible={showRejeitarDialogConvite}
        style={{ width: '50vw' }}
        footer={rejeitarDialogFooterConvite}
        onHide={() => setShowRejeitarDialogConvite(false)}
      >
        <p> Motivo da rejeição: </p>
        <div className="col-8">
          <div className="p-inputgroup">
            <Input155px className="p-inputgroup-addon">
              Rejeição
            </Input155px>
            <Dropdown
              options={listaRejeicaoConviteVendedor}
              value={rejeicaoVendedorMensagem}
              onChange={e => setRejeicaoVendedorMensagem(e.target.value)}
            />
          </div>
        </div>
        <br />
        <p>
          Rejeitar o convite pendente?
        </p>
      </Dialog>
    </>
  );
}
