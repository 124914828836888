export function obterListaTipoUsuario() {
  return (
    [
      { label: 'Selecione...', value: '' },
      { label: 'Administrador', value: 'ADMINISTRADOR' },
      { label: 'Comprador', value: 'COMPRADOR' },
      { label: 'Vendedor', value: 'VENDEDOR' },
    ]
  );
}

export function obterListaTipoUsuarioLogin() {
  return (
    [
      { label: 'Selecione...', value: '' },
      { label: 'Comprador', value: 'COMPRADOR' },
      { label: 'Vendedor', value: 'VENDEDOR' },
    ]
  );
}

export function obterListaTipoUsuarioDestinatario() {
  return (
    [
      { label: 'Todos', value: 'AMBOS' },
      { label: 'Comprador', value: 'COMPRADOR' },
      { label: 'Vendedor', value: 'VENDEDOR' },
    ]
  );
}

export function obterListaNumRespostasPorProduto() {
  return (
    [
      { label: 'Todos', value: '0' },
      { label: '3', value: '3' },
    ]
  );
}
