import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { jsPDF } from 'jspdf';
import { get, orderBy } from 'lodash';

import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

import 'react-toastify/dist/ReactToastify.css';
import { displayErrors } from '../../../util/diversos';

import { fillColumns } from '../../../components/table';
import { Input100px, Input155px, Input250px, InputCampoBusca100px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import Loading from '../../loading';
import axiosCoto from '../../../services/axiosCoto';
import IconButton from '../../../components/IconButton';

export default function ConsultaCotacao() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const idUsuario = useSelector((state) => state.auth.user.id);

  const [isLoading, setIsLoading] = useState(false);
  const [euConcordo, setEuConcordo] = useState('');
  const [cotacaoSelecionada, setCotacaoSelecionada] = useState(null);
  const [dataTableListaCotacoes, setDataTableListaCotacoes] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showConfirmFecharPedidoDialog, setShowConfirmFecharPedidoDialog] = useState(false);
  const [showInformacoesCotacaoDialog, setShowInformacoesCotacaoDialog] = useState(false);

  const [cotacaoProdutos, setCotacaoProdutos] = useState([]);
  const [numProdutos, setNumProdutos] = useState(0);
  const [numRespostas, setNumRespostas] = useState(0);
  const [listaVendedores, setListaVendedores] = useState([]);
  const [listaProdutosSemRespostas, setListaProdutosSemRespostas] = useState([]);

  const [findField, setFindField] = useState('');
  const [sortField, setSortField] = useState('descricao');
  const [sortOrder, setSortOrder] = useState(null);
  const [dataTableColumns] = useState([
    { field: 'descricao', header: 'Descricão', sort: true },
    { field: 'observacao', header: 'Observação', sort: true },
    { field: 'dataAbertura', header: 'Abertura', sort: false },
    { field: 'dataFechamento', header: 'Previsão Fechamento', sort: false },
    { field: 'status', header: 'status', sort: false },
    { field: 'padrao', header: 'Padrao', sort: false },
  ]);

  function verificarId(id) {
    if (id === 0) {
      toast.info('Nenhuma cotação selecionada.');
      return false;
    }
    return true;
  }

  async function getData(searchFilter) {
    try {
      setIsLoading(true);
      let params = `/cotacao/paraconsultacomprador/?idUsuario=${idUsuario}`;
      if (searchFilter && searchFilter.findField !== '') {
        params = `${params}&${sortField}=${searchFilter.findField}`;
      }
      await axiosCoto.get(params)
        .then((res) => {
          setDataTableListaCotacoes(res.data);
          if (res.data.length > 0) {
            setCotacaoProdutos(res.data[0].cotacaoProdutos);
          }
        });
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const handleFind = () => {
    const searchFilter = {
      findField,
      idUsuario,
    };

    setFindField('');

    getData(searchFilter);
  };

  useEffect(() => {
    getData('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleRegister = () => {
    navigate('/cadastrocotacao');
  };

  const handleEdit = async (e) => {
    e.preventDefault();

    const id = get(cotacaoSelecionada, 'id', 0);

    if (!verificarId(id)) return;

    try {
      //const existeResposta = await axiosCoto.get(`/cotacao/existemrespostasparacotacao/${id}`);

      //if (existeResposta.data) {
      //  toast.warning('Esta cotação não pode mais ser editada por conter respostas.');
      //  return;
      //}
      await axiosCoto.put(`/cotacao/alterarstatus/${id}/EDICAO`);
      navigate(`/cadastrocotacao/${id}`);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    }
  };

  const handleRespondendo = async (e) => {
    e.preventDefault();

    const id = get(cotacaoSelecionada, 'id', 0);

    if (!verificarId(id)) return;

    try {
      const existeResposta = await axiosCoto.get(`/cotacao/existemrespostasparacotacao/${id}`);
      if (!existeResposta.data) {
        toast.warning('Não existem repostas para a cotação.');
        return;
      }
      await axiosCoto.put(`/cotacao/alterarstatus/${id}/RESPONDENDO`);
      navigate(`/cadastrocotacaorespondendo/${id}`);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    }
  };

  const handleFecharPedido = async (e) => {
    e.preventDefault();

    const id = get(cotacaoSelecionada, 'id', 0);

    if (!verificarId(id)) return;

    try {
      const existeResposta = await axiosCoto.get(`/cotacao/existemrespostasparacotacao/${id}`);
      if (!existeResposta.data) {
        toast.warning('Não existem repostas para a cotação ser fechada.');
        return;
      }

      const existeAlgumaQuantidadePedido = await axiosCoto.get(`/cotacao/existequantidadepedidoprodutocotacao/${id}`);
      if (!existeAlgumaQuantidadePedido.data) {
        toast.warning('Esta cotação não pode ser fechada, não há quantidade de pedido em nenhum produto.');
        return;
      }

      setShowConfirmFecharPedidoDialog(true);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    }
  };

  const cancelFecharPedido = () => {
    setShowConfirmFecharPedidoDialog(false);
  };

  const confirmFecharPedido = async () => {
    setShowConfirmFecharPedidoDialog(false);

    const id = get(cotacaoSelecionada, 'id', 0);

    if (!verificarId(id)) return;

    const razaoSocial = get(cotacaoSelecionada, 'razaoSocial', '');
    const padrao = get(cotacaoSelecionada, 'padrao', '');
    let pedido = null;
    try {
      setIsLoading(true);
      const { data } = await axiosCoto.get(`/cotacao/${id}`);

      pedido = await axiosCoto.put(`/cotacao/cotacaotransformarempedido/${id}`, data);
      getData('');
      if (padrao) {
        toast.info('Cotação padrão será mantida para reaproveitamento.');
      }
      axiosCoto.put(`/cotacao/enviaremailfechamentopedido/${pedido.data.id}/${razaoSocial}`);
      toast.success('Pedido fechado com sucesso. Email enviado aos vendedores.');
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setShowConfirmFecharPedidoDialog(false);
      setIsLoading(false);
    }
  };

  const handleInformacoesCotacao = async (e) => {
    e.preventDefault();

    const id = get(cotacaoSelecionada, 'id', 0);

    if (!verificarId(id)) return;

    try {
      setIsLoading(true);
      const existeProduto = await axiosCoto.get(`/cotacao/existeprodutonacotacao/${id}`);
      if (!existeProduto.data) {
        toast.warning('Esta cotação não possui produtos.');
        return;
      }

      const response = await axiosCoto.get(`/cotacao/informacaocotacaocomprador/${id}`);

      setNumProdutos(get(response.data, 'numProdutos', 0));
      setNumRespostas(get(response.data, 'numRespostas', 0));

      const paramsAvaliacaoTodos = '/avaliacaovendedor/todos';
      let avaliacoesTodos = '';
      await axiosCoto.get(paramsAvaliacaoTodos).then((res) => {
        avaliacoesTodos = res.data;
      });

      const _listaVendedores = [];
      let media = 0;
      let contador = 0;
      for (let x = 0; x < response.data.vendedores.length; x++) {
        media = 0;
        contador = 0;
        for (let z = 0; z < avaliacoesTodos.length; z++) {
          if (avaliacoesTodos[z].idVendedor === response.data.vendedores[x].id) {
            media += avaliacoesTodos[z].classificacaoMedia;
            contador++;
          }
        }
        let vendedor = '';
        if (contador > 0) {
          vendedor = `${response.data.vendedores[x].email} - ${response.data.vendedores[x].telefone1} Avaliação: ${media / contador} (${contador} voto(s))`;
        } else {
          vendedor = `${response.data.vendedores[x].email} - ${response.data.vendedores[x].telefone1} Não Avaliado`;
        }
        _listaVendedores.push(vendedor);
      }
      setListaVendedores(_listaVendedores);
      setListaProdutosSemRespostas(get(response.data, 'produtosSemResposta', []));

      setShowInformacoesCotacaoDialog(true);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAbrirAosVendedores = async (e) => {
    e.preventDefault();

    const id = get(cotacaoSelecionada, 'id', 0);

    if (!verificarId(id)) return;

    try {
      setIsLoading(true);
      const existeProduto = await axiosCoto.get(`/cotacao/existeprodutonacotacao/${id}`);
      if (!existeProduto.data) {
        toast.warning('Esta cotação não possui produtos.');
        return;
      }
      const status = get(cotacaoSelecionada, 'status', '');
      if (status !== 'ABERTA') {
        await axiosCoto.put(`/cotacao/alterarstatus/${id}/ABERTA`);
        getData('');
        axiosCoto.put(`/cotacao/enviaremailaberturacotacao/${id}`);
        toast.success('Abertura realizada com sucesso. Email enviado aos vendedores');
      } else {
        toast.info('Cotação já esta aberta.');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();

    const id = get(cotacaoSelecionada, 'id', 0);

    if (!verificarId(id)) return;

    setShowConfirmDialog(true);
  };

  const cancelDelete = () => {
    setShowConfirmDialog(false);
    setCotacaoSelecionada();
  };

  const confirmDelete = async () => {
    const id = get(cotacaoSelecionada, 'id', 0);

    if (!verificarId(id)) return;

    if (euConcordo.toUpperCase() !== 'EU CONCORDO') {
      toast.info('Palavra chave não digitada para exclusão.');
      return;
    }

    try {
      setIsLoading(true);

      await axiosCoto.delete(`/cotacao/${id}`);
      const _itens = dataTableListaCotacoes.filter(val => val.id !== id);
      setDataTableListaCotacoes(_itens);

      toast.success('Registro excluído com sucesso.');
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setShowConfirmDialog(false);
      setIsLoading(false);
      setEuConcordo('');
    }
  };

  const cancelInformacoescotacao = () => {
    setShowInformacoesCotacaoDialog(false);
  };

  const confirmDialogFooter = (
    <div>
      <IconButton titulo="Sim" mensagem="Sim" btnStyle="primary" onClick={confirmDelete} icon="check" />
      <IconButton titulo="Não" mensagem="Não" btnStyle="danger" onClick={cancelDelete} icon="times" />
    </div>
  );

  const confirmFecharPedidoDialogFooter = (
    <div>
      <IconButton titulo="Sim" mensagem="Sim" btnStyle="primary" onClick={confirmFecharPedido} icon="check" />
      <IconButton titulo="Não" mensagem="Não" btnStyle="danger" onClick={cancelFecharPedido} icon="times" />
    </div>
  );

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
    setDataTableListaCotacoes(orderBy(dataTableListaCotacoes, [sortField], [sortOrder]));
  };

  const renderTablePrimeReact = useMemo(() => (
    <div>
      <div className="card">
        <DataTable
          id="tabelaCotacoes"
          value={dataTableListaCotacoes}
          size="small"
          stripedRows
          sortField={sortField}
          setOrder={sortOrder}
          onSort={onSort}
          responsiveLayout="scroll"
          selectionMode="single"
          selection={cotacaoSelecionada}
          onSelectionChange={e => setCotacaoSelecionada(e.value)}
          dataKey="id"
          paginator
          rows={10}
          emptyMessage="Nenhum registro a ser exibido"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="{currentPage} de {totalPages}"
          rowsPerPageOptions={[10, 25, 50]}
        >
          {fillColumns(dataTableColumns)}
        </DataTable>
      </div>
    </div>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [dataTableListaCotacoes, cotacaoSelecionada]);

  function headRows() {
    return [
      { Produto: 'Produto' },
    ];
  }

  const handleImprimirProdutosSemResposta = () => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();
    const descricao = get(cotacaoSelecionada, 'descricao', 0);

    const result = [];
    const data = {
      Produto: '',
    };

    const lista = _.sortBy(listaProdutosSemRespostas);

    for (let x = 0; x < lista.length; x++) {
      data.Produto = lista[x];
      result.push(Object.assign({}, data));
    }

    if (result.length > 0) {
      doc.autoTable(
        {
          styles: { fontSize: 8 },
          margin: { top: 38, left: 5, right: 5 },
          theme: 'grid',
          head: headRows(),
          body: result,
          didDrawPage: function (dados) {
            // Header
            doc.rect(5, 5, 200, 30); //  header
            doc.addImage('logo.png', 'JPEG', 10, 12, 25, 15);
            doc.setFontSize(20);
            doc.text('Relatório de Produtos Sem Resposta', 50, 15);

            doc.setFontSize(10);

            doc.text('Cotação:', 45, 24);
            doc.text(descricao, 63, 24);

            const pageSize = doc.internal.pageSize;
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text(`Pag ${doc.internal.getNumberOfPages()}`, dados.settings.margin.right, pageHeight - 8);
            doc.text('Elo Informática Barbacena', 82, pageHeight - 8);
            const periodo = new Date();
            const mes = periodo.getMonth() + 1;
            doc.text('Impresso: ', 172, pageHeight - 8);
            doc.text((`${('00' + periodo.getDate()).slice(-2)}/${('00' + mes).slice(-2)}/${periodo.getFullYear()}`).toString(), 189, pageHeight - 8);
          },
        },
      ).setFontSize(5);
      window.open(doc.output('bloburl'));
    } else {
      toast.info('Nenhum produto sem resposta');
    }
  };

  const handleImprimirProdutos = () => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();
    const descricao = get(cotacaoSelecionada, 'descricao', 0);

    const result = [];
    const data = {
      Produto: '',
    };

    const lista = _.sortBy(cotacaoProdutos);

    for (let x = 0; x < lista.length; x++) {
      data.Produto = lista[x].descricaoProduto;
      result.push(Object.assign({}, data));
    }

    if (result.length > 0) {
      doc.autoTable(
        {
          styles: { fontSize: 8 },
          margin: { top: 38, left: 5, right: 5 },
          theme: 'grid',
          head: headRows(),
          body: result,
          didDrawPage: function (dados) {
            // Header
            doc.rect(5, 5, 200, 30); //  header
            doc.addImage('logo.png', 'JPEG', 10, 12, 25, 15);
            doc.setFontSize(20);
            doc.text('Relatório de Produtos da Cotação', 60, 15);

            doc.setFontSize(10);

            doc.text('Cotação:', 45, 24);
            doc.text(descricao, 63, 24);

            const pageSize = doc.internal.pageSize;
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text(`Pag ${doc.internal.getNumberOfPages()}`, dados.settings.margin.right, pageHeight - 8);
            doc.text('Elo Informática Barbacena', 82, pageHeight - 8);
            const periodo = new Date();
            const mes = periodo.getMonth() + 1;
            doc.text('Impresso: ', 172, pageHeight - 8);
            doc.text((`${('00' + periodo.getDate()).slice(-2)}/${('00' + mes).slice(-2)}/${periodo.getFullYear()}`).toString(), 189, pageHeight - 8);
          },
        },
      ).setFontSize(5);
      window.open(doc.output('bloburl'));
    } else {
      toast.info('Nenhum produto na cotação');
    }
  };

  const informacoesCotacaoDialogFooter = (
    <div>
      <IconButton titulo="Produtos da cotação" mensagem="Gerar Relatório produtos" btnStyle="dark" onClick={handleImprimirProdutos} icon="print" />
      <IconButton titulo="Produtos sem resposta" mensagem="Gerar Relatório produtos sem resposta" btnStyle="dark" onClick={handleImprimirProdutosSemResposta} icon="print" />
      <IconButton titulo="Fechar" mensagem="Fechar" btnStyle="primary" onClick={cancelInformacoescotacao} icon="times" />
    </div>
  );

  return (
    <>
      <Loading isLoading={isLoading} />
      <Card title="Cotações">
        <div>
          <div className="row align-items-center">
            <div className="col-7">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Buscar por:
                </Input155px>
                <InputCampoBusca100px className="p-inputgroup-addon">
                  {dataTableColumns.find(element => element.field === sortField).header}
                </InputCampoBusca100px>
                <InputText
                  autoFocus
                  maxLength={200}
                  value={findField}
                  onChange={e => setFindField(e.target.value)}
                />
                &nbsp; &nbsp;
                <IconButton titulo="" mensagem="Buscar" btnStyle="success" onClick={handleFind} icon="search" />
              </div>
            </div>
            <div className="col-5" style={{ textAlign: 'right' }}>
              <IconButton titulo="" mensagem="Cadastrar" btnStyle="primary" onClick={handleRegister} icon="plus" />
              <IconButton titulo="" mensagem="Editar cotação e produtos inseridos" btnStyle="warning" onClick={handleEdit} icon="pencil" />
              <IconButton titulo="" mensagem="Abrir aos Vendedores" btnStyle="secondary" onClick={handleAbrirAosVendedores} icon="share-alt" />
              <IconButton titulo="" mensagem="Informações e Impressão" btnStyle="dark" onClick={handleInformacoesCotacao} icon="eye" />
              <IconButton titulo="" mensagem="Responder" btnStyle="info" onClick={handleRespondendo} icon="money-bill" />
              <IconButton titulo="" mensagem="Fechar Pedido" btnStyle="success" onClick={handleFecharPedido} icon="check-square" />
              <IconButton titulo="" mensagem="Excluir" btnStyle="danger" onClick={handleDelete} icon="trash" />
            </div>
          </div>
        </div>
        <br />

        {renderTablePrimeReact}
      </Card>
      <Dialog
        header="Exclusão da Cotação"
        visible={showConfirmDialog}
        style={{ width: '50vw' }}
        footer={confirmDialogFooter}
        onHide={() => setShowConfirmDialog(false)}
      >
        <p>
          Digite: &quot;EU CONCORDO&quot; na caixa,
          <InputText
            maxLength={11}
            value={euConcordo}
            onChange={e => setEuConcordo(e.target.value)}
          />
          para a exclusão da cotação.
        </p>
      </Dialog>

      <Dialog
        header="Fechamento de Pedido"
        visible={showConfirmFecharPedidoDialog}
        style={{ width: '50vw' }}
        footer={confirmFecharPedidoDialogFooter}
        onHide={() => setShowConfirmFecharPedidoDialog(false)}
      >
        <p>
          Após Fechar Pedido, não será mais possível alterar a cotação. Ela estará disponível na área de pedidos.
          <br />
          Deseja continuar?
        </p>
      </Dialog>

      <Dialog
        header="Informações da Cotação"
        visible={showInformacoesCotacaoDialog}
        style={{ width: '60vw' }}
        footer={informacoesCotacaoDialogFooter}
        onHide={() => setShowInformacoesCotacaoDialog(false)}
      >
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Produtos
              </Input100px>
              <InputCampoBusca100px className="p-inputgroup-addon">
                {numProdutos}
              </InputCampoBusca100px>
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Respostas
              </Input100px>
              <InputCampoBusca100px className="p-inputgroup-addon">
                {numRespostas}
              </InputCampoBusca100px>
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input250px className="p-inputgroup-addon">
                Vendedores que responderam
              </Input250px>
              <Dropdown
                emptyMessage="Nenhum vendedor respondeu."
                options={listaVendedores}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input250px className="p-inputgroup-addon">
                Produtos sem resposta
              </Input250px>
              <Dropdown
                emptyMessage="Todos os produtos receberam resposta."
                options={listaProdutosSemRespostas}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
