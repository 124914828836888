import React, { useState, useEffect, useMemo } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { get, sortBy } from 'lodash';

import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Card from '../../../components/card';
import IconButton from '../../../components/IconButton';

import { Input100px, Input155px } from '../../../styles/inputGroup';
import { displayErrors, formataMoeda } from '../../../util/diversos';

import Loading from '../../loading';
import axiosCoto from '../../../services/axiosCoto';

import { obterListaTipoEntrega } from '../../../services/tipoEntrega';

export default function CadastroCotacao() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const idVendedor = useSelector((state) => state.auth.user.id);
  const razaoSocialVendedor = useSelector((state) => state.auth.user.razaoSocial);
  const [trabalharComAtacado, setTrabalharComAtacado] = useState(useSelector((state) => state.auth.user.trabalharComAtacado));
  const [trabalharComICMSIPI, setTrabalharComICMSIPI] = useState(useSelector((state) => state.auth.user.trabalharComICMSIPI));
  const idCotacao = get(useParams(), 'id', 0);

  const [razaoSocial, setRazaoSocial] = useState('');
  const [descricao, setDescricao] = useState('');
  const [dataAbertura, setDataAbertura] = useState('');
  const [dataFechamento, setDataFechamento] = useState('');
  const [observacao, setObservacao] = useState('');
  const [pagamentoPrazo, setPagamentoPrazo] = useState('');
  const [diasEntrega, setDiasEntrega] = useState('');
  const [tipoEntrega, setTipoEntrega] = useState('ENTREGA');
  const [idCotacaoVendedorPrazo, setIdCotacaoVendedorPrazo] = useState(0);

  const [showAvisoDialog, setShowAvisoDialog] = useState(false);

  const [dataTableProdutos, setDataTableProdutos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [statusCotacao, setStatusCotacao] = useState('RESPONDENDO');

  useEffect(() => {
    if (!idCotacao) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosCoto.get(`/cotacao/cotacaocomrespostasparavendedorresponder/${idCotacao}/${idVendedor}`);

        setRazaoSocial(data.razaoSocial.trim());
        setDescricao(data.descricao.trim());
        const dataAbre = new Date(data.dataAbertura[0], data.dataAbertura[1] - 1, data.dataAbertura[2]);
        setDataAbertura(dataAbre);
        const dataFecha = new Date(data.dataFechamento[0], data.dataFechamento[1] - 1, data.dataFechamento[2]);
        setDataFechamento(dataFecha);
        setObservacao(data.observacao.trim());
        if (data.cotacaoVendedorPrazo.pagamentoPrazo != null) {
          setPagamentoPrazo(data.cotacaoVendedorPrazo.pagamentoPrazo);
        }
        if (data.cotacaoVendedorPrazo.diasEntrega != null) {
          setDiasEntrega(data.cotacaoVendedorPrazo.diasEntrega);
        }
        if (data.cotacaoVendedorPrazo.tipoEntrega != null) {
          setTipoEntrega(data.cotacaoVendedorPrazo.tipoEntrega);
        }
        if (data.cotacaoVendedorPrazo.id != null) {
          setIdCotacaoVendedorPrazo(data.cotacaoVendedorPrazo.id);
        }
        if (data.cotacaoVendedorPrazo.statusCotacao != null) {
          setStatusCotacao(data.cotacaoVendedorPrazo.statusCotacao);
        }

        data.cotacaoProdutos = sortBy(data.cotacaoProdutos, 'descricaoProduto');

        const cotacaoProdutos = [];
        data.cotacaoProdutos.forEach(element => {
          const idProdutoResposta = get(element.cotacaoProdutoRespostas[0], 'id', 0);
          let qtdePorCaixa = get(element.cotacaoProdutoRespostas[0], 'qtdePorCaixa', 0).toFixed(2);
          qtdePorCaixa = qtdePorCaixa.replace('.', ',');
          let valorCaixa = `R$ ${get(element.cotacaoProdutoRespostas[0], 'valorCaixa', 0).toFixed(2)}`;
          valorCaixa = valorCaixa.replace('.', ',');
          let valorUnitario = `R$ ${get(element.cotacaoProdutoRespostas[0], 'valorUnitario', 0).toFixed(3)}`;
          valorUnitario = valorUnitario.replace('.', ',');
          let valorAtacado = `R$ ${get(element.cotacaoProdutoRespostas[0], 'valorAtacado', 0).toFixed(2)}`;
          valorAtacado = valorAtacado.replace('.', ',');
          let qtdeAtacado = get(element.cotacaoProdutoRespostas[0], 'qtdeAtacado', 0).toFixed(2);
          qtdeAtacado = qtdeAtacado.replace('.', ',');
          let percentualICMS = get(element.cotacaoProdutoRespostas[0], 'percentualICMS', 0).toFixed(2);
          percentualICMS = percentualICMS.replace('.', ',');
          let percentualIPI = get(element.cotacaoProdutoRespostas[0], 'percentualIPI', 0).toFixed(2);
          percentualIPI = percentualIPI.replace('.', ',');

          cotacaoProdutos.push({ label: element.descricaoProduto.trim(),
            value: element.id,
            id: element.id,
            idCotacaoProduto: element.idProduto,
            qtdePorCaixa,
            valorCaixa,
            valorUnitario,
            totalST: element.totalST,
            idProdutoResposta,
            idCotacao,
            valorAtacado,
            qtdeAtacado,
            percentualICMS,
            percentualIPI,
            razaoSocial: razaoSocialVendedor,
          });
        });

        setDataTableProdutos(cotacaoProdutos);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [dispatch, idCotacao, idVendedor, navigate, razaoSocialVendedor]);

  async function salvarProdutoResposta(rowData) {
    try {
      await axiosCoto.get(`/cotacao/${idCotacao}`).then( async (res) => {
        if (res.data.status === 'RESPONDENDO') {
          setShowAvisoDialog(true);
        } else {
          const idCotacaoInt = parseInt(rowData.idCotacao, 10);
          const valorCaixa = parseFloat(rowData.valorCaixa.replace('R$', '').replace('.', '').replace(',', '.'));
          let qtdePorCaixa = get(rowData, 'qtdePorCaixa', 0);
          if (typeof qtdePorCaixa === 'string') {
            qtdePorCaixa = formataMoeda(rowData.qtdePorCaixa.replace(',', '.'));
          }
          const valorUnitario = parseFloat(rowData.valorUnitario.replace('R$', '').replace(',', '.'));

          let qtdeAtacado = get(rowData, 'qtdeAtacado', 0);
          if (typeof qtdeAtacado === 'string') {
            if (qtdeAtacado !== 0 || qtdeAtacado !== '0') {
              qtdeAtacado = parseFloat(qtdeAtacado.replace(',', '.'));
            }
          }

          let percentualICMS = get(rowData, 'percentualICMS', 0);
          if (typeof percentualICMS === 'string') {
            if (percentualICMS !== 0 || percentualICMS !== '0') {
              percentualICMS = parseFloat(percentualICMS.replace(',', '.'));
            }
          }

          let percentualIPI = get(rowData, 'percentualIPI', 0);
          if (typeof percentualIPI === 'string') {
            if (percentualIPI !== 0 || percentualIPI !== '0') {
              percentualIPI = parseFloat(percentualIPI.replace(',', '.'));
            }
          }

          let valorAtacado = get(rowData, 'valorAtacado', 0);
          if (valorAtacado !== 0) {
            valorAtacado = parseFloat(valorAtacado.replace('R$', '').replace(',', '.'));
          }

          const shippingData = {
            idProduto: rowData.idCotacaoProduto,
            id: rowData.idProdutoResposta,
            qtdePorCaixa,
            totalST: 0,
            valorUnitario,
            idVendedor,
            valorCaixa,
            idCotacao: idCotacaoInt,
            qtdePedido: 0,
            qtdeAtacado,
            valorAtacado,
            percentualIPI,
            percentualICMS,
            razaoSocial: razaoSocialVendedor,
          };

          if (valorUnitario > 0) {
            if (rowData.idProdutoResposta === 0) {
              const resposta = await axiosCoto.post('/cotacaoprodutoresposta', shippingData);
              rowData.idProdutoResposta = resposta.data.id;
              toast.success('Dados atualizados com sucesso.');
            } else {
              const id = rowData.idProdutoResposta;
              await axiosCoto.put(`/cotacaoprodutoresposta/${id}`, shippingData);
              toast.success('Dados atualizados com sucesso.');
            }
          } else if (rowData.idProdutoResposta) {
            await axiosCoto.delete(`/cotacaoprodutoresposta/${rowData.idProdutoResposta}`);
            rowData.idProdutoResposta = 0;
            toast.info('Dados atualizados com sucesso.');
          }
        }
      });
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    }
  }

  const salvarPrazoEdias = async () => {
    const idCotacaoInt = parseInt(idCotacao, 10);
    try {
      const shippingData = {
        id: idCotacaoVendedorPrazo,
        idCotacao: idCotacaoInt,
        idVendedor,
        pagamentoPrazo,
        diasEntrega,
        tipoEntrega,
        statusCotacao,
      };

      if (pagamentoPrazo !== '' && diasEntrega !== '' && (idCotacaoVendedorPrazo === 0 || idCotacaoVendedorPrazo === null)) {
        const resposta = await axiosCoto.post('/cotacaovendedorprazo', shippingData);
        setIdCotacaoVendedorPrazo(resposta.data.id);
        toast.success('Prazos cadastrados com sucesso.');
      }
      if (pagamentoPrazo !== '' && diasEntrega !== '' && idCotacaoVendedorPrazo > 0) {
        await axiosCoto.put(`/cotacaovendedorprazo/${idCotacaoVendedorPrazo}`, shippingData);
        toast.success('Prazos atualizados com sucesso.');
      }
      if (pagamentoPrazo === '' && diasEntrega === '') {
        await axiosCoto.delete(`/cotacaovendedorprazo/${idCotacaoVendedorPrazo}`);
        setIdCotacaoVendedorPrazo(0);
        toast.warning('Prazos excluídos com sucesso.');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', 'Não foi possível salvar detalhes da cotação');
    }
  };

  const handleCancel = () => {
    navigate('/consultacotacaovendedor');
  };

  const isNumero = (val) => {
    let str = String(val);
    str = str.trim();
    if (!str || isNaN(str)) {
      return false;
    }
    const n = Number(str);
    return n !== Infinity && String(n) === str && n >= 0;
  };

  const handleSalvarPrazo = (e) => {
    salvarPrazoEdias();
  };

  const confirmAviso = () => {
    handleCancel();
  };

  const avisoDialogFooter = (
    <IconButton titulo=" Sair " mensagem="Sair" btnStyle="primary" onClick={confirmAviso} icon="check" />
  );

  const onCellEditValorUnitarioComplete = (e) => {
    let { rowData, field, originalEvent: event } = e;
    if (event.target.value) {
      rowData[field] = event.target.value;

      const valorUnitario = event.target.value.replace('.', '').replace(',', '.').replace('R$', '');
      const qtdePorCaixa = e.newRowData.qtdePorCaixa.replace('.', '').replace(',', '.');

      if (parseFloat(valorUnitario) > 0) {
        if (parseFloat(qtdePorCaixa) > 0) {
          rowData.valorCaixa = `R$ ${(parseFloat(qtdePorCaixa) * parseFloat(valorUnitario)).toFixed(2).replace('.', ',')}`;
          rowData.qtdePorCaixa = qtdePorCaixa.replace('.', ',');
        } else {
          rowData.valorCaixa = `R$ ${parseFloat(valorUnitario).toFixed(2).replace('.', ',')}`;
          rowData.qtdePorCaixa = '1,00';
        }
        salvarProdutoResposta(rowData);
      } if (parseFloat(valorUnitario) === 0) {
        rowData.valorCaixa = 'R$ 0,00';
        rowData.qtdePorCaixa = '0,00';
        salvarProdutoResposta(rowData);
      }
    } else {
      event.preventDefault();
    }
  };

  const onCellEditValorCaixaComplete = (e) => {
    let { rowData, field, originalEvent: event } = e;
    if (event.target.value) {
      rowData[field] = event.target.value;
      // se valor unitario maior que zero recalcular o valor unitario
      const valorCaixa = event.target.value.replace('.', '').replace(',', '.').replace('R$', '');
      const qtdePorCaixa = e.newRowData.qtdePorCaixa.replace('.', '').replace(',', '.');

      let valorUnitario = 0;
      if (parseFloat(valorCaixa) > 0 && parseFloat(qtdePorCaixa) > 0) {
        valorUnitario = parseFloat(valorCaixa) / parseFloat(qtdePorCaixa);
      }

      if (valorUnitario > 0) {
        rowData.valorUnitario = `R$ ${valorUnitario.toFixed(3).replace('.', ',')}`;
        rowData.valorAtacado = '0';
        rowData.qtdeAtacado = '0';
        salvarProdutoResposta(rowData);
      }
    } else {
      event.preventDefault();
    }
  };

  const onCellEditQtdePorCaixaComplete = (e) => {
    let { rowData, field, originalEvent: event } = e;
    if (event.target.value) {
      rowData[field] = event.target.value;

      const valorCaixa = e.newRowData.valorCaixa.replace('.', '').replace(',', '.').replace('R$', '');
      const qtdePorCaixa = event.target.value;

      let valorUnitario = 0;
      if (parseFloat(valorCaixa) > 0 && parseFloat(qtdePorCaixa) > 0) {
        valorUnitario = parseFloat(valorCaixa) / parseFloat(qtdePorCaixa);
        rowData.valorUnitario = `R$ ${valorUnitario.toFixed(3).replace('.', ',')}`;
        rowData.valorAtacado = 'R$ 0,00';
        rowData.qtdeAtacado = '0,00';
      } else if (parseFloat(valorCaixa) === 0 && parseFloat(qtdePorCaixa) === 0) {
        rowData.valorCaixa = 'R$ 0,00';
        rowData.qtdePorCaixa = '0,00';
        rowData.valorUnitario = 'R$ 0,000';
        rowData.valorAtacado = 'R$ 0,00';
        rowData.qtdeAtacado = '0,00';
        rowData.percentualICMS = '0,00';
        rowData.percentualIPI = '0,00';
      } else if (parseFloat(valorCaixa) === 0 && parseFloat(qtdePorCaixa) > 0 && parseFloat(valorUnitario) > 0) {
        valorCaixa = parseFloat(valorUnitario) * parseFloat(qtdePorCaixa);
        rowData.valorAtacado = 'R$ 0,00';
        rowData.qtdeAtacado = '0,00';
      }
      salvarProdutoResposta(rowData);
    } else {
      event.preventDefault();
    }
  };

  const onCellEditQtdeAtacadoComplete = (e) => {
    let { rowData, field, originalEvent: event } = e;
    if (event.target.value) {
      rowData[field] = event.target.value;

      const qtdeAtacado = rowData.qtdeAtacado.replace('.', '').replace(',', '.');

      if (qtdeAtacado <= 0) {
        rowData.valorAtacado = 'R$ 0,00';
        salvarProdutoResposta(rowData);
      }
    } else {
      event.preventDefault();
    }
  };

  const onCellEditValorAtacadoComplete = (e) => {
    let { rowData, field, originalEvent: event } = e;
    if (event.target.value) {
      const valorAtacado = event.target.value.replace('.', '').replace(',', '.').replace('R$', '');
      if (valorAtacado > 0) {
        rowData[field] = event.target.value;
      }

      const qtdeAtacado = e.newRowData.qtdeAtacado.replace('.', '').replace(',', '.');
      const valorCaixa = e.newRowData.valorCaixa.replace('R$', '');
      const valorUnitario = e.newRowData.valorUnitario.replace('.', '').replace(',', '.').replace('R$', '');

      if (qtdeAtacado <= 0 || valorAtacado <= 0) {
        rowData.qtdeAtacado = '0';
        rowData.valorAtacado = '0';
        salvarProdutoResposta(rowData);
      } else if (qtdeAtacado > 0 && valorUnitario > 0) {
        if (parseFloat(valorAtacado) < valorCaixa.replace(',','.')) {
          salvarProdutoResposta(rowData);
        } else {
          rowData.qtdeAtacado = '0';
          rowData.valorAtacado = '0';
          salvarProdutoResposta(rowData);
          toast.error('Valor Caixa Atacado deve ser menor que valor de caixa e maior que zero.');
        }
      } else {
        rowData.qtdeAtacado = 0;
        rowData.valorAtacado = 'R$ 0,00';
        toast.error('Valor Unitario de ser maior que zero.');
      }
    } else {
      event.preventDefault();
    }
  };

  const onCellEditpercentualICMSComplete = (e) => {
    let { rowData, field, originalEvent: event } = e;
    if (event.target.value) {
      rowData[field] = event.target.value;

      const percentualICMS = rowData.percentualICMS.replace('.', '').replace(',', '.');

      if (percentualICMS > 0) {
        salvarProdutoResposta(rowData);
      }
    } else {
      event.preventDefault();
    }
  };

  const onCellEditpercentualIPIComplete = (e) => {
    let { rowData, field, originalEvent: event } = e;
    if (event.target.value) {
      rowData[field] = event.target.value;

      const percentualIPI = rowData.percentualIPI.replace('.', '').replace(',', '.');

      if (percentualIPI > 0) {
        salvarProdutoResposta(rowData);
      }
    } else {
      event.preventDefault();
    }
  };

  const onCellEditComplete = (e) => {
    let { rowData, field, originalEvent: event } = e;
    switch (field) {
      default:
        if (event.target.value.length > 0) {
          rowData[field] = event.target.value;
        } else {
          event.preventDefault();
        }
        break;
    }
  };

  const handleFocus = (event) => { event.target.value = ''; event.target.select(); };

  const valorcaixaEditor = (options) => (
    <InputNumber
      onFocus={handleFocus}
      value={options.value}
      onValueChange={(e) => options.editorCallback(e.value)}
      mode="currency"
      currency="BRL"
      locale="pt-BR"
      style={{ height: '20px', minWidth: '5rem' }}
    />
  );

  const qtdeporcaixaEditor = (options) => (
    <InputNumber
      onFocus={handleFocus}
      value={options.value}
      onValueChange={(e) => options.editorCallback(e.value)}
      mode="decimal"
      locale="pt-BR"
      minFractionDigits={2}
      style={{ height: '20px', minWidth: '8rem' }}
    />
  );

  const valorUnitarioEditor = (options) => (
    <InputNumber
      onFocus={handleFocus}
      value={options.value}
      onValueChange={(e) => options.editorCallback(e.value)}
      mode="currency"
      currency="BRL"
      locale="pt-BR"
      minFractionDigits={3}
      style={{ height: '20px', minWidth: '5rem' }}
    />
  );

  const valoratacadoEditor = (options) => (
    <InputNumber
      onFocus={handleFocus}
      value={options.value}
      onValueChange={(e) => options.editorCallback(e.value)}
      mode="currency"
      currency="BRL"
      locale="pt-BR"
      style={{ height: '20px', minWidth: '5rem' }}
    />
  );

  const qtdeatacadoEditor = (options) => (
    <InputNumber
      onFocus={handleFocus}
      value={options.value}
      onValueChange={(e) => options.editorCallback(e.value)}
      mode="decimal"
      locale="pt-BR"
      minFractionDigits={2}
      style={{ height: '20px', minWidth: '8rem' }}
    />
  );
  const percentualICMSEditor = (options) => (
    <InputNumber
      onFocus={handleFocus}
      value={options.value}
      onValueChange={(e) => options.editorCallback(e.value)}
      mode="decimal"
      locale="pt-BR"
      minFractionDigits={2}
      style={{ height: '20px', minWidth: '8rem' }}
    />
  );

  const percentualIPIEditor = (options) => (
    <InputNumber
      onFocus={handleFocus}
      value={options.value}
      onValueChange={(e) => options.editorCallback(e.value)}
      mode="decimal"
      locale="pt-BR"
      minFractionDigits={2}
      style={{ height: '20px', minWidth: '8rem' }}
    />
  );

  const cellEditor = (options) => {
    if (options.field === 'valorCaixa') {
      return valorcaixaEditor(options);
    }
    if (options.field === 'qtdePorCaixa') {
      return qtdeporcaixaEditor(options);
    }
    if (options.field === 'qtdeAtacado') {
      return qtdeatacadoEditor(options);
    }
    if (options.field === 'valorAtacado') {
      return valoratacadoEditor(options);
    }
    if (options.field === 'valorUnitario') {
      return valorUnitarioEditor(options);
    }
    if (options.field === 'percentualICMS') {
      return percentualICMSEditor(options);
    }
    if (options.field === 'percentualIPI') {
      return percentualIPIEditor(options);
    }
  };

  let renderTablePrimeReact = '';
  if (trabalharComAtacado && trabalharComICMSIPI) {
    renderTablePrimeReact = useMemo(() => (
      <div className="datatable-produtos">
        <div className="card">
          <DataTable
            id="TabelaProdutos"
            value={dataTableProdutos}
            size="small"
            stripedRows
            responsiveLayout="scroll"
            selectionMode="single"
            dataKey="id"
            editMode="cell"
            onCellEditComplete={onCellEditComplete}
            paginator
            rows={10}
            emptyMessage="ATENÇÃO: Aguardando preenchimento do prazo de pagamento e dias entrega"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50]}
          >
            <Column field="label" header="Descrição" sortable style={{ width: '50%' }}></Column>
            <Column field="valorCaixa" header="Valor cx" style={{ width: '10%', background: '#ecffe6' }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete}></Column>
            <Column field="qtdePorCaixa" header="Qtde por cx" style={{ width: '10%', background: '#ecffe6' }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete}></Column>
            <Column field="valorUnitario" header="Valor un"></Column>
            <Column field="qtdeAtacado" header="Qtde p/ Atacado" style={{ width: '10%', background: '#ecffe6' }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete}></Column>
            <Column field="valorAtacado" header="Valor CX Atacado" style={{ width: '10%', background: '#ecffe6' }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete}></Column>
            <Column field="percentualICMS" header="Alíquota ICMS" style={{ width: '10%', background: '#ecffe6' }} editor={(options) => cellEditor(options)}></Column>
            <Column field="percentualIPI" header="Alíquota IPI" style={{ width: '10%', background: '#ecffe6' }} editor={(options) => cellEditor(options)}></Column>
          </DataTable>
        </div>
      </div>
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [dataTableProdutos]);
  } else if (trabalharComICMSIPI) {
    renderTablePrimeReact = useMemo(() => (
      <div className="datatable-produtos">
        <div className="card">
          <DataTable
            id="TabelaProdutos"
            value={dataTableProdutos}
            size="small"
            stripedRows
            responsiveLayout="scroll"
            selectionMode="single"
            dataKey="id"
            editMode="cell"
            onCellEditComplete={onCellEditComplete}
            paginator
            rows={10}
            emptyMessage="ATENÇÃO: Aguardando preenchimento do prazo de pagamento e dias entrega"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50]}
          >
            <Column field="label" header="Descrição" sortable style={{ width: '50%' }}></Column>
            <Column field="valorCaixa" header="Valor cx" editor={(options) => cellEditor(options)} style={{ width: '10%', background: '#ecffe6' }} onCellEditComplete={onCellEditValorCaixaComplete}></Column>
            <Column field="qtdePorCaixa" header="Qtde por cx" style={{ width: '10%', background: '#ecffe6' }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditQtdePorCaixaComplete}></Column>
            <Column field="valorUnitario" header="Valor un" style={{ width: '10%', background: '#ecffe6' }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditValorUnitarioComplete}></Column>
            <Column field="percentualICMS" header="Alíquota ICMS" style={{ width: '10%', background: '#ecffe6' }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditpercentualICMSComplete}></Column>
            <Column field="percentualIPI" header="Alíquota IPI" style={{ width: '10%', background: '#ecffe6' }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditpercentualIPIComplete}></Column>
          </DataTable>
        </div>
      </div>
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [dataTableProdutos]);
  } else if (trabalharComAtacado) {
    renderTablePrimeReact = useMemo(() => (
      <div className="datatable-produtos">
        <div className="card">
          <DataTable
            id="TabelaProdutos"
            value={dataTableProdutos}
            size="small"
            stripedRows
            responsiveLayout="scroll"
            selectionMode="single"
            dataKey="id"
            editMode="cell"
            onCellEditComplete={onCellEditComplete}
            paginator
            rows={10}
            emptyMessage="ATENÇÃO: Aguardando preenchimento do prazo de pagamento e dias entrega"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50]}
          >
            <Column field="label" header="Descrição" sortable style={{ width: '50%' }}></Column>
            <Column field="valorCaixa" header="Valor cx" editor={(options) => cellEditor(options)} style={{ width: '10%', background: '#ecffe6' }} onCellEditComplete={onCellEditValorCaixaComplete}></Column>
            <Column field="qtdePorCaixa" header="Qtde por cx" style={{ width: '10%', background: '#ecffe6' }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditQtdePorCaixaComplete}></Column>
            <Column field="valorUnitario" header="Valor un" style={{ width: '10%', background: '#ecffe6' }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditValorUnitarioComplete}></Column>
            <Column field="qtdeAtacado" header="Qtde p/ Atacado" style={{ width: '10%', background: '#ecffe6' }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditQtdeAtacadoComplete}></Column>
            <Column field="valorAtacado" header="Valor CX Atacado" editor={(options) => cellEditor(options)} style={{ width: '10%', background: '#ecffe6' }} onCellEditComplete={onCellEditValorAtacadoComplete}></Column>
          </DataTable>
        </div>
      </div>
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [dataTableProdutos]);
  } else {
    renderTablePrimeReact = useMemo(() => (
      <div className="datatable-produtos">
        <div className="card">
          <DataTable
            id="TabelaProdutos"
            value={dataTableProdutos}
            size="small"
            stripedRows
            responsiveLayout="scroll"
            selectionMode="single"
            dataKey="id"
            editMode="cell"
            onCellEditComplete={onCellEditComplete}
            paginator
            rows={10}
            emptyMessage="ATENÇÃO: Aguardando preenchimento do prazo de pagamento e dias entrega"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50]}
          >
            <Column field="label" header="Descrição" sortable style={{ width: '60%' }}></Column>
            <Column field="valorCaixa" header="Valor cx" editor={(options) => cellEditor(options)} style={{ width: '10%', background: '#ecffe6' }} onCellEditComplete={onCellEditValorCaixaComplete}></Column>
            <Column field="qtdePorCaixa" header="Qtde por cx" style={{ width: '10%', background: '#ecffe6' }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditQtdePorCaixaComplete}></Column>
            <Column field="valorUnitario" header="Valor un" style={{ width: '10%', background: '#ecffe6' }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditValorUnitarioComplete}></Column>
            <Column field="qtdeAtacado" header="" hidden style={{ width: '1%' }}/>
            <Column field="valorAtacado" header="" hidden style={{ width: '1%' }}/>
          </DataTable>
        </div>
      </div>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [dataTableProdutos]);
  }

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card title="Precificando Produtos da Cotação">
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Empresa
              </Input155px>
              <InputText
                maxLength={50}
                value={razaoSocial}
                onChange={e => setRazaoSocial(e.target.value)}
                disabled
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Descrição
              </Input155px>
              <InputText
                id="inputDescricao"
                maxLength={50}
                value={descricao}
                onChange={e => setDescricao(e.target.value)}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Abertura
              </Input100px>
              <Calendar
                dateFormat="dd/mm/yy"
                id="dataAbertura"
                value={dataAbertura}
                onChange={e => setDataAbertura(e.target.value)}
                disabled
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Previsão Fechamento
              </Input155px>
              <Calendar
                dateFormat="dd/mm/yy"
                id="dataFechamento"
                value={dataFechamento}
                onChange={e => setDataFechamento(e.target.value)}
                disabled
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Observação
              </Input155px>
              <InputText
                maxLength={50}
                value={observacao}
                onChange={e => setObservacao(e.target.value)}
                disabled
              />
            </div>
          </div>
        </div>
        <hr />

        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Tipo Entrega
              </Input155px>
              <Dropdown
                options={obterListaTipoEntrega()}
                value={tipoEntrega}
                onChange={e => setTipoEntrega(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Minha situação:
              </Input155px>
              <Dropdown
                options={[
                  { label: 'Respondendo', value: 'RESPONDENDO' },
                  { label: 'Finalizada', value: 'FINALIZADA' },
                ]}
                value={statusCotacao}
                onChange={e => setStatusCotacao(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Prazo pagamentos*
              </Input155px>
              <InputText
                autoFocus
                id="pagamentoPrazo"
                maxLength={20}
                value={pagamentoPrazo}
                onChange={e => setPagamentoPrazo(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Dias Entrega*
              </Input155px>
              <InputText
                id="diasEntrega"
                maxLength={20}
                value={diasEntrega}
                onChange={e => setDiasEntrega(e.target.value)}
              />
            </div>
          </div>

          <div className="col-4">
            <IconButton titulo="Salvar detalhes" mensagem="Salvar detalhes" btnStyle="success" onClick={handleSalvarPrazo} icon="check" />
          </div>
        </div>
        {renderTablePrimeReact}
        <div className="col-12" style={{ textAlign: 'right' }}>
          <IconButton titulo="Voltar" mensagem="Voltar" btnStyle="success" onClick={handleCancel} icon="chevron-left" />
        </div>
      </Card>

      <Dialog
        header="Aviso alterar de Status da Cotação"
        visible={showAvisoDialog}
        style={{ width: '50vw' }}
        footer={avisoDialogFooter}
        onHide={() => setShowAvisoDialog(false)}
      >
        <p>
          Status da Cotação alterado para RESPONDENDO. Não será mais possível inserir preços.
        </p>
      </Dialog>
    </div>
  );
}
