import React from 'react';

function Avaliacao(props) {
  if (props.hide) {
    return null;
  }

  let avaliacao = 'Não avaliado';
  if (props.valor > 0) {
    avaliacao = (
      <>
        <img src="star-fill.png" height="20"/>
        <img src="star.png" height="20"/>
        <img src="star.png" height="20"/>
        <img src="star.png" height="20"/>
        <img src="star.png" height="20"/>
      </>
    );
  }
  if (props.valor === 1.5) {
    avaliacao = (
      <>
        <img src="star-fill.png" height="20"/>
        <img src="star-half.png" height="20"/>
        <img src="star.png" height="20"/>
        <img src="star.png" height="20"/>
        <img src="star.png" height="20"/>
      </>
    )
  }
  if (props.valor > 1.5) {
    avaliacao = (
      <>
        <img src="star-fill.png" height="20"/>
        <img src="star-fill.png" height="20"/>
        <img src="star.png" height="20"/>
        <img src="star.png" height="20"/>
        <img src="star.png" height="20"/>
      </>
    );
  }
  if (props.valor === 2.5) {
    avaliacao = (
      <>
        <img src="star-fill.png" height="20"/>
        <img src="star-fill.png" height="20"/>
        <img src="star-half.png" height="20"/>
        <img src="star.png" height="20"/>
        <img src="star.png" height="20"/>
      </>
    )
  }
  if (props.valor > 2.5) {
    avaliacao = (
      <>
        <img src="star-fill.png" height="20"/>
        <img src="star-fill.png" height="20"/>
        <img src="star-fill.png" height="20"/>
        <img src="star.png" height="20"/>
        <img src="star.png" height="20"/>
      </>
    );
  }
  if (props.valor === 3.5) {
    avaliacao = (
      <>
        <img src="star-fill.png" height="20"/>
        <img src="star-fill.png" height="20"/>
        <img src="star-fill.png" height="20"/>
        <img src="star-half.png" height="20"/>
        <img src="star.png" height="20"/>
      </>
    )
  }
  if (props.valor > 3.5) {
    avaliacao = (
      <>
       <img src="star-fill.png" height="20"/>
        <img src="star-fill.png" height="20"/>
        <img src="star-fill.png" height="20"/>
        <img src="star-fill.png" height="20"/>
        <img src="star.png" height="20"/>
      </>
    );
  }
  if (props.valor > 4.5) {
    avaliacao = (
      <>
        <img src="star-fill.png" height="20"/>
        <img src="star-fill.png" height="20"/>
        <img src="star-fill.png" height="20"/>
        <img src="star-fill.png" height="20"/>
        <img src="star-fill.png" height="20"/>
      </>
    );
  }

  const votos = <span title="voto(s)" style={{fontSize: 17, fontFamily: "lato"}}> {props.votos > 0 ? (`( ${props.votos} )`) : ''} </span>

  return (
    <>
      { avaliacao }
      { props.votos > 0 ? votos : '' }
    </>
  );
}

export default Avaliacao;
