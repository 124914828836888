import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './loadingStyled';

export default function IsLoading({ isLoading }) {
  if (!isLoading) return <> </>;

  return (
    <Container>
      <div />
      <span>
        <i className="pi pi-spin pi-spinner" style={{ fontSize: '1em' }}></i>
        Carregando...
      </span>
    </Container>
  );
}

IsLoading.defaultProps = {
  isLoading: false,
};

IsLoading.propTypes = {
  isLoading: PropTypes.bool,
};
