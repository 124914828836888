import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import Card from '../../../components/card';
import { obterListaStatusPagamento } from '../../../services/statusPagamento';

import Loading from '../../loading';
import { Input155px } from '../../../styles/inputGroup';
import axiosCoto from '../../../services/axiosCoto';
import IconButton from '../../../components/IconButton';

export default function CadastroFinanceiro() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(useParams(), 'id', 0);

  const [isLoading, setIsLoading] = useState(false);
  const [idUsuario, setIdsuario] = useState();
  const [orderId, setOrderId] = useState();
  const [status, setStatus] = useState();
  const [listaUsuarios, setListaUsuarios] = useState();
  const [filteredUsuarios, setFilteredUsuarios] = useState(null);
  const [dataQuitacao, setDataQuitacao] = useState();
  const [dataLicenca, setDataLicenca] = useState();
  const [valor, setValor] = useState(0);

  const listaStatusPagamento = obterListaStatusPagamento();

  useEffect(() => {
    async function getUsuarios() {
      try {
        setIsLoading(true);

        const listDropdown = [];
        const { data: _usuarios } = await axiosCoto.get('/usuario/todos');
        _usuarios.forEach(element => {
          listDropdown.push({ label: element.email, value: element.id });
        });

        setListaUsuarios(listDropdown);
      } catch (err) {
        toast.error('Não foi possível recuperar os dados do servidor.');
        navigate('/consultafinanceiro');
      } finally {
        setIsLoading(false);
      }
    }
    getUsuarios();

    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosCoto.get(`/financeiro/${id}`);

        if (data.dataQuitacao != null) {
          const dataQuitacaoAjuste = new Date(data.dataQuitacao[0], data.dataQuitacao[1] - 1, data.dataQuitacao[2]);
          setDataQuitacao(dataQuitacaoAjuste);
        }

        if (data.dataLicenca != null) {
          const dataLicencaAjuste = new Date(data.dataLicenca[0], data.dataLicenca[1] - 1, data.dataLicenca[2]);
          setDataLicenca(dataLicencaAjuste);
        }

        setIdsuario(data.idUsuario);
        setOrderId(data.orderId);
        setValor(data.valor);
        setStatus(data.status);
      } catch (err) {
        toast.error('Não foi possível recuperar os dados do servidor.');
        navigate('/consultafinanceiro');
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [dispatch, id, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const shippingData = {
        idUsuario,
        orderId,
        dataQuitacao,
        valor,
        status,
        dataLicenca,
      };

      if (id > 0) {
        await axiosCoto.put(`/financeiro/${id}`, shippingData);
        toast.success('Finança atualizado com sucesso.');
      } else {
        await axiosCoto.post('/financeiro', shippingData);
        toast.success('Finança cadastrada com sucesso.');
      }

      navigate('/consultafinanceiro');
    } catch (err) {
      const errors = get(err.response.data, 'errors', []);
      if (errors.length > 0) {
        errors.map(error => toast.error(error));
      } else {
        toast.error('Finança já cadastrada para o mês referencia e usuario.');
      }
    } finally {
      setIsLoading(false);
    }
  }

  const searchUsuarios = (event) => {
    const listDropdown = [];

    listaUsuarios.forEach(element => {
      if (element.label.toLowerCase().startsWith(event.query.toLowerCase())) {
        listDropdown.push({ label: element.label, value: element.value });
      }
    });

    setFilteredUsuarios(listDropdown);
  };

  const handleCancel = () => {
    navigate('/consultafinanceiro');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (idUsuario == null) {
      formErros = true;
      toast.error('ID do Usuário deve ser informado.');
    }
    if (orderId == null) {
      formErros = true;
      toast.error('Order id do pagametno deve ser informada.');
    }
    if (dataQuitacao == null) {
      formErros = true;
      toast.error('Data da quitação deve ser informada.');
    }
    if (valor == null || valor <= 0) {
      formErros = true;
      toast.error('Valor deve ser informado.');
    }
    if (status == null) {
      formErros = true;
      toast.error('Status do pagamento deve ser informado.');
    }

    if (formErros) return;

    salvarRegistro();
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <Card title="Finanças">
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Id Usuario *
              </Input155px>
              <InputText
                autoFocus
                maxLength={50}
                value={idUsuario}
                onChange={e => setIdsuario(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Order id *
              </Input155px>
              <InputText
                maxLength={50}
                value={orderId}
                onChange={e => setOrderId(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Data Quitação *
              </Input155px>
              <Calendar
                dateFormat="dd/mm/yy"
                id="dataQuitacao"
                value={dataQuitacao}
                onChange={e => setDataQuitacao(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor
              </Input155px>
              <InputNumber
                maxLength={17}
                value={valor}
                onValueChange={e => setValor(e.target.value)}
                mode="decimal"
                locale="pt-BR"
                minFractionDigits={2}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Status *
              </Input155px>
              <Dropdown
                options={listaStatusPagamento}
                value={status}
                onChange={e => setStatus(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="col-12" style={{ textAlign: 'right' }}>
          <IconButton titulo="Salvar" mensagem="Salvar" btnStyle="success" onClick={handleSubmit} icon="check" />
          <IconButton titulo="Cancelar" mensagem="Cancelar" btnStyle="danger" onClick={handleCancel} icon="times" />
        </div>
      </Card>
    </>
  );
}
