import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { get, orderBy } from 'lodash';
import { Dropdown } from 'primereact/dropdown';

import 'react-toastify/dist/ReactToastify.css';
import { displayErrors } from '../../../util/diversos';

import { fillColumns } from '../../../components/table';
import { Input155px, Input250px, InputCampoBusca100px } from '../../../styles/inputGroup';
import IconButton from '../../../components/IconButton';

import Card from '../../../components/card';
import Loading from '../../loading';
import axiosCoto from '../../../services/axiosCoto';

export default function ConsultaPedido() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const idUsuario = useSelector((state) => state.auth.user.id);

  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [euConcordo, setEuConcordo] = useState();
  const [dataTableList, setDataTableList] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showProdutosSemRespostaDialog, setShowProdutosSemRespostaDialog] = useState(false);
  const [listaProdutosSemRespostas, setListaProdutosSemRespostas] = useState([]);
  const [pedidosSelecionados, setPedidosSelecionados] = useState(null);
  const [findField, setFindField] = useState('');
  const [sortField, setSortField] = useState('descricao');
  const [sortOrder, setSortOrder] = useState(null);

  const [dataTableColunas] = useState([
    { field: 'id', header: 'Id', sort: true },
    { field: 'descricao', header: 'Descricão', sort: true },
    { field: 'data', header: 'Data', sort: false },
  ]);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  async function getData(searchFilter, page = 0, size = lazyParams.rows) {
    try {
      setIsLoading(true);

      let params = `/pedido/searchconsultaparacomprador/?idUsuario=${idUsuario}`;
      if (searchFilter && searchFilter.campoBusca !== '') {
        params = `${params}&${sortField}=${searchFilter.findField}`;
      }
      params = `${params}&page=${page}&size=${size}`;

      let response = '';
      await axiosCoto.get(params)
        .then((res) => {
          response = res.data.content;
          setTotalRecords(res.data.totalElements);
        });

      // response.reverse();
      setDataTableList(response);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const handleFind = () => {
    const searchFilter = {
      findField,
      idUsuario,
    };

    const _lazyParams = { ...lazyParams, first: 0, page: 0 };
    setLazyParams(_lazyParams);

    setFindField('');

    getData(searchFilter, 0, lazyParams.rows);
  };

  const onPage = (event) => {
    setLazyParams(event);

    const searchFilter = { findField, idUsuario };

    getData(searchFilter, event.page, event.rows);
  };

  useEffect(() => {
    getData('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleVisualizar = (e) => {
    e.preventDefault();

    const id = get(pedidosSelecionados, 'id', 0);

    if (id > 0) {
      navigate(`/cadastropedido/${id}`);
    } else {
      toast.warning('Selecione um registro para visualização.');
    }
  };

  const handleProdutosSemResposta = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      const id = get(pedidosSelecionados, 'id', 0);

      const params = `/pedido/produtossemresposta/${id}`;

      await axiosCoto.get(params).then((res) => {
        // console.log('res produtos sem resposta: ', res.data);
        if (res.data[0] != null) {
          setListaProdutosSemRespostas(res.data);
          setShowProdutosSemRespostaDialog(true);
        } else {
          toast.info('Não existem produtos sem resposta para esse pedido.');
        }
      });
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();

    const id = get(pedidosSelecionados, 'id', 0);

    if (id > 0) {
      setShowConfirmDialog(true);
    } else {
      toast.warning('Selecione um registro para exclusão.');
    }
  };

  const cancelDelete = () => {
    setShowConfirmDialog(false);
  };

  const confirmDelete = async () => {
    if (euConcordo.toUpperCase() !== 'EU CONCORDO') {
      toast.success('Palavra chave não digitada para exclusão.');
      return;
    }
    setShowConfirmDialog(false);

    try {
      setIsLoading(true);

      const idExclusao = get(pedidosSelecionados, 'id', 0);
      if (idExclusao > 0) {
        await axiosCoto.delete(`/pedido/${idExclusao}`);

        const _itens = dataTableList.filter(val => val.id !== idExclusao);
        setDataTableList(_itens);
        toast.success('Registro excluído com sucesso.');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
      setEuConcordo('');
    }
  };

  const confirmDialogFooter = (
    <>
      <IconButton titulo="Sim" mensagem="Sim" btnStyle="primary" onClick={confirmDelete} icon="check" />
      <IconButton titulo="Não" mensagem="Não" btnStyle="danger" onClick={cancelDelete} icon="times" />
    </>
  );

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  const cancelProdutosSemResposta = () => {
    setShowProdutosSemRespostaDialog(false);
  };

  const produtosSemRespostaDialogFooter = (
    <div>
      <IconButton titulo="Fechar" mensagem="Fechar" btnStyle="primary" onClick={cancelProdutosSemResposta} icon="times" />
    </div>
  );

  const renderTablePrimeReact = useMemo(() => (
    <div>
      <div className="card">
        <DataTable
          value={dataTableList}
          size="small"
          stripedRows
          sortField={sortField}
          setOrder={sortOrder}
          onSort={onSort}
          responsiveLayout="scroll"
          selectionMode="single"
          selection={pedidosSelecionados}
          onSelectionChange={e => setPedidosSelecionados(e.value)}
          selectableRows
          dataKey="id"
          paginator
          lazy
          first={lazyParams.first}
          rows={lazyParams.rows}
          totalRecords={totalRecords}
          onPage={onPage}
          emptyMessage="Nenhum registro a ser exibido"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="{currentPage} de {totalPages}"
          rowsPerPageOptions={[10, 25, 50]}
        >
          {fillColumns(dataTableColunas)}
        </DataTable>
      </div>
    </div>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [dataTableList, pedidosSelecionados]);

  return (
    <>
      <Loading isLoading={isLoading} />
      <Card title="Pedidos">
        <div>
          <div className="row align-items-center">
            <div className="col-10">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Buscar por:
                </Input155px>
                <InputCampoBusca100px className="p-inputgroup-addon">
                  {dataTableColunas.find(element => element.field === sortField).header}
                </InputCampoBusca100px>
                <InputText
                  autoFocus
                  maxLength={250}
                  value={findField}
                  onChange={e => setFindField(e.target.value)}
                />
                &nbsp; &nbsp;
                <IconButton title="" mensagem="Buscar" btnStyle="success" onClick={handleFind} icon="search" />
              </div>
            </div>
            <div className="col-2" style={{ textAlign: 'right' }}>
              <IconButton title="" mensagem="Produtos Sem Resposta" btnStyle="warning" onClick={(e) => handleProdutosSemResposta(e)} icon="eye" />
              <IconButton title="" mensagem="Visualizar" btnStyle="dark" onClick={(e) => handleVisualizar(e)} icon="eye" />
              <IconButton title="" mensagem="Excluir" btnStyle="danger" onClick={(e) => handleDelete(e)} icon="trash" />
            </div>
          </div>
        </div>
        <br />

        {renderTablePrimeReact}

        <Dialog
          header="Confirmação"
          visible={showConfirmDialog}
          style={{ width: '50vw' }}
          footer={confirmDialogFooter}
          onHide={() => setShowConfirmDialog(false)}
        >
          <p>
            Digite: EU CONCORDO na caixa,
            <InputText
              autofocus
              maxLength={11}
              value={euConcordo}
              onChange={e => setEuConcordo(e.target.value)}
            />
            para a exclusão do Pedido.

          </p>
        </Dialog>
      </Card>

      <Dialog
        header="Produtos sem respostas"
        visible={showProdutosSemRespostaDialog}
        style={{ width: '50vw' }}
        footer={produtosSemRespostaDialogFooter}
        onHide={() => setShowProdutosSemRespostaDialog(false)}
      >
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input250px className="p-inputgroup-addon">
                Produtos sem resposta
              </Input250px>
              <Dropdown
                emptyMessage="Todos os produtos receberam resposta."
                options={listaProdutosSemRespostas}
              />
            </div>
          </div>
        </div>

      </Dialog>
    </>
  );
}
