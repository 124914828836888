import React from 'react';
import { useSelector } from 'react-redux';
import Rotas from '../routes/rotas';

export default function Header() {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  document.body.style.background = '#D5DEE5';

  return (
    <div className="container-fluid fixed-left">
      {isLoggedIn ? (
        <div className="row flex-xl-nowrap" style={{ height: '90vh' }}>
          <main className="col-12 col-md-12 col-xl-12 py-md-3 pl-md-5 bd-content" role="main" style={{ paddingRight: '20px' }}>
            <div>
              <Rotas />
            </div>
          </main>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-8 offset-md-2" style={{ paddingTop: '50px' }}>
            <div className="bs-docs-section">
              <Rotas />
            </div>
          </div>
        </div>
      ) }
    </div>
  );
}
