import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import Card from '../../../components/card';

import Loading from '../../loading';
import { Input200px, Input250px } from '../../../styles/inputGroup';
import axiosCoto from '../../../services/axiosCoto';
import IconButton from '../../../components/IconButton';

export default function CadastroConfiguracao() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [valorMensalComprador, setValorMensalComprador] = useState(0);
  const [valorMensalVendedor, setValorMensalVendedor] = useState(0);
  const [chavePix, setChavePix] = useState();
  const [versaoSistema, setVersaoSistema] = useState();
  const [agenciaNome, setAgenciaNome] = useState();
  const [agenciaNumero, setAgenciaNumero] = useState();
  const [agenciaOperacao, setAgenciaOperacao] = useState();
  const [agenciaConta, setAgenciaConta] = useState();
  const [agenciaTitularConta, setAgenciaTitularConta] = useState();
  const [acessKey, setAcessKey] = useState();
  const [clientId, setClientId] = useState();
  const [secretKey, setSecretKey] = useState();
  const [endPoint, setEndPoint] = useState();
  const [formaPagamento, setFormaPagamento] = useState();

  const id = get(useParams(), 'id', 0);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosCoto.get(`/configuracao/${id}`);

        setValorMensalComprador(data.valorMensalComprador);
        setValorMensalVendedor(data.valorMensalVendedor);
        setChavePix(data.chavePix);
        setVersaoSistema(data.versaoSistema);
        setAgenciaNome(data.agenciaNome);
        setAgenciaNumero(data.agenciaNumero);
        setAgenciaOperacao(data.agenciaOperacao);
        setAgenciaConta(data.agenciaConta);
        setAgenciaTitularConta(data.agenciaTitularConta);
        setAcessKey(data.acessKey);
        setClientId(data.clientId);
        setSecretKey(data.secretKey);
        setEndPoint(data.endPoint);
        setFormaPagamento(data.formaPagamento);

        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);

        toast.error('Não foi possível recuperar os dados do servidor.');

        navigate('/consultaconfiguracao');
      }
    }

    getData();
  }, [dispatch, id, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const shippingData = {
        valorMensalComprador,
        valorMensalVendedor,
        chavePix,
        versaoSistema,
        agenciaNome,
        agenciaNumero,
        agenciaOperacao,
        agenciaConta,
        agenciaTitularConta,
        acessKey,
        clientId,
        secretKey,
        endPoint,
        formaPagamento,
      };

      if (id > 0) {
        await axiosCoto.put(`/configuracao/${id}`, shippingData);
        toast.success('Configuração atualizado com sucesso.');
      } else {
        await axiosCoto.post('/configuracao', shippingData);
        toast.success('Configuração cadastrada com sucesso.');
      }

      setIsLoading(false);

      navigate('/consultaconfiguracao');
    } catch (err) {
      setIsLoading(false);
      const errors = get(err.response.data, 'errors', []);
      errors.map(error => toast.error(error));
    }
  }

  const handleCancel = () => {
    navigate('/consultaconfiguracao');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (valorMensalComprador == null || valorMensalComprador <= 0) {
      formErros = true;
      toast.error('Valor Mensal Comprador deve ser informado.');
    }
    if (valorMensalVendedor == null || valorMensalVendedor <= 0) {
      formErros = true;
      toast.error('Valor Mensal Vendedor deve ser informado.');
    }
    if (chavePix == null) {
      formErros = true;
      toast.error('Chave PIX deve ser informada.');
    }
    if (chavePix == null) {
      formErros = true;
      toast.error('Versão deve ser informada.');
    }
    if (agenciaNome == null) {
      formErros = true;
      toast.error('Nome da Agência deve ser informado.');
    }
    if (agenciaNumero == null) {
      formErros = true;
      toast.error('Número da Agência deve ser informado.');
    }
    if (agenciaOperacao == null) {
      formErros = true;
      toast.error('Tipo da Operação deve ser informada.');
    }
    if (agenciaConta == null) {
      formErros = true;
      toast.error('Número da conta deve ser informado.');
    }
    if (agenciaTitularConta == null) {
      formErros = true;
      toast.error('Titular da conta deve ser informado.');
    }
    if (formaPagamento == null) {
      formErros = true;
      toast.error('Forma de pagamento deve ser informada.');
    }
    if (endPoint == null) {
      formErros = true;
      toast.error('End Point deve ser informado.');
    }
    if (acessKey == null) {
      formErros = true;
      toast.error('Access Key deve ser informada.');
    }
    if (clientId == null) {
      formErros = true;
      toast.error('Client id deve ser informada.');
    }
    if (secretKey == null) {
      formErros = true;
      toast.error('Secret key deve ser informada.');
    }

    if (formErros) return;

    salvarRegistro();
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <Card title="Configurações">
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Valor Mensal Comprador
              </Input200px>
              <InputNumber
                maxLength={17}
                value={valorMensalComprador}
                onValueChange={e => setValorMensalComprador(e.target.value)}
                mode="decimal"
                locale="pt-BR"
                minFractionDigits={2}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Valor Mensal Vendedor
              </Input200px>
              <InputNumber
                maxLength={17}
                value={valorMensalVendedor}
                onValueChange={e => setValorMensalVendedor(e.target.value)}
                mode="decimal"
                locale="pt-BR"
                minFractionDigits={2}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Chave PIX
              </Input200px>
              <InputText
                maxLength={100}
                value={chavePix}
                onChange={e => setChavePix(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Versão do sistema
              </Input200px>
              <InputText
                maxLength={10}
                value={versaoSistema}
                onChange={e => setVersaoSistema(e.target.value)}
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Nome do Banco
              </Input200px>
              <InputText
                maxLength={100}
                value={agenciaNome}
                onChange={e => setAgenciaNome(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Número do banco
              </Input200px>
              <InputText
                maxLength={10}
                value={agenciaNumero}
                onChange={e => setAgenciaNumero(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Número da operação
              </Input200px>
              <InputText
                maxLength={5}
                value={agenciaOperacao}
                onChange={e => setAgenciaOperacao(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Número da Conta
              </Input200px>
              <InputText
                maxLength={10}
                value={agenciaConta}
                onChange={e => setAgenciaConta(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Nome do Titular
              </Input200px>
              <InputText
                maxLength={100}
                value={agenciaTitularConta}
                onChange={e => setAgenciaTitularConta(e.target.value)}
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input250px className="p-inputgroup-addon">
                Forma pagamento(shipay)
              </Input250px>
              <InputText
                maxLength={300}
                value={formaPagamento}
                onChange={e => setFormaPagamento(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                End Point (shipay)
              </Input200px>
              <InputText
                maxLength={100}
                value={endPoint}
                onChange={e => setEndPoint(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Access Key (shipay)
              </Input200px>
              <InputText
                maxLength={100}
                value={acessKey}
                onChange={e => setAcessKey(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Client Id (shipay)
              </Input200px>
              <InputText
                maxLength={300}
                value={clientId}
                onChange={e => setClientId(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Secret Key (shipay)
              </Input200px>
              <InputText
                maxLength={300}
                value={secretKey}
                onChange={e => setSecretKey(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="col-12" style={{ textAlign: 'right' }}>
          <IconButton titulo="Salvar" mensagem="Salvar" btnStyle="success" onClick={handleSubmit} icon="check" />
          <IconButton titulo="Cancelar" mensagem="Cancelar" btnStyle="danger" onClick={handleCancel} icon="times" />
        </div>
      </Card>
    </>
  );
}
