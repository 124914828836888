import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmail } from 'validator';
import { toast } from 'react-toastify';
import { get } from 'lodash';

import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { InputNumber } from 'primereact/inputnumber';
import { Password } from 'primereact/password';
import { Calendar } from 'primereact/calendar';
import { ToggleButton } from 'primereact/togglebutton';
import { addLocale } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';
import { displayErrors } from '../../../util/diversos';
import Loading from '../../loading';
import IconButton from '../../../components/IconButton';
import Card from '../../../components/card';

import { Input155px } from '../../../styles/inputGroup';

import axiosCoto from '../../../services/axiosCoto';
import axiosViaCep from '../../../services/axiosViaCep';

import { obterListaTipoUsuario, obterListaTipoUsuarioLogin } from '../../../services/usuarioService';

export default function CadastroUsuario() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tipoUsuarioLogado = useSelector((state) => state.auth.user.tipoUsuario);
  const logado = useSelector((state) => state.auth.isLoggedIn);
  const idUsuario = get(useParams(), 'id', 0);

  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [tipoUsuario, setTipoUsuario] = useState('');
  const [senha, setSenha] = useState('');
  const [senhaRepeticao, setSenhaRepeticao] = useState('');
  const [telefone1, setTelefone1] = useState('');
  const [telefone2, setTelefone2] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [cnpj, setCnpj] = useState('');
  const [inscricaoEstadual, setInscricaoEstadual] = useState('');
  const [razaoSocial, setRazaoSocial] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [ramoNegocio, setRamoNegocio] = useState('');

  const [endereco, setEndereco] = useState('');
  const [enderecoNumero, setEnderecoNumero] = useState('');
  const [enderecoComplemento, setEnderecoComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [codigoMunicipio, setCodigoMunicipio] = useState('');
  const [cidade, setCidade] = useState('');
  const [filteredMunicipios, setFilteredMunicipios] = useState(null);
  const [uf, setUf] = useState('');
  const [cep, setCep] = useState('');
  const [valorMensal, setValorMensal] = useState(0);
  const [dataCadastro, setDataCadastro] = useState('');
  const [dataLicenca, setDataLicenca] = useState('');

  const [listaRamoNegocio, setListaRamoNegocio] = useState([]);
  const [listaMunicipios, setListaMunicipios] = useState([]);
  const [ativo, setAtivo] = useState();
  const [raioAtuacao, setRaioAtuacao] = useState();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [receberEmails, setReceberEmails] = useState();

  let listaTipoUsuario = '';

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  useEffect(() => {
    async function getRamoNegocios() {
      setIsLoading(true);

      const listDropdown = [{ label: 'Selecione...', value: '' }];

      const { data: _ramoNegocios } = await axiosCoto.get('/ramonegocio');

      _ramoNegocios.forEach(element => {
        listDropdown.push({ label: element.descricao, value: element.id });
      });

      setListaRamoNegocio(listDropdown);

      setIsLoading(false);
    }

    getRamoNegocios();

    async function getMunicipios() {
      setIsLoading(true);

      const listDropdown = [];

      const { data: _municipios } = await axiosCoto.get('/municipio');

      _municipios.forEach(element => {
        listDropdown.push({
          label: element.descricao,
          value: element.descricao,
          codigo: element.codigo,
          uf: element.uf,
          latitude: element.latitude,
          longitude: element.longitude,
        });
      });

      setListaMunicipios(listDropdown);

      setIsLoading(false);
    }

    getMunicipios();

    if (!idUsuario) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosCoto.get(`/usuario/${idUsuario}`);

        setNome(data.nome);
        setEmail(data.email);
        setTipoUsuario(data.tipoUsuario);
        setSenha(data.senha);
        setSenhaRepeticao(data.senha);
        setTelefone1(data.telefone1);
        setTelefone2(data.telefone2);
        setCnpj(data.cnpj);
        setInscricaoEstadual(data.inscricaoEstadual);
        setRazaoSocial(data.razaoSocial);
        setNomeFantasia(data.nomeFantasia);
        setRamoNegocio(data.ramoNegocio.id);
        setEndereco(data.endereco);
        setEnderecoNumero(data.enderecoNumero);
        setEnderecoComplemento(data.enderecoComplemento);
        setBairro(data.bairro);
        setCidade(data.cidade);
        setCodigoMunicipio(data.codigoMunicipio);
        setUf(data.uf);
        setCep(data.cep);
        setAtivo(data.ativo);
        setRaioAtuacao(data.raioAtuacao);
        setValorMensal(data.valorMensal);
        const dataCad = new Date(data.dataCadastro[0], data.dataCadastro[1] - 1, data.dataCadastro[2]);
        setDataCadastro(dataCad);
        const dataValidade = new Date(data.dataLicenca[0], data.dataLicenca[1] - 1, data.dataLicenca[2]);
        setDataLicenca(dataValidade);
        setLatitude(data.latitude);
        setLongitude(data.longitude);
        setReceberEmails(data.receberEmails);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [dispatch, idUsuario, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      if (!inscricaoEstadual) { setInscricaoEstadual('ISENTO'); }

      const shippingData = {
        nome,
        email,
        tipoUsuario,
        senha,
        ativo,
        ramoNegocio,
        telefone1,
        telefone2,
        cnpj,
        inscricaoEstadual,
        razaoSocial,
        nomeFantasia,
        endereco,
        enderecoNumero,
        enderecoComplemento,
        bairro,
        codigoMunicipio,
        cidade,
        uf,
        valorMensal,
        cep,
        dataLicenca,
        raioAtuacao,
        latitude,
        longitude,
        receberEmails,
      };

      if (idUsuario > 0) {
        await axiosCoto.put(`/usuario/${idUsuario}`, shippingData);
        toast.success('Usuário atualizado com sucesso.');
      } else {
        await axiosCoto.post('/usuario', shippingData);
        toast.success('Usuário cadastrado com sucesso.');
      }

      if (tipoUsuarioLogado) {
        navigate('/consultausuario');
      } else {
        navigate('/login');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  async function setLatitudeLongitudeByCodigoMunicipio() {
    listaMunicipios.forEach(element => {
      if (element.codigo === codigoMunicipio) {
        setCidade(element.label);
        setLatitude(element.latitude);
        setLongitude(element.longitude);
      }
    });
  }

  const onBlurCep = (e) => {
    e.preventDefault();

    async function getViaCep() {
      try {
        setIsLoading(true);

        const { data } = await axiosViaCep.get(`/${cep}/json/`);

        if (!data.erro) {
          setEndereco(get(data, 'logradouro', ''));
          setEnderecoComplemento(get(data, 'complemento'));
          setBairro(get(data, 'bairro', ''));
          setCodigoMunicipio(get(data, 'ibge', ''));
          await setLatitudeLongitudeByCodigoMunicipio();

          const listDropdown = [];
          listDropdown.push({
            label: get(data, 'localidade', ''),
            value: get(data, 'localidade', ''),
            codigo: get(data, 'ibge', ''),
            uf: get(data, 'uf', ''),
            latitude: get(data, 'latitude', 0),
            longitude: get(data, 'longitude', 0),
          });

          setCidade(get(data, 'localidade', ''));
          setFilteredMunicipios(listDropdown);
          setUf(get(data, 'uf', ''));
        }
      } finally {
        setIsLoading(false);
      }
    }
    if (cep && cep.length === 8) {
      getViaCep();
    }
  };

  const searchMunicipios = (event) => {
    const listDropdown = [];

    listaMunicipios.forEach(element => {
      if (element.label.toLowerCase().startsWith(event.query.toLowerCase())) {
        listDropdown.push({
          label: element.label,
          value: element.value,
          codigo: element.codigo,
          uf: element.uf,
          latitude: element.latitude,
          longitude: element.longitude,
        });
      }
    });

    setFilteredMunicipios(listDropdown);
  };

  const onBlurCodigoMunicipio = (e) => {
    const listDropdown = [];

    listaMunicipios.forEach(element => {
      if (element.codigo !== undefined && element.codigo === e) {
        listDropdown.push({
          label: element.label,
          value: element.value,
          codigo: element.codigo,
          uf: element.uf,
          latitude: element.latitude,
          longitude: element.longitude,
        });
      }
    });

    setCidade('');
    setUf('');

    if (listDropdown.length > 0) {
      setCidade(get(listDropdown[0], 'value', ''));
      setUf(get(listDropdown[0], 'uf', ''));
      setLatitude(get(listDropdown[0], 'latitude', ''));
      setLongitude(get(listDropdown[0], 'longitude', ''));
    }
  };

  const handleChangeCidade = (event) => {
    const codigo = get(event.value, 'codigo', '');

    if (codigo !== '' && codigo.length === 7) {
      setCodigoMunicipio(codigo);
      onBlurCodigoMunicipio(codigo);
      setLatitude(event.value.latitude);
      setLongitude(event.value.longitude);
    } else {
      setCidade(event.value);
    }
  };

  const handleCancel = () => {
    if (tipoUsuarioLogado) {
      navigate('/consultausuario');
    } else {
      navigate('/login');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (!cnpj) { // CNPJ/CPF validacao
      formErros = true;
      toast.error('CNPJ/CPF deve conter 11 ou 14 números sem pontuação');
    } else if (cnpj.length !== 11 && cnpj.length !== 14) {
      formErros = true;
      toast.error('CNPJ/CPF deve conter 11 ou 14 números sem pontuação.');
    }
    if (!tipoUsuario) {
      formErros = true;
      toast.error('Tipo de Usuário deve ser informado.');
    }
    if (razaoSocial.length < 3) {
      formErros = true;
      toast.error('Razão social/NomeCompleto deve ser informado.');
    }
    if (!isEmail(email)) {
      formErros = true;
      toast.error('E-mail inválido.');
    }
    if (tipoUsuarioLogado !== 'ADMINISTRADOR') {
      if (senha.length < 3 || senha.length > 50) {
        formErros = true;
        toast.error('Senha deve conter de 3 à 50 caracteres.');
      }
      if (senha !== senhaRepeticao) {
        formErros = true;
        toast.error('Senhas digitadas não conferem.');
      }
    }
    if (!ramoNegocio) {
      formErros = true;
      toast.error('Ramo de Negócio deve ser informado.');
    }
    if (telefone1 === '' || telefone1.length > 20) {
      formErros = true;
      toast.error('Telefone 1 deve ser informado.');
    }
    if (raioAtuacao <= 0 || raioAtuacao === '' || raioAtuacao === null) {
      formErros = true;
      toast.error('Raio de atuação deve ser informado e maior que zero.');
    }
    if (cep.length < 8) {
      formErros = true;
      toast.error('CEP deve ser informado.');
    }
    if (!endereco) {
      formErros = true;
      toast.error('Endereço deve ser informado.');
    }
    if (!enderecoNumero) {
      formErros = true;
      toast.error('Número do endereço deve ser informado.');
    }
    if (bairro === '') {
      formErros = true;
      toast.error('Bairro deve ser informado.');
    }
    if (!codigoMunicipio) {
      formErros = true;
      toast.error('Código do Munícipio deve ser informado.');
    }
    if (cidade === '' || cidade === null) {
      formErros = true;
      toast.error('Cidade deve ser informada.');
    }
    if (uf === '') {
      formErros = true;
      toast.error('UF deve ser informada.');
    }
    if (!longitude && !latitude) {
      // pega a longitude baseado no codigo municipio pego pelo cep
      setLatitudeLongitudeByCodigoMunicipio();
    }

    if (formErros) return;

    salvarRegistro();
  };

  if (tipoUsuarioLogado) {
    listaTipoUsuario = obterListaTipoUsuario();
  } else {
    listaTipoUsuario = obterListaTipoUsuarioLogin();
  }

  return (
    <>
      <Loading isLoading={isLoading} />
      <Card title="Usuário">
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                CNPJ/CPF *
              </Input155px>
              <InputText
                id="inputCnpj"
                maxLength={14}
                value={cnpj}
                onChange={e => setCnpj(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Inscrição Estadual
              </Input155px>
              <InputText
                id="inputInscricaoEstadual"
                maxLength={13}
                value={inscricaoEstadual}
                onChange={e => setInscricaoEstadual(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Tipo de Usuário *
              </Input155px>
              <Dropdown
                options={listaTipoUsuario}
                id="inputTipoUsuario"
                value={tipoUsuario}
                onChange={e => setTipoUsuario(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Razão Social *
              </Input155px>
              <InputText
                id="inputRazaoSocial"
                maxLength={50}
                value={razaoSocial}
                onChange={e => setRazaoSocial(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Nome Fantasia / Apelido
              </Input155px>
              <InputText
                id="inputNomeFantasia"
                maxLength={50}
                value={nomeFantasia}
                onChange={e => setNomeFantasia(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                E-mail *
              </Input155px>
              <InputText
                id="inputEmail"
                maxLength={50}
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
          </div>
          {(idUsuario <= 0) ? (
            <>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Senha *
                  </Input155px>
                  <Password
                    id="inputPassword"
                    maxLength={50}
                    toggleMask
                    weakLabel="Fraca"
                    mediumLabel="Média"
                    strongLabel="Forte"
                    value={senha}
                    onChange={e => setSenha(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Repita a Senha *
                  </Input155px>
                  <Password
                    id="inputPassword"
                    maxLength={50}
                    toggleMask
                    weakLabel="Fraca"
                    mediumLabel="Média"
                    strongLabel="Forte"
                    value={senhaRepeticao}
                    onChange={e => setSenhaRepeticao(e.target.value)}
                  />
                </div>
              </div>
            </>
          ) : ('')}
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Ramo Negócio *
              </Input155px>
              <Dropdown
                options={listaRamoNegocio}
                value={ramoNegocio}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setRamoNegocio(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Telefone 1 *
              </Input155px>
              <InputMask
                mask="(99) 9999-9999"
                maxLength={20}
                id="inputTelefone_1"
                value={telefone1}
                onChange={e => setTelefone1(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Telefone 2
              </Input155px>
              <InputMask
                mask="(99) 9999-9999"
                id="inputTelefone_2"
                maxLength={20}
                value={telefone2}
                onChange={e => setTelefone2(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          {tipoUsuarioLogado ? (
            <div className="col-4">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Ativo
                </Input155px>
                <ToggleButton
                  onLabel="Sim"
                  offLabel="Não"
                  onIcon="pi pi-check"
                  offIcon="pi pi-times"
                  checked={ativo}
                  onChange={(e) => setAtivo(e.value)}
                />
              </div>
            </div>
          ) : ('')}
          {tipoUsuarioLogado === 'VENDEDOR' || tipoUsuario === 'VENDEDOR' || tipoUsuarioLogado === 'COMPRADOR' || tipoUsuario === 'COMPRADOR' ? (
            <div className="col-4">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Raio Atuação em km *
                </Input155px>
                <InputNumber
                  id="inputEnderecoNumero"
                  maxLength={4}
                  value={raioAtuacao}
                  onValueChange={e => setRaioAtuacao(e.target.value)}
                  mode="decimal"
                  locale="pt-BR"
                />
              </div>
            </div>
          ) : ('')}
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                CEP *
              </Input155px>
              <InputText
                maxLength={8}
                value={cep}
                onChange={e => setCep(e.target.value)}
                onBlur={e => onBlurCep(e)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-5">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Endereço *
              </Input155px>
              <InputText
                id="inputEndereco"
                maxLength={50}
                value={endereco}
                onChange={e => setEndereco(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Número *
              </Input155px>
              <InputText
                id="inputEnderecoNumero"
                maxLength={20}
                value={enderecoNumero}
                onChange={e => setEnderecoNumero(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Bairro *
              </Input155px>
              <InputText
                id="inputBairro"
                maxLength={50}
                value={bairro}
                onChange={e => setBairro(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Cidade *
              </Input155px>
              <AutoComplete
                value={cidade}
                maxLength={40}
                suggestions={filteredMunicipios}
                completeMethod={searchMunicipios}
                field="label"
                dropdown
                forceSelection
                onChange={(e) => handleChangeCidade(e)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Código Município *
              </Input155px>
              <InputText
                id="inputCodigoMunicipio"
                maxLength={7}
                value={codigoMunicipio}
                onChange={e => setCodigoMunicipio(e.target.value)}
                onBlur={e => onBlurCodigoMunicipio(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                UF *
              </Input155px>
              <InputText
                id="inputUf"
                maxLength={2}
                value={uf}
                onChange={e => setUf(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Complemento
              </Input155px>
              <InputText
                id="inputEnderecoComplemento"
                maxLength={20}
                value={enderecoComplemento}
                onChange={e => setEnderecoComplemento(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Nome Contato
              </Input155px>
              <InputText
                maxLength={50}
                value={nome}
                onChange={e => setNome(e.target.value)}
              />
            </div>
          </div>
        </div>
        {tipoUsuarioLogado && logado ? (
          <div className="row form-elo">
            <div className="col-4">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Valor
                </Input155px>
                <InputNumber
                  maxLength={17}
                  value={valorMensal}
                  onValueChange={e => setValorMensal(e.target.value)}
                  mode="decimal"
                  locale="pt-BR"
                  minFractionDigits={2}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Data Cadastro
                </Input155px>
                <Calendar
                  disabled
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  locale="pt-BR"
                  maxLength={10}
                  id="datetemplate"
                  value={dataCadastro}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Data Licença
                </Input155px>
                <Calendar
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  locale="pt-BR"
                  maxLength={10}
                  value={dataLicenca}
                  onChange={e => setDataLicenca(e.target.value)}
                />
              </div>
            </div>
          </div>
        ) : ('') }
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Receber Emails
              </Input155px>
              <ToggleButton
                onLabel="Sim"
                offLabel="Não"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                checked={receberEmails}
                onChange={(e) => setReceberEmails(e.value)}
              />
            </div>
          </div>
        </div>
        <div className="col-12" style={{ textAlign: 'right' }}>
          <IconButton titulo="Salvar" mensagem="Salvar" btnStyle="success" onClick={handleSubmit} icon="check" />
          <IconButton titulo="Cancelar" mensagem="Cancelar" btnStyle="danger" onClick={handleCancel} icon="times" />
        </div>
      </Card>
    </>
  );
}
