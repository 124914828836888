import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';

import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Card from '../../../components/card';
import IconButton from '../../../components/IconButton';

import { Input100px, Input155px } from '../../../styles/inputGroup';
import { displayErrors } from '../../../util/diversos';
import { fillColumns } from '../../../components/table';

import Loading from '../../loading';
import axiosCoto from '../../../services/axiosCoto';

export default function CadastroPedido() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const idUsuario = useSelector((state) => state.auth.user.id);
  const id = get(useParams(), 'id', 0);

  const [descricao, setDescricao] = useState('');
  const [data, setData] = useState('');
  const [totalPedido, setTotalPedido] = useState(0);

  const [dataTableProdutos, setDataTableProdutos] = useState([]);
  const [produtoSelecionado, setProdutoSelecionado] = useState();
  const produtos = [];
  const [expandedRows, setExpandedRows] = useState(null);
  const [produtosResponder, setProdutosResponder] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [dataTableColumn] = useState([
    { field: 'razaoSocial', header: 'Vendedor', sort: true },
    { field: 'valorCaixa', header: 'Valor da Caixa', sort: false },
    { field: 'qtdePorCaixa', header: 'Qtde por Caixa', sort: false },
    { field: 'valorUnitario', header: 'Valor da UN', sort: false },
    { field: 'qtdePedido', header: 'Pedido em CX', sort: false },
    { field: 'subTotal', header: 'SubTotal', sort: false },
  ]);

  const [dataTableColumnICMSIPI] = useState([
    { field: 'razaoSocial', header: 'Vendedor', sort: true },
    { field: 'valorCaixa', header: 'Valor da Caixa', sort: false },
    { field: 'qtdePorCaixa', header: 'Qtde por Caixa', sort: false },
    { field: 'valorUnitario', header: 'Valor da UN', sort: false },
    { field: 'qtdePedido', header: 'Pedido em CX', sort: false },
    { field: 'subTotal', header: 'SubTotal', sort: false },
    { field: 'percentualICMS', header: 'Perc. ICMS', sort: false },
    { field: 'percentualIPI', header: 'Perc. IPI', sort: false },
  ]);

  const [exibirColunas, setExibirColunas] = useState(dataTableColumn);

  async function getData() {
    try {
      setIsLoading(true);
      const response = await axiosCoto.get(`/pedido/pedidoparacomprador/${id}`);

      setDescricao(response.data.descricao.trim());
      const dataPedido = new Date(response.data.data[0], response.data.data[1] - 1, response.data.data[2]);
      setData(dataPedido);

      let pedidoProdutos = [];
      let valorCompra = 0;
      let contador = 1;
      // eslint-disable-next-line no-undef
      response.data.pedidoProdutos = _.sortBy(response.data.pedidoProdutos, 'descricaoProduto');
      response.data.pedidoProdutos.forEach(element => {
        if (element.qtdeRespostas > 0) {
          valorCompra = element.valorTotalCompra;
          pedidoProdutos.push(
            {
              numItemPedido: contador++,
              label: element.descricaoProduto.trim(),
              value: element.idProduto,
              id: element.id,
              quantidade: element.quantidade,
            },
          );
        }
      });

      setTotalPedido(valorCompra.toFixed(2));
      // eslint-disable-next-line no-undef
      pedidoProdutos = _.sortBy(pedidoProdutos, 'label');
      setDataTableProdutos(pedidoProdutos);

      setIsLoading(false);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!id) return;

    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, idUsuario, navigate]);

  const onRowCollapse = (e) => {
    setExpandedRows({ [e.id]: false });
  };

  const onRowExpand = async (e) => {
    if (e.qtdeRespostas <= 0) {
      toast.error('Não há respostas para este produto.');
      return;
    }
    try {
      setIsLoading(true);
      setExpandedRows({ [e.id]: true });
      setProdutoSelecionado(e);

      const response = await axiosCoto.get(`/pedido/pedidorespostasparacomprador/${id}/${e.value}`);

      const cotacaoProdutos = [];
      response.data.forEach(element => {
        const qtdePorCaixa = get(element, 'qtdePorCaixa', 0);
        let valorCaixa = get(element, 'valorCaixa', 0);
        let valorUnitario = get(element, 'valorUnitario', 0);
        const qtdePedido = get(element, 'qtdePedido', 0);
        const percentualICMS = get(element, 'percentualICMS', 0);
        const percentualIPI = get(element, 'percentualIPI', 0);

        if (parseFloat(percentualICMS) > 0 || parseFloat(percentualIPI) > 0) {
          setExibirColunas(dataTableColumnICMSIPI);
        }

        const valorAtacado = get(element, 'valorAtacado', 0);
        const qtdeAtacado = get(element, 'qtdeAtacado', 0);
        const totalST = get(element, 'totalST', 0);
        let subTotal = 0;
        if (qtdePedido >= qtdeAtacado && qtdeAtacado > 0) {
          valorCaixa = valorAtacado;
          valorUnitario = valorAtacado / qtdePorCaixa;
          subTotal = valorAtacado * qtdePedido;
        } else {
          subTotal = valorCaixa * qtdePedido;
        }
        cotacaoProdutos.push({ label: element.descricaoProduto,
          id: element.id,
          idCotacao: element.idCotacao,
          idProduto: element.idProduto,
          idVendedor: element.idVendedor,
          qtdePedido,
          qtdePorCaixa,
          valorCaixa: `R$ ${valorCaixa.toFixed(2).replace('.', ',')}`,
          valorUnitario: `R$ ${valorUnitario.toFixed(2).replace('.', ',')}`,
          percentualICMS,
          percentualIPI,
          totalST,
          razaoSocial: element.razaoSocial,
          subTotal: `R$ ${subTotal.toFixed(2).replace('.', ',')}`,
        });
      });

      setProdutosResponder(cotacaoProdutos);
      setIsLoading(false);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    }
  };

  const handleCancel = () => {
    navigate('/consultapedido');
  };

  const rowExpansionTemplate = () => (
    <Card>
      <DataTable
        value={produtosResponder}
        responsiveLayout="scroll"
        editMode="row"
        selectionMode="single"
      >
        {fillColumns(exibirColunas)}
      </DataTable>
    </Card>
  );

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card title="Visualizando Pedido">
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Descrição
              </Input155px>
              <InputText
                id="inputDescricao"
                maxLength={50}
                value={descricao}
                onChange={e => setDescricao(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Data
              </Input100px>
              <Calendar
                dateFormat="dd/mm/yy"
                id="data"
                value={data}
                onChange={e => setData(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Total R$
              </Input100px>
              <InputText
                id="inputPedido"
                maxLength={50}
                value={totalPedido}
                onChange={(e) => setTotalPedido(e.value)}
              />
            </div>
          </div>
        </div>
        <div className="datatable-produtos">
          <div className="card">
            <DataTable
              id="TabelaProdutos"
              value={dataTableProdutos}
              expandedRows={expandedRows}
              onRowExpand={(e) => onRowExpand(e.data)}
              onRowCollapse={onRowCollapse}
              rowExpansionTemplate={rowExpansionTemplate}
              size="small"
              stripedRows
              setFilteredItems={produtos}
              selection={produtoSelecionado}
              responsiveLayout="scroll"
              selectionMode="single"
              onSelectionChange={e => setProdutoSelecionado(e)}
              dataKey="id"
              paginator
              rows={10}
              emptyMessage="Nenhum registro a ser exibido"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{currentPage} de {totalPages}"
              rowsPerPageOptions={[10, 25, 50]}
            >
              <Column field="numItemPedido" header="id" sortable></Column>
              <Column field="label" header="Descrição" sortable></Column>
              <Column expander style={{ width: '3em' }} />
            </DataTable>
          </div>
        </div>
      </Card>
      <div className="col-12" style={{ textAlign: 'right' }}>
        <IconButton titulo="Voltar" mensagem="Voltar" btnStyle="success" onClick={handleCancel} icon="chevron-left" />
      </div>
    </div>
  );
}
