/* eslint-disable prefer-template */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Dropdown } from 'primereact/dropdown';
import { orderBy } from 'lodash';

import axiosCoto from '../../../services/axiosCoto';
import { displayErrors } from '../../../util/diversos';

import { Input155px } from '../../../styles/inputGroup';
import IconButton from '../../../components/IconButton';

import Card from '../../../components/card';
import Loading from '../../loading';

export default function RelatorioPedidoVendedor() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const idUsuario = useSelector((state) => state.auth.user.id);

  const [nomeEmpresa, setNomeEmpresa] = useState();
  const [pedido, setPedido] = useState();
  const [listaPedidos, setListaPedidos] = useState([]);
  const [totalPedido, setTotalPedido] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  function headRows() {
    return [
      { numItemPedido: 'Item', Pedido: 'Pedido(CX)', Produto: 'Produto', Caixa: 'Valor Caixa ', Qtde: 'Qtde Por CX', UN: 'Valor UN', Total: 'Total' },
    ];
  }

  function headRowsICMSIPI() {
    return [
      { numItemPedido: 'Item', Pedido: 'Pedido(CX)', Produto: 'Produto', Caixa: 'Valor Caixa ', Qtde: 'Qtde Por CX', UN: 'Valor UN', Total: 'Total', percentualICMS: 'Perc. ICMS', percentualIPI: 'Perc. IPI'},
    ];
  }

  const [headerTabelaImpressao, setHeaderTabelaImpressao] = useState(headRows());

  async function getData() {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();
    try {
      setIsLoading(true);

      // pega os campos da busca
      const id = pedido;

      if (id > 0) {
        const { data: _relatorio } = await axiosCoto.get(`/pedido/pedidopararelatoriovendedor/${id}/${idUsuario}`);

        const dataFechamento = `${('00' + _relatorio.data[2]).slice(-2)}/${('00'+ _relatorio.data[1]).slice(-2)}/${_relatorio.data[0]}`;

        let totalPedido = 0;
        const result = [];
        let data = {
          id: '0',
          Pedido: '0',
          qtdeAtacado: '0',
          valorAtacado: '0',
          Produto: '',
          Caixa: '',
          Qtde: '0',
          UN: '0',
          Total: '0',
          percentualICMS: '',
          percentualIPI: '',
        };

        setHeaderTabelaImpressao(headRows());

        let contador = 1;
        _relatorio.pedidoProdutos = _.sortBy(_relatorio.pedidoProdutos, 'descricaoProduto');
        for (let x = 0; x < _relatorio.pedidoProdutos.length; x++) {
          for (let z = 0; z < _relatorio.pedidoProdutos[x].pedidoProdutoRespostas.length; z++) {
            if (_relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].qtdePedido > 0) {
              data.numItemPedido = contador.toString();
              data.Produto = _relatorio.pedidoProdutos[x].descricaoProduto.toString();
              data.Caixa = _relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].valorCaixa.toFixed(2).toString();
              data.Qtde = _relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].qtdePorCaixa.toString();
              data.UN = _relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].valorUnitario.toFixed(3).toString();
              data.Pedido = _relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].qtdePedido.toString();
              if (_relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].qtdeAtacado === null) {
                data.qtdeAtacado = '0';
              } else {
                data.qtdeAtacado = _relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].qtdeAtacado.toString();
              }
              if (_relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].valorAtacado === null) {
                data.valorAtacado = '0';
              } else {
                data.valorAtacado = _relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].valorAtacado.toFixed(2).toString();
              }
              let valorCompra = 0;
              if (parseFloat(data.Pedido) >= parseFloat(data.qtdeAtacado) && parseFloat(data.qtdeAtacado) > 0) {
                data.Caixa = data.valorAtacado;
                data.UN = (data.valorAtacado / data.Qtde).toFixed(2);
                valorCompra = (parseFloat(data.valorAtacado).toFixed(2) * parseFloat(data.Pedido).toFixed(2));
              } else {
                valorCompra = (parseFloat(data.Caixa).toFixed(2) * parseFloat(data.Pedido).toFixed(2));
              }

              data.Caixa = parseFloat(data.Caixa).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
              data.UN = parseFloat(data.UN).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
              data.Total = valorCompra.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
              totalPedido += valorCompra;

              if (_relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].percentualICMS === null) {
                data.percentualICMS = '0';
              } else {
                data.percentualICMS = _relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].percentualICMS.toString();
              }
              if (_relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].percentualIPI === null) {
                data.percentualIPI = '0';
              } else {
                data.percentualIPI = _relatorio.pedidoProdutos[x].pedidoProdutoRespostas[z].percentualIPI.toString();
              }

              if (parseFloat(data.percentualICMS) > 0 || parseFloat(data.percentualIPI) > 0) {
                setHeaderTabelaImpressao(headRowsICMSIPI());
              }

              result.push(Object.assign({}, data));
              contador++;
            }
          }
        }

        if (result.length <= 0) {
          toast.warning('Nenhuma informação para o relatório.');
          setIsLoading(false);
          return;
        }

        data = {
          Produto: '',
          Caixa: '',
          Qtde: '',
          UN: '',
          Pedido: '',
          qtdeAtacado: '0',
          valorAtacado: '0',
          Total: '',
          percentualICMS: '',
          percentualIPI: '',
        };

        setTotalPedido(totalPedido);
        data.UN = 'Total';
        data.Total = totalPedido.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        result.push(Object.assign({}, data));

        doc.autoTable({
          styles: { fontSize: 6 },
          margin: { top: 38, left: 5, right: 5 },
          theme: 'grid',
          head: headerTabelaImpressao,
          body: result,
          didDrawPage: function (dados) {
            // Header
            doc.rect(5, 5, 200, 30);
            doc.addImage('logo.png', 'JPEG', 10, 12, 25, 15);
            doc.setFontSize(20);
            doc.text('Relatório de Pedido', 75, 15);

            doc.setFontSize(10);
            doc.text('Número: ', 164, 12);
            doc.text(pedido.toString(), 180, 12);

            doc.text('Empresa:', 45, 28);
            doc.text(nomeEmpresa, 63, 28);
            doc.text('Descrição:', 45, 32);
            doc.text(_relatorio.descricao, 63, 32);
            doc.text('Fechamento:', 164, 32);
            doc.text(dataFechamento, 186, 32);

            const pageSize = doc.internal.pageSize;
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text(`Pag ${doc.internal.getNumberOfPages()}`, dados.settings.margin.right, pageHeight - 8);
            doc.text('Elo Informática Barbacena', 82, pageHeight - 8 );
            const periodo = new Date();
            doc.text('Impresso: ', 172, pageHeight - 8);
            doc.text((`${('00' + periodo.getDate()).slice(-2)}/${('00' + (+periodo.getMonth() + 1)).slice(-2)}/${periodo.getFullYear()}`).toString(), 189, pageHeight - 8);
          },
        });
        window.open(doc.output('bloburl'));
      } else {
        toast.warning('Nenhuma informação para o relatório.');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    async function getPedidos() {
      try {
        setIsLoading(true);

        const listDropdown = [];

        const _pedidos = await axiosCoto.get(`/pedido/listapedidosvendedorrelatorio/?idVendedor=${idUsuario}`);

        _pedidos.data.forEach(element => {
          const data = ('00' + element[4][2]).slice(-2)+'/'+('00' + element[4][1]).slice(-2)+'/'+element[4][0];
          const labelExibicao = `${element[0]} - ${data} - ${element[2]} - ${element[3]}`;
          setNomeEmpresa(element[3]);
          listDropdown.push({ label: labelExibicao.toUpperCase(), value: element[0] });
        });

        setListaPedidos(listDropdown);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getPedidos();
  }, [dispatch, navigate, idUsuario, pedido]);

  const handleFind = () => {
    getData();
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <Card title="Relatório de Pedidos">
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Pedido
              </Input155px>
              <Dropdown
                autoFocus
                options={listaPedidos}
                value={pedido}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setPedido(e.target.value)}
              />
              &nbsp; &nbsp;
              <IconButton titulo="" mensagem="Gerar Relatório" btnStyle="primary" onClick={handleFind} icon="print" />
            </div>
          </div>

        </div>
        <div className="row form-elo">
        </div>
      </Card>
    </>
  );
}
