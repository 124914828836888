/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';

import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Card from '../../../components/card';
import { displayErrors } from '../../../util/diversos';
import '../../../styles/custom.css';

import { Input100px, Input155px, Input200px, Input300px } from '../../../styles/inputGroup';
import { obterListaNumRespostasPorProduto } from '../../../services/usuarioService';
import { fillColumns } from '../../../components/table';

import Loading from '../../loading';
import axiosCoto from '../../../services/axiosCoto';
import IconButton from '../../../components/IconButton';
import Avaliacao from '../../../components/avaliacao';

export default function CadastroCotacao() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const idUsuario = useSelector((state) => state.auth.user.id);
  const id = get(useParams(), 'id', 0);

  const [descricao, setDescricao] = useState('');
  const [dataAbertura, setDataAbertura] = useState('');
  const [dataFechamento, setDataFechamento] = useState('');
  const [observacao, setObservacao] = useState('');
  const [totalCotacao, setTotalCotacao] = useState(0);
  const [numeroRespostasPorProduto, setNumeroRespostasPorProduto] = useState(3);
  const [vendedorSelecionado, setVendedorSelecionado] = useState(0);
  const [listaVendedores, setListaVendedores] = useState([]);
  const [dataTableProdutos, setDataTableProdutos] = useState([]);
  const [showValoresVendedoresDialog, setShowValoresVendedoresDialog] = useState(false);
  const [dataTableValoresVendedores, setDataTableValoresVendedores] = useState([]);

  const [produtoAtualDescricao, setProdutoAtualDescricao] = useState('');

  const [dataTableColumns] = useState([
    { field: 'razaoSocial', header: 'Email', sort: true },
    { field: 'avaliacao', header: 'Avaliação', sort: true },
    { field: 'valorUnitario', header: 'Valor', sort: true },
  ]);

  const [showUltimasComprasDialog, setShowUltimasComprasDialog] = useState(false);
  const [dataTableInfoProdutos, setDataTableInfoProdutos] = useState([]);

  async function getData() {
    try {
      setIsLoading(true);

      const { data } = await axiosCoto.get(`/cotacao/cotacaoparacompradorresponder/${id}`);
      setDescricao(data.descricao.trim());
      setDataAbertura(new Date(data.dataAbertura[0], data.dataAbertura[1] - 1, data.dataAbertura[2]));
      setDataFechamento(new Date(data.dataFechamento[0], data.dataFechamento[1] - 1, data.dataFechamento[2]));
      setObservacao(data.observacao.trim());

      let response = '';
      if (numeroRespostasPorProduto > 0) {
        response = await axiosCoto.get(`/cotacao/cotacaorespostasparacompradorresponderlimiterespostas/${id}/${numeroRespostasPorProduto}`);
      } else {
        response = await axiosCoto.get(`/cotacao/cotacaotodasrespostasparacompradorresponder/${id}`);
      }

      const cotacaoProdutos = [];
      let somaTotalCotacao = 0;
      let subTotal = 0;
      response.data.forEach(element => {


        if (vendedorSelecionado === 0) {
          const diasEntrega = element.cotacaoVendedorPrazo.diasEntrega ? element.cotacaoVendedorPrazo.diasEntrega : '';
          const tipoEntrega = element.cotacaoVendedorPrazo.tipoEntrega ? element.cotacaoVendedorPrazo.tipoEntrega : '';

          if (element.qtdePedido >= element.qtdeAtacado && element.qtdeAtacado > 0) {
            subTotal = `R$ ${(element.valorAtacado * element.qtdePedido).toFixed(3)}`;
          } else {
            subTotal = `R$ ${(element.valorCaixa * element.qtdePedido).toFixed(3)}`;
          }
          somaTotalCotacao = parseFloat(somaTotalCotacao) + parseFloat(subTotal.replace('R$', ''));

          cotacaoProdutos.push({
            id: element.id,
            idProduto: element.idProduto,
            idCotacao: element.idCotacao,
            idVendedor: element.idVendedor,
            label: element.produtoDescricao,
            razaoSocial: element.razaoSocial,
            pagamentoPrazo: element.cotacaoVendedorPrazo.pagamentoPrazo,
            diasEntrega: `${diasEntrega}/${tipoEntrega.substring(0, 8)}`,
            valorCaixa: `R$ ${element.valorCaixa.toFixed(2)}`,
            qtdePorCaixa: element.qtdePorCaixa,
            valorUnitario: `R$ ${element.valorUnitario.toFixed(3)}`,
            qtdePedido: element.qtdePedido,
            qtdeAtacado: element.qtdeAtacado,
            percentualICMS: element.percentualICMS,
            percentualIPI: element.percentualIPI,
            valorAtacado: `R$ ${element.valorAtacado.toFixed(2)}`,
            subTotal,
          });
        }

        if (Number(vendedorSelecionado) === Number(element.idVendedor)) {
          const diasEntrega = element.cotacaoVendedorPrazo.diasEntrega ? element.cotacaoVendedorPrazo.diasEntrega : '';
          const tipoEntrega = element.cotacaoVendedorPrazo.tipoEntrega ? element.cotacaoVendedorPrazo.tipoEntrega : '';
          if (element.qtdePedido >= element.qtdeAtacado && element.qtdeAtacado > 0) {
            subTotal = `R$ ${(element.valorAtacado * element.qtdePedido).toFixed(3)}`;
          } else {
            subTotal = `R$ ${(element.valorCaixa * element.qtdePedido).toFixed(3)}`;
          }
          somaTotalCotacao = parseFloat(somaTotalCotacao) + parseFloat(subTotal.replace('R$', ''));

          cotacaoProdutos.push({
            id: element.id,
            idProduto: element.idProduto,
            idCotacao: element.idCotacao,
            idVendedor: element.idVendedor,
            label: element.produtoDescricao,
            razaoSocial: element.razaoSocial,
            pagamentoPrazo: element.cotacaoVendedorPrazo.pagamentoPrazo,
            diasEntrega: `${diasEntrega}/${tipoEntrega.substring(0, 8)}`,
            valorCaixa: `R$ ${element.valorCaixa.toFixed(2)}`,
            qtdePorCaixa: element.qtdePorCaixa,
            valorUnitario: `R$ ${element.valorUnitario.toFixed(3)}`,
            qtdePedido: element.qtdePedido,
            qtdeAtacado: element.qtdeAtacado,
            percentualICMS: element.percentualICMS,
            percentualIPI: element.percentualIPI,
            valorAtacado: `R$ ${element.valorAtacado.toFixed(2)}`,
            subTotal,
          });
        }
      });

      const response2 = await axiosCoto.get(`/cotacao/informacaocotacaocomprador/${id}`);

      const _ListaVendedores = [{ label: 'Todos', value: 0 }];
      for (let x = 0; x < response2.data.vendedores.length; x++) {
        _ListaVendedores.push({ label: `${response2.data.vendedores[x].email}`, value: `${response2.data.vendedores[x].id}` });
      }
      setListaVendedores(_ListaVendedores);

      setTotalCotacao(somaTotalCotacao.toFixed(2).replace('.', ','));
      setDataTableProdutos(cotacaoProdutos);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!id) return;

    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, idUsuario, navigate]);

  const handleCancel = () => {
    navigate('/consultacotacao');
  };

  async function salvarProdutoResposta(itemResposta) {
    try {
      const razaoSocialVendedor = itemResposta.razaoSocial;
      const valorUnitario = parseFloat(itemResposta.valorUnitario.replace('R$', '')).toFixed(3);
      const idCotacaoInt = itemResposta.idCotacao;
      const valorCaixa = parseFloat(itemResposta.valorCaixa.replace('R$', '')).toFixed(3);
      const qtdePedido = parseFloat(itemResposta.qtdePedido.replace('.', '').replace(',', '.')).toFixed(3);
      let qtdeAtacado = get(itemResposta, 'qtdeAtacado', 0);
      if (typeof qtdeAtacado === 'string') {
        qtdeAtacado = parseFloat(itemResposta.qtdeAtacado.replace(',', '.'));
      }
      let percentualICMS = get(itemResposta, 'percentualICMS', 0);
      if (typeof percentualICMS === 'string') {
        percentualICMS = parseFloat(itemResposta.percentualICMS.replace(',', '.'));
      }
      let percentualIPI = get(itemResposta, 'percentualIPI', 0);
      if (typeof percentualIPI === 'string') {
        percentualIPI = parseFloat(itemResposta.percentualIPI.replace(',', '.'));
      }
      let valorAtacado = get(itemResposta, 'valorAtacado', 0);
      if (valorAtacado !== 0) {
        valorAtacado = parseFloat(itemResposta.valorAtacado.replace('R$', '').replace(',', '.'));
      }

      const shippingData = {
        idProduto: itemResposta.idProduto,
        id: itemResposta.id,
        qtdePorCaixa: itemResposta.qtdePorCaixa,
        totalST: 0,
        valorUnitario,
        idVendedor: itemResposta.idVendedor,
        valorCaixa,
        idCotacao: idCotacaoInt,
        qtdePedido,
        qtdeAtacado,
        valorAtacado,
        percentualICMS,
        percentualIPI,
        razaoSocial: razaoSocialVendedor,
      };

      if (qtdePedido >= 0) {
        await axiosCoto.post('/cotacaoprodutoresposta', shippingData);
        toast.success('Quantidade do Pedido alterada com sucesso.');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    }
  }

  const calcularsubTotal = (e, event) => {
    const valorCaixa = parseFloat(e.newRowData.valorCaixa.replace('R$', ''));
    let qtdeAtacado = get(e.newRowData, 'qtdeAtacado', 0);
    if (typeof qtdeAtacado === 'string') {
      qtdeAtacado = parseFloat(e.newRowData.qtdeAtacado.replace(',', '.'));
    }
    let valorAtacado = get(e.newRowData, 'valorAtacado', 0);
    if (valorAtacado !== 0) {
      valorAtacado = parseFloat(e.newRowData.valorAtacado.replace('R$', '').replace(',', '.'));
    }
    const qtdePedido = parseFloat(event.target.value.replace(',', '.'));
    if (qtdePedido > 0 && qtdeAtacado > 0 && qtdePedido >= qtdeAtacado) {
      return parseFloat(valorAtacado * qtdePedido);
    }
    return parseFloat(valorCaixa * qtdePedido);
  };

  const onCellEditComplete = (e) => {
    const { rowData, field, originalEvent: event } = e;

    switch (field) {
      case 'qtdePedido':
        if (event.target.value) {
          rowData[field] = event.target.value;
          try {
            const subTotal = calcularsubTotal(e, event);
            const somaTotalCotacao = (parseFloat(totalCotacao.replace(',', '.')) - parseFloat(rowData.subTotal.replace('R$', ''))) + subTotal;
            setTotalCotacao(somaTotalCotacao.toFixed(2));
            rowData.subTotal = `R$ ${subTotal.toFixed(3)}`;
            salvarProdutoResposta(rowData);
          } catch (err) {
            toast.error('Não foi possivel calcular o valor unitário.');
          }
        } else {
          event.preventDefault();
        }
        break;
      default:
        if (event.target.value.trim().length > 0) {
          rowData[field] = event.target.value;
        } else {
          event.preventDefault();
        }
        break;
    }
  };

  const handleFind = () => {
    getData();
  };

  const handlefiltrarPorVendedor = async () => {
    getData();
  };

  const handleValoresVendedores = async () => {
    try {
      setIsLoading(true);
      setShowValoresVendedoresDialog(true);
      const response = await axiosCoto.get(`/cotacao/cotacaorepostastotalporvendedor/${id}`);

      const paramsAvaliacaoTodos = '/avaliacaovendedor/todos';
      let avaliacoesTodos = '';
      await axiosCoto.get(paramsAvaliacaoTodos).then((res) => {
        avaliacoesTodos = res.data;
      });

      let media = 0;
      let contador = 0;
      const _response = response.data.map(element => {
        media = 0;
        contador = 0;
        for (let z = 0; z < avaliacoesTodos.length; z++) {
          if (avaliacoesTodos[z].idVendedor === element.idVendedor) {
            media += avaliacoesTodos[z].classificacaoMedia;
            contador++;
          }
        }
        if (contador > 0) {
          element.avaliacao = <Avaliacao valor={media / contador} votos={contador} />;
        } else {
          element.avaliacao = 'Não avaliado.';
        }
        return element;
      });

      setDataTableValoresVendedores(_response);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  };

  const handleFocus = (event) => {
    event.target.value = '';
    event.target.select();
  };

  const qtdePedidoEditor = (options) => (
    <InputNumber
      onFocus={handleFocus}
      value={options.value}
      onValueChange={(e) => options.editorCallback(e.value)}
      mode="decimal"
      locale="pt-BR"
      minFractionDigits={1}
      style={{ height: '16px', Width: '8rem' }}
    />
  );

  // eslint-disable-next-line consistent-return
  const cellEditor = (options) => {
    if (options.field === 'qtdePedido') {
      return qtdePedidoEditor(options);
    }
  };

  const sairdialogValoresClientes = () => {
    setShowValoresVendedoresDialog(false);
  };

  const sairUltimasComprasDialog = () => {
    setShowUltimasComprasDialog(false);
  };

  const confirmValoresVendedoresFooter = (
    <IconButton titulo="Sair" mensagem="Sair" btnStyle="success" onClick={sairdialogValoresClientes} icon="times" />
  );

  const confirmUltimasComprasFooter = (
    <IconButton titulo="Sair" mensagem="Sair" btnStyle="success" onClick={sairUltimasComprasDialog} icon="times" />
  );

  const exibeInformacoesProduto = async (options, page = 0, size = 20) => {
    try {
      setIsLoading(true);
      let params = `/pedidoprodutoresposta/search/?idProduto=${options.idProduto}`;

      params = `${params}&page=${page}&size=${size}`;
      await axiosCoto.get(params).then((res) => {
        const itens = [];
        for (let x = 0; x < res.data.content.length; x++) {
          const pedido = res.data.content[x];
          if (pedido.qtdePedido > 0) {
            itens.push({
              idPedido: pedido.idPedido,
              valorCaixa: `R$ ${pedido.valorCaixa.toFixed(2)}`,
              qtdePorCaixa: pedido.qtdePorCaixa,
              valorUnitario: `R$ ${pedido.valorUnitario.toFixed(3)}`,
              vendedor: pedido.razaoSocial,
              qtdePedido: pedido.qtdePedido,
            });
          }
        }
        setShowUltimasComprasDialog(true);
        setDataTableInfoProdutos(itens);
        setProdutoAtualDescricao(options.label);
      }).catch(() => {
        toast.warning('Erro na conexão. Atualize a página ou faça o login novamente.');
      });
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  };

  const statusHeaderTemplate = (rowData) => (

    <div className="row">
      <div className="d-flex justify-content-start">
        <Input300px className="p-inputgroup-addon">
          {rowData.label}
        </Input300px>
        &nbsp;
        <IconButton mensagem="Últimas compras" btnStyle="success" onClick={() => { exibeInformacoesProduto(rowData); }} icon="book" />
      </div>
    </div>
  );

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card title="Respondendo Cotação">
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Descrição
              </Input155px>
              <InputText
                maxLength={50}
                value={descricao}
                onChange={e => setDescricao(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Abertura
              </Input100px>
              <Calendar
                dateFormat="dd/mm/yy"
                value={dataAbertura}
                onChange={e => setDataAbertura(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Fechamento
              </Input100px>
              <Calendar
                dateFormat="dd/mm/yy"
                value={dataFechamento}
                onChange={e => setDataFechamento(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-9">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Observação
              </Input155px>
              <InputText
                maxLength={50}
                value={observacao}
                onChange={e => setObservacao(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Total R$
              </Input100px>
              <InputText
                maxLength={50}
                value={totalCotacao}
                onChange={(e) => setTotalCotacao(e.value)}
              />
            </div>
          </div>
        </div>
        <Card>
          <div className="row form-elo">
            <div className="col-3">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Respostas por Produto
                </Input155px>
                <Dropdown
                  options={obterListaNumRespostasPorProduto()}
                  value={numeroRespostasPorProduto}
                  onChange={e => setNumeroRespostasPorProduto(e.target.value)}
                />
                &nbsp;
                <IconButton titulo="" mensagem="Buscar" btnStyle="success" onClick={handleFind} icon="search" />
              </div>
            </div>
            <div className="col-3">
              <div className="p-inputgroup">
                <Input200px className="p-inputgroup-addon">
                  Valor por vendedor
                </Input200px>
                &nbsp;
                <IconButton titulo="" mensagem="Buscar" btnStyle="success" onClick={handleValoresVendedores} icon="search" />
              </div>
            </div>
            <div className="col-6">
              <div className="p-inputgroup">
                <Input200px className="p-inputgroup-addon">
                  Produtos por Vendedor
                </Input200px>
                <Dropdown
                  emptyMessage="Nenhum vendedor respondeu."
                  value={vendedorSelecionado}
                  onChange={e => setVendedorSelecionado(e.target.value)}
                  options={listaVendedores}
                />
                <IconButton titulo="" mensagem="Buscar" btnStyle="success" onClick={handlefiltrarPorVendedor} icon="search" />
              </div>
            </div>
          </div>
          <DataTable
            size="small"
            stripedRows
            value={dataTableProdutos}
            sortMode="single"
            sortField="label"
            sortOrder={1}
            responsiveLayout="scroll"
            dataKey="id"
            rowGroupMode="subheader"
            groupRowsBy="label"
            rows={10}
            rowGroupHeaderTemplate={statusHeaderTemplate}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[9999, 10, 25, 50]}
          >
            <Column field="label" header="Produto" hidden="true"></Column>
            <Column field="razaoSocial" header="Vendedor"></Column>
            <Column field="pagamentoPrazo" header="Pagamento"></Column>
            <Column field="diasEntrega" header="Entrega(dias)/tipo"></Column>
            <Column field="valorCaixa" header="Valor Cx" style={{ width: '10%' }}></Column>
            <Column field="qtdePorCaixa" header="Qtde por cx" style={{ width: '5%' }}></Column>
            <Column field="valorUnitario" header="Valor UN" style={{ width: '10%' }}></Column>
            <Column field="qtdePedido" header="Pedido CX" style={{ width: '5%', background: '#ecffe6' }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete}></Column>
            <Column field="qtdeAtacado" header="Qtde Atacado" style={{ width: '5%' }}></Column>
            <Column field="valorAtacado" header="Valor CX Atacado" style={{ width: '10%' }}></Column>
            <Column field="subTotal" header="SubTotal" style={{ width: '10%' }}></Column>
          </DataTable>
        </Card>
        <div className="col-12" style={{ textAlign: 'right' }}>
          <IconButton titulo="Voltar" mensagem="Voltar" btnStyle="success" onClick={handleCancel} icon="chevron-left" />
        </div>

        <Dialog
          header="Valores dos vendedores"
          visible={showValoresVendedoresDialog}
          style={{ width: '50vw' }}
          footer={confirmValoresVendedoresFooter}
          onHide={() => setShowValoresVendedoresDialog(false)}
        >
          <DataTable
            size="small"
            stripedRows
            value={dataTableValoresVendedores}
            sortMode="single"
            sortField="label"
            sortOrder={1}
            responsiveLayout="scroll"
            dataKey="id"
            rows={10}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50]}
          >
            {fillColumns(dataTableColumns)}
          </DataTable>
        </Dialog>

        <Dialog
          header={produtoAtualDescricao}
          visible={showUltimasComprasDialog}
          style={{ width: '60vw' }}
          footer={confirmUltimasComprasFooter}
          onHide={() => setShowUltimasComprasDialog(false)}
        >
          <DataTable
            size="small"
            stripedRows
            value={dataTableInfoProdutos}
            sortMode="single"
            sortField="label"
            sortOrder={1}
            responsiveLayout="scroll"
            dataKey="id"
            rows={10}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50]}
          >
            <Column field="idPedido" header="id Pedido"></Column>
            <Column field="vendedor" header="Vendedor"></Column>
            <Column field="valorCaixa" header="Valor CX"></Column>
            <Column field="qtdePorCaixa" header="Qtde por CX"></Column>
            <Column field="valorUnitario" header="Valor UN"></Column>
            <Column field="qtdePedido" header="Comprou"></Column>
          </DataTable>
        </Dialog>

      </Card>

    </div>
  );
}
