import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

import { get, orderBy } from 'lodash';

import 'react-toastify/dist/ReactToastify.css';
import { displayErrors } from '../../../util/diversos';

import { fillColumns } from '../../../components/table';
import { Input100px, Input155px, Input250px, InputCampoBusca100px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import Loading from '../../loading';
import axiosCoto from '../../../services/axiosCoto';
import IconButton from '../../../components/IconButton';

export default function ConsultaPedidoAdministrador() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const idUsuario = useSelector((state) => state.auth.user.id);

  const [isLoading, setIsLoading] = useState(false);
  const [euConcordo, setEuConcordo] = useState();
  const [pedidoSelecionado] = useState(null);
  const [pedidosSelecionados, setPedidosSelecionados] = useState(null);
  const [dataTableListaPedidos, setDataTableListaPedidos] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showInformacoesPedidoDialog, setShowInformacoesPedidoDialog] = useState(false);

  const [totalRecords, setTotalRecords] = useState(0);

  const [numProdutos, setNumProdutos] = useState(0);
  const [numRespostas, setNumRespostas] = useState(0);
  const [listaVendedores, setListaVendedores] = useState([]);

  const [findField, setFindField] = useState('');
  const [sortField, setSortField] = useState('razaoSocial');
  const [sortOrder, setSortOrder] = useState(null);
  const [dataTableColumns] = useState([
    { field: 'id', header: 'Id', sort: true },
    { field: 'razaoSocial', header: 'Razão Social', sort: true },
    { field: 'descricao', header: 'Descricão' },
    { field: 'data', header: 'Fechamento', sort: false },
  ]);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  async function getData(searchFilter, page = 0, size = lazyParams.rows) {
    try {
      setIsLoading(true);

      let params = `/pedido/searchconsultaAdministrador/?idUsuario=${idUsuario}`;

      if (searchFilter && searchFilter.findField !== '') {
        params = `${params}&${sortField}=${searchFilter.findField}`;
      }
      params = `${params}&page=${page}&size=${size}`;

      const response = await axiosCoto.get(params);

      setTotalRecords(response.data.totalElements);
      setDataTableListaPedidos(response.data.content);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const onPage = (event) => {
    setLazyParams(event);

    const searchFilter = { findField, idUsuario };

    getData(searchFilter, event.page, event.rows);
  };

  const handleFind = () => {
    const searchFilter = {
      findField,
      idUsuario,
    };

    // setFindField('');

    getData(searchFilter);
  };

  useEffect(() => {
    getData('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleInformacoesPedido = async (e) => {
    e.preventDefault();

    const id = get(pedidosSelecionados, 'id', 0);

    if (id > 0) {
      try {
        setIsLoading(true);

        const response = await axiosCoto.get(`/pedido/informacaopedidoparacomprador/${id}`);

        setNumProdutos(get(response.data, 'numProdutos', 0));
        setNumRespostas(get(response.data, 'numRespostas', 0));
        setListaVendedores(get(response.data, 'razaoSocialVendedores', []));
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }

      setShowInformacoesPedidoDialog(true);
    } else {
      toast.warning('Selecione um registro para exibir informações.');
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();

    const id = get(pedidosSelecionados, 'id', 0);

    if (id > 0) {
      setShowConfirmDialog(true);
    } else {
      toast.warning('Selecione um registro para exclusão.');
    }
  };

  const cancelDelete = () => {
    setShowConfirmDialog(false);
  };

  const confirmDelete = async () => {
    const idExclusao = get(pedidosSelecionados, 'id', 0);

    if (euConcordo.toUpperCase() !== 'EU CONCORDO') {
      toast.success('Palavra chave não digitada para exclusão.');
      return;
    }

    setShowConfirmDialog(false);

    try {
      setIsLoading(true);

      if (idExclusao > 0) {
        await axiosCoto.delete(`/pedido/${idExclusao}`);

        const _itens = dataTableListaPedidos.filter(val => val.id !== idExclusao);
        setDataTableListaPedidos(_itens);

        toast.success('Registro excluído com sucesso.');
        setEuConcordo('');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  };

  const cancelInformacoesPedido = () => {
    setShowInformacoesPedidoDialog(false);
  };

  const confirmDialogFooter = (
    <div>
      <IconButton titulo="Sim" mensagem="Sim" btnStyle="primary" onClick={confirmDelete} icon="check" />
      <IconButton titulo="Não" mensagem="Não" btnStyle="danger" onClick={cancelDelete} icon="times" />
    </div>
  );

  const informacoesCotacaoDialogFooter = (
    <div>
      <IconButton titulo="Fechar" mensagem="Fechar" btnStyle="primary" onClick={cancelInformacoesPedido} icon="times" />
    </div>
  );

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableListaPedidos(orderBy(dataTableListaPedidos, [sortField], [sortOrder]));
  };

  const renderTablePrimeReact = useMemo(() => (
    <div>
      <div className="card">
        <DataTable
          id="tabelaPedidos"
          value={dataTableListaPedidos}
          size="small"
          stripedRows
          sortField={sortField}
          setOrder={sortOrder}
          onSort={onSort}
          responsiveLayout="scroll"
          selectionMode="single"
          selection={pedidoSelecionado}
          onSelectionChange={e => setPedidosSelecionados(e.value)}
          dataKey="id"
          paginator
          lazy
          first={lazyParams.first}
          rows={lazyParams.rows}
          totalRecords={totalRecords}
          onPage={onPage}
          emptyMessage="Nenhum registro a ser exibido"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="{currentPage} de {totalPages}"
          rowsPerPageOptions={[10, 25, 50]}
        >
          {fillColumns(dataTableColumns)}
        </DataTable>
      </div>
    </div>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [dataTableListaPedidos, pedidosSelecionados]);

  return (
    <>
      <Loading isLoading={isLoading} />
      <Card title="Pedidos">
        <div>
          <div className="row align-items-center">
            <div className="col-8">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Buscar por:
                </Input155px>
                <InputCampoBusca100px className="p-inputgroup-addon">
                  {dataTableColumns.find(element => element.field === sortField).header}
                </InputCampoBusca100px>
                <InputText
                  autoFocus
                  maxLength={200}
                  value={findField}
                  onChange={e => setFindField(e.target.value)}
                />
                &nbsp; &nbsp;
                <IconButton titulo="" mensagem="Buscar" btnStyle="success" onClick={handleFind} icon="search" />
              </div>
            </div>
            <div className="col-4" style={{ textAlign: 'right' }}>
              <IconButton titulo="" mensagem="Informações" btnStyle="dark" onClick={handleInformacoesPedido} icon="eye" />
              <IconButton titulo="" mensagem="Excluir" btnStyle="danger" onClick={handleDelete} icon="trash" />
            </div>
          </div>
        </div>
        <br />

        {renderTablePrimeReact}

        <Dialog
          header="Exclusão da Cotação"
          visible={showConfirmDialog}
          style={{ width: '50vw' }}
          footer={confirmDialogFooter}
          onHide={() => setShowConfirmDialog(false)}
        >
          <p>
            Digite: EU CONCORDO na caixa,
            <InputText
              maxLength={11}
              value={euConcordo}
              onChange={e => setEuConcordo(e.target.value)}
            />
            para a exclusão da cotação.

          </p>
        </Dialog>

        <Dialog
          header="Informações da Cotação"
          visible={showInformacoesPedidoDialog}
          style={{ width: '50vw' }}
          footer={informacoesCotacaoDialogFooter}
          onHide={() => setShowInformacoesPedidoDialog(false)}
        >
          <div className="row">
            <div className="col-3">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Produtos
                </Input100px>
                <InputCampoBusca100px className="p-inputgroup-addon">
                  {numProdutos}
                </InputCampoBusca100px>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Respostas
                </Input100px>
                <InputCampoBusca100px className="p-inputgroup-addon">
                  {numRespostas}
                </InputCampoBusca100px>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="p-inputgroup">
                <Input250px className="p-inputgroup-addon">
                  Vendedores que responderam
                </Input250px>
                <Dropdown
                  emptyMessage="Nenhum vendedor respondeu."
                  options={listaVendedores}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </Card>
    </>
  );
}
