import React, { useState, useEffect } from 'react';

import { Captcha } from 'primereact/captcha';
import { useNavigate } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';

import { useDispatch } from 'react-redux';
import { isEmail } from 'validator';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputText } from 'primereact/inputtext';
import IconButton from '../components/IconButton';
import * as actions from '../store/modules/auth/actions';

import Card from '../components/card';

import { Input155px } from '../styles/inputGroup';
import Loading from './loading';
import axiosCoto from '../services/axiosCoto';
import { SITEKEY } from '../config/constantes';

export default function NovaSenha() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [captchaResponse, setCaptchaResponse] = useState();

  useEffect(() => {
    const newTitle = 'Alteração de Senha';
    dispatch(actions.titleHeaderChange({ newTitle }));
  }, [dispatch]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const captcha = captchaResponse.response;

      const shippingData = {
        email,
        captcha,
      };

      await axiosCoto.post('/usuario/alterarsenhapeloemail', shippingData);

      toast.success('Nova senha enviada para o email informado.');

      navigate('/login');
    } catch (err) {
      const errors = get(err.response.data, 'errors', []);
      if (errors.length > 0) {
        errors.map(error => toast.error(error));
      } else {
        toast.error('Não foi possível alterar a senha.');
      }
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    navigate('/login');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (!captchaResponse) {
      formErros = true;
      toast.error('Mostre que você não é um robô.');
    }

    if (!isEmail(email)) {
      formErros = true;
      toast.error('Email inválido.');
    }
    if (formErros) return;

    salvarRegistro();
  };

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card title="Nova Senha">
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                E-mail
              </Input155px>
              <InputText
                id="inputEmail"
                maxLength={254}
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
          </div>
          <br />
          <div className="col-12">
            <br />
            <Captcha
              siteKey={SITEKEY}
              onResponse={setCaptchaResponse}
              language="pt-br"
            />
          </div>
        </div>
        <div className="col-12" style={{ textAlign: 'right' }}>
          <IconButton titulo="Enviar" mensagem="Enviar" btnStyle="success" onClick={handleSubmit} icon="check" />
          <IconButton titulo="Cancelar" mensagem="Cancelar" btnStyle="danger" onClick={handleCancel} icon="times" />
        </div>
      </Card>
    </div>
  );
}
