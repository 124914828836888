import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { get, orderBy } from 'lodash';
import IconButton from '../../../components/IconButton';

import 'react-toastify/dist/ReactToastify.css';
import { displayErrors } from '../../../util/diversos';

import { fillColumns } from '../../../components/table';
import { Input155px, InputCampoBusca100px } from '../../../styles/inputGroup';

import Loading from '../../loading';
import axiosCoto from '../../../services/axiosCoto';
import Card from '../../../components/card';

export default function ConsultaRamoNegocio() {
  const [isLoading, setIsLoading] = useState(false);
  const [dataTableListaRamoNegocio, setDataTableListaRamoNegocio] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [ramoNegocioSelecionados, setRamoNegocioSelecionados] = useState(null);
  const [campoBusca, setCampoBusca] = useState('');
  const [campoOrdenacao, setCampoOrdenacao] = useState('descricao');
  const [tipoOrdenacao, setTipoOrdenacao] = useState(null);

  const [dataTableColunas] = useState([
    { field: 'descricao', header: 'Descricão', sort: true },
  ]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function getListaRamoNegocios(buscarPor) {
    try {
      setIsLoading(true);

      let params = '/ramonegocio';

      if (buscarPor && buscarPor.campoBusca !== '') {
        params = `${params}?${campoOrdenacao}=${buscarPor.campoBusca}`;
      }

      const response = await axiosCoto.get(params);

      setDataTableListaRamoNegocio(response.data);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const handleBuscarPor = () => {
    const buscarPor = {
      campoBusca,
    };

    setCampoBusca('');
    getListaRamoNegocios(buscarPor);
  };

  useEffect(() => {
    getListaRamoNegocios('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleCadastrar = () => {
    navigate('/cadastroramonegocio');
  };

  const handleEditar = (e) => {
    e.preventDefault();

    const idRamoNegocio = get(ramoNegocioSelecionados, 'id', 0);

    if (idRamoNegocio > 0) {
      navigate(`/cadastroramonegocio/${idRamoNegocio}`);
    } else {
      toast.warning('Por favor, selecione o registro para edição.');
    }
  };

  const handleDeletar = (e) => {
    e.preventDefault();

    const idRamoNegocio = get(ramoNegocioSelecionados, 'id', 0);

    if (idRamoNegocio > 0) {
      setShowConfirmDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para exclusão.');
    }
  };

  const cancelarDelecao = () => {
    setShowConfirmDialog(false);
  };

  const confirmarDelecao = async () => {
    setShowConfirmDialog(false);

    try {
      setIsLoading(true);

      const idExclusao = get(ramoNegocioSelecionados, 'id', 0);

      if (idExclusao > 0) {
        await axiosCoto.delete(`/ramonegocio/${idExclusao}`);

        const _listaRamoNegocios = dataTableListaRamoNegocio.filter(val => val.id !== idExclusao);

        setDataTableListaRamoNegocio(_listaRamoNegocios);
        toast.success('Registro excluído com sucesso.');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  };

  const ordenar = (e) => {
    setCampoOrdenacao(e.sortField);
    setTipoOrdenacao(e.sortOrder);
    setDataTableListaRamoNegocio(orderBy(dataTableListaRamoNegocio, [campoOrdenacao], [tipoOrdenacao]));
  };

  const confirmDialogFooter = (
    <div>
      <IconButton titulo="Sim" mensagem="Sim" btnStyle="primary" onClick={confirmarDelecao} icon="check" />
      <IconButton titulo="Não" mensagem="Não" btnStyle="danger" onClick={cancelarDelecao} icon="times" />
    </div>
  );

  const tabelaRamoNegocio = useMemo(() => (
    <div className="datatable-ramonegocios">
      <DataTable
        value={dataTableListaRamoNegocio}
        size="small"
        stripedRows
        sortField={campoOrdenacao}
        setOrder={tipoOrdenacao}
        onSort={ordenar}
        responsiveLayout="scroll"
        selectionMode="single"
        selection={ramoNegocioSelecionados}
        onSelectionChange={e => setRamoNegocioSelecionados(e.value)}
        selectableRows
        dataKey="id"
        paginator
        rows={10}
        emptyMessage="Nenhum registro a ser exibido"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="{currentPage} de {totalPages}"
        rowsPerPageOptions={[10, 25, 50]}
      >
        {fillColumns(dataTableColunas)}
      </DataTable>
    </div>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [dataTableListaRamoNegocio, ramoNegocioSelecionados]);

  return (
    <>
      <Loading isLoading={isLoading} />
      <Card title="Ramo de Negócios">
        <div>
          <div className="row align-items-center">
            <div className="col-lg-10">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Buscar por:
                </Input155px>
                <InputCampoBusca100px className="p-inputgroup-addon">
                  {dataTableColunas.find(element => element.field === campoOrdenacao).header}
                </InputCampoBusca100px>
                <InputText
                  maxLength={250}
                  value={campoBusca}
                  onChange={e => setCampoBusca(e.target.value)}
                />
                &nbsp; &nbsp;
                <IconButton titulo="" mensagem="Buscar" btnStyle="success" onClick={handleBuscarPor} icon="search" />
              </div>
            </div>
            <div className="col-2" style={{ textAlign: 'right' }}>
              <IconButton titulo="" mensagem="Cadastrar" btnStyle="primary" onClick={handleCadastrar} icon="plus" />
              <IconButton titulo="" mensagem="Editar" btnStyle="warning" onClick={handleEditar} icon="pencil" />
              <IconButton titulo="" mensagem="Excluir" btnStyle="danger" onClick={handleDeletar} icon="trash" />
            </div>
          </div>
        </div>
        <br />

        {tabelaRamoNegocio}

        <Dialog
          header="Confirmação"
          visible={showConfirmDialog}
          style={{ width: '50vw' }}
          footer={confirmDialogFooter}
          onHide={() => setShowConfirmDialog(false)}
        >
          <p>
            Excluir o registro selecionado?
          </p>
        </Dialog>
      </Card>
    </>
  );
}
