/* eslint-disable prefer-template */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { orderBy } from 'lodash';
import { DataTable } from 'primereact/datatable';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { addLocale } from 'primereact/api';
import { displayErrors } from '../../../util/diversos';
import Card from '../../../components/card';

import axiosCoto from '../../../services/axiosCoto';
import IconButton from '../../../components/IconButton';
import { fillColumns } from '../../../components/table';
import { Input100px } from '../../../styles/inputGroup';

import Loading from '../../loading';

export default function RelatorioFinanceiroVendedor() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const idUsuario = useSelector((state) => state.auth.user.id);
  const usuario = useSelector((state) => state.auth.user);

  const [dataTableList, setDataTableList] = useState([]);
  const [selectedItens, setSelectedItens] = useState(null);
  const [sortField, setSortField] = useState('email');
  const [sortOrder, setSortOrder] = useState(null);
  const [dataInicial, setDataInicial] = useState();
  const [dataFinal, setDataFinal] = useState();

  const meses = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
    'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];

  const [dataTableColumn] = useState([
    { field: 'email', header: 'email', sort: true },
    { field: 'dataQuitacao', header: 'Data Quitação', sort: false },
    { field: 'status', header: 'Status', sort: true },
    { field: 'valor', header: 'Valor', sort: true },
    { field: 'dataLicenca', header: 'Data Licenca', sort: false },
  ]);

  function headRows() {
    return [
      { id: '', email: 'Email', dataQuitacao: 'Data Quitação', valor: 'Valor', status: 'Status', dataLicenca: 'Licença aplicada' },
    ];
  }

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  const getData = async () => {
    try {
      setIsLoading(true);

      if (dataInicial == null && dataFinal == null) {
        toast.info('Datas devem ser informadas.');
        return;
      }

      if (dataInicial > dataFinal) {
        toast.info('Datas final anterior a data inicial.');
        return;
      }

      const shippingData = {
        dataInicial,
        dataFinal,
        idUsuario,
      };

      const response = await axiosCoto.post('/financeiro/financeirorelatoriousuario', shippingData);
      setDataTableList(response.data);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  async function imprimir() {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();
    try {
      setIsLoading(true);

      if (dataInicial == null && dataFinal == null) {
        toast.info('Datas devem ser informadas.');
        return;
      }

      if (dataInicial > dataFinal) {
        toast.info('Datas final anterior a data inicial.');
        return;
      }

      const shippingData = {
        dataInicial,
        dataFinal,
        idUsuario,
      };

      const response = await axiosCoto.post('/financeiro/financeirorelatoriousuario', shippingData);

      if (response.data.length <= 0) {
        toast.info('Não há registros a serem exibidos.');
        return;
      }

      setDataTableList(response.data);

      let totalRecebimentos = 0;
      const result = [];
      let data = {
        id: '',
        email: '',
        dataQuitacao: '',
        valor: '0',
        status: '',
        dataLicenca: '',
      };

      for (let x = 0; x < response.data.length; x++) {
        data.id = x + 1;
        data.email = response.data[x].email;
        const dataQuitacao = `${('00' + response.data[x].dataQuitacao[2]).slice(-2)}/${('00' + response.data[x].dataQuitacao[1]).slice(-2)}/${response.data[x].dataQuitacao[0]}`;
        data.dataQuitacao = dataQuitacao;
        data.status = response.data[x].status;
        data.valor = response.data[x].valor;
        if (data.status === 'Aprovado') {
          totalRecebimentos += data.valor;
        }

        const dataLicenca = `${('00' + response.data[x].dataLicenca[2]).slice(-2)}/${('00' + response.data[x].dataLicenca[1]).slice(-2)}/${response.data[x].dataLicenca[0]}`;
        data.dataLicenca = dataLicenca;

        data.valor = `R$ ${response.data[x].valor.toFixed(2).toString()}`;

        result.push({ ...data });
      }

      data = {
        id: '',
        email: '',
        dataQuitacao: '',
        valor: '',
        status: '',
        dataLicenca: '',
      };

      data.dataQuitacao = 'Total';
      data.valor = `R$ ${totalRecebimentos.toFixed(2).replace('.', ',').toString()}`;
      result.push({ ...data });

      const mesInicial = dataInicial.getMonth() + 1;
      const dataInicialImpressao = ('00' + dataInicial.getDate()).slice(-2).toString() + '/' + ('00' + mesInicial).slice(-2).toString() + '/' + dataInicial.getFullYear().toString();

      const mesFinal = dataFinal.getMonth() + 1;
      const dataFinalImpressao = ('00' + dataFinal.getDate()).slice(-2).toString() + '/' + ('00' + mesFinal).slice(-2).toString() + '/' + dataFinal.getFullYear().toString();

      doc.autoTable(
        {
          styles: { fontSize: 8 },
          margin: { top: 38, left: 5, right: 5 },
          theme: 'grid',
          head: headRows(),
          body: result,
          didDrawPage: (dados) => {
            // Header
            doc.rect(5, 5, 200, 30); //  header
            doc.addImage('logo.png', 'JPEG', 10, 12, 25, 15);
            doc.setFontSize(20);
            doc.text('Relatório Financeiro', 75, 15);

            doc.setFontSize(10);

            doc.text('Período:', 45, 32);
            doc.text(dataInicialImpressao, 65, 32);
            doc.text('à', 85, 32);
            doc.text(dataFinalImpressao, 89, 32);

            const { pageSize } = doc.internal;
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text(`Pag ${doc.internal.getNumberOfPages()}`, dados.settings.margin.right, pageHeight - 8);
            doc.text('Elo Informática Barbacena', 82, pageHeight - 8);
            const periodo = new Date();
            const mes = periodo.getMonth() + 1;
            doc.text('Impresso: ', 172, pageHeight - 8);
            doc.text((`${('00' + periodo.getDate()).slice(-2)}/${('00' + mes).slice(-2)}/${periodo.getFullYear()}`).toString(), 189, pageHeight - 8);
          },
        },
      ).setFontSize(5);
      window.open(doc.output('bloburl'));
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
  }, [dispatch, idUsuario]);

  const handleFind = () => {
    imprimir();
  };

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  const handleImprimirComprovante = async (e) => {
    try {
      setIsLoading(true);

      const usuarioNome = usuario.razaoSocial.toUpperCase().trim();
      const usuarioEmail = usuario.email.trim();
      const usuarioCidade = usuario.cidade;
      const dataQuitacao = ('00' + e.dataQuitacao[2]).slice(-2) + '/' + ('00' + e.dataQuitacao[1]).slice(-2) + '/' + e.dataQuitacao[0];
      const dataLicenca = ('00' + e.dataLicenca[2]).slice(-2) + '/' + ('00' + e.dataLicenca[1]).slice(-2) + '/' + e.dataLicenca[0];
      const valorMensalidade = e.valor.toFixed(2).toString();
      const mesExtenso = meses[e.dataQuitacao[2]];
      const referencia = 'Locação do Sistema EloCotacao.com.br';
      const dataImpressao = ('00' + new Date().getDate()).slice(-2) + '/' + ('00' + new Date().getMonth()).slice(-2) + '/' + new Date().getFullYear();

      // eslint-disable-next-line new-cap
      const doc = new jsPDF();

      doc.autoTable({
        styles: { fontSize: 10 },
        margin: { bottom: 58, top: 38, left: 5, right: 5 },
        theme: 'grid',
        head: '',
        body: [],
        didDrawPage: () => {
          // Header
          doc.rect(5, 5, 200, 28);
          doc.addImage('logo.png', 'JPEG', 10, 12, 25, 15);
          doc.setFontSize(20);
          doc.text('COMPROVANTE DE PAGAMENTO', 57, 22);

          // body
          doc.rect(5, 30, 200, 58);
          doc.setFontSize(12);
          doc.text('Recebemos de ', 10, 43);
          doc.setFontSize(15);
          doc.text(usuarioNome, 41, 43);

          // valor
          doc.setFontSize(12);
          doc.text('A quantia de (', 10, 50);
          doc.setFontSize(15);
          doc.text('R$ ' + valorMensalidade + ' ) ', 38, 50);
          doc.setFontSize(15);
          doc.text('Dezenove Reais e Noventa Centavos', 65, 50);

          // referente
          doc.setFontSize(12);
          doc.text('Referente à ', 10, 57);
          doc.setFontSize(15);
          doc.text(referencia, 36, 57);

          // Pago em
          doc.setFontSize(12);
          doc.text('Data Quitação ', 10, 67);
          doc.setFontSize(15);
          doc.text(dataQuitacao, 40, 67);

          // Pago em
          doc.setFontSize(12);
          doc.text('Licença renovada até ', 129, 67);
          doc.setFontSize(15);
          doc.text(dataLicenca, 173, 67);

          // Cidade
          doc.setFontSize(14);
          doc.text(usuarioCidade + ', ', 65, 80);
          // data
          doc.text(('00' + e.dataQuitacao[1]).slice(-2), 92, 80);
          doc.text('de ', 100, 80);
          doc.text(mesExtenso, 109, 80);
          doc.text('de ', 132, 80);
          doc.text(e.dataQuitacao[0].toString(), 140, 80);

          doc.setFontSize(8);
          doc.text('impressão', 170, 85);
          doc.text(dataImpressao, 185, 85);
        },
      });
      window.open(doc.output('bloburl'));
    } catch (err) {
      // console.log(err);
      displayErrors(err, dispatch, navigate, '', 'Não foi possível imprimir o comprovante');
    } finally {
      setIsLoading(false);
    }
  };

  const actionBodyTemplateComprovante = (rowData) => (
    <div className="row col-12">
      <div className="col-lg-4">
        <button
          type="button"
          title="Imprimir Comprovante"
          className="btn-success rounded outlined"
          style={{ borderRadius: '15px' }}
          onClick={() => handleImprimirComprovante(rowData)}
        >
          <i className="pi pi-print"></i>
        </button>
      </div>
    </div>
  );

  return (
    <>
      <Loading isLoading={isLoading} />
      <Card title="Relatório Financeiro">
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Data Inicial
              </Input100px>
              <Calendar
                locale="pt-BR"
                dateFormat="dd/mm/yy"
                id="dataInicial"
                value={dataInicial}
                onChange={e => setDataInicial(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Data Final
              </Input100px>
              <Calendar
                locale="pt-BR"
                dateFormat="dd/mm/yy"
                id="dataFinal"
                value={dataFinal}
                onChange={e => setDataFinal(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <IconButton titulo="" title="Gerar Relatório" btnStyle="success" onClick={getData} icon="search" />
            </div>
          </div>
          <div className="col-2" style={{ textAlign: 'right' }}>
            <IconButton titulo="imprimir" title="Gerar Relatório" btnStyle="primary" onClick={handleFind} icon="print" />
          </div>
        </div>
        <div className="datatable-financeiro">
          <div className="card">
            <DataTable
              value={dataTableList}
              size="small"
              stripedRows
              sortField={sortField}
              setOrder={sortOrder}
              onSort={onSort}
              responsiveLayout="scroll"
              selectionMode="single"
              selection={selectedItens}
              onSelectionChange={e => setSelectedItens(e.value)}
              selectableRows
              dataKey="id"
              paginator
              rows={10}
              emptyMessage="Nenhum registro a ser exibido"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{currentPage} de {totalPages}"
              rowsPerPageOptions={[10, 25, 50]}
            >
              {fillColumns(dataTableColumn)}
              <Column body={actionBodyTemplateComprovante} exportable={false} style={{ width: '50px' }}></Column>
            </DataTable>
          </div>
        </div>

      </Card>
    </>
  );
}
