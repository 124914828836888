import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import Loading from '../loading';

import IconButton from '../../components/IconButton';
import Card from '../../components/card';
import { displayErrors } from '../../util/diversos';

import store from '../../store/store';
import axiosCoto from '../../services/axiosCoto';

export default function HomeComprador() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const idComprador = useSelector((state) => state.auth.user.id);

  const [numVendedores, setNumVendedores] = useState(0);
  const [numProdutos, setNumProdutos] = useState(0);
  const [numVinculosPendentes, setNumVinculosPendentes] = useState(0);
  const { dataLicenca } = store.getState().auth.user;
  // eslint-disable-next-line prefer-template
  const dataLicencaComBarras = ('00' + dataLicenca[2]).slice(-2) + '/' + ('00' + dataLicenca[1]).slice(-2) + '/' + dataLicenca[0];

  async function getNumVendedores() {
    try {
      setIsLoading(true);
      const params = `/vinculocompradorvendedor/numerovendedoresvinculados/${idComprador}`;
      await axiosCoto.get(params).then((res) => {
        setNumVendedores(get(res, 'data', 0));
      });
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  async function getNumVinculosPendentes() {
    try {
      setIsLoading(true);
      const params = `/vinculocompradorvendedor/numerovinculospendentescomprador/${idComprador}`;
      await axiosCoto.get(params).then((res) => {
        setNumVinculosPendentes(get(res, 'data', 0));
      });
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  async function getNumProdutos() {
    try {
      setIsLoading(true);
      const params = `/produto/numeroProdutos/${idComprador}`;
      await axiosCoto.get(params).then((res) => {
        setNumProdutos(get(res, 'data', 0));
      });
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getNumVendedores();
    getNumProdutos();
    getNumVinculosPendentes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleVendedores = () => {
    navigate('/consultavendedor');
  };

  const handleFinanceiro = () => {
    navigate('/financeirocomprador');
  };

  const handleClassificar = () => {
    navigate('/consultavendedor');
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <div className="row">
        <div className="col-6 counter-item">
          <Card title="Vendedores">
            <span className="number" data-from="0" data-to="32" data-refresh-interval="100">
              {numVendedores}
            </span>
          </Card>
        </div>
        <div className="col-6 counter-item">
          <Card title="Validade da licença">
            <p style={{ fontSize: '24px' }}>
              {dataLicencaComBarras}
              <br />
              <IconButton titulo="ir para pagamento" mensagem="ir para pagamento" btnStyle="primary" onClick={handleFinanceiro} />
            </p>
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-6 counter-item">
          <Card title="Produtos">
            <span className="number" data-from="0" data-to="32" data-refresh-interval="100">
              {numProdutos}
            </span>
          </Card>
        </div>
        <div className="col-6 counter-item">
          <Card title="Convites Pendentes">
            <span className="number" data-from="0" data-to="32" data-refresh-interval="100">
              {numVinculosPendentes}
              {(numVinculosPendentes > 0)
                ? (
                  <>
                    &nbsp; &nbsp;
                    <IconButton titulo="Visualizar" mensagem="Visualizar convites" btnStyle="primary" onClick={handleVendedores} icon="plus" />
                  </>
                ) : ('')}
            </span>
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-12 counter-item">
          <Card title="Classificar vendedores">
            <span style={{ fontSize: '14px' }} className="number" data-from="0" data-to="32" data-refresh-interval="100">
              Após a confirmação do convite pelo vendedor, a classificação estará disponível, sendo:
              <br />
              Atendimento:
              <br />
              a forma como o vendedor atua no processo da cotação seja pelo site ou contato direto.
              <br />
              <br />
              Entrega:
              <br />
              Se as entregas respeitam os prazos preenchidos pelo vendedor.
              <br />
              <br />
              Produto:
              <br />
              Se os produtos enviados pelo vendedor respeitam as marcas cotadas.
              <br />
              <br />
              A classificação será realizada no menu vendedores, na aba Convidados/convites
              <br />
              <IconButton titulo="Classificar agora" mensagem="ir classificação de vendedores" btnStyle="primary" onClick={handleClassificar} />
            </span>
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-12 counter-item">
          <Card title="Atenção compradores para alteração na criação da cotação">
            <span style={{ fontSize: '14px' }} className="number" data-from="0" data-to="32" data-refresh-interval="100">
              Primeiro criar a cotação:
              <br />
              Prencher os campos da criação da cotação e salvar.
              <br />
              <br />
              Depois selecionar a cotação salva e editar para inserção dos produtos.
              <br />
              <br />
              Essa alteração foi feita para previnir perdas de produtos durante a criação da cotação e inserção de produtos, seja por queda de energia ou falha na internet.
              <br />
              O produto será salvo no momento que é inserido na cotação.
            </span>
          </Card>
        </div>

      </div>
    </>
  );
}
