import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { DataTable } from 'primereact/datatable';
import { get, orderBy } from 'lodash';

import 'react-toastify/dist/ReactToastify.css';
import { Dialog } from 'primereact/dialog';
import { displayErrors } from '../../../util/diversos';

import { fillColumns } from '../../../components/table';
import IconButton from '../../../components/IconButton';

import Card from '../../../components/card';
import Loading from '../../loading';
import axiosCoto from '../../../services/axiosCoto';

export default function ConsultaProdutoPedidos() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const idProduto = get(useParams(), 'id', 0);

  const [totalRecords, setTotalRecords] = useState(0);
  const [dataTableListaProdutos, setDataTableListaProdutos] = useState([]);
  const [selectedItens, setSelectedItens] = useState(null);
  const [sortField, setSortField] = useState('razaoSocial');
  const [sortOrder, setSortOrder] = useState(null);

  const [dataTableColumn] = useState([
    { field: 'idPedido', header: 'Pedido Id', sort: false },
    { field: 'razaoSocial', header: 'Razão Social', sort: true },
    { field: 'qtdePedido', header: 'Qtde Pedido', sort: false },
    { field: 'valorCaixa', header: 'Valor Caixa', sort: false },
    { field: 'qtdePorCaixa', header: 'Qtde Por CX', sort: false },
    { field: 'valorUnitario', header: 'Valor UN', sort: false },
    { field: 'qtdeAtacado', header: 'Qtde Atacado', sort: false },
    { field: 'valorAtacado', header: 'Valor Atacado', sort: false },
  ]);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  // const idUsuario = useSelector((state) => state.auth.user.id);

  async function getData(page = 0, size = lazyParams.rows) {
    try {
      setIsLoading(true);

      let params = `/pedidoprodutoresposta/search/?idProduto=${idProduto}`;

      params = `${params}&page=${page}&size=${size}`;

      let response = '';
      await axiosCoto.get(params).then((res) => {
        response = res.data.content;
        setTotalRecords(res.data.totalElements);
      }).catch(() => {
        toast.warning('Erro na conexão. Atualize a página ou faça o login novamente.');
      });

      setDataTableListaProdutos(response);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const onPage = (event) => {
    setLazyParams(event);

    getData(event.page, event.rows);
  };

  useEffect(() => {
    getData('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleCancel = () => {
    navigate('/consultaproduto');
  };

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableListaProdutos(orderBy(dataTableListaProdutos, [sortField], [sortOrder]));
  };

  const renderTablePrimeReact = useMemo(() => (
    <div className="datatable-produtos">
      <div className="card">
        <DataTable
          value={dataTableListaProdutos}
          size="small"
          stripedRows
          sortField={sortField}
          setOrder={sortOrder}
          onSort={onSort}
          responsiveLayout="scroll"
          selectionMode="single"
          selection={selectedItens}
          onSelectionChange={e => setSelectedItens(e.value)}
          selectableRows
          dataKey="id"
          paginator
          lazy
          first={lazyParams.first}
          rows={lazyParams.rows}
          totalRecords={totalRecords}
          onPage={onPage}
          emptyMessage="Nenhum registro a ser exibido"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="{currentPage} de {totalPages}"
          rowsPerPageOptions={[10, 25, 50]}
        >
          {fillColumns(dataTableColumn)}
        </DataTable>
      </div>
    </div>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [dataTableListaProdutos, selectedItens]);

  return (
    <Dialog header="" visible closable={false} style={{ width: '80vw', height: '70%' }}>
      <Loading isLoading={isLoading} />
      <Card title="Consulta Pedidos do Produto">
        {renderTablePrimeReact}
        <div className="col-12" style={{ textAlign: 'right' }}>
          <IconButton titulo="Voltar" mensagem="Voltar" btnStyle="success" onClick={handleCancel} icon="chevron-left" />
        </div>
      </Card>
    </Dialog>
  );
}
