import React, { useState } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { Calendar } from 'primereact/calendar';
import Card from '../../../components/card';

import Loading from '../../loading';
import { Input155px } from '../../../styles/inputGroup';
import axiosCoto from '../../../services/axiosCoto';
import IconButton from '../../../components/IconButton';

export default function GerarFinanceiro() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [mesReferencia, setMesReferencia] = useState();

  async function gerarFinanceiro() {
    try {
      setIsLoading(true);

      const shippingData = {
        mesReferencia,
      };

      await axiosCoto.post('/financeiro/gerarfinanceiro', shippingData);
      toast.success('Finança gerada com sucesso.');

      navigate('/consultafinanceiro');
    } catch (err) {
      const errors = get(err.response.data, 'errors', []);
      if (errors.length > 0) {
        errors.map(error => toast.error(error));
      } else {
        toast.error('Finança já gerada para o mês referênacia.');
      }
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    navigate('/consultafinanceiro');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (mesReferencia == null) {
      formErros = true;
      toast.error('Mês de Referência deve ser informado.');
    }

    if (formErros) return;

    gerarFinanceiro();
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <Card>
        <div>
          <div className="row align-items-center">
            <div className="col-12 text-center">
              <h4> Geração de Finanças </h4>
            </div>
          </div>
        </div>
      </Card>
      <div className="row form-elo">
        <div className="col-3">
          <div className="p-inputgroup">
            <Input155px className="p-inputgroup-addon">
              Mês Referência *
            </Input155px>
            <Calendar
              dateFormat="mm/yy"
              id="mesReferencia"
              value={mesReferencia}
              onChange={e => setMesReferencia(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="col-12" style={{ textAlign: 'right' }}>
        <IconButton titulo="Gerar" mensagem="Gerar Financeiro" btnStyle="success" onClick={handleSubmit} icon="check" />
        <IconButton titulo="Cancelar" mensagem="Cancelar" btnStyle="danger" onClick={handleCancel} icon="times" />
      </div>
    </>
  );
}
