/* eslint-disable prefer-template */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { Chart } from 'primereact/chart';
import { Dropdown } from 'primereact/dropdown';
import store from '../../store/store';

import Card from '../../components/card';
import IconButton from '../../components/IconButton';
import Loading from '../loading';
import { displayErrors } from '../../util/diversos';
import { obterLista } from '../../services/statusPeriodo';
import { Input100px, Input155px } from '../../styles/inputGroup';

import axiosCoto from '../../services/axiosCoto';

export default function HomeVendedor() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const listaPeriodo = obterLista();

  const [periodo, setPeriodo] = useState('SEMANA');
  const [dataInicial, setDataInicial] = useState(new Date(new Date().setDate(new Date().getDate() - 7)));
  const [dataFinal, setDataFinal] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
  const [desativarInputDatas, setDesativarInputDatas] = useState(true);
  const [numPedidos, setNumPedidos] = useState();
  const [valorTotal, setValorTotal] = useState(0);

  const [numCompradores, setNumCompradores] = useState(0);
  const [numVinculosPendentes, setNumVinculosPendentes] = useState(0);
  const [numCotacoesAbertas, setNumCotacoesAbertas] = useState(0);

  const idUsuario = useSelector((state) => state.auth.user.id);
  const idVendedor = useSelector((state) => state.auth.user.id);
  const { dataLicenca } = store.getState().auth.user;
  const dataLicencaComBarras = ('00' + dataLicenca[2]).slice(-2) + '/' + ('00' + dataLicenca[1]).slice(-2) + '/' + dataLicenca[0];

  const [chartData, setChartData] = useState({});

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  function desativarInputs() {
    setDesativarInputDatas(true);
  }

  function ativarInputs() {
    setDesativarInputDatas(false);
  }

  async function setDataPeriodo() {
    switch (periodo) {
      case 'HOJE':
        desativarInputs();
        setDataInicial(new Date());
        setDataFinal(new Date());
        break;
      case 'ONTEM':
        desativarInputs();
        setDataInicial(new Date(new Date().setDate(new Date().getDate() - 1)));
        setDataFinal(new Date(new Date().setDate(new Date().getDate() - 1)));
        break;
      case 'SEMANA':
        desativarInputs();
        setDataInicial(new Date(new Date().setDate(new Date().getDate() - 7)));
        setDataFinal(new Date(new Date().setHours(0, 0, 0, 0)));
        break;
      case 'MES':
        desativarInputs();
        setDataInicial(new Date(new Date().setMonth(new Date().getMonth() - 1)));
        setDataFinal(new Date(new Date().setHours(0, 0, 0, 0)));
        break;
      case 'SEMESTRE':
        desativarInputs();
        setDataInicial(new Date(new Date().setMonth(new Date().getMonth() - 6)));
        setDataFinal(new Date(new Date().setHours(0, 0, 0, 0)));
        break;
      case 'ANO':
        desativarInputs();
        setDataInicial(new Date(new Date().setMonth(new Date().getMonth() - 12)));
        setDataFinal(new Date(new Date().setHours(0, 0, 0, 0)));
        break;
      default:
        ativarInputs();
        break;
    }
  }

  const basicOptions = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        labels: {
          color: '#495057',
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#495057',
        },
        grid: {
          color: '#ebedef',
        },
      },
      y: {
        ticks: {
          color: '#495057',
        },
        grid: {
          color: '#ebedef',
        },
      },
    },
  };

  async function getData() {
    try {
      setIsLoading(true);
      // mostrar numero de pedidos que o vendedor participou e ganhou
      const shippingData = {
        dataInicial,
        dataFinal,
        idVendedor: idUsuario,
      };

      const labels = [];
      const data = [];
      await axiosCoto.post('/pedido/dadospedidosperiodovendedor', shippingData).then(response => {
        setNumPedidos(response.data.numeroPedidos);
        if (response.data.numeroPedidos < 1) {
          setValorTotal('0,00');
          toast.info('Não há informações pra esse período.');
          return;
        }
        const valor = parseFloat(response.data.valorTotal).toFixed(2);
        setValorTotal(valor);

        // varrer o array
        for (let x = 0; x < response.data.valorCompradores.length; x++) {
          labels.push(response.data.valorCompradores[x][2]);
          data.push(response.data.valorCompradores[x][0]);
        }
      });

      setChartData({
        labels,
        datasets: [{
          type: 'bar',
          label: 'Total',
          borderColor: '#42A5F5',
          borderWidth: 2,
          fill: false,
          tension: 0.4,
          data,
        }],
      });
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  async function getNumeroCompradores() {
    try {
      setIsLoading(true);

      const params = `/vinculocompradorvendedor/numerocompradoresvinculados/${idVendedor}`;

      let response = '';
      await axiosCoto.get(params)
        .then((res) => {
          response = res.data;
        });

      setNumCompradores(response);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  async function getNumeroVinculosPendentes() {
    try {
      setIsLoading(true);

      const params = `/vinculocompradorvendedor/numerovinculospendentesparavendedor/${idVendedor}`;

      let response = '';
      await axiosCoto.get(params)
        .then((res) => {
          response = res.data;
        });

      setNumVinculosPendentes(response);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  async function getNumeroCotacoesAberta() {
    try {
      setIsLoading(true);

      const params = `/cotacao/paraconsultavendedor/?idVendedor=${idVendedor}`;

      const listaCotacoes = [];
      await axiosCoto.get(params)
        .then((res) => {
          for (let x = 0; x < res.data.length; x++) {
            if (res.data[x].cotacaoVendedores.length > 0) {
              if (res.data[x].cotacaoVendedores.some(cotacaoVendedores => cotacaoVendedores.idVendedor === idVendedor)) {
                listaCotacoes.push(res.data[x]);
              }
            } else {
              listaCotacoes.push(res.data[x]);
            }
          }
        });

      setNumCotacoesAbertas(listaCotacoes.length);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getNumeroCompradores();
    getNumeroVinculosPendentes();
    getNumeroCotacoesAberta();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFinal]);

  const handleFind = () => {
    if (dataInicial === '' || dataInicial === undefined) {
      toast.info('Data inicial em branco');
      return;
    }
    if (dataFinal === '' || dataFinal === undefined) {
      toast.info('Data final em branco');
      return;
    }
    if (dataInicial > dataFinal) {
      toast.info('Período inválido');
      return;
    }
    getData();
  };

  useEffect(() => {
    setDataPeriodo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodo]);

  const handleCompradores = () => {
    navigate('/consultacomprador');
  };

  const handleFinanceiro = () => {
    navigate('/financeirovendedor');
  };

  let col = 'col-5';
  if (periodo !== 'PERSONALIZADO') { col = 'col-12'; }

  return (
    <>
      <Loading isLoading={isLoading} />
      <div className="row">
        <div className="col-6 counter-item">
          <Card title="Cotações Abertas">
            <span className="number" data-from="0" data-to="32" title="Se disponível a todos os vendedores.">
              {numCotacoesAbertas}
            </span>
          </Card>
        </div>
        <div className="col-6 counter-item">
          <Card title="Validade da licença">
            <p style={{ fontSize: '24px' }}>
              {dataLicencaComBarras}
              <br />
              <IconButton titulo="ir para pagamento" mensagem="ir para pagamento" btnStyle="primary" onClick={handleFinanceiro} icon="plus" />
            </p>
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-6 counter-item">
          <Card title="Compradores">
            <span className="number" data-from="0" data-to="32">
              {numCompradores}
            </span>
          </Card>
        </div>
        <div className="col-6 counter-item">
          <Card title="Convites Pendentes">
            <span className="number" data-from="0" data-to="32">
              {numVinculosPendentes}
              {(numVinculosPendentes > 0) ? (
                <>
                  &nbsp; &nbsp;
                  <IconButton titulo="Visualizar" mensagem="Visualizar convites" btnStyle="primary" onClick={handleCompradores} icon="plus" />
                </>
              ) : ('')}
            </span>
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-12 counter-item">
          <Card title="Atenção nas alterações no preenchimento da cotação">
            <div className="row">
              <div className="col-4">
                Forma padrão:
                <br />
                Prencher o Valor da Caixa e Quantidade por caixa, Valor Unitário será preenchido automaticamente.
              </div>
              <div className="col-4">
                Forma 2:
                <br />
                Preencher a Quantidade por Caixa e o Valor Unitário, o Valor da Caixa será preenchido automaticamente.
              </div>
              <div className="col-4">
                Forma 3:
                <br />
                Preencher somente o Valor Unitário, o Valor da Caixa será igual Valor Unitário e Quantidade por Caixa será 1.
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <br />
                Atenção para precedência:
                <br />
                A Forma Padrão tem precedência sobre as outras formas, então se Valor de Caixa estiver preenchido e mudar a Quantidade por Caixa, o Valor Unitário será recalculado.
              </div>
            </div>
          </Card>
        </div>
      </div>
      <div className="row">
        <div className="col-12 counter-item">
          <Card title={`Total por Comprador - Pedidos: ${numPedidos} - Período: ${periodo.toLowerCase()} - Total : R$ ${valorTotal}`}>
            <div className="row form-elo">
              <div className={col}>
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Período
                  </Input155px>
                  <Dropdown
                    options={listaPeriodo}
                    value={periodo}
                    onChange={e => setPeriodo(e.target.value)}
                  />
                </div>
              </div>
              { periodo === 'PERSONALIZADO' ? (
                <>
                  <div className="col-3">
                    <div className="p-inputgroup">
                      <Input100px className="p-inputgroup-addon">
                        Data Inicial
                      </Input100px>
                      <Calendar
                        locale="pt-BR"
                        disabled={desativarInputDatas}
                        dateFormat="dd/mm/yy"
                        id="dataInicial"
                        value={dataInicial}
                        onChange={e => setDataInicial(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="p-inputgroup">
                      <Input100px className="p-inputgroup-addon">
                        Data Final
                      </Input100px>
                      <Calendar
                        locale="pt-BR"
                        disabled={desativarInputDatas}
                        dateFormat="dd/mm/yy"
                        id="dataFinal"
                        value={dataFinal}
                        onChange={e => setDataFinal(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-1" style={{ textAlign: 'right' }}>
                    <IconButton titulo="" mensagem="Gerar Informações" btnStyle="dark" onClick={handleFind} icon="eye" />
                  </div>
                </>
              ) : ('')}
            </div>
            { (numPedidos > 0) ? (
              <div className="row form-elo">
                <div className="card">
                  <Chart type="bar" data={chartData} options={basicOptions} />
                </div>
              </div>
            ) : ('') }
          </Card>
        </div>
      </div>

    </>
  );
}
