export function obterListaRejeicaoVendedor() {
  return (
    [
      { label: 'Selecione...', value: '' },
      { label: 'Naõ atendo sua cidade', value: 'Não atende a esta cidade.' },
      { label: 'Não atendo a essa área da cidade', value: 'Não atende essa área da cidade.' },
    ]
  );
}

export function obterListaRejeicaoComprador() {
  return (
    [
      { label: 'Selecione...', value: '' },
      { label: 'Bloquear vendedor sem que ele saiba', value: 'Bloquear' },
      { label: 'Desentendimento Comercial', value: 'Desentendimento Comercial.' },
      { label: 'Não compra de fora do estado', value: 'Não compra de fora do estado.' },
      { label: 'Negociações pendentes', value: 'Negociações pendentes.' },
      { label: 'Prazo entrega insatisfatório', value: 'Prazo entrega insatisfatório.' },
    ]
  );
}
