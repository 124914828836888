import React, { useState, useEffect, useMemo } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';

import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { TreeSelect } from 'primereact/treeselect';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { AutoComplete } from 'primereact/autocomplete';
import { ToggleButton } from 'primereact/togglebutton';
import Card from '../../../components/card';
import { displayErrors } from '../../../util/diversos';

import { Input100px, Input155px, Input200px } from '../../../styles/inputGroup';

import Loading from '../../loading';
import axiosCoto from '../../../services/axiosCoto';
import IconButton from '../../../components/IconButton';

import { obterListaStatus } from '../../../services/statusService';

export default function CadastroCotacao() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const idUsuario = useSelector((state) => state.auth.user.id);
  const razaoSocial = useSelector((state) => state.auth.user.razaoSocial);
  const id = get(useParams(), 'id', 0);

  const [descricaoCotacao, setDescricaoCotacao] = useState('');
  const [dataAbertura, setDataAbertura] = useState(new Date());
  const [dataFechamento, setDataFechamento] = useState('');
  const [observacao, setObservacao] = useState('');
  const [status, setStatus] = useState('EDICAO');
  const [padrao, setPadrao] = useState(false);
  const [emailEnviado, setEmailEnviado] = useState('False');

  const [listaCotacoesPadrao, setListaCotacoesPadrao] = useState([]);
  const [cotacaoPadrao, setCotacaoPadrao] = useState();

  const [descricaoProduto, setDescricaoProduto] = useState('');
  const [descricaoProdutoUltimaBusca, setDescricaoProdutoUltimBusca] = useState('');

  const [dataTableProdutos, setDataTableProdutos] = useState([]);
  const [filteredProdutos, setFilteredProdutos] = useState(null);
  const [produto, setProduto] = useState();
  const [produtos, setProdutos] = useState([]);
  const [listaProdutos, setListaProdutos] = useState();
  const [selectedItens, setSelectedItens] = useState(null);

  const [selectedVendedores, setSelectedVendedores] = useState();
  const [listaVendedores, setListaVendedores] = useState();

  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showConfirmCadastroProdutoDialog, setShowConfirmCadastroProdutoDialog] = useState(false);
  const [showColetarProdutosCotacaoPadraoDialog, setShowColetarProdutosCotacaoPadraoDialog] = useState(false);

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  async function getProdutos() {
    const listDropdown = [];
    try {
      setIsLoading(true);
      const { data: _produtos } = await axiosCoto.get(`/produto/?idUsuario=${idUsuario}`);
      _produtos.forEach(element => {
        listDropdown.push({ label: element.descricao, idProduto: element.idProduto, value: element.id });
      });

      setListaProdutos(listDropdown);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  async function getVendedores() {
    const listDropdown = [];
    try {
      setIsLoading(true);
      const { data: _vendedores } = await axiosCoto.get(`/vinculocompradorvendedor/?idComprador=${idUsuario}`);
      const paramsAvaliacaoTodos = '/avaliacaovendedor/todos';

      let avaliacoesTodos = '';
      await axiosCoto.get(paramsAvaliacaoTodos).then((res) => {
        avaliacoesTodos = res.data;
      });

      _vendedores.vendedores.forEach(element => {
        let media = 0;
        let contador = 0;
        for (let z = 0; z < avaliacoesTodos.length; z++) {
          if (avaliacoesTodos[z].idVendedor === element.id) {
            media += avaliacoesTodos[z].classificacaoMedia;
            contador++;
          }
        }
        let vendedor = element.email;

        if (contador > 0) {
          vendedor += ` - Avaliacao: ${(media / contador)} (${contador})`;
        }

        listDropdown.push({ key: element.id, label: vendedor });
      });

      setListaVendedores(listDropdown);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  async function getCotacoesPadrao() {
    const listDropdownCotacoesPadrao = [];
    try {
      setIsLoading(true);
      const { data: _cotacoesPadrao } = await axiosCoto.get(`/cotacao/?idUsuario=${idUsuario}`);

      _cotacoesPadrao.forEach(element => {
        if (element.padrao) {
          const labelExibicaoCotacapPadrao = `${element.descricao}`;
          listDropdownCotacoesPadrao.push({ label: labelExibicaoCotacapPadrao, value: element.id });
        }
      });

      setListaCotacoesPadrao(listDropdownCotacoesPadrao);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  async function getData() {
    try {
      setIsLoading(true);

      const { data } = await axiosCoto.get(`/cotacao/${id}`);

      setDescricaoCotacao(data.descricao.trim());
      setDataAbertura(new Date(data.dataAbertura[0], data.dataAbertura[1] - 1, data.dataAbertura[2]));
      setDataFechamento(new Date(data.dataFechamento[0], data.dataFechamento[1] - 1, data.dataFechamento[2]));
      setObservacao(data.observacao.trim());
      setStatus(data.status);
      setEmailEnviado(data.emailEnviado);
      setPadrao(data.padrao);

      let dadosSetKey = {};
      data.cotacaoVendedores.forEach(element => {
        dadosSetKey = { ...dadosSetKey, [element.idVendedor]: { checked: true, partialChecked: true } };
      });

      setSelectedVendedores(dadosSetKey);

      const cotacaoProdutos = [];
      data.cotacaoProdutos.forEach(element => {
        cotacaoProdutos.push({ label: element.descricaoProduto.trim(), value: element.idProduto, id: element.id });
      });

      setDataTableProdutos(cotacaoProdutos);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!id) return;

    getProdutos();
    getVendedores();
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, navigate, idUsuario, dispatch]);

  async function atualizarCotacao() {
    try {
      setIsLoading(true);

      const cotacaoProdutos = [];
      dataTableProdutos.forEach(element => {
        cotacaoProdutos.push({ idProduto: element.value, descricaoProduto: element.label.trim(), id: element.id });
      });

      const listaCotacaoVendedores = [];
      if (selectedVendedores !== null && selectedVendedores !== undefined) {
        Object.keys(selectedVendedores).forEach((item) => {
          listaCotacaoVendedores.push(+item);
        });
      }

      const shippingData = {
        descricao: descricaoCotacao,
        dataAbertura,
        dataFechamento,
        observacao,
        idUsuario,
        status,
        cotacaoProdutos,
        razaoSocial,
        emailEnviado,
        padrao,
        listaCotacaoVendedores,
      };

      if (id > 0) {
        await axiosCoto.put(`/cotacao/${id}`, shippingData);
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  async function deleteProduto(idCotacaoProduto) {
    try {
      setIsLoading(true);

      if (idCotacaoProduto > 0) {
        await axiosCoto.delete(`/cotacaoproduto/${idCotacaoProduto}`);
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  async function salvarCotacao() {
    try {
      setIsLoading(true);

      const cotacaoProdutos = [];
      dataTableProdutos.forEach(element => {
        cotacaoProdutos.push({ idProduto: element.value, descricaoProduto: element.label.trim(), id: element.id });
      });

      const listaCotacaoVendedores = [];
      if (selectedVendedores !== null && selectedVendedores !== undefined) {
        Object.keys(selectedVendedores).forEach((item) => {
          listaCotacaoVendedores.push(+item);
        });
      }

      const shippingData = {
        descricao: descricaoCotacao,
        dataAbertura,
        dataFechamento,
        observacao,
        idUsuario,
        status,
        cotacaoProdutos,
        razaoSocial,
        emailEnviado,
        padrao,
        listaCotacaoVendedores,
      };

      if (id > 0) {
        await axiosCoto.put(`/cotacao/${id}`, shippingData);
        toast.success('Cotação atualizada com sucesso');
      } else {
        await axiosCoto.post('/cotacao', shippingData);
        toast.success('Cotacao cadastrada com sucesso');
      }

      navigate('/consultacotacao');
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const searchProdutos = (event) => {
    setDescricaoProdutoUltimBusca('');
    setDescricaoProduto(event.query);

    const listDropdown = [];
    listaProdutos.forEach(element => {
      if (element.label.toLowerCase().startsWith(event.query.toLowerCase())) {
        listDropdown.push({ label: element.label, value: element.value });
      }
    });

    setFilteredProdutos(listDropdown);
  };

  function handleAddProduto() {
    if (produto) {
      let encontrou = false;
      for (let index = 0; index < dataTableProdutos.length; index++) {
        const obj = dataTableProdutos[index];
        if (obj.label === produto.label || obj.value === produto.value) {
          setProduto('');
          encontrou = true;
          break;
        }
      }

      if (encontrou === false) {
        dataTableProdutos.push(produto);
        setDataTableProdutos(dataTableProdutos);
        setProdutos(dataTableProdutos);
        setProduto('');
        atualizarCotacao();
        toast.success('Produto incluído com sucesso.');
      } else {
        toast.error('Produto já está na cotação.');
      }
    } else if (descricaoProduto !== '') {
      const existe = dataTableProdutos.find(v => (descricaoProduto === v.label.trim));
      if (!existe) {
        setShowConfirmCadastroProdutoDialog(true);
      } else {
        toast.error('Produto já incluído na cotação.');
      }
    }
  }

  function handleAddProdutosCotacaoPadrao() {
    getCotacoesPadrao();
    setShowColetarProdutosCotacaoPadraoDialog(true);
  }

  const handleDelete = async (e) => {
    e.preventDefault();

    const idExclusao = selectedItens.value.value;
    if (idExclusao > 0) {
      const existe = await axiosCoto.get(`/cotacao/existemrespostasparaprodutodacotacao/${id}/${idExclusao}`);
      if (existe.data.length > 0) {
        toast.error('Produto já possui respostas, não será possível a exclusão.');
      } else {
        setShowConfirmDialog(true);
      }
    } else {
      toast.warning('Por favor, selecione o registro para exclusão.');
    }
  };

  async function handleRemoverProduto(e) {
    e.preventDefault();

    const idExclusao = selectedItens.value.value;

    if (idExclusao > 0) {
      setShowConfirmDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para exclusão.');
    }
  }

  const adicionaProdutoNovoViaEnter = (e) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      if (descricaoProduto !== '') {
        const existeProduto = filteredProdutos.find(v => (produto.label === v.label));
        if (existeProduto) {
          handleAddProduto();
          return;
        }
        const existe = dataTableProdutos.find(v => (descricaoProduto === v.label.trim));
        if (!existe) {
          setShowConfirmCadastroProdutoDialog(true);
        } else {
          toast.error('Produto já incluído na cotação.');
        }
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;
    if (descricaoCotacao.length < 3 || descricaoCotacao.length > 100) {
      formErros = true;
      toast.error('Descrição  da cotação deve conter entre 3 à 100 caracteres.');
    }

    if (dataAbertura === null || dataAbertura === '') {
      formErros = true;
      toast.error('Data abertura deve ser preenchida.');
    }
    if (dataFechamento === null || dataFechamento === '') {
      formErros = true;
      toast.error('Data Fechamento deve ser preenchida.');
    }

    if (formErros) return;

    salvarCotacao();
  };

  const handleCancelCadastro = () => {
    setShowCancelDialog(true);
  };

  const cancelCancelDialog = async () => {
    setShowCancelDialog(false);
  };

  const cancelColetarProdutosCotacaoPadrao = async () => {
    setShowColetarProdutosCotacaoPadraoDialog(false);
  };

  const confirmCancelDialog = async () => {
    navigate('/consultacotacao');
  };

  const cancelDialogFooter = (
    <>
      <IconButton titulo=" Sim " mensagem="Sim" btnStyle="primary" onClick={confirmCancelDialog} icon="check" />
      <IconButton titulo=" Não " mensagem="Não" btnStyle="danger" onClick={cancelCancelDialog} icon="times" />
    </>
  );

  const cancelDelete = () => {
    setShowCancelDialog(true);
    setShowConfirmDialog(false);
  };

  const confirmDelete = async () => {
    setShowConfirmDialog(false);

    const novoArray = dataTableProdutos.filter((element) => element.label !== selectedItens.value.label);
    setDataTableProdutos(novoArray);
    setProdutos(novoArray);
    setProduto('');
    deleteProduto(selectedItens.value.id);

    toast.success('Produto removido com sucesso.');
  };

  const confirmDialogFooter = (
    <>
      <IconButton titulo=" Sim " mensagem="Sim" btnStyle="primary" onClick={confirmDelete} icon="check" />
      <IconButton titulo=" Não " mensagem="Não" btnStyle="danger" onClick={cancelDelete} icon="times" />
    </>
  );

  const confirmCadastroProduto = async (e) => {
    e.preventDefault();
    const descricao = descricaoProduto;
    const shippingData = {
      descricao,
      idUsuario,
    };

    const produtoNovo = await axiosCoto.post('/produto', shippingData);
    const produtoACadastrar = {
      label: produtoNovo.data.descricao,
      value: produtoNovo.data.id,
    };

    setProduto(produtoACadastrar);

    dataTableProdutos.push(produtoACadastrar);
    setDataTableProdutos(dataTableProdutos);
    setProdutos(dataTableProdutos);

    setProduto('');
    atualizarCotacao();

    toast.success('Produto incluído com sucesso.');

    setDescricaoProdutoUltimBusca(descricaoProduto);
    setDescricaoProduto('');

    setShowConfirmCadastroProdutoDialog(false);
  };

  const confirmColetarProdutosCotacaoPadrao = async (e) => {
    e.preventDefault();

    try {
      if (cotacaoPadrao) {
        setIsLoading(true);
        const { data: _cotacao } = await axiosCoto.get(`/cotacao/cotacaopadrao/?id=${cotacaoPadrao}`);

        const dataTableProdutosBase = dataTableProdutos;
        for (let x = 0; x < _cotacao.cotacaoProdutos.length; x++) {
          let encontrou = false;
          for (let index = 0; index < dataTableProdutosBase.length; index++) {
            const obj = dataTableProdutosBase[index];
            if (obj.label.trim() === _cotacao.cotacaoProdutos[x].descricaoProduto) {
              encontrou = true;
              break;
            }
          }

          if (encontrou === false) {
            const produtoCotacaoPadrao = { label: _cotacao.cotacaoProdutos[x].descricaoProduto, value: _cotacao.cotacaoProdutos[x].idProduto };
            dataTableProdutosBase.push(produtoCotacaoPadrao);
          }
        }

        setShowColetarProdutosCotacaoPadraoDialog(false);
        setDataTableProdutos(dataTableProdutosBase);
        setProdutos(dataTableProdutosBase);
        setProduto('');
        atualizarCotacao();

        toast.success('Produtos da cotação padrão coletados com sucesso.');
      } else {
        toast.info('Selecione uma cotação padrão para coleta.');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  };

  const cancelCadastroProduto = () => {
    setShowConfirmCadastroProdutoDialog(false);
    setDescricaoProduto('');
  };

  const confirmCadastroProdutoDialogFooter = (
    <>
      <IconButton titulo=" Sim " foco="true" mensagem="Sim" btnStyle="primary" onClick={confirmCadastroProduto} icon="check" />
      <IconButton titulo=" Não " mensagem="Não" btnStyle="danger" onClick={cancelCadastroProduto} icon="check" />
    </>
  );

  const confirmColetarProdutosCotacaoPadraoDialogFooter = (
    <>
      <IconButton titulo=" Sim " foco="true" mensagem="Sim" btnStyle="primary" onClick={confirmColetarProdutosCotacaoPadrao} icon="check" />
      <IconButton titulo=" Não " mensagem="Não" btnStyle="danger" onClick={cancelColetarProdutosCotacaoPadrao} icon="check" />
    </>
  );

  const renderTablePrimeReact = useMemo(() => (
    <div className="datatable-produtos">
      <div className="card">
        <DataTable
          id="TabelaProdutos"
          value={dataTableProdutos}
          size="small"
          stripedRows
          setFilteredItems={produtos}
          responsiveLayout="scroll"
          selectionMode="single"
          onSelectionChange={e => setSelectedItens(e)}
          dataKey="id"
          paginator
          rows={10}
          emptyMessage="Nenhum registro a ser exibido"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="{currentPage} de {totalPages}"
          rowsPerPageOptions={[10, 25, 50]}
        >
          <Column field="label" header="Descrição" sortable style={{ width: '80%' }}></Column>
        </DataTable>
      </div>
    </div>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [handleAddProduto, handleRemoverProduto]);

  return (
    <div>
      <Dialog header="Manutenção de Cotação" visible closable={false} style={{ width: '80vw', height: '95%' }}>
        <Loading isLoading={isLoading} />
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Descrição *
              </Input155px>
              <InputText
                autoFocus
                maxLength={50}
                value={descricaoCotacao}
                onChange={e => setDescricaoCotacao(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Abertura *
              </Input100px>
              <Calendar
                locale="pt-BR"
                dateFormat="dd/mm/yy"
                value={dataAbertura}
                onChange={e => setDataAbertura(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Previsão Fechamento *
              </Input155px>
              <Calendar
                locale="pt-BR"
                dateFormat="dd/mm/yy"
                value={dataFechamento}
                onChange={e => setDataFechamento(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Observação
              </Input155px>
              <InputText
                maxLength={50}
                value={observacao}
                onChange={e => setObservacao(e.target.value)}
              />
            </div>
          </div>
          { id > 0 ? (
            <div className="col-3">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Status da cotação
                </Input155px>
                <Dropdown
                  disabled
                  options={obterListaStatus()}
                  value={status}
                  onChange={e => setStatus(e.target.value)}
                />
              </div>
            </div>
          ) : (
            <div className="col-3">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Status da cotação
                </Input155px>
                <Dropdown
                  disabled
                  options={obterListaStatus()}
                  value={status}
                  onChange={e => setStatus(e.target.value)}
                />
              </div>
            </div>
          )}
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Padrão
              </Input155px>
              <ToggleButton
                onLabel="Sim"
                offLabel="Não"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                checked={padrao}
                onChange={(e) => setPadrao(e.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-7">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon" title="Selecionar todos ou nenhum tem o mesmo efeito.">
                Vendedores
              </Input155px>
              <TreeSelect
                title="Selecionar todos ou nenhum tem o mesmo efeito."
                value={selectedVendedores}
                className="form-control"
                selectionMode="checkbox"
                filter
                emptyMessage="Nenhum registro a ser exibido"
                options={listaVendedores}
                onChange={(e) => setSelectedVendedores(e.value)}
                placeholder="Selecione algum vendedor ou nenhum para todos participarem..."
              />
            </div>
          </div>
        </div>

        { id ? (
          <Card>
            <div className="row form-elo">
              <div>
                Última busca / produto inserido / produto novo:[
                <strong>
                  {descricaoProduto}
                  {descricaoProdutoUltimaBusca}
                </strong>
                ]
              </div>
              <div className="col-8">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Produto
                  </Input155px>
                  <AutoComplete
                    value={produto}
                    maxLength={100}
                    suggestions={filteredProdutos}
                    completeMethod={searchProdutos}
                    field="label"
                    dropdown
                    forceSelection
                    onChange={(e) => setProduto(e.target.value)}
                    onKeyPress={(e) => adicionaProdutoNovoViaEnter(e)}

                  />
                </div>
              </div>
              <div className="col-1">
                <div className="p-inputgroup">
                  <IconButton titulo="" mensagem="Cadastrar Produto" btnStyle="primary" onClick={() => handleAddProduto()} icon="plus" />
                  <IconButton titulo="" mensagem="Remover Produto" btnStyle="danger" onClick={(e) => handleDelete(e)} icon="trash" />
                </div>
              </div>
              <div className="col-3" style={{ textAlign: 'right' }}>
                <IconButton titulo="Coletar cotação padrão" mensagem="Cadastrar Produto" btnStyle="info" onClick={() => handleAddProdutosCotacaoPadrao()} icon="plus" />
              </div>
            </div>

            {renderTablePrimeReact}

          </Card>
        ) : (
          <>
            <br />
            Após salvar a cotação vá no botão editar para inserir produtos. Assim os produtos serão salvos a cada inclusão.
            <br />
          </>
        ) }
        <div className="col-12" style={{ textAlign: 'right' }}>
          <IconButton titulo=" Salvar " mensagem="Salvar" btnStyle="primary" onClick={handleSubmit} icon="check" />
          <IconButton titulo=" Cancelar " mensagem="Cancelar" btnStyle="danger" onClick={handleCancelCadastro} icon="times" />
        </div>
      </Dialog>
      <br />
      <br />

      <Dialog
        header="Confirmação"
        visible={showConfirmDialog}
        style={{ width: '50vw' }}
        footer={confirmDialogFooter}
        onHide={() => setShowConfirmDialog(false)}
      >
        <p>
          Excluir o registro?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showCancelDialog}
        style={{ width: '50vw' }}
        footer={cancelDialogFooter}
        onHide={() => setShowCancelDialog(false)}
      >
        <p>
          As últimas alterações realizadas serão perdidas. Deseja continuar?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showConfirmCadastroProdutoDialog}
        style={{ width: '50vw' }}
        footer={confirmCadastroProdutoDialogFooter}
        onHide={() => setShowConfirmCadastroProdutoDialog(false)}
      >
        <p>
          Produto [
          <strong>
            {descricaoProduto}
          </strong>
          ]
          não cadastrado. Adicionar a tabela de produtos?
        </p>
      </Dialog>

      <Dialog
        header="Coletar Cotação Padrão"
        visible={showColetarProdutosCotacaoPadraoDialog}
        style={{ width: '50vw' }}
        footer={confirmColetarProdutosCotacaoPadraoDialogFooter}
        onHide={() => setShowColetarProdutosCotacaoPadraoDialog(false)}
      >
        <div className="col-12">
          <div className="p-inputgroup">
            <Input200px className="p-inputgroup-addon">
              Selecione a Cotação Padrão
            </Input200px>
            <Dropdown
              options={listaCotacoesPadrao}
              value={cotacaoPadrao}
              emptyMessage="Nenhum registro a ser exibido"
              onChange={e => setCotacaoPadrao(e.target.value)}
            />
          </div>
        </div>
        <br />
        <p>
          Adicionar produtos da cotação padrao na cotacao atual?
        </p>
      </Dialog>
    </div>
  );
}
