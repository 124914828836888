import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { get, orderBy } from 'lodash';

import 'react-toastify/dist/ReactToastify.css';
import { displayErrors } from '../../../util/diversos';

import { fillColumns } from '../../../components/table';
import { Input155px, InputCampoBusca100px } from '../../../styles/inputGroup';
import IconButton from '../../../components/IconButton';

import Card from '../../../components/card';
import Loading from '../../loading';
import axiosCoto from '../../../services/axiosCoto';

export default function ConsultaPedidoVendedor() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const idVendedor = useSelector((state) => state.auth.user.id);

  const [isLoading, setIsLoading] = useState(false);
  const [dataTableList, setDataTableList] = useState([]);
  const [selectedItens, setSelectedItens] = useState(null);
  const [findField, setFindField] = useState('');
  const [sortField, setSortField] = useState('razaoSocial');
  const [sortOrder, setSortOrder] = useState(null);

  const [dataTableColumn] = useState([
    { field: 'id', header: 'Número', sort: false },
    { field: 'razaoSocial', header: 'Empresa', sort: true, style: { width: '40%' } },
    { field: 'descricao', header: 'Descricão', sort: true },
    { field: 'data', header: 'Data', sort: false },
  ]);

  async function getData(searchFilter) {
    try {
      setIsLoading(true);

      let params = `/pedido/consultatodosparavendedorbypedido/?idVendedor=${idVendedor}`;
      if (searchFilter && searchFilter.findField !== '') {
        params = `${params}&${sortField}=${searchFilter.findField}`;
      }

      const response = await axiosCoto.get(params);

      const data = [];

      for (let x = 0; x < response.data.length; x++) {
        const pedido = {
          id: response.data[x][0],
          razaoSocial: response.data[x][2],
          descricao: response.data[x][3],
          data: response.data[x][4],
        };
        data.push(pedido);
      }

      setDataTableList(data);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const handleFind = () => {
    const searchFilter = {
      findField,
      idVendedor,
    };

    setFindField('');

    getData(searchFilter);
  };

  useEffect(() => {
    getData('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleEdit = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      navigate(`/cadastropedidovendedor/${id}`);
    } else {
      toast.warning('Por favor, selecione o registro para visualização.');
    }
  };

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  const renderTablePrimeReact = useMemo(() => (
    <div>
      <div className="card">
        <DataTable
          value={dataTableList}
          size="small"
          stripedRows
          sortField={sortField}
          setOrder={sortOrder}
          onSort={onSort}
          responsiveLayout="scroll"
          selectionMode="single"
          selection={selectedItens}
          onSelectionChange={e => setSelectedItens(e.value)}
          selectableRows
          dataKey="id"
          paginator
          rows={10}
          emptyMessage="Nenhum registro a ser exibido"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="{currentPage} de {totalPages}"
          rowsPerPageOptions={[10, 25, 50]}
        >
          {fillColumns(dataTableColumn)}
        </DataTable>
      </div>
    </div>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [dataTableList, selectedItens]);

  return (
    <>
      <Loading isLoading={isLoading} />
      <Card title="Pedidos">
        <div>
          <div className="row align-items-center">
            <div className="col-11">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Buscar por:
                </Input155px>
                <InputCampoBusca100px className="p-inputgroup-addon">
                  {dataTableColumn.find(element => element.field === sortField).header}
                </InputCampoBusca100px>
                <InputText
                  autoFocus
                  maxLength={250}
                  value={findField}
                  onChange={e => setFindField(e.target.value)}
                />
                &nbsp; &nbsp;
                <IconButton titulo="" mensagem="Buscar" btnStyle="success" onClick={handleFind} icon="search" />
              </div>
            </div>
            <div className="col-1" style={{ textAlign: 'right' }}>
              <IconButton titulo="" mensagem="Visualizar" btnStyle="dark" onClick={handleEdit} icon="eye" />
            </div>
          </div>
        </div>
        <br />
        {renderTablePrimeReact}
      </Card>
    </>
  );
}
