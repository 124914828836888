import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';

import { InputText } from 'primereact/inputtext';
import { displayErrors } from '../../../util/diversos';

import Loading from '../../loading';
import axiosCoto from '../../../services/axiosCoto';
import IconButton from '../../../components/IconButton';
import Card from '../../../components/card';

export default function CadastroRamoNegocio() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const idRamoNegocio = get(useParams(), 'id', 0);

  const [descricao, setDescricao] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!idRamoNegocio) return;

    async function getRamoNegocio() {
      try {
        setIsLoading(true);
        const { data } = await axiosCoto.get(`/ramonegocio/${idRamoNegocio}`);
        setDescricao(data.descricao.trim());
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getRamoNegocio();
  }, [dispatch, idRamoNegocio, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const shippingData = {
        descricao,
      };

      if (idRamoNegocio > 0) {
        await axiosCoto.put(`/ramonegocio/${idRamoNegocio}`, shippingData);
        toast.success('Ramo de Negócio atualizado com sucesso.');
      } else {
        await axiosCoto.post('/ramonegocio', shippingData);
        toast.success('Ramo de Negócio cadastrado com sucesso.');
      }

      navigate('/consultaramonegocio');
    } catch (err) {
      const errors = get(err.response.data, 'errors', []);
      if (errors.length > 0) {
        errors.map(error => toast.error(error));
      } else {
        toast.error(err.response.data);
      }
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancelar = () => {
    navigate('/consultaramonegocio');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let formErros = false;
    if (descricao.length < 3 || descricao.length > 50) {
      formErros = true;
      toast.error('Descrição deve conter de 3 à 50 caracteres.');
    }
    if (formErros) return;
    salvarRegistro();
  };

  return (
    <div>
      <Loading processando={isLoading} />
      <Card title="Ramo de Negócios">
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                Descrição *
              </span>
              <InputText
                className="p-inputtext"
                maxLength={50}
                value={descricao}
                onChange={e => setDescricao(e.target.value)}
              />
            </div>
          </div>
        </div>
        <br />
        <div className="col-12" style={{ textAlign: 'right' }}>
          <IconButton titulo="Salvar" mensagem="Salvar" btnStyle="success" onClick={handleSubmit} icon="check" />
          <IconButton titulo="Cancelar" mensagem="Cancelar" btnStyle="danger" onClick={handleCancelar} icon="times" />
        </div>
      </Card>
    </div>
  );
}
