/* eslint-disable prefer-template */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { displayErrors } from '../../../util/diversos';
import Card from '../../../components/card';

import axiosCoto from '../../../services/axiosCoto';
import IconButton from '../../../components/IconButton';

import { Input100px } from '../../../styles/inputGroup';

import Loading from '../../loading';

export default function RelatorioFinanceiroComprador() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const idUsuario = useSelector((state) => state.auth.user.id);

  const [dataInicial, setDataInicial] = useState();
  const [dataFinal, setDataFinal] = useState();

  const [isLoading, setIsLoading] = useState(false);

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  function headRows() {
    return [
      { id: '', email: 'Email', dataQuitacao: 'Data Quitação', valor: 'Valor', status: 'Status', dataLicenca: 'Licença aplicada' },
    ];
  }

  async function getData() {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();
    try {
      setIsLoading(true);

      if (dataInicial == null && dataFinal == null) {
        toast.info('Datas devem ser informadas.');
        return;
      }

      if (dataInicial > dataFinal) {
        toast.info('Datas final anterior a data inicial.');
        return;
      }

      const shippingData = {
        dataInicial,
        dataFinal,
        idUsuario,
      };

      const response = await axiosCoto.post('/financeiro/financeirorelatoriousuario', shippingData);

      if (response.data.length <= 0) {
        toast.info('Não há registros a serem exibidos.');
        return;
      }

      let totalRecebimentos = 0;
      let NumeroRecebimentosAprovados = 0;
      const result = [];
      let data = {
        id: '',
        email: '',
        dataQuitacao: '',
        valor: '0',
        status: '',
        dataLicenca: '',
      };

      for (let x = 0; x < response.data.length; x++) {
        data.id = x + 1;
        data.email = response.data[x].email;
        const dataQuitacao = `${('00' + response.data[x].dataQuitacao[2]).slice(-2)}/${('00' + response.data[x].dataQuitacao[1]).slice(-2)}/${response.data[x].dataQuitacao[0]}`;
        data.dataQuitacao = dataQuitacao;
        data.status = response.data[x].status;
        data.valor = response.data[x].valor;
        if (data.status === 'Aprovado') {
          totalRecebimentos += data.valor;
        }

        const dataLicenca = `${('00' + response.data[x].dataLicenca[2]).slice(-2)}/${('00' + response.data[x].dataLicenca[1]).slice(-2)}/${response.data[x].dataLicenca[0]}`;
        data.dataLicenca = dataLicenca;

        data.valor = `R$ ${response.data[x].valor.toFixed(2).toString()}`;

        result.push({ ...data });
      }

      data = {
        id: '',
        email: '',
        dataQuitacao: '',
        valor: '',
        status: '',
        dataLicenca: '',
      };

      data.dataQuitacao = 'Total';
      data.valor = `R$ ${totalRecebimentos.toFixed(2).replace('.', ',').toString()}`;
      result.push({ ...data });

      const mesInicial = dataInicial.getMonth() + 1;
      const dataInicialImpressao = ('00' + dataInicial.getDate()).slice(-2).toString() + '/' + ('00' + mesInicial).slice(-2).toString() + '/' + dataInicial.getFullYear().toString();

      const mesFinal = dataFinal.getMonth() + 1;
      const dataFinalImpressao = ('00' + dataFinal.getDate()).slice(-2).toString() + '/' + ('00' + mesFinal).slice(-2).toString() + '/' + dataFinal.getFullYear().toString();

      doc.autoTable(
        {
          styles: { fontSize: 8 },
          margin: { top: 38, left: 5, right: 5 },
          theme: 'grid',
          head: headRows(),
          body: result,
          didDrawPage: (dados) => {
            // Header
            doc.rect(5, 5, 200, 30); //  header
            doc.addImage('logo.png', 'JPEG', 10, 12, 25, 15);
            doc.setFontSize(20);
            doc.text('Relatório Financeiro', 75, 15);

            doc.setFontSize(10);

            doc.text('Período:', 45, 32);
            doc.text(dataInicialImpressao, 65, 32);
            doc.text('à', 85, 32);
            doc.text(dataFinalImpressao, 89, 32);

            const { pageSize } = doc.internal;
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text(`Pag ${doc.internal.getNumberOfPages()}`, dados.settings.margin.right, pageHeight - 8);
            doc.text('Elo Informática Barbacena', 82, pageHeight - 8);
            const periodo = new Date();
            const mes = periodo.getMonth() + 1;
            doc.text('Impresso: ', 172, pageHeight - 8);
            doc.text((`${('00' + periodo.getDate()).slice(-2)}/${('00' + mes).slice(-2)}/${periodo.getFullYear()}`).toString(), 189, pageHeight - 8);
          },
        },
      ).setFontSize(5);
      window.open(doc.output('bloburl'));
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
  }, [dispatch, idUsuario]);

  const handleFind = () => {
    getData();
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <Card title="Relatório Financeiro">
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Data Inicial
              </Input100px>
              <Calendar
                locale="pt-BR"
                dateFormat="dd/mm/yy"
                id="dataInicial"
                value={dataInicial}
                onChange={e => setDataInicial(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Data Final
              </Input100px>
              <Calendar
                locale="pt-BR"
                dateFormat="dd/mm/yy"
                id="dataFinal"
                value={dataFinal}
                onChange={e => setDataFinal(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">

            </div>
          </div>
          <div className="col-2" style={{ textAlign: 'right' }}>
            <IconButton titulo="imprimir" title="Gerar Relatório" btnStyle="primary" onClick={handleFind} icon="print" />
          </div>
        </div>
      </Card>
    </>
  );
}
