import { toast } from 'react-toastify';
import { get } from 'lodash';

import * as actions from '../store/modules/auth/actions';

export function soNumeros(string) {
  if (!string) return '';

  const _str = string.replace(/[^0-9]/g, '');
  return _str.trim();
}

export function formataTelefone(string) {
  let _str = soNumeros(string);

  if (_str.length === 8) {
    _str = `${_str.slice(0, 4)}-${_str.slice(4, 8)}`;
  } else if (_str.length === 9) {
    _str = `${_str.slice(0, 5)}-${_str.slice(5, 9)}`;
  } else if (_str.length === 10) {
    _str = `(${_str.slice(0, 2)})${_str.slice(2, 6)}-${_str.slice(6, 10)}`;
  } else if (_str.length === 11) {
    _str = `(${_str.slice(0, 2)})${_str.slice(2, 7)}-${_str.slice(7, 11)}`;
  }

  return _str;
}

export function formataCnpjCpf(string) {
  let _str = soNumeros(string);

  if (_str.length === 11) {
    _str = `${_str.slice(0, 3)}.${_str.slice(3, 6)}.${_str.slice(6, 9)}-${_str.slice(9)}`;
  } else if (_str.length === 14) {
    _str = `${_str.slice(0, 2)}.${_str.slice(2, 5)}.${_str.slice(5, 8)}/${_str.slice(8, 12)}-${_str.slice(12)}`;
  }

  return _str;
}

export function formataData(data) {
  if (data != null) {
    return (('00' + data[2]).slice(-2) + '/' + ('00' + data[1]).slice(-2) + '/' + ('0000' + data[0]).slice(-4));
  }
}

export function formataDataReferencia(data) {
  if (data != null) {
    return (('00' + data[1]).slice(-2) + '/' + ('0000' + data[0]).slice(-4));
  }
}

export function formataMoeda(value) {
  let _value = value;

  try {
    _value = value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  } catch (err) {
    _value = 'R$ 0,00';
  }

  return _value;
}

export function displayErrors(response, dispatch, navigate, navTo, msg) {
  let _msg = msg;

  if (!_msg) {
    _msg = 'Não foi possível se conectar ao servidor';
  }

  if (get(response, 'response.status', 0) === 403) {
    dispatch(actions.loginFailure());
    navigate('/login');
    toast.warning('A sua sessão expirou! Por favor, conecte-se novamente.');
  } else {
    const errors = get(response, 'response.data.errors', []);
    if (errors.length > 0) {
      errors.map(error => toast.error(error));
    } else {
      toast.error(_msg);
      if (navTo) navigate(navTo);
    }
  }
}
