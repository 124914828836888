/* eslint-disable camelcase */
/* eslint-disable prefer-template */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import { get } from 'lodash';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Input155px, Input200px } from '../../../styles/inputGroup';

import store from '../../../store/store';

import IconButton from '../../../components/IconButton';
import { displayErrors } from '../../../util/diversos';

import Loading from '../../loading';
import Card from '../../../components/card';
import axiosCoto from '../../../services/axiosCoto';
import * as actions from '../../../store/modules/auth/actions';

export default function FinanceiroComprador() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const tipoUsuarioLogado = useSelector((state) => state.auth.user.tipoUsuario);
  const email = useSelector((state) => state.auth.user.email);
  const id = useSelector((state) => state.auth.user.id);
  // const accessToken = useSelector((state) => state.auth.user.shipayAccessToken.body);

  const { dataLicenca } = store.getState().auth.user;
  const dataLicencaAtual = ('00' + dataLicenca[2]).slice(-2) + '/' + ('00' + dataLicenca[1]).slice(-2) + '/' + dataLicenca[0];

  const [idPendente, setIdPendente] = useState();
  const [orderIdPendente, setOrderIdPendente] = useState(null);
  const [qrCodeCodigoPendente, setQrCodeCodigoPendente] = useState(null);
  const [statusPendente, setStatusPendente] = useState();
  const [qrCodeCodigo, setQrCodeCodigo] = useState('');
  const [orderId, setOrderId] = useState('');
  const [botaoGerarPagamentoDisabledStatus, setBotaoGerarPagamentoDisabledStatus] = useState(false);
  const [token, setToken] = useState(null);
  const [valorPagamento, setValorPagamento] = useState('0.0');
  const [showQrCodeDialog, setShowQrCodeDialog] = useState(false);
  const [showQrCodePendenteDialog, setShowQrCodePendenteDialog] = useState(false);
  const [showPagamentoAprovadoDialog, setShowPagamentoAprovadoDialog] = useState(false);
  const [showPagamentoReembolsoDialog, setShowPagamentoReembolsoDialog] = useState(false);

  async function getData() {
    try {
      setIsLoading(false);
      const response = await axiosCoto.get(`/financeiro/?idUsuario=${id}`);
      return response;
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
    return null;
  }

  async function atualizarFinanceiro() {
    try {
      setIsLoading(true);

      const shippingData = {
        id: idPendente,
        idUsuario: id,
        orderId: orderIdPendente,
        valor: valorPagamento,
        status: 'Aprovado',
        qrCodeCodigo: qrCodeCodigoPendente,
        email,
      };

      if (id > 0) {
        const params = `/financeiro/${idPendente}`;
        await axiosCoto.put(params, shippingData);
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  async function deletarFinanceiro() {
    try {
      setIsLoading(true);

      if (idPendente > 0) {
        const params = `/financeiro/${idPendente}`;
        await axiosCoto.delete(params);
        toast.info('Financeiro deletado por estar expirado. Gere novo pagametno');
        setOrderIdPendente();
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const getAutenticacao = async () => {
    if (token == null) {
      try {
        const response = await axiosCoto.post('shipay/autenticar');
        const responseToken = response.data.access_token;
        setToken(responseToken);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      }
    }
  };

  async function getValorPagamento() {
    try {
      let response;
      if (tipoUsuarioLogado === 'VENDEDOR') {
        const params = '/configuracao/getvalorvendedor';
        response = await axiosCoto.get(params);
      }
      if (tipoUsuarioLogado === 'COMPRADOR') {
        const params = '/configuracao/getvalorcomprador';
        response = await axiosCoto.get(params);
      }

      const valor = response.data;
      setValorPagamento(parseFloat(valor).toFixed(2));
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  async function getOrder() {
    try {
      const shippingData = {
        access_token: token,
      };
      return await axiosCoto.post(`/shipay/order/${id}`, shippingData);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const getOrderStatus = async () => {
    try {
      const shippingData = {
        access_token: token,
      };
      const order_id = orderId;

      const responseOrder = await axiosCoto.post(`/shipay/orderstatus/${order_id}`, shippingData);

      return responseOrder;
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  };

  async function salvarFinanceiroAprovado() {
    try {
      setIsLoading(true);

      const shippingData = {
        idUsuario: id,
        orderId: orderIdPendente,
        valor: valorPagamento,
        status: 'Aprovado',
        qrCodeCodigo,
        email,
      };

      if (id > 0) {
        const params = '/financeiro/salvaraprovado';
        await axiosCoto.post(params, shippingData);
        toast.info('Pagamento confirmado. Faça novamente o login.');
      }
    } catch (err) {
      const errors = get(err.response.data, 'errors', []);
      if (errors.length > 0) {
        errors.map(error => toast.error(error));
      } else {
        toast.error('Não foi possível atualizar o registro');
      }
    } finally {
      setIsLoading(false);
    }
  }

  const getOrderStatusPosterior = async () => {
    const shippingData = {
      access_token: token,
    };
    const order_id = orderIdPendente;
    await axiosCoto.post(`/shipay/orderstatus/${order_id}`, shippingData).then(
      response => {
        const json = JSON.parse(response.data.body);
        setOrderIdPendente(orderIdPendente);
        setStatusPendente(json.status);
        if (json.status === 'approved') {
          atualizarFinanceiro().then(() => {
            setOrderIdPendente();
            setStatusPendente('Aprovado');
          });

          setShowQrCodePendenteDialog(false);
          setShowPagamentoAprovadoDialog(true);

          toast.info('Pagamento aprovado. Faça o login novamente para atualizar a validade.');

          dispatch(actions.loginFailure());
        } else if (json.status === 'expired') {
          deletarFinanceiro();
          setOrderIdPendente();
          setStatusPendente('');
          setShowQrCodePendenteDialog(false);

          toast.info('Pagamento expirado, gere um novo pagamento.');
        } else if (json.status === 'cancelled') {
          deletarFinanceiro();
          setOrderIdPendente();
          setStatusPendente('');
          setShowQrCodePendenteDialog(false);

          toast.info('Pagamento cancelado, gere um novo pagamento.');
        }
        return response;
      },
    );
  };

  async function salvarFinanceiro(data) {
    try {
      setIsLoading(true);

      const shippingData = {
        idUsuario: id,
        orderId: data.order_id,
        valor: valorPagamento,
        status: data.status,
        qrCodeCodigo: data.qr_code_text,
        email,
      };

      if (id > 0) {
        const params = '/financeiro/';
        await axiosCoto.post(params, shippingData);
      }
    } catch (err) {
      const errors = get(err.response.data, 'errors', []);
      if (errors.length > 0) {
        errors.map(error => toast.error(error));
      } else {
        toast.error('Não foi possível atualizar o registro');
      }
    } finally {
      setIsLoading(false);
    }
  }

  const mostrarQrCodeNovamente = () => {
    if (qrCodeCodigo !== null && (statusPendente === 'Pendente' || statusPendente === 'pending')) {
      try {
        setIsLoading(true);
        setShowQrCodePendenteDialog(true);
        toast.warning('Pagamento em status pendente... aguarde mais alguns minutos se necessário.');
      } catch (err) {
        toast.error('Não foi possível exibir o QrCode.');
      } finally {
        setIsLoading(false);
      }
    } else {
      toast.warning('Somente Pagamentos de status pending podem ser exibidos.');
    }
    getOrderStatusPosterior().then(() => {
      // nao fazer nada
    }).catch(() => {
      toast.warning('Não foi possível consultar o pagamento. Tente mais tarde.');
    });
  };

  const handleGerarPagamentoPix = async () => {
    if (orderIdPendente === null || statusPendente === 'Aprovado' || statusPendente === '') {
      setIsLoading(true);
      try {
        setBotaoGerarPagamentoDisabledStatus(true);

        getOrder().then(responseOrder => {
          salvarFinanceiro(responseOrder.data).then(() => {
            getData().then(responseData => {
              setOrderIdPendente(responseData.data.orderIdPendente);
            });
          });
        }).catch(() => {
          toast.warning('Não foi possível gerar o pagamento. Tente mais tarde');
        });
      } catch (err) {
        toast.error('Não foi possível gerar o QrCode para pagamento. Tente mais tarde.');
      } finally {
        setOrderId(orderId);
        setIsLoading(false);
      }
    } else {
      toast.warning('Existe um pagamento pendente de confirmacao. Verifique o pagamento.');
    }
    botaoGerarPagamentoDisabledStatus(false);
  };

  const handlePagamentoEfetuado = () => {
    getOrderStatus().then(responseOrderStatus => {
      const json = JSON.parse(responseOrderStatus.data.body);
      if (json.status === 'pending') {
        salvarFinanceiro().then(() => {
          getData().then(responseData => {
            setOrderIdPendente(responseData.data[0].orderId);
            setIdPendente(responseData.data[0].id);
            setQrCodeCodigoPendente(responseData.data[0].qrCodeCodigo);
            if (responseData.data[0].status === 'pending') {
              setStatusPendente('Pendente');
            } else {
              setStatusPendente('Pendente');
            }
          });
        });
      }
      if (json.status === 'approved') {
        salvarFinanceiroAprovado();
        setShowPagamentoAprovadoDialog(true);
      }
      setQrCodeCodigoPendente(qrCodeCodigo);
    }).catch(() => {
      toast.warning('Não foi possível consultar status e salvar pagamento. Tente mais tarde.');
    });
    setShowQrCodeDialog(false);
    setBotaoGerarPagamentoDisabledStatus(false);
  };

  useEffect(() => {
    getData().then(responseData => {
      if (responseData.data[0] !== undefined) {
        setOrderIdPendente(responseData.data[0].orderId);
        setIdPendente(responseData.data[0].id);
        setQrCodeCodigoPendente(responseData.data[0].qrCodeCodigo);
        if (responseData.data[0].status === 'pending') {
          setStatusPendente('Pendente');
        }
        if (responseData.data[0].status === 'Reembolso pendente') {
          setStatusPendente('Reembolso Pendente');
        }
        if (responseData.data[0].status === 'Aprovado') {
          setStatusPendente('Aprovado');
        }
      }
    });
    getValorPagamento();
    getAutenticacao();
  });

  const handleQrCodePendenteSair = () => {
    getOrderStatusPosterior();
    setShowQrCodePendenteDialog(false);
  };

  const handlePagamentoAprovadoSair = () => {
    setShowPagamentoAprovadoDialog(false);
  };

  const handlePagamentoReembolsoSair = () => {
    setShowPagamentoReembolsoDialog(false);
  };

  function copyToClipboard(qrCodecopy) {
    navigator.clipboard.writeText(qrCodecopy);
    toast.success('Texto Qrcode copiado!');
  }

  const confirmQrCodeFooter = (
    <div>
      <IconButton titulo="Após pagamento CLIQUE AQUI" mensagem="Pagamento efetuado" btnStyle="success" onClick={handlePagamentoEfetuado} icon="check" />
    </div>
  );

  const confirmQrCodePendenteFooter = (
    <div>
      <IconButton titulo="Confirmar Pagamento / Sair" mensagem="Sair" btnStyle="success" onClick={handleQrCodePendenteSair} icon="check" />
    </div>
  );

  const buttonGerarFinanceiro = (
    <IconButton titulo="Gerar Pagamento Pix" mensagem="Gerar o pagamento" disabled={botaoGerarPagamentoDisabledStatus} btnStyle="success" onClick={handleGerarPagamentoPix} icon="check" />
  );

  const confirmPagamentoAprovadoDialogFooter = (
    <IconButton titulo="Voltar" mensagem="voltar" btnStyle="success" onClick={handlePagamentoAprovadoSair} icon="check" />
  );

  const confirmPagamentoReembolsadoDialogFooter = (
    <IconButton titulo="Voltar" mensagem="voltar" btnStyle="success" onClick={handlePagamentoReembolsoSair} icon="check" />
  );

  return (
    <>
      <Loading isLoading={isLoading} />
      <Card title="Financeiro">

        {(orderIdPendente) ? (
          <Card title="Informações de pagamento Pendente">
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Código do pagamento *
                  </Input200px>
                  <InputText
                    autoFocus
                    disabled
                    type="text"
                    value={orderIdPendente}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Status atual *
                  </Input155px>
                  <InputText
                    autoFocus
                    disabled
                    type="text"
                    value={statusPendente}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    QrCode texto*
                  </Input155px>
                  <InputText
                    autoFocus
                    disabled
                    type="text"
                    value={qrCodeCodigoPendente}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <IconButton titulo="Copiar QrCode texto" mensagem="copiar código do QrCode" btnStyle="info" onClick={() => copyToClipboard(qrCodeCodigoPendente)} icon="check" />
                  <IconButton titulo="Mostrar QrCode" mensagem="Mostrar QrCode" btnStyle="success" onClick={mostrarQrCodeNovamente} icon="check" />
                  <IconButton titulo="Verificar pagamento" mensagem="Verificar Pagamento se pendente mostrará o qrCode" btnStyle="warning" onClick={mostrarQrCodeNovamente} icon="check" />
                </div>
              </div>
            </div>
          </Card>
        ) : ('')}

        <div className="row form-elo">
          <Card title="Como efetuar o pagamento">
            <div className="p-inputgroup">
              <div className="col-6">
                <p>
                  Sua licença tem validade até
                  {dataLicencaAtual}
                  .
                </p>
                <p>
                  1 - Abra seu aplicativo do banco
                </p>
                <p>
                  2 - Escolha a opção pagamento via pix
                </p>
                <p>
                  3 - clique no botão Efetuar pagamento via pix ao lado
                </p>
                <p>
                  4 - Faça a leitura do QrCode pelo aplicativo do banco.
                </p>
                <p>
                  5 - Após o pagamento, na tela do qrcode,  clique no botao Pagamento Efetuado para confirmação.
                </p>
                <p>
                  6 - Serão adicionados 30 dias a data da licença ou a data atual.
                </p>
              </div>
              <div className="col-6">
                <p>
                  Para pagamento pelo site do banco:
                </p>
                <p>
                  1 - Abra seu aplicativo do banco no navegador
                </p>
                <p>
                  2 - Escolha a opção pagamento via pix
                </p>
                <p>
                  3 - clique no botão gerar pagamento ao lado.
                </p>
                <p>
                  4 - clique no botão copiar texto na tela do qrCode, o codigo ficará na memória do computador.
                </p>
                <p>
                  5 - Cole o texto do qrCode, usando CTRL + V ou clicando com o botão direito do mouse, no campo referente do aplicativo do banco e colar.
                </p>
                <p>
                  6 - Após o pagamento, na tela do qrcode, clique no botão Pagamento Efetuado para confirmação.
                </p>
                <p>
                  7 - Serão adicionados 30 dias a data da licença ou a data atual.
                </p>
              </div>

            </div>
          </Card>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            Beneficiário do pagamento:
            <br />
            Elo Informática Barbacena (Banco Original).
            <br />
            <br />
            Contato de Suporte e dúvidas de pagamento:
            <br />
            suporte@eloretaguarda.com.br.
            <br />
            <br />
            zap : (32) 98503-1517
          </div>
          <div className="col-8">
            {buttonGerarFinanceiro}
            <Card title="Valor">
              <p style={{ fontSize: '52px', textAlign: 'center' }}>
                R$
                {valorPagamento.replace('.', ',')}
              </p>
            </Card>
          </div>
        </div>
      </Card>

      <Dialog
        header="QrCode"
        visible={showQrCodeDialog}
        style={{ width: '90vw' }}
        footer={confirmQrCodeFooter}
        closable={false}
        onShow={() => setOrderId(orderId)}
      >
        <Card title="Faça a leitura do código">
          <div className="row form-elo">
            <div className="col-12" style={{ textAlign: 'center' }}>
              {(qrCodeCodigo) ? (
                <>
                  <QRCode value={qrCodeCodigo} />
                  <br />
                  <br />
                  <IconButton
                    titulo="Copiar QrCode texto"
                    mensagem="copiar código do QrCode"
                    btnStyle="success"
                    onClick={() => copyToClipboard(qrCodeCodigo)}
                    icon="check"
                  />
                  <br />
                  <br />
                  <p style={{ fontSize: '18px' }}>
                    {orderId}
                  </p>
                  <p style={{ fontSize: '18px' }}>
                    O confirmação do pagamento pelo banco pode demorar até meia hora.
                  </p>
                </>
              ) : ('')}
            </div>
          </div>
        </Card>
      </Dialog>

      <Dialog
        header="QrCode"
        visible={showQrCodePendenteDialog}
        style={{ width: '90vw' }}
        footer={confirmQrCodePendenteFooter}
        closable={false}
      >
        <Card title="Releitura do código">
          <div className="row form-elo">
            <div className="col-12" style={{ textAlign: 'center' }}>
              {(qrCodeCodigoPendente) ? (
                <>
                  <QRCode value={qrCodeCodigoPendente} />
                  <br />
                  <br />
                  <IconButton
                    titulo="Copiar QrCode texto"
                    mensagem="copiar código do QrCode"
                    btnStyle="success"
                    onClick={() => copyToClipboard(qrCodeCodigoPendente)}
                    icon="check"
                  />
                  <br />
                  <br />
                </>
              ) : ('')}
            </div>
          </div>
        </Card>
      </Dialog>

      <Dialog
        header="Confirmação do pagamento"
        visible={showPagamentoAprovadoDialog}
        style={{ width: '50vw' }}
        footer={confirmPagamentoAprovadoDialogFooter}
        closable={false}
      >
        <Card>
          <div className="row form-elo">
            <div className="col-12" style={{ textAlign: 'center' }}>
              {(qrCodeCodigoPendente) ? (
                <>
                  <p style={{ fontSize: '44px', textAlign: 'center' }}>
                    Pagamento Aprovado.
                    <br />
                    Por favor, faça o login novamente.
                  </p>
                  <br />
                  <br />
                </>
              ) : ('')}
            </div>
          </div>
        </Card>
      </Dialog>

      <Dialog
        header=" Reembolso do pagamento"
        visible={showPagamentoReembolsoDialog}
        style={{ width: '50vw' }}
        footer={confirmPagamentoReembolsadoDialogFooter}
        closable={false}
      >
        <Card>
          <div className="row form-elo">
            <div className="col-12" style={{ textAlign: 'center' }}>
              <p style={{ fontSize: '44px', textAlign: 'center' }}>
                Pagamento Reembolsado.
                <br />
                R$
                {valorPagamento.replace('.', ',')}
              </p>
              <br />
              <br />
            </div>
          </div>
        </Card>
      </Dialog>
    </>
  );
}
