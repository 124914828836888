import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { get, orderBy } from 'lodash';

import 'react-toastify/dist/ReactToastify.css';
import { displayErrors } from '../../../util/diversos';

import { fillColumns } from '../../../components/table';
import { Input155px, InputCampoBusca100px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import Loading from '../../loading';
import axiosCoto from '../../../services/axiosCoto';
import IconButton from '../../../components/IconButton';

export default function ConsultaUsuario() {
  const [isLoading, setIsLoading] = useState(false);
  const [dataTableListaUsuario, setDataTableListaUsuario] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [usuariosSelecionados, setUsuariosSelecionados] = useState(null);

  const [campoBusca, setCampoBusca] = useState('');
  const [campoOrdenacao, setCampoOrdenacao] = useState('razaoSocial');
  const [tipoOrdenacao, setTipoOrdenacao] = useState(null);
  const [dataTableColunas] = useState([
    { field: 'id', header: 'Id', sort: true },
    { field: 'cnpj', header: 'CNPJ', sort: true },
    { field: 'razaoSocial', header: 'Razão Social', sort: true },
    { field: 'nome', header: 'Nome', sort: true },
    { field: 'email', header: 'E-Mail', sort: true },
    { field: 'tipoUsuario', header: 'Tipo Usuário', sort: true },
    { field: 'dataLicenca', header: 'Data Licença', sort: false },
    { field: 'ativo', header: 'Ativo', sort: false },
  ]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function getListaUsuarios(buscarPor) {
    try {
      setIsLoading(true);
      let params = '/usuario';
      if (buscarPor && buscarPor.campoBusca !== '') {
        params = `${params}?${campoOrdenacao}=${buscarPor.campoBusca}`;
      }
      const response = await axiosCoto.get(params);
      setDataTableListaUsuario(response.data);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const handleBuscarPor = () => {
    const buscarPor = {
      campoBusca,
    };
    setCampoBusca('');
    getListaUsuarios(buscarPor);
  };

  useEffect(() => {
    getListaUsuarios('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleCadastrar = () => {
    navigate('/cadastrousuario');
  };

  const handleEditar = (e) => {
    e.preventDefault();
    const id = get(usuariosSelecionados, 'id', 0);
    if (id > 0) {
      navigate(`/cadastrousuario/${id}`);
    } else {
      toast.warning('Por favor, selecione o registro para edição.');
    }
  };

  const handleDeletar = (e) => {
    e.preventDefault();
    const id = get(usuariosSelecionados, 'id', 0);
    if (id > 0) {
      setShowConfirmDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para exclusão.');
    }
  };

  const cancelDeletar = () => {
    setShowConfirmDialog(false);
  };

  const confirmDeletar = async () => {
    setShowConfirmDialog(false);
    try {
      setIsLoading(true);
      const idExclusao = get(usuariosSelecionados, 'id', 0);
      if (idExclusao > 0) {
        await axiosCoto.delete(`/usuario/${idExclusao}`);
        const _itens = dataTableListaUsuario.filter(val => val.id !== idExclusao);
        setDataTableListaUsuario(_itens);

        toast.success('Registro excluído com sucesso.');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmDialogFooter = (
    <div>
      <IconButton titulo="Sim" mensagem="Sim" btnStyle="primary" onClick={confirmDeletar} icon="check" />
      <IconButton titulo="Não" mensagem="Não" btnStyle="danger" onClick={cancelDeletar} icon="times" />
    </div>
  );

  const onSort = (e) => {
    setCampoOrdenacao(e.sortField);
    setTipoOrdenacao(e.sortOrder);
    setDataTableListaUsuario(orderBy(dataTableListaUsuario, [campoOrdenacao], [tipoOrdenacao]));
  };

  const renderTablePrimeReact = useMemo(() => {
    const rowClass = (data) => ({
      'row-accessories': data.tipoUsuario === 'ADMINISTRADOR',
    });

    return (
      <div className="datatable-usuarios">
        <DataTable
          value={dataTableListaUsuario}
          rowClassName={rowClass}
          size="small"
          selectionMode="single"
          stripedRows
          sortField={campoOrdenacao}
          setOrder={tipoOrdenacao}
          onSort={onSort}
          selection={usuariosSelecionados}
          onSelectionChange={e => setUsuariosSelecionados(e.value)}
          responsiveLayout="scroll"
          dataKey="id"
          paginator
          rows={10}
          emptyMessage="Nenhum registro a ser exibido"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="{currentPage} de {totalPages}"
          rowsPerPageOptions={[10, 25, 50]}
        >
          {fillColumns(dataTableColunas)}
        </DataTable>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTableListaUsuario, usuariosSelecionados]);

  return (
    <>
      <Loading processando={isLoading} />
      <Card title="Usuários">
        <div>
          <div className="row align-items-center">
            <div className="col-lg-10">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Buscar por:
                </Input155px>
                <InputCampoBusca100px className="p-inputgroup-addon">
                  {dataTableColunas.find(element => element.field === campoOrdenacao).header}
                </InputCampoBusca100px>
                <InputText
                  maxLength={250}
                  value={campoBusca}
                  onChange={e => setCampoBusca(e.target.value)}
                />
                &nbsp; &nbsp;
                <IconButton titulo="" mensagem="Buscar" btnStyle="success" onClick={handleBuscarPor} icon="search" />
              </div>
            </div>
            <div className="col-2" style={{ textAlign: 'right' }}>
              <IconButton titulo="" mensagem="Cadastrar" btnStyle="primary" onClick={handleCadastrar} icon="plus" />
              <IconButton titulo="" mensagem="Editar" btnStyle="warning" onClick={handleEditar} icon="pencil" />
              <IconButton titulo="" mensagem="Excluir" btnStyle="danger" onClick={handleDeletar} icon="trash" />
            </div>
          </div>
        </div>
        <br />

        {renderTablePrimeReact}

        <Dialog
          header="Confirmação"
          visible={showConfirmDialog}
          style={{ width: '50vw' }}
          footer={confirmDialogFooter}
          onHide={() => setShowConfirmDialog(false)}
        >
          <p>
            Excluir o registro?
          </p>
        </Dialog>
      </Card>
    </>
  );
}
