import { useSelector } from 'react-redux';
//import { useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

export default function PrivateRoute({ children, tipoUsuario, licenca }) {
  //const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const tipoUsuarioLogado = useSelector((state) => state.auth.user.tipoUsuario);

  if (isLoggedIn && tipoUsuario === tipoUsuarioLogado && licenca) {
    return children;
  }

  return <Navigate to="/login" />;
}
