import React, { useState, useEffect, useMemo } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Card from '../../../components/card';
import IconButton from '../../../components/IconButton';

import { Input100px, Input155px } from '../../../styles/inputGroup';
import { displayErrors } from '../../../util/diversos';

import Loading from '../../loading';
import axiosCoto from '../../../services/axiosCoto';

export default function CadastroPedidoVendedor() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const idUsuario = useSelector((state) => state.auth.user.id);
  const idPedido = get(useParams(), 'id', 0);

  const [razaoSocial, setRazaoSocial] = useState('');
  const [descricao, setDescricao] = useState('');
  const [data, setData] = useState('');
  const [totalPedido, setTotalPedido] = useState(0);

  const [dataTableProdutos, setDataTableProdutos] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!idPedido) return;

    async function getData() {
      try {
        setIsLoading(true);
        const response = await axiosCoto.get(`/pedido/pedidocomrespostasparavendedor/${idPedido}/${idUsuario}`);

        setRazaoSocial(response.data.razaoSocial.trim());
        setDescricao(response.data.descricao.trim());
        const dataPedido = new Date(response.data.data[0], response.data.data[1] - 1, response.data.data[2]);
        setData(dataPedido);
        setTotalPedido(response.data.valorTotalCompra.toFixed(2));

        const pedidoProdutos = [];
        let contador = 1;
        response.data.pedidoProdutos = _.sortBy(response.data.pedidoProdutos, 'descricaoProduto');
        response.data.pedidoProdutos.forEach(element => {
          const qtdePedido = get(element.pedidoProdutoRespostas[0], 'qtdePedido', 0);

          if (qtdePedido > 0) {
            const idProdutoResposta = get(element.pedidoProdutoRespostas[0], 'id', 0);
            const qtdePorCaixa = get(element.pedidoProdutoRespostas[0], 'qtdePorCaixa', 0);
            let valorCaixa = get(element.pedidoProdutoRespostas[0], 'valorCaixa', 0);
            let valorUnitario = get(element.pedidoProdutoRespostas[0], 'valorUnitario', 0);
            const valorAtacado = get(element.pedidoProdutoRespostas[0], 'valorAtacado', 0);
            const qtdeAtacado = get(element.pedidoProdutoRespostas[0], 'qtdeAtacado', 0);
            let subTotal = 0;
            if (qtdePedido >= qtdeAtacado && qtdeAtacado > 0) {
              valorCaixa = valorAtacado;
              valorUnitario = valorAtacado / qtdePorCaixa;
              subTotal = (valorAtacado * qtdePedido).toFixed(2);
            } else {
              subTotal = (valorCaixa * qtdePedido).toFixed(2);
            }

            subTotal = parseFloat(subTotal).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
            valorCaixa = valorCaixa.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
            valorUnitario = valorUnitario.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
            pedidoProdutos.push({ numItemPedido: contador++,
              label: element.descricaoProduto.trim(),
              value: element.id,
              id: element.id,
              idCotacaoProduto: element.idProduto,
              quantidade: element.quantidade,
              qtdePorCaixa,
              qtdePedido,
              valorCaixa,
              valorUnitario,
              totalST: element.totalST,
              idProdutoResposta,
              idPedido,
              subTotal,
            });
          }
        });

        setDataTableProdutos(pedidoProdutos);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [dispatch, idPedido, idUsuario, navigate]);

  const handleCancel = () => {
    navigate('/consultapedidovendedor');
  };

  const renderTablePrimeReact = useMemo(() => (
    <div className="datatable-produtos">
      <div className="card">
        <DataTable
          id="TabelaProdutos"
          value={dataTableProdutos}
          size="small"
          stripedRows
          responsiveLayout="scroll"
          selectionMode="single"
          dataKey="id"
          paginator
          rows={10}
          emptyMessage="Nenhum registro a ser exibido"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="{currentPage} de {totalPages}"
          rowsPerPageOptions={[10, 25, 50]}
        >
          <Column field="numItemPedido" header="id" style={{ width: '5%' }}></Column>
          <Column field="label" header="Descrição" sortable style={{ width: '50%' }}></Column>
          <Column field="valorCaixa" header="Valor cx" style={{ width: '10%' }}></Column>
          <Column field="qtdePorCaixa" header="Qtde por cx" style={{ width: '10%' }}></Column>
          <Column field="valorUnitario" header="Valor un"></Column>
          <Column field="qtdePedido" header="Pedido(CX)" style={{ width: '10%' }}></Column>
          <Column field="subTotal" header="SubTotal" style={{ width: '10%' }}></Column>
        </DataTable>
      </div>
    </div>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [dataTableProdutos]);

  const titleCard = `Visualização de Pedido Número ${idPedido}`;

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card title={titleCard}>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Razão Social
              </Input155px>
              <InputText
                maxLength={50}
                value={razaoSocial}
                onChange={e => setRazaoSocial(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Data
              </Input100px>
              <Calendar
                dateFormat="dd/mm/yy"
                id="data"
                value={data}
                onChange={e => setData(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Total R$
              </Input100px>
              <InputText
                id="inputPedido"
                maxLength={50}
                value={totalPedido}
                onChange={(e) => setTotalPedido(e.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Descrição
              </Input155px>
              <InputText
                id="inputDescricao"
                maxLength={50}
                value={descricao}
                onChange={e => setDescricao(e.target.value)}
              />
            </div>
          </div>
        </div>
        {renderTablePrimeReact}
      </Card>
      <div className="col-12" style={{ textAlign: 'right' }}>
        <IconButton titulo="Voltar" mensagem="Voltar" btnStyle="success" onClick={handleCancel} icon="chevron-left" />
      </div>
    </div>
  );
}
