import React from 'react';

import { Column } from 'primereact/column';

import { formataCnpjCpf, formataData, formataDataReferencia } from '../util/diversos';

const formatCurrency = (value) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

function chavePorValor(obj, val) {
  let _valueReturn = null;

  // eslint-disable-next-line consistent-return
  Object.keys(obj).forEach((item) => {
    if (item === val) {
      _valueReturn = obj[item];
      return _valueReturn;
    }
  });

  return _valueReturn;
}

const formataBoleano = (valorBoleano) => {
  if (valorBoleano === null || valorBoleano <= 0) {
    return 'Não';
  } else {
    return 'Sim';
  }
};

const onMask = (col) => {
  if (col.boleano === true) {
    return (rowData) => formataBoleano(chavePorValor(rowData, col.field));
  }
  if (col.field === 'valor') {
    return (rowData) => formatCurrency(rowData.valor);
  }
  if (col.field === 'valorMensal') {
    return (rowData) => formatCurrency(rowData.valorMensal);
  }
  if (col.field === 'valorUnitario') {
    return (rowData) => formatCurrency(rowData.valorUnitario);
  }
  if (col.field === 'valorMensalComprador') {
    return (rowData) => formatCurrency(rowData.valorMensalComprador);
  }
  if (col.field === 'valorMensalVendedor') {
    return (rowData) => formatCurrency(rowData.valorMensalVendedor);
  }
  if (col.field === 'valorCaixa') {
    return (rowData) => formatCurrency(rowData.valorCaixa);
  }
  if (col.field === 'valorAtacado') {
    return (rowData) => formatCurrency(rowData.valorAtacado);
  }
  if (col.field === 'dataLicenca') {
    return (rowData) => (formataData(rowData.dataLicenca));
  }
  if (col.field === 'data') {
    return (rowData) => (formataData(rowData.data));
  }
  if (col.field === 'dataEnvio') {
    return (rowData) => (formataData(rowData.dataEnvio));
  }
  if (col.field === 'dataAbertura') {
    return (rowData) => (formataData(rowData.dataAbertura));
  }
  if (col.field === 'dataQuitacao') {
    return (rowData) => (formataData(rowData.dataQuitacao));
  }
  if (col.field === 'mesReferencia') {
    return (rowData) => (formataDataReferencia(rowData.mesReferencia));
  }
  if (col.field === 'dataFechamento') {
    return (rowData) => (formataData(rowData.dataFechamento));
  }
  if (col.field === 'dataConviteComprador') {
    return (rowData) => (formataData(rowData.dataConviteComprador));
  }
  if (col.field === 'cnpj') {
    return (rowData) => (formataCnpjCpf(rowData.cnpj));
  }
  if (col.field === 'ativo') {
    return (rowData) => (rowData.ativo ? 'Sim' : 'Não');
  }
  if (col.field === 'padrao') {
    return (rowData) => (rowData.padrao ? 'Sim' : 'Não');
  }

  return '';
};

export function fillColumns(dadosRecebidos) {
  const cols = dadosRecebidos.map(col => (
    <Column
      key={col.field}
      field={col.field}
      header={col.header}
      sortable={col.sort}
      body={onMask(col)}
    />
  ));

  return cols;
}
