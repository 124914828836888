/* eslint-disable camelcase */
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';

import { get, orderBy } from 'lodash';
import Card from '../../../components/card';
import Loading from '../../loading';
import axiosCoto from '../../../services/axiosCoto';

import 'react-toastify/dist/ReactToastify.css';
import { displayErrors } from '../../../util/diversos';

import { fillColumns } from '../../../components/table';
import { Input155px, InputCampoBusca100px } from '../../../styles/inputGroup';
import IconButton from '../../../components/IconButton';

export default function ConsultaFinanceiro() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accessToken = useSelector((state) => state.auth.user.shipayAccessToken.body);

  const [isLoading, setIsLoading] = useState(false);
  const [euConcordo, setEuConcordo] = useState();
  const [token, setToken] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showConfirmQuitacaoDialog, setShowConfirmQuitacaoDialog] = useState(false);
  const [showConfirmReembolsoDialog, setShowConfirmReembolsoDialog] = useState(false);

  const [dataTableList, setDataTableList] = useState([]);
  const [selectedItens, setSelectedItens] = useState(null);
  const [findField, setFindField] = useState('');
  const [sortField, setSortField] = useState('email');
  const [sortOrder, setSortOrder] = useState(null);

  const [dataTableColumn] = useState([
    { field: 'id', header: 'id ', sort: true },
    { field: 'idUsuario', header: 'Usuário id ', sort: true },
    { field: 'email', header: 'email', sort: true },
    { field: 'orderId', header: 'Order Id', sort: true },
    { field: 'dataQuitacao', header: 'Data Quitação', sort: false },
    { field: 'status', header: 'Status', sort: true },
    { field: 'valor', header: 'Valor', sort: true },
    { field: 'dataLicenca', header: 'Data Licenca', sort: false },
  ]);

  async function getData(searchFilter) {
    try {
      setIsLoading(true);

      let params = '/financeiro';
      if (searchFilter && searchFilter.findField !== '') {
        params = `${params}?${sortField}=${searchFilter.findField}`;
      }

      let response = '';
      await axiosCoto.get(params).then((res) => {
        response = res.data;
      });

      setDataTableList(response);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  /* const handleDelete = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      setShowConfirmDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para exclusão');
    }
  }; */

  const handleFind = () => {
    const searchFilter = {
      findField,
    };

    setFindField('');

    getData(searchFilter);
  };

  async function getAutenticacao() {
    const json = JSON.parse(accessToken);
    setToken(json.access_token);
  }

  useEffect(() => {
    getData('');

    getAutenticacao();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleRegister = () => {
    navigate('/cadastrofinanceiro');
  };

  /* const handleGerarFinanceiro = () => {
    navigate('/gerarfinanceiro');
  }; */

  const getOrderStatus = async () => {
    const shippingData = {
      access_token: token,
    };
    const order_id = get(selectedItens, 'orderId', 0);
    const responseOrder = await axiosCoto.post(`/shipay/orderstatus/${order_id}`, shippingData);
    return responseOrder;
  };

  const handleReembolso = (e) => {
    e.preventDefault();

    getOrderStatus().then(response => {
      const json = JSON.parse(response.data.body);
      if (json.status !== 'approved') {
        toast.info('Somente Pagamento aprovado pode ser reembolsado.');
      } else {
        const id = get(selectedItens, 'id', 0);
        if (id > 0) {
          setShowConfirmReembolsoDialog(true);
        } else {
          toast.warning('Selecione um registro para reembolso.');
        }
      }
    }).catch(() => {
      toast.info('Não foi possível consultar o status do pagamento na plataforma de pagamento.');
    });
  };

  /* const handleQuitar = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);
    const dataQuitacao = get(selectedItens, 'dataQuitacao', 0);

    if (id > 0 && !dataQuitacao) {
      setShowConfirmQuitacaoDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para quitação em aberto');
    }
  }; */

  const confirmQuitacao = async () => {
    try {
      setIsLoading(true);

      const id = get(selectedItens, 'id', 0);
      const idUsuarioFinanceiro = get(selectedItens, 'idUsuario', 0);
      const mesReferencia = get(selectedItens, 'mesReferencia', 0);
      const dataQuitacao = new Date();
      const valor = get(selectedItens, 'valor', 0);
      const razaoSocial = get(selectedItens, 'razaoSocial', 0);

      const shippingData = {
        idUsuario: idUsuarioFinanceiro,
        mesReferencia,
        dataQuitacao,
        valor,
        razaoSocial,
      };

      if (id > 0) {
        await axiosCoto.put(`/financeiro/${id}`, shippingData);
        await axiosCoto.put(`/usuario/alterardatalicenca/${idUsuarioFinanceiro}`);
        setShowConfirmQuitacaoDialog(false);
        toast.success('Finança quitada com sucesso.');
        getData();
      }

      navigate('/consultafinanceiro');
    } catch (err) {
      const errors = get(err.response.data, 'errors', []);
      if (errors.length > 0) {
        errors.map(error => toast.error(error));
      } else {
        toast.error('Finança já cadastrada para o mês referencia e usuario.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  /* const handleEdit = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      navigate(`/cadastrofinanceiro/${id}`);
    } else {
      toast.warning('Por favor, selecione o registro para edição');
    }
  }; */

  const confirmDelete = async () => {
    setShowConfirmDialog(false);

    try {
      setIsLoading(true);

      const idExclusao = get(selectedItens, 'id', 0);

      if (idExclusao > 0) {
        await axiosCoto.delete(`/financeiro/${idExclusao}`);

        const _itens = dataTableList.filter(val => val.id !== idExclusao);
        setDataTableList(_itens);

        toast.success('Registro excluído com sucesso');
      }
    } catch (err) {
      const errors = get(err.response.data, 'errors', []);
      if (errors.length > 0) {
        errors.map(error => toast.error(error));
      } else {
        toast.error('Não foi possível excluir o registro');
      }
    } finally {
      setIsLoading(false);
    }
  };

  async function atualizarFinanceiro(id, status) {
    const response = await axiosCoto.put(`/financeiro/alterarstatus/${id}/${status}`);
    return response;
  }

  const handleConsultaStatus = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      const id = get(selectedItens, 'id', 0);
      const orderId = get(selectedItens, 'orderId', '');

      if (id > 0) {
        const shippingData = {
          access_token: token,
        };

        const order_id = orderId;
        const responseOrder = await axiosCoto.post(`/shipay/orderstatus/${order_id}`, shippingData);
        const json = JSON.parse(responseOrder.data.body);

        if (json.status === 'approved') {
          toast.info('Aprovado');
          atualizarFinanceiro(id, 'Aprovado');
        }
        if (json.status === 'expired') {
          toast.info('Pagamento expirado.');
          confirmDelete();
        }
        if (json.status === 'pending') {
          toast.info('Aguardando pagamento pelo cliente.');
        }
        if (json.status === 'refund_pending') {
          toast.info('Aguardando aprovação');
        }
        if (json.status === 'refunded') {
          atualizarFinanceiro(id, 'Reembolsado');
          getData();
          toast.info('Reembolsado');
        }
      }
    } catch (err) {
      const errors = get(err.response.data, 'errors', []);
      if (errors.length > 0) {
        errors.map(error => toast.error(error));
      } else {
        toast.error('Não foi possível reembolsar');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  const confirmReembolso = async () => {
    if (euConcordo.toUpperCase() !== 'EU CONCORDO' || euConcordo == null || euConcordo === '') {
      toast.success('Palavra chave não digitada para exclusão.');
      return;
    }

    setShowConfirmReembolsoDialog(false);

    try {
      setIsLoading(true);

      const id = get(selectedItens, 'id', 0);

      if (id > 0) {
        const shippingData = {
          access_token: token,
        };

        await axiosCoto.post(`/shipay/reembolso/${id}`, shippingData).then(
          response => {
            const json = JSON.parse(response.data.body);

            if (json.status === 'refunded') {
              atualizarFinanceiro(id, 'Reembolsado');
              toast.success('Reembolso concluído com sucesso');
              getData('');
            }

            if (json.status === 'refund_pending') {
              atualizarFinanceiro(id, 'Reembolso pendente');
              toast.success('Reembolso aguardando confirmacao da carteira digital');
              getData('');
            } else {
              toast.info('Não foi possível efetuar o reembolso. Tente mais tarde.');
            }
          },
        ).catch(() => {
          toast.warning('Não foi possível efetuar o Reembolso');
        });
      }
    } catch (err) {
      const errors = get(err.response.data, 'errors', []);
      if (errors.length > 0) {
        errors.map(error => toast.error(error));
      } else {
        toast.error('Não foi possível reembolsar');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const cancelDelete = () => {
    setShowConfirmDialog(false);
  };

  const cancelReembolso = () => {
    setShowConfirmReembolsoDialog(false);
  };

  const cancelQuitacao = () => {
    setShowConfirmQuitacaoDialog(false);
  };

  const confirmDialogFooter = (
    <div>
      <IconButton titulo="Sim" mensagem="Sim" btnStyle="primary" onClick={confirmDelete} icon="check" />
      <IconButton titulo="Não" mensagem="Não" btnStyle="danger" onClick={cancelDelete} icon="check" />
    </div>
  );

  const confirmDialogQuitacaoFooter = (
    <div>
      <IconButton titulo="Sim" mensagem="Sim" btnStyle="primary" onClick={confirmQuitacao} icon="check" />
      <IconButton titulo="Não" mensagem="Não" btnStyle="danger" onClick={cancelQuitacao} icon="check" />
    </div>
  );

  const confirmDialogReembolsoFooter = (
    <div>
      <IconButton titulo="Sim" mensagem="Sim" btnStyle="primary" onClick={confirmReembolso} icon="check" />
      <IconButton titulo="Não" mensagem="Não" btnStyle="danger" onClick={cancelReembolso} icon="times" />
    </div>
  );

  const renderTablePrimeReact = useMemo(() => (
    <div className="datatable-financeiro">
      <div className="card">
        <DataTable
          value={dataTableList}
          size="small"
          stripedRows
          sortField={sortField}
          setOrder={sortOrder}
          onSort={onSort}
          responsiveLayout="scroll"
          selectionMode="single"
          selection={selectedItens}
          onSelectionChange={e => setSelectedItens(e.value)}
          selectableRows
          dataKey="id"
          paginator
          rows={10}
          emptyMessage="Nenhum registro a ser exibido"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="{currentPage} de {totalPages}"
          rowsPerPageOptions={[10, 25, 50]}
        >
          {fillColumns(dataTableColumn)}
        </DataTable>
      </div>
    </div>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [dataTableList, selectedItens]);

  // <IconButton mensagem="Editar" btnStyle="success" onClick={handleEdit} icon="pencil" />
  // <IconButton mensagem="Quitar" btnStyle="info" onClick={handleQuitar} icon="money-bill" />

  return (
    <>
      <Loading isLoading={isLoading} />
      <Card title="Financeiro">
        <div className="row align-items-center">
          <div className="col-lg-8">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Buscar por:
              </Input155px>
              <InputCampoBusca100px className="p-inputgroup-addon">
                {dataTableColumn.find(element => element.field === sortField).header}
              </InputCampoBusca100px>
              <InputText
                maxLength={250}
                value={findField}
                onChange={e => setFindField(e.target.value)}
              />
              &nbsp; &nbsp;
              <IconButton titulo="" mensagem="Buscar" btnStyle="success" onClick={handleFind} icon="search" />
            </div>
          </div>
          <div className="col-4" style={{ textAlign: 'right' }}>
            <IconButton mensagem="Cadastrar" btnStyle="primary" onClick={handleRegister} icon="plus" />
            <IconButton mensagem="Consultar" btnStyle="secondary" onClick={handleConsultaStatus} icon="eye" />
            <IconButton mensagem="Reembolso" btnStyle="dark" onClick={handleReembolso} icon="minus-circle" />
          </div>
        </div>
        <br />
        {renderTablePrimeReact}
        <Dialog
          header="Confirmação"
          visible={showConfirmDialog}
          style={{ width: '50vw' }}
          footer={confirmDialogFooter}
          onHide={() => setShowConfirmDialog(false)}
        >
          <p>
            Excluir o registro?
          </p>
        </Dialog>

        <Dialog
          header="Confirmar Quitação"
          visible={showConfirmQuitacaoDialog}
          style={{ width: '50vw' }}
          footer={confirmDialogQuitacaoFooter}
          onHide={() => setShowConfirmQuitacaoDialog(false)}
        >
          <p>
            Excluir o registro?
          </p>
        </Dialog>

        <Dialog
          header="Reembolso"
          visible={showConfirmReembolsoDialog}
          style={{ width: '50vw' }}
          footer={confirmDialogReembolsoFooter}
          onHide={() => setShowConfirmDialog(false)}
        >
          <p>
            Digite: EU CONCORDO na caixa,
            <InputText
              maxLength={11}
              value={euConcordo}
              onChange={e => setEuConcordo(e.target.value)}
            />
            para a exclusão da cotação.

          </p>
        </Dialog>
      </Card>
    </>
  );
}
