import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { orderBy, get } from 'lodash';
import { toast } from 'react-toastify';
import haversine from 'haversine';

import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { AutoComplete } from 'primereact/autocomplete';
import IconButton from '../../../components/IconButton';

import 'react-toastify/dist/ReactToastify.css';
import { displayErrors } from '../../../util/diversos';

import { fillColumns } from '../../../components/table';
import { Input155px, InputCampoBusca100px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import Loading from '../../loading';
import axiosCoto from '../../../services/axiosCoto';

export default function ConsultaProduto() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const idComprador = useSelector((state) => state.auth.user.id);
  const latitudeComprador = useSelector((state) => state.auth.user.latitude);
  const longitudeComprador = useSelector((state) => state.auth.user.longitude);

  const [isLoading, setIsLoading] = useState(false);
  const [km, setKm] = useState(200);
  const [listaMunicipios, setListaMunicipios] = useState([]);
  const cidadeComprador = useSelector((state) => state.auth.user.cidade);
  const [cidade, setCidade] = useState(cidadeComprador);
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [filteredMunicipios, setFilteredMunicipios] = useState(null);
  const [showConfirmDialogConvite, setShowConfirmDialogConvite] = useState(false);
  const [dataTableList, setDataTableList] = useState([]);
  const [selectedItens, setSelectedItens] = useState(null);
  const [findField, setFindField] = useState('');
  const [sortField, setSortField] = useState('email');
  const [sortOrder, setSortOrder] = useState(null);

  const [dataTableColumn] = useState([
    { field: 'razaoSocial', header: 'Nome', sort: true },
    { field: 'email', header: 'E-mail', sort: true },
    { field: 'telefone1', header: 'Telefone', sort: true },
    { field: 'cidade', header: 'Cidade', sort: true },
    { field: 'convite', header: 'Convite', sort: true },
  ]);

  async function getMunicipioInicial() {
    try {
      const municipioInicial = await axiosCoto.get(`/municipio/?descricao=${cidadeComprador}`);
      setCidade(municipioInicial.data[0].descricao);
      setLatitude(municipioInicial.data[0].latitude);
      setLongitude(municipioInicial.data[0].longitude);

      const municipioDropDown = [];
      municipioDropDown.push({
        label: municipioInicial.data[0].descricao,
        value: municipioInicial.data[0].descricao,
        codigo: municipioInicial.data[0].codigo,
        uf: municipioInicial.data[0].uf,
        latitude: municipioInicial.data[0].latitude,
        longitude: municipioInicial.data[0].longitude,
      });

      setFilteredMunicipios(municipioDropDown);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    }
  }

  async function getData(searchFilter) {
    try {
      setIsLoading(true);
      let params = `/usuario/?idComprador=${idComprador}`;

      if (searchFilter && searchFilter.findField !== '') {
        params = `${params}&${sortField}=${searchFilter.findField}`;
      }

      let response = '';
      await axiosCoto.get(params).then((res) => {
        response = res.data;
      }).catch(() => {
        toast.warning('Erro na conexão. Atualize a página ou faça o login novamente.');
      });

      let vinculos = '';
      await axiosCoto.get(`/vinculocompradorvendedor/?idComprador=${idComprador}`).then((res) => {
        vinculos = res.data;
      }).catch(() => {
        toast.warning('Erro na conexão. Atualize a página ou faça o login novamente.');
      });

      const encontreVinculo = function (lista, id) {
        const existe = lista.find((value) => value.id === id && (value.dataConviteComprador !== null || value.aceiteComprador !== true));
        return existe;
      };

      const listaVendedores = [];
      for (let x = 0; x < response.length; x++) {
        // calculo km usando latitude e longitude no change cidade.
        const start = {
          latitude: latitudeComprador,
          longitude: longitudeComprador,
        };
        const end = {
          latitude: response[x].latitude,
          longitude: response[x].longitude,
        };

        // distancia entre as cidades
        const distanciaEmKm = haversine(start, end, { unit: 'km' });

        if (haversine(start, end, { unit: 'km' }) >= 0) {
          if (response[x].raioAtuacao >= distanciaEmKm) {
            // verifica se vinculo existe e se ja enviou convite
            if (!encontreVinculo(vinculos.vendedores, response[x].id)) {
              listaVendedores.push(response[x]);
            }
          }
        }
      }

      setDataTableList(listaVendedores);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getData('');
    getMunicipioInicial();

    async function getMunicipios() {
      setIsLoading(true);

      const listDropdown = [];

      const { data: _municipios } = await axiosCoto.get('/municipio');

      _municipios.forEach(element => {
        listDropdown.push({
          label: element.descricao,
          value: element.descricao,
          codigo: element.codigo,
          uf: element.uf,
          latitude: element.latitude,
          longitude: element.longitude,
        });
      });

      setListaMunicipios(listDropdown);
      setIsLoading(false);
    }

    getMunicipios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleChangeCidade = (event) => {
    setCidade(event.value);
    setLatitude(get(event.value, 'latitude', ''));
    setLongitude(get(event.value, 'longitude', ''));
  };

  const searchMunicipios = (event) => {
    const listDropdown = [];
    listaMunicipios.forEach(element => {
      if (element.label.toLowerCase().startsWith(event.query.toLowerCase())) {
        listDropdown.push({
          label: element.label,
          value: element.value,
          codigo: element.codigo,
          uf: element.uf,
          latitude: element.latitude,
          longitude: element.longitude,
        });
      }
    });
    setFilteredMunicipios(listDropdown);
  };

  const handleFind = () => {
    const searchFilter = {
      findField,
      idComprador,
    };

    setFindField('');
    getData(searchFilter);
  };

  const handleFindVendedor = () => {
    const searchFilter = {
      findField,
      idComprador,
    };

    setFindField('');
    getData(searchFilter);
  };

  const handleConvidar = (e) => {
    e.preventDefault();
    const id = get(selectedItens, 'id', 0);
    const aguardoConvite = get(selectedItens, 'convite', '');
    if (id > 0 && aguardoConvite === '') {
      setShowConfirmDialogConvite(true);
    } else {
      toast.warning('Vendedor não selecionado ou aguardando confirmação do convite.');
    }
  };

  async function vincularRegistro() {
    let conviteComSucesso = false;
    let emailVendedor = '';
    try {
      setIsLoading(true);

      const idVendedor = get(selectedItens, 'id', 0);
      emailVendedor = get(selectedItens, 'email', '');
      const dataConviteComprador = new Date().toISOString();
      const aceiteComprador = true;
      const shippingData = {
        idVendedor,
        idComprador,
        dataConviteComprador,
        aceiteComprador,
      };

      if (idVendedor > 0) {
        await axiosCoto.post('/vinculocompradorvendedor/', shippingData);
        toast.success('Convite enviado ao Vendedor com sucesso.');
        setShowConfirmDialogConvite(false);
      }

      conviteComSucesso = true;
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }

    if (conviteComSucesso) {
      await axiosCoto.put(`/vinculocompradorvendedor/enviaremailconvite/${emailVendedor}`);
    }
  }

  const cancelConvite = () => {
    setShowConfirmDialogConvite(false);
  };

  const confirmConvite = (e) => {
    e.preventDefault();
    const idVendedor = get(selectedItens, 'id', 0);
    if (idVendedor > 0) {
      vincularRegistro();
    } else {
      toast.warning('Por favor, selecione o registro para edição.');
    }
  };

  const handleCancel = () => {
    navigate('/consultavendedor');
  };

  const confirmDialogFooterConvite = (
    <div>
      <IconButton titulo="Sim" mensagem="Sim" btnStyle="primary" onClick={confirmConvite} icon="check" />
      <IconButton titulo="Não" mensagem="Não" btnStyle="primary" onClick={cancelConvite} icon="times" />
    </div>
  );

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  const renderTablePrimeReact = useMemo(() => (
    <div className="datatable-vendedores">
      <div className="card">
        <DataTable
          value={dataTableList}
          size="small"
          stripedRows
          sortField={sortField}
          setOrder={sortOrder}
          onSort={onSort}
          responsiveLayout="scroll"
          selectionMode="single"
          selection={selectedItens}
          onSelectionChange={e => setSelectedItens(e.value)}
          selectableRows
          dataKey="id"
          paginator
          rows={10}
          emptyMessage="Nenhum registro a ser exibido"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="{currentPage} de {totalPages}"
          rowsPerPageOptions={[10, 25, 50]}
        >
          {fillColumns(dataTableColumn)}
        </DataTable>
      </div>
    </div>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [dataTableList, selectedItens]);

  return (
    <>
      <Loading isLoading={isLoading} />
      <Card title="Convidar Vendedores">
        <div>
          <div className="row align-items-center">
            <div className="col-7">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Cidade
                </Input155px>
                <AutoComplete
                  autoFocus
                  value={cidade}
                  maxLength={40}
                  suggestions={filteredMunicipios}
                  completeMethod={searchMunicipios}
                  field="label"
                  dropdown
                  forceSelection
                  onChange={(e) => handleChangeCidade(e)}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon" title="Área de atendimento dos vendedores.">
                  Raio em KM
                </Input155px>
                <InputText
                  maxLength={3}
                  value={km}
                  onChange={e => setKm(e.target.value)}
                />
                <IconButton titulo="" mensagem="Buscar" btnStyle="primary" onClick={handleFind} icon="search" />
              </div>
            </div>
            <div className="col-1" style={{ textAlign: 'right' }}>
              <IconButton titulo="" mensagem="Convidar" btnStyle="primary" onClick={handleConvidar} icon="plus" />
            </div>
          </div>
        </div>
        <br />
        <hr />
        <div>
          <div className="row align-items-center">
            <div className="col-9">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Buscar por:
                </Input155px>
                <InputCampoBusca100px className="p-inputgroup-addon">
                  {dataTableColumn.find(element => element.field === sortField).header}
                </InputCampoBusca100px>
                <InputText
                  autoFocus
                  maxLength={250}
                  value={findField}
                  onChange={e => setFindField(e.target.value)}
                />
                <IconButton titulo="" mensagem="Buscar" btnStyle="success" onClick={handleFindVendedor} icon="search" />
              </div>
            </div>
          </div>
        </div>
        <br />

        {renderTablePrimeReact}

        <Dialog
          header="Confirmação"
          visible={showConfirmDialogConvite}
          style={{ width: '50vw' }}
          footer={confirmDialogFooterConvite}
          onHide={() => setShowConfirmDialogConvite(false)}
        >
          <p>
            Convidar vendedor?
          </p>
        </Dialog>

        <div className="col-12" style={{ textAlign: 'right' }}>
          <IconButton titulo="Voltar" mensagem="Voltar" btnStyle="success" onClick={handleCancel} icon="chevron-left" />
        </div>
      </Card>
    </>
  );
}
