import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { get, orderBy } from 'lodash';

import 'react-toastify/dist/ReactToastify.css';
import { displayErrors } from '../../../util/diversos';

import { fillColumns } from '../../../components/table';
import { Input155px, InputCampoBusca100px } from '../../../styles/inputGroup';
import IconButton from '../../../components/IconButton';

import Card from '../../../components/card';
import Loading from '../../loading';
import axiosCoto from '../../../services/axiosCoto';

export default function ConsultaProduto() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const idUsuario = useSelector((state) => state.auth.user.id);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataTableListaProdutos, setDataTableListaProdutos] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedItens, setSelectedItens] = useState(null);
  const [findField, setFindField] = useState('');
  const [sortField, setSortField] = useState('descricao');
  const [sortOrder, setSortOrder] = useState(null);
  const [dataTableColumn] = useState([
    { field: 'descricao', header: 'Descricão', sort: true },
  ]);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  async function getData(searchFilter, page = 0, size = lazyParams.rows) {
    try {
      setIsLoading(true);

      let params = `/produto/search/?idUsuario=${idUsuario}`;
      if (searchFilter && searchFilter.findField !== '') {
        params = `${params}&${sortField}=${searchFilter.findField}`;
      }
      params = `${params}&page=${page}&size=${size}`;

      const response = await axiosCoto.get(params);
      setTotalRecords(response.data.totalElements);
      setDataTableListaProdutos(response.data.content);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const handleFind = () => {
    const searchFilter = {
      findField,
      idUsuario,
    };

    const _lazyParams = { ...lazyParams, first: 0, page: 0 };

    setLazyParams(_lazyParams);

    setFindField('');

    getData(searchFilter, 0, lazyParams.rows);
  };

  const onPage = (event) => {
    setLazyParams(event);

    const searchFilter = { findField, idUsuario };

    getData(searchFilter, event.page, event.rows);
  };

  useEffect(() => {
    getData('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleRegister = () => {
    navigate('/cadastroproduto');
  };

  const handleEdit = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      navigate(`/cadastroproduto/${id}`);
    } else {
      toast.warning('Por favor, selecione o registro para edição');
    }
  };

  const handleProdutoPedidos = async (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);

    try {
      setIsLoading(true);

      const numRespostas = await axiosCoto.get(`/pedidoprodutoresposta/search/?idProduto=${id}`);
      if (numRespostas.data.content.length <= 0) {
        toast.info('Este produto não contêm histórico para ser visualizado.');
        return;
      }

      if (id > 0) {
        navigate(`/consultaprodutopedido/${id}`);
      } else {
        toast.warning('Por favor, selecione o registro para visualização');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      setShowConfirmDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para exclusão');
    }
  };

  const confirmDelete = async () => {
    setShowConfirmDialog(false);

    try {
      setIsLoading(true);

      const idExclusao = get(selectedItens, 'id', 0);
      if (idExclusao > 0) {
        await axiosCoto.delete(`/produto/${idExclusao}`);
        const _itens = dataTableListaProdutos.filter(val => val.id !== idExclusao);
        setDataTableListaProdutos(_itens);
        toast.success('Registro excluído com sucesso');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível excluir o registro');
    } finally {
      setIsLoading(false);
    }
  };

  const cancelDelete = () => {
    setShowConfirmDialog(false);
  };

  const confirmDialogFooter = (
    <>
      <IconButton titulo="Sim" mensagem="Sim" btnStyle="primary" onClick={confirmDelete} icon="plus" />
      <IconButton titulo="Não" mensagem="Não" btnStyle="danger" onClick={cancelDelete} icon="times" />
    </>
  );

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableListaProdutos(orderBy(dataTableListaProdutos, [sortField], [sortOrder]));
  };

  const renderTablePrimeReact = useMemo(() => (
    <div className="datatable-produtos">
      <div className="card">
        <DataTable
          value={dataTableListaProdutos}
          size="small"
          stripedRows
          sortField={sortField}
          setOrder={sortOrder}
          onSort={onSort}
          responsiveLayout="scroll"
          selectionMode="single"
          selection={selectedItens}
          onSelectionChange={e => setSelectedItens(e.value)}
          selectableRows
          dataKey="id"
          paginator
          lazy
          first={lazyParams.first}
          rows={lazyParams.rows}
          totalRecords={totalRecords}
          onPage={onPage}
          emptyMessage="Nenhum registro a ser exibido"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="{currentPage} de {totalPages}"
          rowsPerPageOptions={[10, 25, 50]}
        >
          {fillColumns(dataTableColumn)}
        </DataTable>
      </div>
    </div>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [dataTableListaProdutos, selectedItens]);

  return (
    <>
      <Loading isLoading={isLoading} />
      <Card title="Produtos">
        <div className="row align-items-center">
          <div className="col-9">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Buscar por:
              </Input155px>
              <InputCampoBusca100px className="p-inputgroup-addon">
                {dataTableColumn.find(element => element.field === sortField).header}
              </InputCampoBusca100px>
              <InputText
                autoFocus
                maxLength={250}
                value={findField}
                onChange={e => setFindField(e.target.value)}
              />
              &nbsp; &nbsp;
              <IconButton titulo="" mensagem="Buscar" btnStyle="success" onClick={handleFind} icon="search" />
            </div>
          </div>
          <div className="col-3" style={{ textAlign: 'right' }}>
            <IconButton mensagem="Cadastrar" btnStyle="primary" onClick={handleRegister} icon="plus" />
            <IconButton mensagem="Editar" btnStyle="success" onClick={handleEdit} icon="pencil" />
            <IconButton mensagem="Visualizar histórico" btnStyle="dark" onClick={handleProdutoPedidos} icon="eye" />
            <IconButton mensagem="Excluir" btnStyle="danger" onClick={handleDelete} icon="trash" />
          </div>
        </div>
        <br />

        {renderTablePrimeReact}

        <Dialog
          header="Confirmação"
          visible={showConfirmDialog}
          style={{ width: '50vw' }}
          footer={confirmDialogFooter}
          onHide={() => setShowConfirmDialog(false)}
        >
          <p>
            Excluir o registro?
          </p>
        </Dialog>
      </Card>
    </>
  );
}
