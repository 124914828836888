import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputText } from 'primereact/inputtext';

import Card from '../../../components/card';

import Loading from '../../loading';
import { Input155px } from '../../../styles/inputGroup';
import axiosCoto from '../../../services/axiosCoto';
import IconButton from '../../../components/IconButton';

export default function CadastroBilhete() {
  const navigate = useNavigate();
  const tipoUsuarioLogado = useSelector((state) => state.auth.user.tipoUsuario);
  const idUsuario = useSelector((state) => state.auth.user.id);

  const id = get(useParams(), 'id', 0);

  const [descricao, setDescricao] = useState();
  const [resposta, setResposta] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosCoto.get(`/bilhete/${id}`);

        setDescricao(data.descricao);
        setResposta(data.resposta);
      } catch (err) {
        toast.error('Não foi possível recuperar os dados do servidor.');

        if (tipoUsuarioLogado === 'ADMINISTRADOR') {
          navigate('/consultabilheteadministrador');
        }
        if (tipoUsuarioLogado === 'COMPRADOR') {
          navigate('/consultabilhetecomprador');
        }
        if (tipoUsuarioLogado === 'VENDEDOR') {
          navigate('/consultabilhetevendedor');
        }
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  async function salvarBilhete() {
    try {
      setIsLoading(true);

      const shippingData = {
        descricao,
        resposta,
        idUsuario,
      };

      if (id > 0) {
        await axiosCoto.put(`/bilhete/${id}`, shippingData);
        toast.success('Sugestão ou Crítica  atualizado com sucesso.');
        if (tipoUsuarioLogado === 'ADMINISTRADOR') {
          navigate('/consultabilheteadministrador');
        }
      } else {
        await axiosCoto.post('/bilhete', shippingData);
        toast.success('Sugestão ou Crítica cadastrado com sucesso.');
        if (tipoUsuarioLogado === 'ADMINISTRADOR') {
          navigate('/consultabilheteadministrador');
        }
        if (tipoUsuarioLogado === 'COMPRADOR') {
          navigate('/consultabilhetecomprador');
        }
        if (tipoUsuarioLogado === 'VENDEDOR') {
          navigate('/consultabilhetevendedor');
        }
      }
    } catch (err) {
      const errors = get(err.response.data, 'errors', []);
      if (errors.length > 0) {
        errors.map(error => toast.error(error));
      } else {
        toast.error('Sugestão ou Crítica não pode ser alterada.');
      }
    } finally {
      setIsLoading(false);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;
    if (descricao === null) {
      formErros = true;
      toast.error('Descrição não pode estar em branco.');
    }
    if (formErros) return;

    salvarBilhete();
  };

  const handleVoltar = (e) => {
    e.preventDefault();
    // eslint-disable-next-line no-restricted-globals
    history.back();
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <Card title="Informe sua dica, crítica ou sugestão.">
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Descrição (250 letras)*
              </Input155px>
              <InputText
                autoFocus
                maxLength={250}
                value={descricao}
                onChange={e => setDescricao(e.target.value)}
              />
            </div>
          </div>
          {(tipoUsuarioLogado === 'ADMINISTRADOR')
            ? (
              <div className="row form-elo">
                <div className="col-12">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Resposta
                    </Input155px>
                    <InputText
                      maxLength={250}
                      value={resposta}
                      onChange={e => setResposta(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            ) : ('')}
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ textAlign: 'right' }}>
            <IconButton titulo="Salvar" mensagem="Salvar" btnStyle="success" onClick={handleSubmit} icon="check" />
            <IconButton titulo="Cancelar" mensagem="Cancelar" btnStyle="danger" onClick={handleVoltar} icon="times" />
          </div>
        </div>
      </Card>
    </>
  );
}
