import React from 'react';

export default function Card(props) {
  return (
    <div className="card mb-4">
      { props.title ? (<h3 className="card-header"
      style={{ backgroundImage: "linear-gradient(to right,#eee, #CCF2F1)",
      fontfamily: 'Droid serif',
      fontsize: '36px',
      fontweight: '400',
      fontstyle: 'italic',
      lineheight: '44px',
      margin: '0 0 12px',
      textalign: 'center', }}>{props.title}</h3>) : ('') }
      <div className="card-body">
        {props.children}
      </div>
    </div>
  );
}
