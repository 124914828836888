import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputText } from 'primereact/inputtext';
import Card from '../../../components/card';
import { displayErrors } from '../../../util/diversos';

import Loading from '../../loading';
import { Input155px } from '../../../styles/inputGroup';
import axiosCoto from '../../../services/axiosCoto';
import IconButton from '../../../components/IconButton';

export default function CadastroProduto() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const idUsuario = useSelector((state) => state.auth.user.id);
  const id = get(useParams(), 'id', 0);

  const [descricao, setDescricao] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosCoto.get(`/produto/${id}`);

        if (data.idUsuario !== idUsuario) {
          toast.error('Acesso Negado');
          navigate('/consultaproduto');
        }

        setDescricao(data.descricao.trim());
        setIsLoading(false);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [dispatch, id, idUsuario, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const shippingData = {
        descricao,
        idUsuario,
      };

      if (id > 0) {
        await axiosCoto.put(`/produto/${id}`, shippingData);
        toast.success('Produto atualizado com sucesso.');
      } else {
        await axiosCoto.post('/produto', shippingData);
        toast.success('Produto cadastrado com sucesso.');
      }

      navigate('/consultaproduto');
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    navigate('/consultaproduto');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (descricao.length < 3 || descricao.length > 100) {
      formErros = true;
      toast.error('Descrição deve conter de 3 à 100 caracteres.');
    }

    if (formErros) return;

    salvarRegistro();
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <Card title="Manutenção de Produtos">
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Descrição *
              </Input155px>
              <InputText
                autoFocus
                maxLength={100}
                type="text"
                name="descricao"
                value={descricao}
                onChange={e => setDescricao(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ textAlign: 'right' }}>
            <IconButton titulo="Salvar" mensagem="Salvar" btnStyle="success" onClick={handleSubmit} icon="check" />
            <IconButton titulo="Cancelar" mensagem="Cancelar" btnStyle="danger" onClick={handleCancel} icon="times" />
          </div>
        </div>
      </Card>
    </>
  );
}
