import styled from 'styled-components';

export const Input155px = styled.span`
  background-color: #647283;
  color: #fff;
  width: calc(100vw / 9) !important;
`;

export const Input200px = styled.span`
  background-color: #647283;
  color: #fff;
  width: calc(100vw / 7) !important;
`;


export const Input250px = styled.span`
  background-color: #647283;
  color: #fff;
  width: calc(100vw / 5) !important;
`;

export const Input300px = styled.span`
  background-color: #647283;
  color: #fff;
  width: calc(100vw / 4) !important;
`;

export const Input100px = styled.span`
  background-color: #647283;
  color: #fff;
  width: calc(100vw / 13) !important;
`;

export const Input80px = styled.span`
  background-color: #647283;
  color: #fff;
  width: calc(100vw / 20) !important;
`;

export const Input50px = styled.span`
  background-color: #647283;
  color: #fff;
  width: calc(100vw / 30) !important;
`;

export const InputBranco100px = styled.span`
  background-color: white !important;
  color: black !important;
  width: calc(100vw / 13) !important;
`;

export const InputCampoBusca155px = styled.span`
  background-color: #f2f2f2 !important;
  color: black !important;
  width: calc(100vw / 9) !important;
`;

export const InputCampoBusca100px = styled.span`
  background-color: #f2f2f2 !important;
  color: black !important;
  width: calc(100vw / 13) !important;
`;
