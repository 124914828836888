import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import * as actions from './actions';
import * as types from '../types';
import axiosCoto from '../../../services/axiosCoto';

function* loginRequest({ payload }) {
  try {
    const response = yield call(axiosCoto.post, '/usuario/autenticar', payload);
    yield put(actions.loginSucess({ ...response.data }));
    if (!response.data.user.ativo) {
      toast.error('Usuário bloqueado. Favor entrar em contato com o administrador do site.');
      yield put(actions.loginFailure());
      return;
    }
    axiosCoto.defaults.headers.Authorization = `Bearer ${response.data.token}`;
  } catch (e) {
    toast.error('Usuário ou senha inválido');
    yield put(actions.loginFailure());
  }
}

function persistRehydrate({ payload }) {
  const token = get(payload, 'auth.token');
  if (!token) return;
  axiosCoto.defaults.headers.Authorization = `Bearer ${token}`;
}

export default all([
  takeLatest(types.LOGIN_REQUEST, loginRequest),
  takeLatest(types.PERSIST_REHYDRATE, persistRehydrate),
]);
